import React, { Component } from 'react'
import astyles from "./src/app/css/app.module.css"
import bstyles from "./src/app/css/uikit-custom.module.css"
import profilepic from './src/img/profile.jpg'
import Contact from './contact'


class aboutSection extends Component {

    state = {
        categories: '',
        skills: ''
    }




    render() {
        return (
            <div>
                <main className={`${astyles['yb-main-content']}`}>
                    {/* -- about section --> */}
                    <div id="about" className={`${astyles['yb-section']}`}>
                        <div className={`${astyles['yb-my-profile']}`}>
                            <div className={`${bstyles['uk-container']}`}>
                                <div className={`${bstyles['uk-grid-collapse']} ${bstyles['uk-child-width-expand@m']} ${bstyles['data-uk-grid']} row`} >
                                    <div>
                                        <div className={`${astyles['yb-my-profile-img']} ${bstyles['uk-background-cover']} ${bstyles['uk-background-norepeat']} ${bstyles['uk-background-top-center']}`}
                                            style={{ backgroundImage: `url(${this.props.shopBackgroundPicture || profilepic})` }} data-uk-img data-uk-lightbox>
                                            <a href="#" className={`${astyles['yb-my-intro']}`}>
                                                <span data-uk-icon="icon: play; ratio: 2"></span>
                                                <span className={`${astyles['yb-my-intro-text']}`}>Introduction</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`${astyles['yb-my-profile-desc']}`}>
                                            <div className={`${astyles['yb-section-point-wrapper']}`}>
                                                <div className={`${astyles['yb-section-point']} ${astyles['right']}  `}>
                                                    <sup>02</sup>
                                                    <span>About</span>
                                                </div>
                                            </div>
                                            <div className={`${astyles['yb-my-profile-desc-top']} `}>
                                                <h2 className={`${astyles['yb-section-title']} text-black`}>Hello There!</h2>
                                                <p className="text-black">
                                                    {this.props.description}
                                                </p>
                                            </div>
                                            <div className={`${astyles['yb-my-profile-desc-bottom']} ${astyles['yb-bg-soft']}`}>
                                                <ul className={`${astyles['yb-my-profile-desc-info']} ${bstyles['uk-list']}`}>
                                                    <li>
                                                        <span>Name</span> : {this.props.providerName}
                                                    </li>
                                                    <li>
                                                        <span>Phone</span> : {this.props.phoneNumber}
                                                    </li>
                                                    <li>
                                                        <span>Email</span> : get.someone@gmail.com
                                                     </li>
                                                    <li>
                                                        <span>Addresses</span> : {this.props.providerAddress}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${astyles['yb-border-top']}`}>

                            <div>
                                {this.props.filteredSkills && <div className={`${astyles['yb-box-services']} ${astyles['yb-box-services']} ${bstyles['uk-child-width-1-4@l']} ${bstyles['uk-child-width-1-2']} ${bstyles['uk-text-center']} ${bstyles['uk-grid-divider']} row `} >
                                    {this.props.filteredSkills.map((skill, id) => {
                                        return (
                                            <div className={`${astyles['yb-box-service']} col-md-4`} key={id}
                                                style={{ display: 'flex', justifyContent: 'center' }}
                                            >
                                                <span className={`${bstyles['uk-icon']} text-primary`}><i class="fas fa-book-open"></i></span>
                                                <h5 className=" text-nowrap" style={{ color: 'black' }}>{skill}</h5>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                }
                            </div>

                        </div>
                    </div>
                    {/* -- end about section --> */}
                    <Contact
                        providerName={this.props.providerName}
                        providerAddress={this.props.providerAddress}
                        phoneNumber={this.props.phoneNumber}
                        logo={this.props.logo}
                        shopColorButton={this.props.shopColorButton}
                    />
                </main>
                {/* !-- end main section --></div> */}
            </div>
        )
    }
}

export default aboutSection
