import React, { Component } from 'react'
import data from '../imageData'
import Women from "./images/women.jpg";
import Fashion from "./images/children.jpg"
import Men from "./images/men.jpg"


const prefix = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2F"

class Categories extends Component {


    state = {
        categories: '',
        services: '',
        images: data.images,
        url: ''
    }

    filterCategories = async () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        /* const values1 = values.slice(0, values.length)
        const values2 = values1.join(' ') */
        const newvalues = [].concat(...values)


        console.log(' the categories are: ', keys, ' and the services are: ', values)
        await this.setState({ categories: keys, services: newvalues });
        return

    }


    componentDidMount = async () => {
        if (this.props.servicesOffered) {
            console.log('services offered in Rave category component are: ', this.props.servicesOffered)
            await this.filterCategories();
            // this.getCategoryURLs();
        }
    }


    render() {
        return (
            <div className="site-section site-blocks-2">

                <div className="container">
                    {this.state.services &&
                        <div className="row justify-content-center" style={{ marginBottom: 50 }}>
                            <div className="col-md-7 site-section-heading text-center pt-4">
                                <h2 className='text-uppercase rave-title' style={{ color: 'black', fontFamily: "Montserrat, sans-serif", fontWeight: 'lighter' }}>Services</h2>
                            </div>
                        </div>}

                    {this.state.categories && <div className="row" style={{ textAlign: 'center', justifyContent: 'center', marginTop: -30 }}>
                        {this.state.services.map((service, id) => {

                            return (
                                <div key={id} className="row" style={{ width: '100%', marginBottom: 20 }} >
                                    <div class="col-md-6"
                                        style={{
                                            width: '100%',
                                            height: 400,
                                            backgroundColor: '#000',
                                            backgroundImage: `url(${this.state.images[service] || prefix + "Beauty%2FAccessories%2Faccessories-accessory-hat-1460838.jpg?alt=media&token=df7bff06-400d-47e6-9d01-dff638da1d38"})`,
                                            backgroundRepeat: 'no-repeat', backgroundSize: '110%', overflow: 'hidden',
                                            backgroundPosition: 'center center',
                                            backgroundSize:'cover'
                                        }}>
                                        <div style={{ width: '100%', background: 'rgba(0,0,0,0.5)' }}></div>
                                    </div>
                                    <div className="col-md-6 text-center "
                                        style={{
                                            background: `linear-gradient(to left, ${this.props.shopColorGradient1}, ${this.props.shopColorGradient2})` || 'linear-gradient(to right, rgba(12,0,255,0.8), rgba(250,16,83,0.8))',
                                            padding: 30,
                                            display:'flex', justifyContent:'center', alignItems:'center',
                                            height: 400,
                                        }}>
                                        <div className="" >
                                            <span className="align-middle">
                                                <h3 className='text-uppercase rave' style={{ fontFamily: "Helvetica, sans-serif", fontWeight: 'bold', alignSelf: 'center', }} >
                                                    {service}
                                                </h3>
                                            </span>
                                        </div>
                                    </div>
                                    {/*  <div class="col" style={{ background: 'linear-gradient(to right, rgba(12,0,255,0.8), rgba(250,16,83,0.8))' }}>
                                        <span className="text-uppercase" style={{ fontFamily: "Montserrat, sans-serif" }}>Collections</span>
                                        <h3 className='text-uppercase' >{service}</h3>
                                    </div> */}
                                </div>
                            )
                        })}
                    </div>}
                </div>
            </div>

        );
    }
}

export default Categories;





