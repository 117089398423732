import React, { Component } from 'react'
import Footer from '../components/layout/Footer'
//contexts contexts contexts///////////////////
import CartContext from '../contexts/context';
///////////////////////////////////////////////
class BankDetails extends Component {

    state = {
        cart: '',
        shopNamefromURL: '',
        hasCustomDomain: '',
        nameShop: '',
        shopCartBankAccount: {
            bankName: '',
            name: '',
            number: ''
        },
        initialURL: '',
        hasCustomDomain: '',
        shopData: '',
        shopCategory: '',
        url: '',
        defaultURLandValidShopnamePresent: '',
        shopNamefromURL: ''
    }




    fetchDataWithURL = async () => {

        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    url: `${this.state.initialURL}`
                })
            });
            const r2 = await r.json();

            if (r2.msg == 'SUCCESS') {
                // await this.setState({ hasCustomDomain: true })
                console.log("Data received from Home. Server data is: ", r2.msg);
                console.log("Server Data is: ", r2.data);
                console.log("Profile ", r2.data.profile);

                let shopData = r2.data
                let shopCartBankAccount = r2.data.shop.shopCartBankAccount
                this.setState({ hasCustomDomain: true, shopData, url: this.parseURLandReturnRelevantURL(), defaultURLandValidShopnamePresent: true, shopCartBankAccount })



            } else {
                console.log('no custom URL detected... ', r2.msg)
                this.fetchDataWithShopName()
                //this.setState({ hasCustomDomain: false, loading: false })

            }

            return

        } catch (err) {
            console.log("Error from firebase is: ", err);
            this.setState({ hasCustomDomain: false })

        }

    }

    fetchDataWithShopName = async () => {

        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    shopName: `${this.state.shopNamefromURL}`
                })
            });
            const r2 = await r.json();

            if (r2.msg == 'SUCCESS') {
                // await this.setState({ hasCustomDomain: true }) 
                console.log("Server Data is: ", r2.data);
                console.log("Profile ", r2.data.profile);


                let shopData = r2.data
                let shopCategory = r2.data.shop.shopCategory
                let shopCartBankAccount = r2.data.shop.shopCartBankAccount
                console.log("shopCategory is:  ", shopCategory)
                await this.setState({ defaultURLandValidShopnamePresent: true, shopData, shopCartBankAccount })

            } else {
                console.log('no valid shopName detected from URL... ', r2.msg)
                // this.setState({ loading: false, redirect: true })
            }

            return

        } catch (err) {
            console.log("Error from firebase is: ", err);
            // this.setState({ loading: false, redirect: true })

        }

    }


    parseURLandReturnRelevantURL = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            await this.setState({ initialURL: fullURLsubStrings[2], shopNamefromURL: fullURLsubStrings[3] })
            console.log('the shopnamefromURL in Home js is: ', this.state.shopNamefromURL);
            //await this.setState({ url: fullURLsubStrings[2] })*/
            // return fullURLsubStrings[2]; 
        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }




    componentDidMount = async () => {


         await this.parseURLandReturnRelevantURL()
         if ((this.state.initialURL === 'wakanda-market.com' || this.state.initialURL === 'www.wakanda-market.com' || this.state.initialURL === 'localhost:3000') && this.state.shopNamefromURL) {
             this.fetchDataWithShopName()
         }
         /////////////////// WAKANDA EDUCATION LOADING PARAMETERS /////////////////////
         else if ((this.state.initialURL === 'wakanda-education.com' || this.state.initialURL === 'www.wakanda-education.com' || this.state.initialURL === 'localhost:3000') && this.state.shopNamefromURL) {
             this.fetchDataWithShopName()
         } else {
             this.fetchDataWithURL()
         } 
    }

    /* render() {
        return (
            <CartContext.Consumer>
                {props => {
                    console.log('props passed via context are: ', props)
                    return (
                        <div style={{ flex: 1, backgroundColor: 'white', height: window.innerHeight, justifyContent: 'center', alignItems: 'center', paddingTop: 150, paddingBottom: 250 }}>
                            <main className='container' style={{ backgroundColor: '#ff4d4d', paddingBottom: 80, borderRadius: 30, border: '3px solid pink', margin: '0 auto', }}>
                                <h4 style={{ color: 'white', paddingTop: 80, textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Account Name:</span> {props.shopCartBankAccount.name ||   <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                                <h4 style={{ color: 'white', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Account Number:</span> {props.shopCartBankAccount.number || <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                                <h4 style={{ color: 'white', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Bank:</span> {props.shopCartBankAccount.bankName ||   <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                            </main>

                            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                                <Footer backgroundColor={'#b3b3b3'} color={'black'} nameShop={''} />
                            </div>

                        </div>

                    )
                }}
            </CartContext.Consumer>
        )
    } */
    render() {

        return (
            <div className='container' 
            style={{ display: "flex",
            minHeight: "100vh",
            flexDirection: "column", flex: 1, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', paddingTop: 150, paddingBottom: 250 }}>
                <main className='container' style={{ backgroundColor: '#ff4d4d', paddingBottom: 80, borderRadius: 30, border: '3px solid pink', margin: '0 auto', }}>
                    <h4 style={{ color: 'white', paddingTop: 80, textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Account Name:</span> {this.state.shopCartBankAccount.name || <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                    <h4 style={{ color: 'white', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Account Number:</span> {this.state.shopCartBankAccount.number || <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                    <h4 style={{ color: 'white', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Bank:</span> {this.state.shopCartBankAccount.bankName || <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                </main>

                <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    <Footer backgroundColor={'#b3b3b3'} color={'black'} nameShop={''} />
                </div>

            </div>

        )
    }
}


export default BankDetails;