import React, { Component } from 'react'; 


class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div className="site-section site-blocks-2" style={{ backgroundColor:'#F3F3F3'}}>

                <div className="container">
                  
                        <div className="row justify-content-center"> 
                                <h2 style={{ color: 'blue', fontWeight:'bold' }}>Gallery</h2>
                        </div>

                     <div className="row" style={{ textAlign: 'center', justifyContent: 'center', marginTop: 0 }}>
                        {this.props.images.map((item, id) => {
                           
                            return (
                                <div className="col-sm-6 col-md-2 mb-4 mb-lg-0 "  >
                                    <a className="block-2-item" href="#shop">
                                        <div key={id}
                                            style={{
                                                width: '100%', height: 200, backgroundColor: '#000', justifyContent: 'center', alignItems: 'center',
                                                backgroundImage: `url(${item.image})`,
                                                backgroundRepeat: 'no-repeat', backgroundSize: '100%', marginTop: 30, marginRight: 30, overflow: 'hidden', justifyContent: 'center'
                                            }}>
                                        </div>
                                    </a>
                                </div>
                            )
                        })


                        }
                    </div>
                </div>
            </div>

        );
    }
}

export default Gallery;