import React, { Component } from "react";
//import firebase from "../../Blog/Firebase";
import "./BlogPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Switch, Route, NavLink, Redirect, Link } from "react-router-dom";
import Post from "../Post/Post";
import FullBlogPost from "../FullBlogPost/FullBlogPost";



import config from '../../config/chatConfig'
import 'firebase/firestore';
const firebase = require('firebase') 

var db = firebase.firestore()




const Styles = {
  headerImage: {}
};
const blog = "blog";


export default class BlogPage extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      user: "",
      description: "",
      hasCustomDomain: '',
      initialURL: '',
      menu_class: "",
      selectedblogId: null,
      headerImage: "https://image.ibb.co/kYJK8x/showcase.jpg",
      profileImage: ""
    };
  }




  componentDidMount = async () => {
    //console.log("props", this.props.location.user, this.props.hasCustomDomain);
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
  }
    await this.getUserName();

    if (this.state.user === '') {
      this.fetchUserWithURL()
    } else {
      await this.getImageUrl();
    }

    console.log("vistors blog is", this.props.location.blogData);
    if (this.state.user) {
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Blogs")
        .where("author", "==", this.state.user)
        .onSnapshot(async collection => {
          if (collection.empty) {
            console.log("No matching documents");
            return;
          }
          const posts = [];
          collection.forEach(doc => {
            let items = doc.data();
            let fileID = doc.id;
            posts.push({
              author: items.author,
              title: items.title,
              content: items.content,
              imageUrl: items.imageUrl,
              fileId: fileID
            });
            // console.log("helllooooo...", posts);
          });
          this.setState({ posts });
        });
    } else {
      this.setState({ posts: this.props.location.blogData });
    }
  };



  getUserName = async () => {
    try {
      const fullURL = window.location.href.split('#')[0]
      const fullURLsubStrings = fullURL.split('/');
      this.setState({ initialURL: fullURLsubStrings[2] })
      console.log("full window URl from BlogPage component  ", fullURLsubStrings);
      if (this.props.location.user) {
        await this.setState({
          user: this.props.location.user,
          hasCustomDomain: this.props.location.hasCustomDomain
        });
      } else {
        await this.setState({
          user: (fullURLsubStrings[3] === 'blog') ? "" : fullURLsubStrings[3],
          hasCustomDomain: false
          //  shopNamefromURL: fullURLsubStrings[3]
        });
      }

      console.log("the user is: ", this.state.user);

    } catch (err) {
      console.log("issues with parsing URL ", err);
    }
  };



  fetchUserWithURL = async () => {
    console.log('attempting to fetch data with URL', this.state.initialURL)
    try {
      const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
        method: "POST",
        //mode: "no-cors",
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({
          url: `${this.state.initialURL}`
        })
      });
      const r2 = await r.json();

      if (r2.msg == 'SUCCESS') {
        // await this.setState({ hasCustomDomain: true })
        console.log("Data received from Home. Server data is: ", r2.msg);
        console.log("Server Data is: ", r2.data);
        console.log("Profile ", r2.data.profile);

        let user = r2.data.shop.shopName
        console.log('user from fetchdata with URL is: ', user)
        //await this.setState({ hasCustomDomain: true, shopData, url: this.parseURLandReturnRelevantURL(), defaultURLandValidShopnamePresent: true, theme, loading: false, shopCategory })
        //window.location.href('/')
        await this.setState({ hasCustomDomain: true, user, profileImage: r2.data.profile.pictureURL, description: r2.data.profile.description })



      } else {
        console.log('no custom URL detected... ', r2.msg)
        alert(`this isn't a valid shop bog!`)

        //this.fetchDataWithShopName()
        //this.setState({ hasCustomDomain: false, loading: false })

      }

    } catch (err) {
      console.log("Error from firebase using fetchdatawithurl is: ", err);
      //this.setState({ hasCustomDomain: false, redirect: true })

    }

  }







  getImageUrl = async () => {
    try {
      const r = await fetch(
        "https://us-central1-afbase-7ee60.cloudfunctions.net/shop",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user
          })
        }
      );
      const r2 = await r.json();
      console.log("imageUrl", r2);
      await this.setState({ profileImage: r2.data.profile.pictureURL, description: r2.data.profile.description });
    } catch (err) {
      console.log(err, "there seems to be an error");
    }
  };

  setToggleTopMenuClass = () => {
    if (this.state.menu_class === "") {
      this.setState({
        menu_class: "toggled"
      });
    } else {
      if (this.state.menu_class === "toggled") {
        this.setState({
          menu_class: ""
        });
      }
    }
  };

  blogPostSelectedHandler = id => {
    console.log("this is a  clicked item", id);
    this.setState({ selectedblogId: id }, () => {
      console.log("seletedblogid", this.state.selectedblogId); // This is guaranteed to return the updated state.
    });
  };

  render() {
    console.log(this.state.menu_class);
    let top_menu_class = `top-menu ${this.state.menu_class}`;

    return (
      <div className="blog-page-container">
        {/* <div className={top_menu_class}>
          <FontAwesomeIcon
            style={{}}
            icon="bars"
            className="top-menu-icon"
            onClick={this.setToggleTopMenuClass}
          />
        </div> */}

        <section className="section-blog blogJumbotron">
          <div
            className="blog-header-image"
            style={{
              backgroundImage: `url(${this.state.headerImage})`
              // backgroundColor: "white"
            }}
          >
            <div className="blog-header-text">
              <h1> Welcome to My blog </h1>
            </div>
          </div>
        </section>

        <div className="container" style={{ borderBottomWidth:1, borderBottomColor:'black', backgroundColor:'#fefefe', paddingBottom:50, }} >
          <div className="blogSectionHeader">
            <h2 className="text-center mb-4 font-weight-bold" style={{ color: 'black' }}>Featured Articles</h2>
          </div>
          <div className="blog-card-section">
            {this.state.posts.map(item => {
              return (
                <Link
                  key={item.fileId}
                  to={{
                    pathname: `/${
                      this.state.hasCustomDomain
                        ? blog
                        : this.state.user + "/" + blog
                      }/${item.fileId}`,

                    blogPostData: item,
                    profileImage: this.state.profileImage
                  }}
                  className="links"
                >
                  <Post
                    title={item.title}
                    author={item.author}
                    imageUrl={item.imageUrl}
                    // fileId={item.fileId}
                    clicked={() => this.blogPostSelectedHandler(item.fileId)}
                  />
                </Link>
              );
            })}
          </div>
        </div>
        {this.state.selectedblogId && <Redirect to="/full-blog-post" />}

        <div className="aboutMe container ">
          <div className="infoClass">
            <img
              className="profile-image"
              src={this.state.profileImage}
              alt=""
            />
            <div className='container' style={{ width: '100%' }}>
              <div className="mx-auto text-center white">
                <h2 className="text-center mb-4 font-weight-bold" style={{ color: 'black' }}>About Me</h2>
                <hr style={{ align: "center", width: 160, margin: "auto", color: '#efb31f' }} />
                <p style={{ color: 'black', marginTop: 50 }}>{this.state.description || '...'}.</p>
              </div>
            </div>

            {/* <div className="profile-info">
              <h1>About Me</h1>
              <p>
                {this.state.description || `Fusce quis volutpat porta, ut tincidunt eros est nec diam erat
                quis volutpat porta, neque massa, ut tincidunt eros est nec diam
                FusceFusce quis volutpat porta, ut tincidunt eros est nec diam
                erat quis volutpat porta, neque massa, ut tincidunt eros est nec
                diam FusceFusce quis volutpat porta, ut tincidunt eros est nec`}
              </p>
              <div className="profile-socials"></div>
            </div> */}
          </div>
        </div>

        <footer>
          <p className="footer-blog">
            &copy; {new Date().getFullYear()} Copyright {this.state.user}
          </p>
        </footer>

        {/* <Switch>
          <Route
            // path={`${this.props.match.path}/full-blog-post}`}
            path={`${this.props.match.url}/:${blogUrl}`}
            render={props => (
              <FullBlogPost {...props} blogPostData={PostData} />
            )}
          />
        </Switch> */}
      </div>
    );
  }
}
