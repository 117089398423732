import React, { Component } from 'react'




export default class Footer extends Component {
    state = {
        providerName: '',
        shopProfileName: '',
        descriptionShop: '',
        services: [],
        address: '',
        phoneNumber: '',
        senderName: '',
        shopName: '',
        msg: false,
        error: false,
        categories: '',
        services: ''

    }


    filterCategories = () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        //  const values1 = values.slice(0, values.length)
        //   const values2 = values1.join(' ')
        //const values1 = values.slice(0, values.length)
        //const values2 = values1.join(' ')
        const newvalues = [].concat(...values)


        console.log(' the categories are: ', keys, ' and the services are: ', values)
        this.setState({ categories: keys, services: newvalues });

    }



    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = (e) => {
        e.preventDefault()

        console.log('Username' + this.state.senderName)

        const newMessage = {
            shopName: this.props.shopNamefromURL,
            senderName: this.state.senderName,
            senderPhoneNumber: this.state.senderPhoneNumber,
            message: this.state.message
        }
        console.log('new', newMessage)
        const url = "https://us-central1-afbase-7ee60.cloudfunctions.net/shopMessage"
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newMessage)
        }).then((res) => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {


                console.log('Success:', response)
                if (response.msg == 'SUCCESS') {
                    this.setState({ msg: true, senderName: '', senderPhoneNumber: '', message: '' })
                } else {
                    this.setState({ error: true })
                }
            });


    }



    componentDidMount = () => {
        if (this.props.servicesOffered) {
            console.log('services offered in Rave footer component are: ', this.props.servicesOffered)
            this.filterCategories();
        }
    }


    render() {
        return (
            <div style={{ width: '100%' }} id="contact">
                <footer className=" border-top" style={{ backgroundColor: '#f8f9fa', fontFamily: "Montserrat, sans-serif" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                                <div>
                                    <h6 className="footer-custom mb-4 pb-4 float-center" style={{ 
                                        //color: this.props.shopColorButton || '#7a73ea', backgroundColor: '#f8f9fa', 
                                        color: 'black', }}>SEND A MESSAGE TO THE SELLER</h6>
                                    <form onSubmit={this.onSubmit}>
                                        <div className="form-group my-1 ">

                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Full Name"
                                                required
                                                name="senderName"
                                                value={this.state.senderName}
                                                onChange={this.onChange}
                                                style={{fontFamily: "Raleway, sans-serif"}}
                                            />
                                        </div>
                                        <div className="form-group my-1  ">

                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="Phone number e.g +2348056666666"
                                                required
                                                name="senderPhoneNumber"
                                                value={this.state.senderPhoneNumber}
                                                onChange={this.onChange}
                                                style={{fontFamily: "Raleway, sans-serif"}}
                                            />
                                        </div>
                                        <div className="form-group text-uppercase my-1">

                                            <textarea
                                                type="text"
                                                placeholder="Please type in your message here"
                                                className="form-control"
                                                required
                                                name="message"
                                                style={{ height: '130px',fontFamily: "Raleway, sans-serif", }}
                                                value={this.state.message}
                                                onChange={this.onChange}>

                                            </textarea>

                                        </div>

                                        <div>
                                            <p className="mt-5 ml-5 text-right ">{this.state.msg && <div>
                                                <p className="text-success py-1 px-4  "> Message sent, seller will contact you shortly</p></div>}
                                                {this.state.error && <div><p className="text-danger px-4 py-1">Oops!, Message not sent</p></div>}</p>
                                        </div>
                                        <div>  <button type="submit" class="btn-rave btn-custom-rave" style={{ backgroundColor: this.props.shopColorButton || '#7a73ea' }}>Submit</button></div>
                                    </form>

                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4">
                                {this.props.servicesOffered && <div className="">
                                    <h6 className="footer-custom my-4 ml-4 text-uppercase" style={{ color: 'black',  }}>CATEGORIES</h6>
                                    <div style={{ height: '10px' }}></div>
                                    {this.state.categories && <ul className="list-unstyled float-center list-group" style={{marginTop:-5}}>
                                        {this.state.services.map((service, id) => {
                                            return (
                                                <li key={id}><p className="text-center mt-1 text-uppercase" style={{color:'black', lineHeight:2, fontFamily: "Raleway, sans-serif",}} >{service}</p></li>
                                            )
                                        }
                                        )}
                                    </ul>}
                                </div>}


                            </div>


                            <div class="col-md-4 col-lg-4">
                                <h6 className="footer-custom mt-4" style={{ color: 'black', }}>CONTACT INFO</h6>
                                <div style={{ height: '10px' }}></div>
                                <ul class="list-unstyled text-small text-uppercase" style={{ fontFamily: "Raleway, sans-serif", lineHeight:2, marginTop:-5 }}>
                                    <li><a class="text-muted" ><i className="fa fa-user"></i> &nbsp; {this.props.providerName || 'Not Available'}</a></li>
                                    <li><a class="text-muted" ><i className="fa fa-map-marker" ></i> &nbsp; {this.props.providerAddress || 'Not Available'}</a></li>
                                    <li><a class="text-muted" ><i className="fa fa-phone"></i> &nbsp; {this.props.phoneNumber || 'Not Available'}</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>


                </footer>


                {/* <footer className="footer small p-4 text-center" style={{ backgroundColor: '#ffffff', color: '#a19cf4' }}> */}
                <footer className="footer small p-4 text-center" style={{ backgroundColor: '#343434', color: 'white', width: '100%' }}>
                    Copyright &copy; {new Date().getFullYear()} {this.props.nameShop ? this.props.nameShop : ''}. All rights reserved!
                     </footer>

            </div>
        )
    }
}
