import React, { Component } from 'react';
import { Redirect } from "react-router-dom"

import Navbar from './Navbar'
import Header from './header'
import Landing from './Landing'
import Profile from './Profile'
import Cart from '../../../checkout/cartModal'
import Chat from '../../chats/Chat'
import About from "./About"
import Footer from "./Footer"

//import productImage from 'https://images.pexels.com/photos/709841/pexels-photo-709841.jpeg'

//import './App.css'; 



let FullCart = []
let offers = [{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey13.png?alt=media&token=5d274fa3-c2bd-4a2e-95da-95ae76c11005',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},

{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Ffrance-confectionery-raspberry-cake-fruit-69817.jpeg?alt=media&token=52f64d71-e50c-4ce1-8938-c1812d06a698',
  title: 'Birthday Cake',
  price: '22,000',
  productAction: 'Order Now'

},


{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey2.png?alt=media&token=6731c348-189a-4418-9d6b-c670885c5c9b',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey10.png?alt=media&token=e251cf60-2673-4eab-823b-5b0ab5b05c37',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcake13.png?alt=media&token=7e09de82-3bdc-4a11-b682-7612f40357b6',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey11.png?alt=media&token=59811d80-5c65-4f02-9c50-e0bf41b6cfaa',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey.png?alt=media&token=45d4e817-12de-419a-bd59-3a6f494ec00a',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey9.png?alt=media&token=d3e511f8-1d88-4c4b-9554-bb4062f62f4b',
  title: 'Wedding Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fwhite-round.png?alt=media&token=e4b5ac4a-1a4b-47f6-8964-bfe6150ea1f4',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey5.png?alt=media&token=096b26a1-6fb9-43e0-8586-e2892148fa3e',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey8.png?alt=media&token=5e9c3de7-dbef-46aa-9d8b-bdeb489831d0',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey9.png?alt=media&token=d3e511f8-1d88-4c4b-9554-bb4062f62f4b',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcake12.png?alt=media&token=d8407204-1b25-4e4b-8090-88dc99e515d7',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},
{
  pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FFood%2FCakesandBakery%2Fcakey7.png?alt=media&token=15a6b9f9-0297-4ac3-9a65-2ede5b0179e9',
  title: 'Birthday Cake',
  price: '15,000',
  productAction: 'Order Now'

},

]

class CFactorySample extends Component {

  state = {
    nameShop: '',
    descriptionShop: '',
    tagline: '',
    shopBackgroundPicture: '',
    shopCartStatus: '',
    shopChat: '',
    shopCartBankAccount: '',
    shopSocials: {
      facebook: "",
      instagram: "",
      twitter: ""
    },
    postIDs: [],
    modalData: {
      price: 0,
      title: '',
      text: '',
      pictureURL: ''

    },
    logo: '',
    categories: '',
    servicesOffered: '',
    hasloaded: false,
    redirect: false,
    shopNamefromURL: '',
    shopID: '',
    hasCustomDomain: false,
    url: '',
    theme: '',
    HomeData: '',
    FullCart: [],
    cartModalWidth: 0,
    showModalCart: false,
    clickedItems: '',
    selectedItems: '',
    cartShowbankDetails: '',
    cartTotal: ''
  }




  parseURLandReturnRelevantID = async () => {
    try {
      const fullURL = window.location.href.split('#')[0]
      const fullURLsubStrings = fullURL.split('/');
      console.log(fullURLsubStrings);
      fullURLsubStrings.forEach(itemURL => {
        if (fullURLsubStrings.indexOf(`${itemURL}`) === 3) {
          console.log('item url retreived in App.js is:', itemURL, ' and url is ', fullURLsubStrings[2])
          this.setState({ shopNamefromURL: itemURL, url: fullURLsubStrings[2] })
        }
      })
      return
    } catch (err) {
      console.log('error parsing url...')
    }

  }

  filterCategories = () => {

    const keys = Object.keys(this.state.servicesOffered)
    const values = Object.values(this.state.servicesOffered)
    const newvalues = [].concat(...values)

    console.log(' the categories are: ', keys, ' the values are :', newvalues)
    newvalues.forEach(newvalue => {
      console.log('newvalue is: ', newvalue)
      //this.iconSelector(newvalue)

    })
    return (newvalues);

  }


  openAndCloseCartModal = () => {
    this.setState({
      cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
      showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
    })
  }

  updateModalCart = () => {
    this.setState({
      cartModalDetails: {
        FullCart: this.state.FullCart,
        shopNamefromURL: this.props.shopNamefromURL,
        hasCustomDomain: this.props.hasCustomDomain,
        nameShop: this.state.nameShop,
        shopCartBankAccount: this.state.shopCartBankAccount
      }
    })
  }


  componentDidMount = async () => {


    const categories = {
      Electronics: "../../images/categories/Electronics.png",
      Home: "../../images/categories/Home.png",
      Fashion: "../../images/categories/Fashion.png",
      Cars: "../../images/categories/Cars.png",
      Food: "../../images/categories/Food.png",
      Beauty: "../../images/categories/Beauty.png",
      Professionals: "../../images/categories/Professionals.png",
      Repair_Refill: "../../images/categories/Repair_Refill.png",
      Handyman: "../../images/categories/Handyman.png",
      QuickJobs: "../../images/categories/QuickJobs.png",
      Health: "../../images/categories/Health.png",
      Art: "../../images/categories/Art.png",
      Housing: "../../images/categories/Housing.png",
      Travel: "../../images/categories/Travel.png",
      Commercial: "../../images/categories/Commercial.png"
    }
    await this.setState({ categories });



    if (this.props.shopData) {
      console.log('Data received in APP JS is: ', this.props.shopData)
      document.title = this.props.shopData.profile.businessName
      let shopProfile = this.props.shopData.profile
      let shopProfileName = shopProfile.businessName
      let shopProfileDescription = shopProfile.description
      // let shopLogo = shopProfile.pictureURL
      let hasCustomDomain = this.props.hasCustomDomain
      //////////////////////////////////////////////////////////////////////////////

      let providerName = shopProfile.name;
      let providerAddress = shopProfile.locationWorkAddress
      let phoneNumber = shopProfile.phoneNumber
      let servicesOffered = shopProfile.servicesOffered
      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopPost = this.props.shopData.posts


      let shop = this.props.shopData.shop
      console.log('shops', shop)
      let shopLogo = shop.shopLogoPicture
      let tagline = shop.shopSlogan
      let shopID = shop.shopName
      let shopNamefromURL = shop.shopName
      let shopBackgroundPicture = shop.shopBackgroundPicture
      let theme = shop.shopType

      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopColorBackground = shop.shopColorBackground
      let shopColorButton = shop.shopColorButton
      let shopColorHeader = shop.shopColorHeader
      let shopColorTitle = shop.shopColorTitle
      let shopFrontPicture = shop.shopFrontPicture
      let shopHeader = shop.shopHeader
      let shopCartStatus = shop.shopCartStatus
      let shopChat = shop.shopChat
      let shopCartBankAccount = shop.shopCartBankAccount
      let shopSocials = shop.shopSocials
      ////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////
      console.log(' shopNamefromURL from APP JS is: ', shopNamefromURL)

      console.log('shopName is shop ID which is: ', shopID)
      await this.setState({
        nameShop: shopProfileName,
        tagline,
        descriptionShop: shopProfileDescription,
        shopBackgroundPicture,
        shopBackgroundPicture,
        shopColorBackground,
        shopColorButton,
        shopColorHeader,
        shopColorTitle,
        shopFrontPicture,
        shopCartStatus,
        shopChat,
        shopCartBankAccount,
        shopSocials,
        postIDs: shopPost,
        providerName,
        providerAddress,
        phoneNumber,
        servicesOffered,
        logo: shopLogo,
        hasloaded: true,
        url: this.props.url,
        shopNamefromURL,
        shopID,
        hasCustomDomain,
        theme,
        shopHeader
      })
      //  console.log('first postID is: ', this.state.postIDs[0].xID)
      console.log('hascustomDomain in state is: ', this.state.hasCustomDomain)
      this.topFunction()
    }
    /* else {
      
      this.parseURLandReturnRelevantID();

      //this.fetchdataWithURL()

      if (this.state.url === 'wakanda-market.com' || 'www.wakanda-market.com'){
        console.log('the URL seen is: ',this.state.url)
        this.fetchdataWithShopName();
      }else {
        console.log('the URL should go to ',this.state.url) 
        return(<Redirect to='/'/>)
      
      } 
      

    }*/

    //await this.parseURLandReturnRelevantID();


    const servicesOffered = this.filterCategories()
    console.log('Offered services ', servicesOffered)


    //window.addEventListener("scroll", this.handleScroll());

  }



  topFunction = () => {
    window.scrollTo(0, 0)
    return
  }


  render() {
    return (

      <div id="page-container" className="App container-fluid pr-0 pl-0 w-100 h-100"
        style={{ backgroundColor: '#fff', overflowX: 'hidden', width: '100%', top: 0, left: 0, position: 'fixed', scrollBehavior: "smooth" }} >

        <Navbar
          showCartModal={() => {
            this.openAndCloseCartModal()
            this.updateModalCart()
          }}
          showAbout={true}
          showContact={true}
          nameShop={this.state.nameShop}
          logo={this.state.logo}
          shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
          url={this.state.url}
          showSearch={false}
          shopColorBackground={this.state.shopColorBackground}
          FullCart={this.state.FullCart}
          hasCustomDomain={this.props.hasCustomDomain}
          shopCartStatus={this.state.shopCartStatus}
          shopCartBankAccount={this.state.shopCartBankAccount}
        />


        <Header />



        {!this.state.showModalCart && <Landing

          FullCart={this.state.FullCart}
          selectedItems={this.state.selectedItems}
          clickedItems={this.state.clickedItems}
          shopCartStatus={this.state.shopCartStatus}
          shopCartBankAccount={this.state.shopCartBankAccount}
          shopData={this.props.shopData}
          shopNamefromURL={this.state.shopNamefromURL}
          url={this.props.url}
          nameShop={this.state.nameShop}
          descriptionShop={this.state.descriptionShop}
          //postIDs={this.state.postIDs}
          postIDs={offers}
          providerName={this.state.providerName}
          providerAddress={this.state.providerAddress}
          phoneNumber={this.state.phoneNumber}
          shopID={this.state.shopID}
          //shopLogo={this.state.logo}
          shopLogo={this.state.logo}
          servicesOffered={this.state.servicesOffered}
          hasloaded={this.state.hasloaded}
          hasCustomDomain={this.props.hasCustomDomain}
          theme={this.state.theme}
          shopColorTitle={this.state.shopColorTitle}
          shopColorBackground={this.state.shopColorBackground}
          shopSocials={this.state.shopSocials}
          shopCartStatus={this.state.shopCartStatus}
          shopChat={this.state.shopChat}
          shopCartBankAccount={this.state.shopCartBankAccount}
          shopSocials={this.state.shopSocials}

          offers={this.offers}

        ></Landing>}
        {/* 
        <div id="about">
          <div className='container' style={{ width: '100%', marginTop: 50 }}>
            {this.props.servicesOffered && <About
              descriptionShop={this.props.descriptionShop}
              //categories={this.props.categories}
              servicesOffered={this.props.servicesOffered}
              hasloaded={this.props.hasloaded}
            />

            }

          </div>
        </div> */}
        <div id="about">
          <div style={{ width: '100%', marginTop: 50 }}>
            <About
              descriptionShop={this.props.descriptionShop}
              //categories={this.props.categories}
              servicesOffered={this.props.servicesOffered}
              hasloaded={this.props.hasloaded}
            />



          </div>
        </div>


        {!this.state.showModalCart &&
          <Profile
            providerName={this.state.providerName}
            providerAddress={this.state.providerAddress}
            phoneNumber={this.state.phoneNumber}
            shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
            shopSocials={this.state.shopSocials}

          ></Profile>

        }


        {(this.state.shopChat.status === "LIVE") && <Chat
          backgroundColor={this.state.shopColorBackground}
          nameShop={this.state.nameShop}
          shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}
        />}
        <div style={{}}>
          <Footer
            nameShop={this.state.nameShop}
          />
        </div>

        <div id='cart-modal' style={{
          width: this.state.cartModalWidth,
          height: 'inherit',
          position: 'absolute',
          right: 0, top: 60,
          backgroundColor: 'white',
          WebkitTransition: 'width 0.5s',
          transition: 'width 0.5s',
          paddingTop: 50,
          paddingBottom: 100
        }}>

          <div onClick={this.openAndCloseCartModal}>
            <h1 style={{ color: 'black', paddingLeft: 50 }}>X</h1> </div>

          {this.state.showModalCart && <div style={{ height: 'inherit', }}>
            <Cart
              CartDetails={this.state.cartModalDetails}
              // cartShowbankDetails={this.state.cartShowbankDetails}
              cartShowbankDetails={true}
              cartTotal={this.state.cartTotal}
              nameShop={this.state.nameShop}

            />
          </div>}

        </div>
      </div >

    );
  }

}

export default CFactorySample
