import React, { Component } from 'react'
import stylez from "./stylesheets/modaz.module.css"
import { Link } from "react-router-dom"
//import wkLogo from "../../images/logos/home_logo1.png"

export default class Footer extends Component {



    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }





    render() {
        const shopLogo = this.props.logo
        return (
            <div className="container">
                <footer className={stylez.modazFooter} style={{ backgroundColor: "#FFFFFF", borderBottom: '1px solid #f5f5f5' }}>
                    
                        <div className="row">
                            <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center mb-4 " style={{marginLeft: -10}}>
                               
                                    <ul>
                                        <li><a href="#about" style={{color: 'black'}}>About Us</a></li>
                                        <li><a href="#store" style={{color: 'black'}}>Store</a></li>
                                        <li><a href="#contact" style={{color: 'black'}}>Contact Us</a></li>
                                    </ul>
                                
                            </div>
                            <div class="col-sm-12 col-md-4 d-flex justify-content-center align-items-center mb-4" style={{marginLeft: -10}}>
                              
                                    <ul>
                                        <li><a href="#" style={{color: 'black'}}>Blog</a></li>
                                        <li><a href="#" style={{color: 'black'}}>Privacy Policy</a></li>
                                        <li><a href="#" style={{color: 'black'}}>Categories</a></li>

                                    </ul>
                               
                            </div>

                            <div className="col-sm-12 col-md-4 mb-4">
                                <div  style={{
                                    color: '#333333'
                                }}>
                                    {shopLogo && <div className={`${stylez.logo} ${stylez.logoFooter}`}>
                                        <Link to={this.chooseHomeURL()} >
                                            <div className="container-fluid text-md-center text-center"
                                                style={{
                                                    width: '62px', height: '62px', borderRadius: '50%',
                                                    justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${shopLogo || 'Logo here'})`,
                                                    backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', borderStyle: 'solid',
                                                    borderColor: 'white', borderWidth: '3px'
                                                }}>

                                            </div>
                                        </Link>
                                    </div>}

                                    <ul>
                                        <li class="email" style={{fontSize:13}}> {this.props.providerName || ' Not Available'}</li>
                                        <li class="address" style={{fontSize:13}}>{this.props.providerAddress || 'Not Available'}</li>
                                        <li class="phone" style={{fontSize:13}}>{this.props.phoneNumber || 'Not Available'}</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                   
                </footer>

                <div className={stylez.footerBottom}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 pt-2">
                                <p className={`${stylez.copyright} text-center`} style={{ color: '#666666', fontSize: 13 }}>Copyright &copy; {new Date().getFullYear()} {this.props.nameShop+'.' || 'Wakanda Market.'}<span style={{ color: '#000' }}> All rights reserved!</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Go Top --> */}
                <a className={stylez.goTop}>
                    <i class="fas fa-chevron-up"></i>
                </a>

            </div>
        )
    }
}


