import React, { Component } from 'react'
import Image1 from '../../assets/Image1.jpeg'
import Image2 from '../../assets/Image2.jpeg'
import Image3 from '../../assets/Image3.jpeg'
import Image4 from '../../assets/Image4.jpeg'
import Image5 from '../../assets/Image5.jpeg'
import Image6 from '../../assets/Image6.jpeg'
import Image7 from '../../assets/Image7.jpeg'
import Image8 from '../../assets/Image8.jpeg'
import Image9 from '../../assets/Image9.jpeg'
import Image10 from '../../assets/Image10.jpeg'
import Image11 from '../../assets/Image11.jpeg'
//import Image12 from '../../assets/Image12.jpeg'

import Modal from '../layout/Modal'


export default class ImageGrid extends Component {
    render() {
        return (
            <div className="container-fluid p-4" style={{ backgroundColor: '#F6F7FD' }} >
                <div class="text-center container mb-0 pb-0">
                    <h1 className="text-black p-3  font-weight-bold">Explore Wakanda</h1>
                    <hr style={{ width: "70px", height: 5, margin: "auto", borderColor: '#DD4B39', backgroundColor: '#DD4B39' }} />

                    <div className="row" style={{ marginTop: 50 }} >
                        <div className='col-md-3' style={{
                            backgroundImage: `url(${Image1})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300,
                        }}>

                        </div>
                        <div className='col-md-3 m-0 p-0' style={{
                            backgroundImage: `url(${Image5})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300,
                        }}>

                        </div>
                        <div className='col-md-3 m-0 p-0' style={{
                            backgroundImage: `url(${Image10})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300,

                        }}>
                        </div>
                        <div className='col-md-3 m-0 p-0' style={{
                            backgroundImage: `url(${Image3})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300,

                        }}>
                        </div>
                    </div>
                    <div className="row" style={{}}>
                        <div className='col-md-3' style={{
                            backgroundImage: `url(${Image4})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300,

                        }}>

                        </div>
                        <div className='col-md-3' style={{
                            backgroundImage: `url(${Image2})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300,

                        }}>

                        </div>
                        <div className='col-md-3' style={{
                            backgroundImage: `url(${Image6})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300,
                        }}>
                        </div>
                        <div className='col-md-3' style={{
                            backgroundImage: `url(${Image7})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300,
                        }}>
                        </div>
                    </div>
                    <div className="row" style={{}}>
                        <div className='col-md-4' style={{
                            backgroundImage: `url(${Image8})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300
                        }}>
                        </div>
                        <div className='col-md-4' style={{
                            backgroundImage: `url(${Image11})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300
                        }}>
                        </div>
                        <div className='col-md-4' style={{
                            backgroundImage: `url(${Image9})`, backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 300
                        }}>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
