import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import firebase from "../../Firebase";
import "./NewPost.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NewPost extends Component {
  constructor(props) {
    super(props);
    this.unsubscribeFirestore = () => { };
    this._isMounted = false;
    this.state = {
      posts: [],
      user: "",
      blogPostID: "",
      author: "",
      title: " ",
      content: "",
      imageUrl: "",
      EditData: {},
      selectedFile: null,
      downloadUrl: "",
      displayUrl: "",
      spinnerIndicator: false,
      hasCustomDomain: "",
      redirect: false,
      firebaseDataRequested:'',
    };
  }

  componentDidMount = async () => {
    if (this.props.location.user) {
      await this.setState({
        user: this.props.location.user,
        hasCustomDomain: this.props.location.hasCustomDomain
      });


      console.log("user", this.state.user);
      firebase.auth().onIdTokenChanged(function (user) {
        if (user) {
          // User is signed in or token was refreshed.
          global.fetchIdToken();
        }
      });
  
      global.fetchIdToken = async () => {
        try {
          const idToken = await firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true);
          if (idToken) {
            global.idToken = idToken;
          }
          console.log("Success fetching idToken", global.idToken);
        } catch (err) {
          console.log("ERROR fetching idToken", err);
        }
      };
      await this.parseURLandReturnRelevantID();
      await (this.setState({firebaseDataRequested:'STARTED'}))
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Blogs")
        .where("author", "==", this.state.user)
        .onSnapshot(async collection => {
          if (collection.empty) {
            console.log("No matching documents");
            return;
          }
          const posts = [];
          collection.forEach(doc => {
            let items = doc.data();
            let fileID = doc.id;
            posts.push({
              author: items.author,
              title: items.title,
              content: items.content,
              imageUrl: items.imageUrl,
              fileId: fileID
            });
            // console.log("helllooooo...", posts);
          });
          this.setState({ posts });
         /*  let postData = {
            author: "",
            title: "",
            content: "",
            imageUrl: ""
          }; */
          let postData = {};
          if (this.state.blogPostID === "new-post") {
            this.setState({
              author: "",
              title: "",
              content: "",
              imageUrl: ""
            });
            
          } else {
            postData = this.state.posts.find(
              x => x.fileId === this.state.blogPostID
            );
  
            this.setState({
              author: postData.author || this.state.user,
              title: postData.title,
              content: postData.content,
              imageUrl: postData.imageUrl
            });
          }
        });







    } else {
      console.log(' No user props available... will redirect shortly')
      this.parseURLandReturnRelevantID()
      if (this.state.hasCustomDomain && this.state.user && this.state.firebaseDataRequested == 'ENDED') {
        this.setState({ redirect: true })
      }

      //this.props.history.push("/harmonyuniconcept/admin");
    }
    
  };

  parseURLandReturnRelevantID = async () => {
    try {
      const fullURL = window.location.href.split("/");
      console.log(fullURL);
      await this.setState({
        blogPostID: fullURL[5],
        hasCustomDomain: fullURL[3] === 'admin' ? true : false,
        user: fullURL[3],
        redirect: this.props.location.user ? false : true
      });
      console.log("the blog post in Newpost js is: ", this.state.blogPostID);
    } catch (err) {
      console.log("issues with parsing URL ", err);
    }
  };

  componentWillUnmount() {
    this.unsubscribeFirestore();
    this._isMounted = false;
  }

  postBlog = async () => {
    this.setState({ spinnerIndicator: true });
    try {
      let file = this.state.selectedFile;
      if (file) {
        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg"
        };

        let storageRef = firebase.storage().ref(file.name);

        // Upload file and metadata of the storageRef
        let uploadTask = storageRef.put(file, metadata);

        const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
        this.setState({ downloadUrl });

        console.log("here is the downloadUrl", downloadUrl);
      }

      const r = await fetch(
        " https://us-central1-af-shop.cloudfunctions.net/postBlog",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user,
            title: this.state.title,
            content: this.state.content,
            imageUrl: this.state.downloadUrl,
            idToken: global.idToken
          })
        }
      );
      const r2 = await r.json();
      console.log(r2);
      if (r2.msg === "SUCCESS") {
        console.log(r2.data);
        this.props.history.push(this.state.hasCustomDomain == true ? `/admin`  : `/${this.state.user}/admin`);
      }
    } catch (err) {
      alert("An error occurred.");
      console.log(err, "there seems to be an error");
      // this.setState({ activityIndicatorAnimating: false });
    }
  };

  upDatePost = async () => {
    this.setState({ spinnerIndicator: true });
    try {
      let file = this.state.selectedFile;
      if (file) {
        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg"
        };

        let storageRef = firebase.storage().ref(file.name);

        // Upload file and metadata of the storageRef
        let uploadTask = storageRef.put(file, metadata);

        const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
        this.setState({ downloadUrl });

        console.log("here is the downloadUrl", downloadUrl);
      }
      const r = await fetch(
        " https://us-central1-af-shop.cloudfunctions.net/updateBlogPost",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user,
            title: this.state.title,
            content: this.state.content,
            imageUrl: this.state.downloadUrl,
            idToken: global.idToken,
            blogID: this.state.blogPostID
          })
        }
      );

      const r2 = await r.json();
      console.log(r2);
      if (r2.msg === "SUCCESS") {
        console.log("message", r2.msg);
        this.props.history.push(this.state.hasCustomDomain == true ? `/admin`  : `/${this.state.user}/admin`);
      }
    } catch {
      alert("An error occurred.");
      console.log("there seems to be an error");
    }
  };

  chooseAdminURL = () => {
    if (this.state.hasCustomDomain) {
      return "/admin";
    } else if (
      window.location.href === "http://localhost:3000/" ||
      window.location.href === "https://localhost:3000/" ||
      window.location.href === "http://wakanda-market.com" ||
      window.location.href === "https://wakanda-market.com"
    ) {
      return "/" + this.state.user + "/admin";
    } else {
      return "/" + this.state.user + "/admin";
    }
  };

  render() {
    console.log("blogId", this.state.blogPostID);
    console.log("posts", this.state.posts);
    console.log("title", this.state.title);
    console.log("content", this.state.content);
    console.log("downloadUrl", this.state.downloadUrl);
    let postHeader = "";
    let postButton = "";

    if (this.state.blogPostID === "new-post") {
      postHeader = "new Post";
      postButton = "Add Post";
    } else {
      postHeader = "UpDate Post";
      postButton = "UpDate Post";
    }

    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          paddingTop: 20,
          paddingBottom: 20,
          display: "flex",
          justifyContent: "center"
        }}
      >
        {this.state.redirect && <Redirect to={this.state.hasCustomDomain == true ? "/admin" : `/${this.state.user}/admin`} />}
        <div
          className="NewPost"
          style={{
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
            backgroundColor: "white"
          }}
        >
          <h1 className="NewPostHeader"> {postHeader}</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 0 1rem 0.5rem"
            }}
          >
            <span
              className="fa form-control-feedback"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "8%"
              }}
            >
              <FontAwesomeIcon
                style={{ color: "#797979", fontSize: 20 }}
                icon="plus-circle"
                // className="top-menu-icon"
                onClick={this.setToggleTopMenuClass}
              />
            </span>
            <input
              className="titleInput"
              type="text"
              placeholder="Add Title"
              value={this.state.title}
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                width: "80%"
              }}
              onChange={event => {
                this.setState({ title: event.target.value });
              }}
            />
          </div>

          <form>
            <div
              className="upload"
              style={{
                // display: "flex",
                // margin: "5px 0",
                // padding: "10px",
                // font: "inherit",
                // border: "1px solid #fa923f",
                // backgroundColor: "transparent",
                // color: " #fa923f",
                // cursor: "pointer",
                // width: "6rem"
                padding: "5px 10px",
                background: "#00ad2d",
                border: "1px solid #00ad2d",
                position: "relative",
                color: "#fff",
                borderRadius: "2px",
                textAlign: "center",
                float: "left",
                cursor: "pointer"
              }}
            >
              Add image
              <input
                style={{
                  position: "absolute",
                  zIndex: "1000",
                  opacity: "0",
                  cursor: "pointer",
                  right: "0",
                  top: "0",
                  height: "100%",
                  fontSize: "24px",
                  width: "100%"
                }}
                type="file"
                class="inputfile"
                name="upload"
                onChange={event =>
                  this.setState({
                    selectedFile: event.target.files[0],
                    file: URL.createObjectURL(event.target.files[0])
                  })
                }
              />
            </div>
          </form>

          <div
            className="image"
            style={{
              height: "300px",
              width: "100%",
              backgroundImage:
                "url(" + `${this.state.file || this.state.imageUrl}` + ")",

              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          ></div>

          <div
            className="mt-4"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              padding: "0 0 1rem 0.5rem"
            }}
          >
            <span
              class="fa form-control-feedback"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "8%"
              }}
            >
              <FontAwesomeIcon
                style={{ color: "#797979", fontSize: 20 }}
                icon="plus-circle"
                // className="top-menu-icon"
                onClick={this.setToggleTopMenuClass}
              />
            </span>

            <textarea
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                width: "80%"
              }}
              placeholder=" Add content"
              rows="32"
              value={this.state.content}
              onChange={
                // this.props.location.onChange2
                event => {
                  this.setState({ content: event.target.value });
                }
              }
            />
          </div>
          <div className="wrap">
            {this.state.spinnerIndicator ? (
              <div className="authenticateIcon">
                <FontAwesomeIcon icon="spinner" className="loadingIcon " />
              </div>
            ) : (
                <button
                  onClick={
                    this.state.blogPostID === "new-post"
                      ? this.postBlog
                      : this.upDatePost
                  }
                >
                  {postButton}
                </button>
              )}
          </div>
        </div>

      </div>
    );
  }
}

export default NewPost;
