import React, { Component } from 'react';

class Modal extends Component {



  render() {
    return (
      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">

          <div class="modal-content" style={{ zIndex: 101 }} >
            <div class="modal-header">
              <h4 class="modal-title">{this.props.title}</h4>
              <button type="button" class="close" data-dismiss="modal">X</button>
            </div>
            <div class="modal-body" >
              <img src={this.props.pictureURL} className="img-fluid" alt="Responsive image" />
              <p>{this.props.text}</p>
              <p>{this.props.price}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>

        </div>
      </div>

    );
  }
}

export default Modal;