import React, { Component } from 'react'
//import "./stylesheets/styleModaz.css"
import "./rev-slider/css/navigation.css"
import styles from "./stylesheets/modaz.module.css"
import image from "./images/logo.png"
import Navigation from './Navigation'
import Navigation2 from './Navigation2'
import Cart from '../../../checkout/cartModal'
import Slider from './Slider'
import SlideCard from './slideCard';
import MainSlider from './mainSlider'
import ModazCategories from './ModazCategories'
import Footer from './footer'
import Contact from './Contact'
import Chat from '../../../components/chats/Chat'
import FeaturedProducts from './FeaturedProducts'
import { openAndCloseCartModal, updateModalCart, updateCart } from '../utilityfunctions'

import ReactPixel from 'react-facebook-pixel'
import Socials from '../../socials/socials'

const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('1719865501642535', options);
ReactPixel.pageView();

let FullCart = []
let cartStorage = {}
let WM_DATA_Storage = {};
console.log('cartStorage and WM_DATA_Storage initialized are:  ', 'cartStorage: ', JSON.stringify(cartStorage), 'WM_DATA_Storage: ', JSON.stringify(WM_DATA_Storage))
export class Modaz extends Component {


    state = {
        nameShop: '',
        description: '',
        tagline: '',
        shopBackgroundPicture: '',
        shopColorBackground: '',
        shopColorButton: '',
        shopColorHeader: '',
        shopColorTitle: '',
        shopFrontPicture: '',
        shopCartStatus: '',
        shopChat: '',
        shopCartBankAccount: '',
        shopSocials: {
            facebook: "",
            instagram: "",
            twitter: ""
        },
        postIDs: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: ''

        },
        logo: '',
        categories: '',
        services: '',
        servicesOffered: '',
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        hasloaded: false,
        redirect: false,
        shopNamefromURL: '',
        shopID: '',
        hasCustomDomain: false,
        url: '',
        theme: 'Rave',
        FullCart: [],
        cartModalWidth: 0,
        showModalCart: false,
        clickedItems: '',
        selectedItems: '',
        cartShowbankDetails: '',
        cartTotal: ''
    }



    openAndCloseCartModal = () => {
        this.setState({
            cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
            showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
        })
    }

    updateModalCart = () => {
        this.setState({
            cartModalDetails: {
                FullCart: this.state.FullCart,
                shopNamefromURL: this.props.shopNamefromURL,
                hasCustomDomain: this.props.hasCustomDomain,
                nameShop: this.state.nameShop,
                shopCartBankAccount: this.state.shopCartBankAccount
            }
        })
    }

    componentWillMount() {
        console.log('firing component will mount in StandardWhite.js')
        if (JSON.parse(localStorage.getItem("WM_DATA_Storage"))) {
            WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
            cartStorage = WM_DATA_Storage.FullCart
        } else {

            cartStorage = []

        }

        localStorage.setItem("cartStorage", JSON.stringify(cartStorage))
        this.setState({ FullCart: cartStorage })


    }

    componentDidMount = async () => {
        if (this.props.shopData) {
            console.log('Data received in MODAZ JS is: ', this.props.shopData)
            document.title = this.props.shopData.profile.businessName
            let shopProfile = this.props.shopData.profile
            let shopProfileName = shopProfile.businessName
            let shopProfileDescription = shopProfile.description
            // let shopLogo = shopProfile.pictureURL
            let hasCustomDomain = this.props.hasCustomDomain
            //////////////////////////////////////////////////////////////////////////////

            let providerName = shopProfile.name;
            let providerAddress = shopProfile.locationWorkAddress
            let phoneNumber = shopProfile.phoneNumber
            let servicesOffered = shopProfile.servicesOffered
            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopPost = this.props.shopData.posts


            let shop = this.props.shopData.shop
            console.log('shops', shop)
            let shopLogo = shop.shopLogoPicture
            let tagline = shop.shopSlogan
            let shopID = shop.shopName
            let shopNamefromURL = shop.shopName
            let shopBackgroundPicture = shop.shopBackgroundPicture
            let theme = shop.shopType


            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopColorBackground = shop.shopColorBackground
            let shopColorButton = shop.shopColorButton
            let shopColorHeader = shop.shopColorHeader
            let shopColorTitle = shop.shopColorTitle
            let shopFrontPicture = shop.shopFrontPicture
            let shopHeader = shop.shopHeader
            let shopCartStatus = shop.shopCartStatus
            let shopChat = shop.shopChat
            let shopCartBankAccount = shop.shopCartBankAccount
            let shopSocials = shop.shopSocials

            ///////////////////////////////////////////////////////////////////////////////////////////
            console.log(' shopNamefromURL from HOME JS is: ', shopNamefromURL)

            console.log('shopName is shop ID which is: ', shopID)
            await this.setState({
                nameShop: shopProfileName,
                tagline,
                descriptionShop: shopProfileDescription,
                shopBackgroundPicture,
                shopColorBackground,
                shopColorButton,
                shopColorHeader,
                shopColorTitle,
                shopFrontPicture,
                shopCartStatus,
                shopChat,
                shopCartBankAccount,
                shopSocials,
                postIDs: shopPost,
                providerName,
                providerAddress,
                phoneNumber,
                servicesOffered,
                logo: shopLogo,
                hasloaded: true,
                url: this.props.url,
                shopNamefromURL,
                shopID,
                hasCustomDomain,
                theme,
                shopHeader,
                FullCart: this.props.FullCart,
                clickedItems: this.props.clickedItems
            })
            //  console.log('first postID is: ', this.state.postIDs[0].xID)
            console.log('hascustomDomain in state is: ', this.state.hasCustomDomain)
            this.topFunction()

            if (this.props.clickedItems) {

                let cart = []
                let posts = this.state.postIDs
                let keys = Object.keys(this.props.clickedItems)
                keys.forEach(key => {
                    if (this.props.clickedItems[key] === 'clicked') {
                        posts.find(post => {
                            if (post.xID === key) {
                                cart.push(post)
                            }
                        })
                    }
                })
                console.log('cart generated from clicked items is: ', cart)
                this.setState({ FullCart: cart })
            }
        }







    }

    componentWillMount() {
        //after loaded show chat component
    }

    topFunction = () => {
        window.scrollTo(0, 0)
        return
    }


    render() {
        return (
            <div className="App container-fluid pr-0 pl-0 h-100"
                style={{
                    backgroundColor: 'white', overflowX: 'hidden', width: '100%',
                    top: 0, left: 0, scrollBehavior: "smooth",
                    position: 'fixed',
                }}>
                <div >
                    {/*    <Navigation
                        showCartModal={() => {
                            this.openAndCloseCartModal()
                            this.updateModalCart()
                        }}
                        nameShop={this.state.nameShop}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        hasCustomDomain={this.props.hasCustomDomain}
                        shopCartStatus={this.state.shopCartStatus}
                        shopCartBankAccount={this.state.shopCartBankAccount}
                    /> */}
                    <Navigation2
                        showCartModal={async () => {
                            let result = openAndCloseCartModal(this.state.cartModalWidth, this.state.showModalCart)
                            this.setState({ cartModalWidth: result.cartModalWidth, showModalCart: result.showModalCart })
                            await this.setState({ cartModalDetails: updateModalCart(this.state.FullCart, this.state.shopNamefromURL, this.state.hasCustomDomain, this.state.nameShop, this.state.shopCartBankAccount) })
                            console.log('the this.state.cartModalDetails received is: ', this.state.cartModalDetails)
                        }}
                        nameShop={this.state.nameShop}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart || []}
                        hasCustomDomain={this.props.hasCustomDomain}
                        shopCartStatus={this.state.shopCartStatus}
                        shopCartBankAccount={this.state.shopCartBankAccount}
                    />


                    {/* <Slider /> */}
                    {this.state.shopBackgroundPicture && <div style={{ height: '100%' }}>
                        <MainSlider
                            products={[
                                {
                                    pictureURL: this.state.shopBackgroundPicture || 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image.jpg?alt=media&token=d394849b-c350-4b71-bd3e-9ff05c2b9228',
                                    title: this.state.shopHeader || 'what a great summer!',
                                    text: this.state.tagline || 'find awesome things to wear this summer...',
                                    callToAction: 'Shop Now!'
                                }/* ,
                        {
                            pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image10.jpg?alt=media&token=d0c06722-8b34-4fea-aefc-2a4b15cac3a9',
                            title: "There's Something for Everyone!",
                            text: "whether mom, dad, boy or girl, everyone's invited!",
                            callToAction: 'Check it Out!'
                        },
                        {
                            pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image11.jpg?alt=media&token=f08daa64-3175-4b19-a004-e787cb649668',
                            title: 'December Arrivals ',
                            text: "Don't carry last, buy what you need before the rush",
                            callToAction: 'Buy Now!'
                        },
                        {
                            pictureURL: 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image13.jpg?alt=media&token=1d390713-4676-43e8-ab09-0ec92c504752',
                            title: 'Is It Your Birthday?',
                            text: 'We just might have something for you...!',
                            callToAction: 'Order Now!'
                        } */
                            ]}

                        />
                    </div>}
                    <div style={{
                        position: "fixed",
                        paddingLeft: 30,
                        marginBottom: 100,
                        marginLeft: -60,
                        zIndex: 110,
                        height: 120,
                        width: 6,
                        position: 'absolute'
                    }} className="d-none d-sm-block"><Socials shopSocials={this.state.shopSocials} /></div>
                    <div style={{ width: '80%', overflowX: 'hidden', margin: '0 auto', }} id='products'>
                        {!this.state.showModalCart && this.state.servicesOffered && <ModazCategories
                            products={this.props.shopData.posts}
                            nameShop={this.state.nameShop}
                            logo={this.state.logo}
                            shopNamefromURL={this.props.hasCustomDomain ? 'shop' : this.props.shopNamefromURL}
                            providerName={this.props.shopData.profile.name}
                            providerAddress={this.props.shopData.profile.locationWorkAddress}
                            phoneNumber={this.props.shopData.profile.phoneNumber}
                            url={this.state.url}
                            servicesOffered={this.state.servicesOffered}
                            theme={'electro'}
                            description={this.props.shopData.profile.description}
                            productTitleColor={this.state.shopColorTitle}
                            buttonColor={this.state.shopColorButton}
                            hasCustomDomain={this.props.hasCustomDomain}
                        />}
                    </div>





                    {!this.state.showModalCart && <div id="shop" style={{ marginTop: -100, overflow: 'hidden' }}>

                        <FeaturedProducts
                            updateCart={async (FullCart) => {
                                updateCart(FullCart)
                                await this.setState({ FullCart })
                                console.log('FullCart is...', this.state.FullCart)
                            }}
                            saveProductData={(clicked, selected) => {
                                this.setState({ clickedItems: clicked, selectedItems: selected })
                            }}
                            FullCart={this.state.FullCart}
                            selectedItems={this.state.selectedItems}
                            clickedItems={this.state.clickedItems || {}}
                            description={this.state.description}
                            products={this.props.shopData.posts}
                            nameShop={this.state.nameShop}
                            logo={this.state.logo}
                            shopNamefromURL={this.props.hasCustomDomain ? 'shop' : this.props.shopNamefromURL}
                            providerName={this.props.shopData.profile.name}
                            providerAddress={this.props.shopData.profile.locationWorkAddress}
                            phoneNumber={this.props.shopData.profile.phoneNumber}
                            url={this.state.url}
                            servicesOffered={this.props.shopData.profile.servicesOffered}
                            theme={'Modaz Cart'}
                            productTitleColor={this.state.shopColorTitle}
                            shopColorButton={this.state.shopColorButton}
                            shopColorBackground={this.props.shopData.shop.shopColorBackground}
                            hasCustomDomain={this.props.hasCustomDomain}
                            shopColorGradient1={this.props.shopData.shop.shopColorGradient1}
                            shopColorGradient2={this.props.shopData.shop.shopColorGradient2}
                            shopCartStatus={this.state.shopCartStatus}
                            shopSocials={this.state.shopSocials}
                        /></div>}


                    <Contact
                        shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                        shopSocials={this.state.shopSocials}
                    />


                    {(this.state.shopChat.status === "LIVE") && <Chat
                        textColor={'white'}
                        backgroundColor={'#cd0000'}
                        nameShop={this.state.nameShop}
                        shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}
                    />}

                    {!this.state.showModalCart && this.state.servicesOffered &&
                        <Footer
                            servicesOffered={this.state.servicesOffered}
                            providerName={this.props.shopData.profile.name}
                            providerAddress={this.props.shopData.profile.locationWorkAddress}
                            phoneNumber={this.props.shopData.profile.phoneNumber}
                            textColor={this.state.shopColorTitle}
                            buttonColor={this.state.shopColorButton}
                            shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                            shopSocials={this.state.shopSocials}
                            nameShop={this.state.nameShop ? this.state.nameShop : 'wakanda'}
                        />}


                    <div id='cart-modal' style={{
                        width: this.state.cartModalWidth,
                        height: 'inherit',
                        position: 'absolute',
                        right: 0, top: 60,
                        backgroundColor: 'white',
                        WebkitTransition: 'width 0.5s',
                        transition: 'width 0.5s',
                        paddingTop: 50,
                        paddingBottom: 100
                    }}>

                        <div onClick={() => {
                            let result = openAndCloseCartModal(this.state.cartModalWidth, this.state.showModalCart)
                            this.setState({ cartModalWidth: result.cartModalWidth, showModalCart: result.showModalCart })
                        }}>
                            <h1 style={{ color: 'black', paddingLeft: 50 }}>X</h1> </div>

                        {this.state.showModalCart && <div style={{ height: 'inherit', }}>
                            <Cart
                                CartDetails={this.state.cartModalDetails}
                                // cartShowbankDetails={this.state.cartShowbankDetails}
                                cartShowbankDetails={this.state.cartShowbankDetails}
                                cartTotal={this.state.cartTotal}
                                nameShop={this.state.nameShop}
                                updateCart={(FullCart) => {

                                    localStorage.setItem("cartStorage", JSON.stringify(FullCart));
                                    cartStorage = JSON.parse(localStorage.getItem("cartStorage"))
                                    WM_DATA_Storage.FullCart = cartStorage
                                    localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                    console.log('products added to cart are: ', this.state.FullCart, 'items stored in browser is therefore: ', WM_DATA_Storage.FullCart)
                                    this.setState({ FullCart })

                                }}

                                clearLocalStorageCart={() => {
                                    WM_DATA_Storage.FullCart = ''
                                    localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                    localStorage.setItem("cartStorage", "");
                                    this.setState({ FullCart: '' })
                                }}

                                storeCartDetails={(a, b) => {
                                    this.setState({ cartShowbankDetails: a, cartTotal: b })
                                }}
                            />
                        </div>}

                    </div>

                </div>
            </div>
        )
    }
}

export default Modaz
