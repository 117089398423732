import React, { Component } from 'react'
import { Link } from "react-router-dom"
import Cart from '../../../checkout/Cart'
import wkLogo from "../../../images/logos/home_logo1.png"
class Navbar extends Component {
    state = {
        shopNamefromURL: '',
        homeURL: '/',

        //search: true
    }


    componentWillMount = () => {



        setTimeout(() => {

            this.setState({
                shopNamefromURL: this.props.shopNamefromURL,
                homeURL: '/' + this.props.shopNamefromURL,

            })

            console.log('the referrer from the Navbar is: ', document.referrer || window.location.href)
        }, 3000);


    }

    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }


    chooseCartURL = () => {
        if (this.props.hasCustomDomain) {
            return '/cart'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
        else {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
    }




    render() {
        const shopLogo = this.props.logo
        console.log('checking', this.props.logo)

        return (
            <nav className="navbar navbar-expand-sm navbar-dark mb-4 bg-custom sticky-top " style={{ backgroundColor: '#fff', paddingBottom: 10 }}>
                <div className="container">

                    <Link to={this.chooseHomeURL()} style={{ fontWeight: 'bold', fontFamily: "Montserrat, sans-serif", color: 'black' }} className="navbar-brand text-uppercase rave-nav">{this.props.nameShop || 'WAKANDA - MARKET'}</Link>

                    <div className="" >

                        <ul className="navbar-nav ml-auto">
                            {this.props.showAbout && <li className="nav-item">
                                <a href="#about" className="nav-link" style={{color:'black'}}>About</a>
                            </li>}
                            {this.props.showContact && <li className="nav-item">
                                <a href="#contact" className="nav-link" style={{color:'black'}}>Contact</a>
                            </li>}
                            {this.props.shopCartStatus === "LIVE" && (
                                <li className="nav-item mt-2 ml-1 ">
                                    <a onClick={() => { this.props.showCartModal() }}>
                                        <i className="fas fa-shopping-cart text-black"  ></i>
                                        {this.props.FullCart.length > 0 && (
                                            <small
                                                style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    color: "white",
                                                    paddingTop: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                {this.props.FullCart.length}
                                            </small>
                                        )}
                                    </a>

                                </li> 

                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar