import React, { Component } from 'react';

class Footer extends Component {


    state = {
        providerName: '',
        shopProfileName: '',
        descriptionShop: '',
        services: [],
        address: '',
        phoneNumber: '',
        senderName: '',
        shopName: '',
        msg: false,
        error: false,
        categories: '',
        services: '',
        loading: ''

    }


    filterCategories = () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        //  const values1 = values.slice(0, values.length)
        //   const values2 = values1.join(' ')
        //const values1 = values.slice(0, values.length)
        //const values2 = values1.join(' ')
        const newvalues = [].concat(...values)


        console.log(' the categories are: ', keys, ' and the services are: ', values)
        this.setState({ categories: keys, services: newvalues });

    }


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })

        console.log('Username' + this.state.senderName)

        const newMessage = {
            shopName: this.props.shopNamefromURL,
            senderName: this.state.senderName,
            senderPhoneNumber: this.state.senderPhoneNumber,
            message: this.state.message
        }
        console.log('new', newMessage)
        const url = "https://us-central1-afbase-7ee60.cloudfunctions.net/shopMessage"
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newMessage)
        }).then((res) => res.json())
            .catch(error => { console.error('Error:', error); this.setState({ loading: false }) })
            .then(response => {


                console.log('Success:', response)
                if (response.msg == 'SUCCESS') {
                    this.setState({ msg: true, senderName: '', senderPhoneNumber: '', message: '', loading: false })
                } else {
                    this.setState({ error: true, loading: false })
                }
            });


    }
    componentDidMount = () => {
        if (this.props.servicesOffered) {
            console.log('services offered in shoppers footer component are: ', this.props.servicesOffered)
            this.filterCategories();
        }
    }



    render() {
        const { loading } = this.state
        return (
            <div>
                <div id="newsletter" className="section text-center">
                    <h4 className="text-black " >Send a message to <span style={{ color: '#fd4f53' }}><strong>{this.props.providerName}</strong></span></h4>
                    <div id="contact" class="section">
                        <form className="justify-content-center align-items-center" onSubmit={this.onSubmit}>
                            <div class="container justify-content-center align-items-center " >

                                <div class="row">
                                    <div class=" col-sm-12 col-md-6 col-lg-4">
                                        <div className="input-group ">
                                            <input
                                                className="input form-control-electro"
                                                type="text"
                                                placeholder="Enter Your Name"
                                                required
                                                name="senderName"
                                                value={this.state.senderName}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="input-group my-2">
                                            <input
                                                className="input form-control-electro input-space"
                                                type="tel"
                                                placeholder="Enter Your Phone Number"
                                                required
                                                name="senderPhoneNumber"
                                                value={this.state.senderPhoneNumber}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>

                                    <div class=" col-sm-12 col-md-6 col-lg-4">
                                        <div class="form-group input-group">
                                            <textarea
                                                className="form-control-electro input"
                                                id="exampleFormControlTextarea1"
                                                placeholder="Write your message..."
                                                rows="5"
                                                required
                                                name="message"
                                                style={{ height: '120px' }}
                                                value={this.state.message}
                                                onChange={this.onChange}>

                                            </textarea>
                                        </div>
                                    </div>



                                    <div class="col-md-4 ">
                                        <div className="form-group ">

                                            <button class="newsletter-btn">{loading && <i class="fa fa-spinner fa-spin"></i>} &nbsp;<i class="fa fa-envelope"></i> Send Message</button>
                                        </div>
                                        <div>
                                            <p className="mt-1 ">{this.state.msg && <div>
                                                <p className="text-success py-1 px-4  "> Message sent, seller will contact you shortly</p></div>}
                                                {this.state.error && <div><p className="text-danger px-4 py-1">Oops!, Message not sent</p></div>}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>

                    </div>

                </div>


                {/*====================  footer area ====================*/}

                <footer id="footer-electro">

                    <div class="section">

                        <div class="container">

                            <div class="row">
                                <div class="col-md-4 col-xs-6 mb-4">
                                    <div class="footer-electro">
                                        <h3 className="h2-electro" class="footer-title">About Us</h3>
                                        <p className="mb-2">{this.props.description}</p>
                                        <ul class="footer-links list-unstyled list-group">
                                            <li><a href="#" className="text-white"><i class="fas fa-map-marker-alt"></i>{this.props.address}</a></li>
                                            <li><a href="#" className="text-white"><i class="fas fa-phone"></i>{this.props.phoneNumber}</a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-4 col-xs-6 mb-4">
                                    <div class="footer-electro">
                                        <h3 className="h2-electro" class="footer-title">Categories</h3>
                                        {this.props.servicesOffered && <div>
                                            {this.state.categories && <ul className="list-unstyled list-group">
                                                {this.state.services.map((service, id) => {
                                                    return (
                                                        <li key={id}><p className="mt-1" >{service}</p></li>
                                                    )
                                                }
                                                )}
                                            </ul>}
                                        </div>}
                                    </div>
                                </div>

                                <div class="col-md-4 col-xs-6 mb-4">
                                    <div class="footer-electro">
                                        <h3 className="h2-electro" class="footer-title">Information</h3>
                                        <ul className="list-unstyled list-group ">
                                            <li className="text-white"><a href="#contact" className="text-white">Contact Us</a></li>
                                        </ul>
                                        <ul className="list-inline list-unstyled ">
                                            {this.props.shopSocials.facebook && <li className="list-inline-item m-1">
                                                <a href={this.props.shopSocials.facebook} target='_blank'>
                                                    <i class="fab fa-facebook-f fa-lg"></i>
                                                </a>
                                            </li>}
                                            {this.props.shopSocials.twitter && <li className="list-inline-item m-1">
                                                <a href={this.props.shopSocials.twitter} target='_blank'>
                                                    <i class="fab fa-twitter fa-lg"></i>
                                                </a>
                                            </li>}
                                            {this.props.shopSocials.instagram && <li className="list-inline-item m-1">
                                                <a href={this.props.shopSocials.instagram} target='_blank'>
                                                    <i class="fab fa-instagram fa-lg"></i>
                                                </a>
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>



                    <div id="bottom-footer" class="section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <span class="copyright">
                                        Copyright &copy; {new Date().getFullYear()} {this.props.nameShop ? this.props.nameShop : ''}. All rights reserved!
                                    </span>
                                </div>
                            </div>

                        </div>

                    </div>

                </footer>

            </div>
        )
    }
}


export default Footer;