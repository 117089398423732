import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import close from "../../../images/icons/cross.svg"
import wkLogo from "../../../images/logos/home_logo1.png"
//import Home from "../../Home"





const Navbar = (props) => {

    const shopLogo = props.logo
    console.log('checking', props.logo)

    const [shopNamefromURL, setShopNamefromURL] = useState('')
    const [homeURL, setHomeURL] = useState('/')
    const [headerTextWidth, setHeaderTextWidth] = useState((window.innerWidth <= 375) ? 200 : 500)
    const [shopInitials, setShopInitials] = useState('')
    const [lastName, setLastName] = useState('')
    const [pieces, setPieces] = useState('')

    useEffect(() => {
        setTimeout(() => {
                setShopNamefromURL(props.shopNamefromURL)
                setHomeURL('/' + props.shopNamefromURL)
           

            console.log('the referrer from the Navbar is: ', document.referrer || window.location.href)
        }, 3000);

        if (props.nameShop) {
            getInitialsColored()
        }


    }, [props])



    const chooseHomeURL = () => {
        if (props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + props.shopNamefromURL
        }
    }



    const chooseCartURL = () => {
        if (this.props.hasCustomDomain) {
            return '/cart'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
        else {
            return '/' + props.shopNamefromURL + '/cart'
        }
    }

    const getInitialsColored = () => {
        let name = props.nameShop.trim('')
        let pieces = name.split(' ')
        let initials = ''
        let lastName = ''

        console.log('your name pieces after split are: ', pieces)

       
        pieces.forEach(item => {
            initials += item[0]
        })
        if(pieces.length > 1){
        lastName = pieces.pop()
        setLastName(lastName)
        console.log('Pieces is ' , pieces)
        setPieces(pieces.join(' '))
    }
    else{
        
        setPieces(pieces.join(' '))
    }

        setShopInitials(initials )

        console.log('your shop initials are: ', initials)

    }

    return (
        <nav className="navbar navbar-expand-md navbar-dark sticky-top" style={{ backgroundColor: 'rgba(255,255,255,1 )' }}>
            <div className="container h-100" style={{ marginTop: 15, marginBottom: 15 }}>
                <Link to={chooseHomeURL()} style={{
                    fontWeight: 'bold', fontFamily: "Lato, sans-serif",
                    textOverflow: "ellipsis"
                }} className="navbar-brand">
                    <span style={{ backgroundColor: 'black', padding: 8, marginLeft: 0, color: 'white' }} className="text-uppercase">{shopInitials}</span>&nbsp;
                    <span className="text-black text-uppercase">{pieces}</span>&nbsp;<b style={{ color: props.shopColorHeader || '#f970b9' , textTransform: 'uppercase' }}>{lastName}</b></Link>

                {/* <span className='d-none' style={{ color: '#fff', fontSize: '30px', fontWeight: "lighter", marginBottom: '5px' }}> &nbsp;|&nbsp; </span> */}
                {/* <Link to={this.chooseHomeURL()} style={{
                    fontWeight: 'bold', fontFamily: "Lato, sans-serif", paddingLeft: 5,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }} className="navbar-brand">{this.props.nameShop || 'WAKANDA - MARKET'}</Link> */}
                <button className="navbar-toggler navbar-toggler-right collapsed" style={{ background: '#000' }} type="button" data-toggle="collapse" data-target="#navbar-collapse-x" aria-controls="navbar-collapse-x" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="my-1 mx-1 close text-white"><small>x</small></span>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse mt-2" id="navbar-collapse-x">
                    {props.showSearch &&
                        <form className="form-inline flex-nowrap  mx-0 mx-lg-auto">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                                </div>
                                <input className="form-control mr-sm-2 search-input " type="search" placeholder="Search" name="search" id="search" />
                            </div>

                        </form>}

                    <ul className="navbar-nav ml-auto">
                        {props.showAbout && <li className="nav-item">
                            <a href="#about" className="nav-link" style={{ color: "#000" }}>About</a>
                        </li>}
                        {props.showContact && <li className="nav-item">
                            <a href="#contact" className="nav-link" style={{ color: "#000" }}>Contact</a>
                        </li>}
                        {props.shopCartStatus === "LIVE" && (
                            <li className="nav-item mt-2 ml-1 ">
                                <a onClick={() => { props.showCartModal() }}>
                                    <i className="fas fa-shopping-cart text-black"  ></i>
                                    {props.FullCart.length > 0 && (
                                        <small
                                            style={{
                                                backgroundColor: "red",
                                                borderRadius: "50%",
                                                paddingRight: 5,
                                                paddingLeft: 5,
                                                color: "white",
                                                paddingTop: 2,
                                                paddingBottom: 2
                                            }}
                                        >
                                            {props.FullCart.length}
                                        </small>
                                    )}
                                </a>

                            </li>

                        )}

                    </ul>
                </div>
            </div>
        </nav>
    )









}


export default Navbar
