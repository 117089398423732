import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import NavBar from './Navbar'

import Footer from './Footer'
import leftArrow from '../../../assets/left-arrow.svg'
//import shoppingCartIcon from "../../../images/icons/shopping-cart.svg"
import shoppingCartIcon from "../../../images/icons/add_shopping_cart-24px.svg"
import shippingIcon from "../../../images/icons/truck.svg"


class RaveProductPage extends Component {
    state = {
        product: {
            title: '',
            text: '',
            price: '',
            pictureURL: '',
        },
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        postIDs: '',
        xID: '',
        shopNamefromURL: '',
        nameShop: '',
        url: '',
        shopID: '',
        redirect: false,
        productExists: true,
        loading: true,
        shopCartStatus: '',
        shopCartBankAccount: '',
        shopChat: '',
        shopNamefromURL: '',
        tagline: '',
        FullCart: '',
        cartModalDetails: {
            FullCart: '',
            shopNamefromURL: '',
            hasCustomDomain: '',
            nameShop: '',
            shopCartBankAccount: ''
        },
        cartModalWidth: 0,
        showModalCart: false,
        theme: 'Rave'
    }

    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }

    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + " " + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + " " + pString;
            }
            else {
                priceString =
                    currency + " " + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = " ";
            }
            return priceString
        }
        //  return pString
    }




    render() {
        return (
            <div style={{ backgroundColor: 'white' }}>
                <div style={{ borderWidth: '2px', borderBottomWidth: '2px', borderBottomColor: 'linear-gradient(to right, rgba(12, 0, 255, 0.8),rgba(250, 16, 83, 0.8))' }}>
                    <NavBar

                        showSearch={false}
                        showAbout={true}
                        showContact={true}
                        showMenuItems={true}
                        nameShop={this.props.nameShop}
                        logo={this.props.logo}
                        shopNamefromURL={this.props.hasCustomDomain ? '' : this.props.shopNamefromURL}
                        description={this.props.description}
                        FullCart={this.props.FullCart}
                        hasCustomDomain={this.props.hasCustomDomain}
                        shopCartStatus={this.props.shopCartStatus}
                        shopCartBankAccount={this.props.shopCartBankAccount}
                    />
                </div>


                {/*  <section>
                    <div>
                        {this.state.loading && this.state.productExists === '' && <p className="lead">Product loading...</p>}

                    </div>
                    <div style={{ width: '300px', margin: 'auto', display: 'block' }} className="my-4" >
                        <img src={this.props.pictureURL} alt="picture" className="img-fluid rounded mx-auto d-block" />
                    </div>
                    <div className="container" style={{ justifyContent: 'center' }} >
                        <h3 className="text-muted text-center"><b>{this.props.title || 'Product title'}</b></h3>
                        <p className="text-muted text-center">{this.props.text || 'Product text'}</p>
                        <h5 className="text-center" style={{ color: 'black' }}>{this.props.price || 'Product price'}</h5>
                        <hr />
                    </div>
                </section> */}

                <div className="container mb-4">
                    {(this.props.shopNamefromURL || this.props.nameShop) && <Link to={this.chooseHomeURL()}><img src={leftArrow} style={{ width: 40, height: 40, cursor: 'pointer' }} /></Link>}</div>
                <div className=" mx-auto container row mb-4">

                    {this.props.productExists === false && this.props.loading && <div style={{ height: '200px' }} >
                        <h2 >Oops! :'( Product Does Not Exist!</h2>
                        <p>Please contact the provider for a valid product link</p>
                    </div>
                    }
                    <div className="col-md-6">



                        {/* this.props.productExists && <div style={{ justifyContent: 'center' }} className="mb-4">
                            <img src={this.props.pictureURL} alt="picture"
                                className="img-fluid img-thumbnail" />
                        </div> */}


{this.props.productExists && <div  style={{
                                    backgroundImage: `url(${this.state.imageInView || this.props.pictureURL})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundAttachment: 'relative',
                                    backgroundColor: 'rgba(255,255,255,0.3)',
                                    overflow: 'hidden',
                                    height: "auto",
                                    maxWidth: '104%',
                                    width: '100%',
                                    height: 400,
                                    border: '1px solid rgba(204, 204, 204, 0.5)', 
                                    marginBottom:20
                                }}></div> }

                        {this.props.productImages[this.props.xID] && this.props.productExists && (this.props.productImages[this.props.xID].length > 1) && <div style={{ display: 'flex', justifyContent: 'left', marginTop: 10, marginBottom: 10, width: '100%', height:'auto' }}>
                            {this.props.productImages[this.props.xID].map((image, id) => {
                                return (
                                    <div key={id} style={{
                                        backgroundImage: `url(${image})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center",
                                        backgroundAttachment: 'relative',
                                        backgroundColor: 'rgba(255,255,255,0.3)',
                                        overflow: 'hidden',
                                        height: "auto",
                                        maxWidth: '104%',
                                        width: 100,
                                        height: 100,
                                        border: '1px solid rgba(204, 204, 204, 0.5)',
                                        marginRight: 10
                                    }}
                                        onClick={() => this.setState({ imageInView: image })}
                                    ></div>
                                )
                            })}
                        </div>}




                        {!this.props.pictureURL &&
                            <p className="lead">Product loading...</p>
                        }


                    </div>

                    <div class=" mr-md-3  px-3 px-md-5 overflow-hidden col-md-5 m-0" >


                        <h3 style={{ color: 'black' }} className="m-0 p-0" >{this.props.title}</h3>
                        <h5 className=" mt-0" style={{ color: this.props.shopColorHeader || "#f970b9", marginBottom: 30 }} >{this.props.price}</h5>
                        <hr />
                        {this.props.shopCartStatus === 'LIVE' && <div>
                            {/* <p className="text-black py-1 " style={{ fontSize: 14 }}><span className="mr-3">Quantity</span><span className="border py-3"><span><button style={{ backgroundColor: 'white', color: 'black', }} >-</button></span><span className="border-right border-left px-3">{this.state.quantity}</span><span><button style={{ backgroundColor: 'white', color: 'black' }}>+</button></span></span></p> */}
                            {/* <hr /> */}
                            <button style={{ backgroundColor: 'black', paddingLeft: 50, paddingRight: 50 }}>BUY NOW</button>
                            <button style={{ backgroundColor: 'transparent', color: 'black', fontSize: 12 }}><img
                                src={shoppingCartIcon}
                                alt="shopping cart icon"
                                width="30"
                                height="30"
                            />Add to Cart</button>
                            <hr />
                        </div>}


                    </div>
                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="mb-4 box-shadow">
                                <div class="card-body" style={{ backgroundColor: "#F3F3F3" }}>
                                    <h5 style={{ color: 'black' }}>DESCRIPTION</h5>
                                    <hr />
                                    <p class="card-text" style={{ color: 'black', fontSize: 12, lineHeight: 2 }}>{this.props.text}</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class=" mb-4 box-shadow">
                                <div class="card-body" style={{ backgroundColor: "#F3F3F3" }}>
                                    <h5 style={{ color: 'black' }}>SHIPPING & RETURNS</h5>
                                    <hr />
                                    <p class="card-text" style={{ color: 'black', fontSize: 12, lineHeight: 2 }}><img
                                        src={shippingIcon}
                                        alt="shopping cart icon"
                                        width="30"
                                        height="30"
                                    />&nbsp;Contact us for delivery details.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class=" mb-4 box-shadow">
                                <div class="card-body" style={{ backgroundColor: "#F3F3F3" }}>
                                    <h5 style={{ color: 'black' }}>REVIEWS</h5>
                                    <hr />
                                    <p class="card-text" style={{ color: 'black', fontSize: 12, lineHeight: 2 }}> No reviews at this time.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer
                    servicesOffered={this.props.servicesOffered}
                    providerName={this.props.providerName || this.state.providerName}
                    providerAddress={this.props.providerAddress || this.state.providerAddress}
                    phoneNumber={this.props.phoneNumber || this.state.phoneNumber}
                    servicesOffered={this.props.servicesOffered}
                    shopColorButton={this.props.shopColorButton}
                    nameShop={this.props.nameShop ? this.props.nameShop : 'wakanda'}
                />


            </div>
        );
    }
}

export default RaveProductPage;