import React, { Component } from 'react'

import Styles from './Home.module.css'

import Wakanda from './components/Home/wakandaHome'
import Wakanda2 from './components/Home/wakandaHome2'
import App from './App'
import Loader from './components/layout/Loader'
import { Redirect } from 'react-router-dom'
import Shoppers from './components/themes/shoppers/shoppers'
import Electro from './components/themes/electro-master/electro'
import Rave from './components/themes/rave/Rave'
import HighFashion from './components/themes/HighFashion/HighFashion'
import FredaEdu from './components/themes/Freda-Edu/fredaEdu'
import Modaz from "./components/themes/modaz/Modaz"
import WakandaEducation from './WakandaEducation'
import Engineer from './components/themes/Engineer/Engineer'
import CakeFactory from "./components/themes/CakeFactory/CFactory"
import StandardWhite from "./components/themes/StandardWhite/StandardWhite"

//import 'firebase/firestore';
//import config from './config/chatConfig'


//------------------ Imports for Blog -------------------- //
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faSpinner,
    faBars,
    faUpload,
    faPlusCircle,
    faThumbsUp
} from "@fortawesome/free-solid-svg-icons";

library.add(faSpinner, faBars, faUpload, faPlusCircle, faThumbsUp);

//------------------ Firebase -------------------- //
/* 
const firebase = require('firebase')
firebase.initializeApp(config) */
//------------------ Firebse -------------------- //

let WM_DATA_Storage = {
    shopData: "",
    url: "",
    hasCustomDomain: "",
    theme: "",
    shopNamefromURL: "",
    nameShop: "",
    FullCart: [],
    clickedItems: {}
}

if (localStorage.getItem("WM_DATA_Storage") !== "" || localStorage.getItem("WM_DATA_Storage") !== null) {
    WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
    console.log('WM_DATA_Storage in Home is null or undefined: ', WM_DATA_Storage, ' in localstorage it is: ', localStorage.getItem("WM_DATA_Storage"))
} else {
    localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage))
    console.log('WM_DATA_Storage in Home is available: ', WM_DATA_Storage, ' in localstorage it is: ', JSON.parse(localStorage.getItem("WM_DATA_Storage")))
}

let clickedItems = JSON.parse(localStorage.getItem("clickedItems")) == undefined ? {} : JSON.parse(localStorage.getItem("clickedItems"))


class Home extends Component {
    state = {
        hasCustomDomain: false,
        shopData: '',
        shopNamefromURL: '',
        url: '',
        initialURL: '',
        loading: true,
        redirect: false,
        theme: '',
        loadHomePage: false,
        defaultURLandValidShopnamePresent: false,
        HomePageData: '',
        loadEducation: false,
        shopCategory: 'market'
    }

    fetchDataWithURL = async () => {
        console.log('attempting to fetch data with URL', this.state.initialURL)
        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    url: `${this.state.initialURL}`
                })
            });
            const r2 = await r.json();

            if (r2.msg == 'SUCCESS') {
                // await this.setState({ hasCustomDomain: true })
                console.log("Data received from Home. Server data is: ", r2.msg);
                console.log("Server Data is: ", r2.data);
                console.log("Profile ", r2.data.profile);

                let shopData = r2.data
                let theme = r2.data.shop.shopType
                let shopCategory = r2.data.shop.shopCategory
                /* console.log('shopPost', shopPost)
                let shopPost = r2.data.posts
                let shop = r2.data.shop*/
                console.log('shopData from fetchdata with URL is: ', shopData)
                //await this.setState({ hasCustomDomain: true, shopData, url: this.parseURLandReturnRelevantURL(), defaultURLandValidShopnamePresent: true, theme, loading: false, shopCategory })
                //window.location.href('/')
                await this.setState({ hasCustomDomain: true, shopData, defaultURLandValidShopnamePresent: true, theme, loading: false, shopCategory })
                console.log('shopData from fetchdata with URL is: ', shopData)
                //WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
                //localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                WM_DATA_Storage = {
                    shopData: this.state.shopData,
                    url: this.state.initialURL,
                    hasCustomDomain: this.state.hasCustomDomain,
                    theme: this.state.theme,
                    shopNamefromURL: this.state.shopNamefromURL,
                    nameShop: this.state.nameShop,
                    FullCart: this.state.FullCart,
                    clickedItems: clickedItems
                }
                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));


            } else {
                console.log('no custom URL detected... ', r2.msg)

                //this.fetchDataWithShopName()
                //this.setState({ hasCustomDomain: false, loading: false })

            }

        } catch (err) {
            console.log("Error from firebase using fetchdatawithurl is: ", err);
            //this.setState({ hasCustomDomain: false, redirect: true })

        }

    }

    fetchDataWithShopName = async () => {

        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    shopName: `${this.state.shopNamefromURL}`
                })
            });
            const r2 = await r.json();

            if (r2.msg == 'SUCCESS') {
                // await this.setState({ hasCustomDomain: true }) 
                console.log("Server Data is: ", r2.data);
                console.log("Profile ", r2.data.profile);


                let shopData = r2.data
                let theme = r2.data.shop.shopType
                let shopCategory = r2.data.shop.shopCategory
                console.log("shopCategory is:  ", shopCategory);
                /* console.log('shopPost', shopPost)
                let shopPost = r2.data.posts
                let shop = r2.data.shop
                console.log('shops', shop) */
                await this.setState({ defaultURLandValidShopnamePresent: true, shopData, loading: false, theme, shopCategory })
                //await this.setState({ defaultURLandValidShopnamePresent: true, shopData, loading: false, shopCategory })
                WM_DATA_Storage = {
                    shopData: this.state.shopData,
                    url: this.state.url,
                    hasCustomDomain: this.state.hasCustomDomain,
                    theme: this.state.theme,
                    shopNamefromURL: this.state.shopNamefromURL,
                    nameShop: this.state.nameShop,
                    FullCart: this.state.FullCart,
                    clickedItems: clickedItems
                }
                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));


            } else {
                console.log('no valid shopName detected from URL... ', r2.msg)
                this.setState({ loading: false, redirect: true })
            }

            return

        } catch (err) {
            console.log("Error from firebase using fetchdatawithshopname is: ", err);
            this.setState({ loading: false, redirect: true })

        }

    }


    parseURLandReturnRelevantURL = async () => {
        try {
            const fullURL = window.location.href.split('#')[0]
            const fullURLsubStrings = fullURL.split('/');
            console.log(fullURLsubStrings);
            await this.setState({ initialURL: fullURLsubStrings[2], shopNamefromURL: fullURLsubStrings[3] })
            console.log('the shopnamefromURL in Home js is: ', this.state.shopNamefromURL);
            //await this.setState({ url: fullURLsubStrings[2] })*/
            // return fullURLsubStrings[2]; 
        } catch (err) {
            console.log('issues with parsing URL ', err)
        }

    }

    componentDidMount = async () => {



        await this.parseURLandReturnRelevantURL()


        if (!clickedItems) {
            WM_DATA_Storage.clickedItems = clickedItems
            console.log(" blah blah what's happening here? ...")
            localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage))
            this.setState({ clickedItems })
        } else {
            this.setState({ clickedItems })
        }

        if (WM_DATA_Storage && (WM_DATA_Storage.shopNamefromURL === this.state.shopNamefromURL)) {

            console.log('loading Data from Browser Local Storage: ', WM_DATA_Storage, ' FullCart items are ', WM_DATA_Storage.FullCart)
            const { shopData, url, hasCustomDomain, theme, shopNamefromURL, nameShop, FullCart } = WM_DATA_Storage


            if ((this.state.initialURL === 'wakanda-market.com' || this.state.initialURL === 'www.wakanda-market.com' || this.state.initialURL === 'localhost:3000') && (this.state.shopNamefromURL === WM_DATA_Storage.shopNamefromURL)) {

                this.setState({ FullCart, shopData, url, hasCustomDomain, theme, shopNamefromURL, nameShop, defaultURLandValidShopnamePresent: true, loading: false })
                this.fetchDataWithShopName()

            } else if ((this.state.initialURL === 'wakanda-market.com' || this.state.initialURL === 'www.wakanda-market.com' || this.state.initialURL === 'localhost:3000') && this.state.shopNamefromURL === '') {
                this.setState({ loadHomePage: true, loading: false })
            }
            /////////////////// WAKANDA EDUCATION LOADING PARAMETERS /////////////////////
            else if ((this.state.initialURL === 'wakanda-education.com' || this.state.initialURL === 'www.wakanda-education.com' || this.state.initialURL === 'localhost:3000') && (this.state.shopNamefromURL === WM_DATA_Storage.shopNamefromURL)) {

                await this.setState({ shopData, url, hasCustomDomain, theme, shopNamefromURL, nameShop, defaultURLandValidShopnamePresent: true, loading: false })
                this.fetchDataWithShopName()
            }
            else if ((this.state.initialURL === 'wakanda-education.com' || this.state.initialURL === 'www.wakanda-education.com' || this.state.initialURL === 'localhost:3000') && this.state.shopNamefromURL === '') {
                this.setState({ loadEducation: true, loading: false })
            } else {
                await this.setState({ FullCart, shopData, url, hasCustomDomain, theme, shopNamefromURL, nameShop, loading: false })
                this.fetchDataWithURL()
            }
            /*  else if ((this.state.initialURL === 'wakanda-education.com') && this.state.shopNamefromURL && (this.state.shopCategory !== 'education')){
                 this.setState({loading: false, redirect:true})
             } */
            /////////////////// WAKANDA EDUCATION LOADING PARAMETERS END /////////////////////



        }
        else {
            if ((this.state.initialURL === 'wakanda-market.com' || this.state.initialURL === 'www.wakanda-market.com' || this.state.initialURL === 'localhost:3000') && this.state.shopNamefromURL) {
                console.log('Browser Local Storage not available  ')
                await this.fetchDataWithShopName()
                WM_DATA_Storage = {
                    shopData: this.state.shopData,
                    url: this.state.url,
                    hasCustomDomain: this.state.hasCustomDomain,
                    theme: this.state.theme,
                    shopNamefromURL: this.state.shopNamefromURL,
                    nameShop: this.state.nameShop,
                    FullCart: [],
                    clickedItems: {}
                }
                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                localStorage.removeItem("cartStorage");
                localStorage.removeItem("clickedItems");
            }
            else if (this.state.initialURL !== 'wakanda-market.com' && this.state.initialURL !== 'wakanda-education.com' && this.state.initialURL !== 'www.wakanda-market.com' && this.state.initialURL !== 'www.wakanda-education.com' && this.state.initialURL !== 'localhost:3000') {
                console.log('no shopnamefromURL found so calling fetchdatawithURL()...')
                await this.fetchDataWithURL()
            }
            else if ((this.state.initialURL === 'wakanda-market.com' || this.state.initialURL === 'www.wakanda-market.com' || this.state.initialURL === 'localhost:3000') && this.state.shopNamefromURL === '') {
                console.log('loadHomePage: true, loading: false...')
                this.setState({ loadHomePage: true, loading: false })
            }

            /////////////////// WAKANDA EDUCATION LOADING PARAMETERS /////////////////////
            else if ((this.state.initialURL === 'wakanda-education.com' || this.state.initialURL === 'www.wakanda-education.com' || this.state.initialURL === 'localhost:3000') && this.state.shopNamefromURL) {
                console.log('calling... this.fetchDataWithShopName()')
                this.fetchDataWithShopName()
            }
            else if ((this.state.initialURL === 'wakanda-education.com' || this.state.initialURL === 'www.wakanda-education.com' || this.state.initialURL === 'localhost:3000') && this.state.shopNamefromURL === '') {
                console.log('loadEducation: true, loading: false')
                this.setState({ loadEducation: true, loading: false })
            }
            /*  else if ((this.state.initialURL === 'wakanda-education.com') && this.state.shopNamefromURL && (this.state.shopCategory !== 'education')){
                 this.setState({loading: false, redirect:true})
             } */
            /////////////////// WAKANDA EDUCATION LOADING PARAMETERS END /////////////////////


            else {
                this.setState({ redirect: true })
            }
        }

    }


    render() {
        return (
            <div style={{ height: '100%', width: '100%' }} className={`${Styles.main}`} >
                {this.state.redirect && <div>
                    <Redirect
                        to='/not-found'
                    />
                </div>}
                {this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Standard' && <div >
                    <App
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}

                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Shoppers') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Shoppers')) && <div>
                    <Shoppers
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Electro') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Electro')) && <div>
                    <Electro
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Rave') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Rave')) && <div>
                    <Rave
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Modaz') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Modaz')) && <div>
                    <Modaz
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'HighFashion') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'HighFashion')) && <div>
                    <HighFashion
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Engineer') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Engineer')) && <div>
                    <Engineer
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Cake Factory') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Cake Factory')) && <div>
                    <CakeFactory
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Standard White') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Standard White')) && <div>
                    <StandardWhite
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Teacher' && this.state.shopCategory === 'education') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Teacher' && this.state.shopCategory === 'education')) && <div>
                    <FredaEdu
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                        FullCart={this.state.FullCart}
                        clickedItems={this.state.clickedItems}
                    />
                </div>}
                {((this.state.defaultURLandValidShopnamePresent && this.state.theme === 'Teacher') || (!this.state.defaultURLandValidShopnamePresent && this.state.hasCustomDomain && this.state.theme === 'Teacher')) && <div>
                    <FredaEdu
                        shopData={this.state.shopData}
                        url={this.state.url}
                        hasCustomDomain={this.state.hasCustomDomain}
                        theme={this.state.theme}
                        shopNamefromURL={this.state.shopNamefromURL}
                    />
                </div>}

                {/*  {this.state.loading &&
                    <div style={{ height: '100%' }}> <Loader />
                    </div>} */}

                {this.state.loadHomePage && !this.state.loading && <div>
                    <Wakanda2></Wakanda2>
                    {/* <Wakanda></Wakanda> */}
                    {/* <NewLandingPage /> */}
                </div>}
                {this.state.loadEducation && !this.state.loading && <div>
                    <WakandaEducation></WakandaEducation>
                    {/* <NewLandingPage /> */}
                </div>}

            </div>

        );
    }
}

export default Home;