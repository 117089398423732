import React, { Component } from 'react'
import './css/new-age.css'
import './device-mockups/device-mockups.css'
import './vendor/simple-line-icons/css/simple-line-icons.css'

class socialConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <section className="contact bg-social" id="contact">
                    <div className="container">
                        <h2 style={{color:'white'}}>We
                            <i className="fas fa-heart"></i>
                            new friends!</h2>
                            <ul className="list-inline list-social">
                            <li className="list-inline-item social-twitter">
                                <a href="https://twitter.com/WakandaMarket" target="_blank" >
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li className="list-inline-item social-facebook">
                                <a href="https://www.facebook.com/wakandamrkt/" target="_blank" >
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li className="list-inline-item social-google-plus">
                                <a href="https://www.instagram.com/wakandamarketapp/" target="_blank" >
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>

        );
    }
}

export default socialConnect;