import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Admin from "../Admin/Admin";

import firebase from "../Firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SignIn.css";

export default class SignIn extends Component {
  state = {
    renderVerification: false,
    showSignIn: true,
    code: "",
    error: "",
    renderAdminpage: false,
    isLoading: false,
    loading: false,
    reLoad: false,
    user: "",
    resendMessage: false,
    initialURL: "",
    hasCustomDomain: false
  };

  componentDidMount = async () => {
    //1. determine user shopname/url
    await this.parseURLandReturnRelevantURL();

    //2.  store user incase of reload
    //if stored user == user from parseurlfunction then it will set certian value true or false
    let presentuser = localStorage.getItem("WAKANDA_BLOG_SIGNEDIN_USER");
    let hasCustomDomain = localStorage.getItem(
      "WAKANDA_USER_HAS_CUSTOM_DOMAIN"
    );

    if (presentuser && this.state.user == presentuser) {
      this.setState({
        showSignIn: false,
        renderVerification: false,
        renderAdminpage: true,
        resendMessage: false,
        isLoading: false,
        loading: false,
        user:presentuser,
        hasCustomDomain: hasCustomDomain == null ?false:hasCustomDomain
      });
      console.log('user in signin is: ', this.state.user )
    }

    localStorage.setItem("WAKANDA_BLOG_SIGNEDIN_USER", this.state.user);
    localStorage.setItem(
      "WAKANDA_USER_HAS_CUSTOM_DOMAIN",
      this.state.hasCustomDomain
    );

    // include signout button
  };

  parseURLandReturnRelevantURL = async () => {
    try {
      const fullURL = window.location.href.split("#")[0];
      const fullURLsubStrings = fullURL.split("/");
      console.log(fullURLsubStrings);
      await this.setState({
        initialURL: fullURLsubStrings[2],
        user: fullURLsubStrings[3]
      });
      console.log(
        "the user in signin js is: ",
        this.state.user,
        "initial url is: ",
        this.state.initialURL
      );

      if (fullURLsubStrings[3] == "blog") {
        this.fetchDataWithURL();
      }
    } catch (err) {
      console.log("issues with parsing URL ", err);
    }
  };

  fetchDataWithURL = async () => {
    console.log("attempting to fetch data with URL", this.state.initialURL);
    try {
      const r = await fetch(
        "https://us-central1-afbase-7ee60.cloudfunctions.net/shop",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            url: `${this.state.initialURL}`
          })
        }
      );
      const r2 = await r.json();

      if (r2.msg == "SUCCESS") {
        // await this.setState({ hasCustomDomain: true })
        console.log("Data received from Home. Server data is: ", r2.msg);
        console.log("Server Data is: ", r2.data);
        console.log("Profile ", r2.data.profile);

        let shopData = r2.data;
        let user = shopData.shop.shopName;
        /* 
        let theme = r2.data.shop.shopType
        let shopCategory = r2.data.shop.shopCategory  */
        console.log("shopData from fetchdata with URL is: ", shopData);
        await this.setState({ hasCustomDomain: true, user });
        console.log("shopData from fetchdata with URL is: ", shopData);
      } else {
        console.log("no custom URL detected... ", r2.msg);

        //this.fetchDataWithShopName()
        //this.setState({ hasCustomDomain: false, loading: false })
      }
    } catch (err) {
      console.log("Error from firebase using fetchdatawithurl is: ", err);
      //this.setState({ hasCustomDomain: false, redirect: true })
    }
  };

  handleshopURL = async () => {
    console.log("this function has been called");
    this.setState({ isLoading: true });

    try {
      const r = await fetch(
        " https://us-central1-af-shop.cloudfunctions.net/signIn",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user
          })
        }
      );
      const r2 = await r.json();
      console.log(r2);
      if (r2.msg === "SUCCESS") {
        await this.setState({
          renderVerification: true,
          showSignIn: false
        });
      }
      // alert("Error: " + r2.msg);
    } catch (err) {
      alert("An error occurred.");
      console.log(err, "there seems to be an error");
      // this.setState({ activityIndicatorAnimating: false });
    }
  };
  getOtp = async e => {
    // console.log("this is to getotp: ");
    this.setState({ loading: true });

    try {
      const r = await fetch(
        "https://us-central1-af-shop.cloudfunctions.net/verifyOTP",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user,
            otpCode: this.state.code
          })
        }
      );
      const r3 = await r.json();
      console.log("this is the otp code", r3.token);
      console.log(r3.msg);
      if (r3.msg === "AUTH_SUCCESS") {
        this.setState({
          showSignIn: false,
          renderVerification: false,
          renderAdminpage: true,
          resendMessage: false,
          isLoading: false,
          loading: false
        });
        console.log(r3.token);
        await firebase.auth().signInWithCustomToken(r3.token);

        console.log("Successfully authenticated with Firebase");
      } else {
        this.setState({ error: r3.authMessage, loading: false });
      }
    } catch (err) {
      // alert("An error occurred.");
      console.log(err, "there seems to be an error");
      // this.setState({ error: err });
    }
  };
  resendOtp = async () => {
    console.log("this function has been called");

    await this.setState({ reLoad: true, error: "" });

    try {
      const r = await fetch(
        " https://us-central1-af-shop.cloudfunctions.net/signIn",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user
          })
        }
      );
      const r2 = await r.json();
      console.log("response message", r2);
      if (r2.msg === "SUCCESS") {
        await this.setState({
          reLoad: false,
          resendMessage: true
        });
      }
      // alert("Error: " + r2.msg);
    } catch (err) {
      alert("An error occurred.");
      console.log(err, "there seems to be an error");
      // this.setState({ activityIndicatorAnimating: false });
    }
  };

  render() {
    console.log("SignIn Page", this.state.showSignIn);

    return (
      <div>
        <div className="pageContainer">
          <div className="wrapper">
            {this.state.showSignIn && (
              <div className="signin-form">
                <h1 className="pageText">Sign In To Access Your Page</h1>

                <div>
                  <div>
                    <p className="name-help">{this.state.error}</p>
                  </div>

                  <button onClick={this.handleshopURL} className="signinButton">
                    sign in
                  </button>
                </div>
                <div className="signinLoading">
                  {this.state.isLoading ? (
                    <div className="loading">
                      <FontAwesomeIcon icon="spinner" className="loading" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            {this.state.renderVerification && (
              <div id="wrapper">
                <div id="dialog" className="dialog">
                  <p>
                    Please Enter Verification Code Sent Via Wakanda Mobile App
                  </p>

                  <div id="form">
                    <div>
                      <input
                      className='input-blog'
                        value={this.state.code}
                        onChange={event =>
                          this.setState({ code: event.target.value })
                        }
                        type="text"
                        maxLength="6"
                        size="6"
                        min="0"
                        max="9"
                        pattern="[0-9]{6}"
                      />
                    </div>
                    <div className="buttonDiv">
                      {" "}
                      <button
                        className="btn btn-primary btn-embossed"
                        onClick={this.getOtp}
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                  {this.state.loading ? (
                    <div className="SignInauthenticate">
                      <FontAwesomeIcon icon="spinner" className="loading " />
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.error === "TOO_MANY_ATTEMPTS" ? (
                    <div>
                      <div className="errorMessage">
                        Too Many atempt, try again in 24hrs
                      </div>
                    </div>
                  ) : this.state.error === "INCORRECT_CODE" ? (
                    <div className="error">
                      <p> Incorrect code</p>
                      <Link className="reVerify" onClick={this.resendOtp}>
                        Send code again
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.reLoad ? (
                    <div className="SignInauthenticate">
                      <FontAwesomeIcon icon="spinner" className="loading " />
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.resendMessage === true ? (
                    <div>
                      <p>OTP has been sent</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
          {this.state.renderAdminpage && (
            <Admin
              user={this.state.user}
              hasCustomDomain={this.state.hasCustomDomain} 
            />
          )}
        </div>
      </div>
    );
  }
}
