import React, { Component } from "react";
import SideDrawer from "./sideDrawer";
import Backdrop from "./backdrop";
import { Link } from "react-router-dom";

class NavSection extends Component {
    state = {
        sideDrawerOpen: false,
        showAbout: false,
        showNavigation: false
    };

    toggleDrawer = () => {
        this.setState(prevState => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen };
        });
    };

    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false });
    };

    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return "/";
        } else if (
            window.location.href === "http://localhost:3000/" ||
            window.location.href === "https://localhost:3000/" ||
            window.location.href === "http://wakanda-market.com" ||
            window.location.href === "https://wakanda-market.com"
        ) {
            return "/";
        } else {
            return "/" + this.props.shopNamefromURL;
        }
    };

    chooseCartURL = () => {
        if (this.props.hasCustomDomain) {
            return "/cart";
        } else if (
            window.location.href === "http://localhost:3000/" ||
            window.location.href === "https://localhost:3000/" ||
            window.location.href === "http://wakanda-market.com" ||
            window.location.href === "https://wakanda-market.com"
        ) {
            return "/" + this.props.shopNamefromURL + "/cart";
        } else {
            return "/" + this.props.shopNamefromURL + "/cart";
        }
    };

    render() {
        let sideDrawer;
        let backdrop;

        if (this.state.sideDrawerOpen) {
            sideDrawer = (
                <SideDrawer
                    FullCart={this.props.FullCart}
                    shopCartStatus={this.props.shopCartStatus}
                    shopCartBankAccount={this.props.shopCartBankAccount}
                    showNavigation={this.state.showNavigation}
                    showAbout={this.state.showAbout}
                    description={this.props.description}
                    showMenuItems={this.props.showMenuItems}
                    shopNamefromURL={this.props.shopNamefromURL}
                    showCartModal={()=>{this.props.showCartModal()}}
                />
            );
            backdrop = <Backdrop click={this.backdropClickHandler} />;
        }
        let sideDrawer2 = <SideDrawer />;

        return (
            <div className="site-navbar anotherStyle">

                <div className="row col-sm-12">
                    <div className="site-logo">
                        <a href={this.chooseHomeURL()}>
                            {this.props.nameShop || "Shoppers"}
                        </a>
                    </div>

                    <div className="col-sm-12">
                        <div className="">
                            <ul>
                                <li className="d-inline d-md-none ml-md-0">
                                    <a
                                        href="#"
                                        style={{ textDecoration: "none" }}
                                        onClick={() => {
                                            this.toggleDrawer();
                                            this.setState({
                                                showNavigation: true,
                                                showAbout: false
                                            });
                                        }}
                                        className=""
                                    >
                                        <span className="icons8-menu float-right"></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <nav className="site-navigation text-center" role="navigation">
                    <div className="container">
                        <ul className="site-menu js-clone-nav d-none d-md-block">
                            <li>
                                <a href={this.chooseHomeURL()}>Home</a>
                            </li>
                            {this.props.showMenuItems && (
                                <li>
                                    <a href="#about">About</a>
                                </li>
                            )}
                            {this.props.showMenuItems && (
                                <li>
                                    <a href="#shop">Shop</a>
                                </li>
                            )}
                            {this.props.showMenuItems && (
                                <li>
                                    <a href="#collections">Collections</a>
                                </li>
                            )}
                            <li>
                                <a href="#footer">Contact</a>
                            </li>
                            {this.props.shopCartStatus === "LIVE" && (
                                <li>
                                    <a onClick={() => { this.props.showCartModal() }}>
                                        <i className="fas fa-shopping-cart"></i>
                                        {this.props.FullCart.length > 0 && (
                                            <small
                                                style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    color: "white",
                                                    paddingTop: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                {this.props.FullCart.length}
                                            </small>
                                        )} </a>
                                </li>
                            )}
                            {/* this.props.shopCartStatus === "LIVE" && (
                                <li>
                                    <Link
                                        to={{
                                            pathname: `${this.chooseCartURL()}`,
                                            cartItems: {
                                                FullCart: this.props.FullCart || [],
                                                shopNamefromURL: this.props.shopNamefromURL,
                                                hasCustomDomain: this.props.hasCustomDomain
                                            }
                                        }}
                                    >
                                        <i className="fas fa-shopping-cart"></i>
                                        {this.props.FullCart.length > 0 && (
                                            <small
                                                style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    color: "white",
                                                    paddingTop: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                {this.props.FullCart.length}
                                            </small>
                                        )}
                                    </Link>
                                </li> )*/ }
                        </ul>
                    </div>
                </nav>
                {sideDrawer}
                {backdrop}
            </div>
        );
    }
}

export default NavSection;
