import React from 'react';
import PropTypes from 'prop-types';
import Styles from './stylesheets/modaz.module.css'
import { Link } from 'react-router-dom'


const Card = ({ property }) => {
    const { pictureURL, title, text, callToAction } = property;
    return (
        <div className={`card ${Styles['card-electro']}`} style={{
            backgroundImage: `url(${pictureURL || 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2Fheader-image.jpg?alt=media&token=10956c6c-b578-40de-9a25-a71ddeb39b97'})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundAttachment: 'relative',
            height: 620
        }}><div
        style={{
            height: '100%', position: 'absolute',  zIndex:20,
            backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.6), rgba(255,255,255,0))`,
            position: "absolute",
            width: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            //backgroundBlendMode:'saturation',
            //mixBlendMode: "overlay",
        }}>

    </div>
            <div className={`container ${Styles['card-electro']}`} style={{ height: '100%', position: 'relative',  zIndex:999, }} >
                <div style={{ paddingTop: 100 }} className="container" style={{display: 'flex', height: 620, justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                    <div className=" col-xl-6 col-lg-7 text-white mt-4 text-center " >
                        <h1 className={`text-wrap text-capitalize `} style={{ lineHeight: 1.2, fontFamily: "Abril Fatface, cursive", fontStyle: 'italic ' }}>{title || 'Find Great Products!'}</h1>
                        <p className={`text-center mb-0 ${Styles['p']}`} style={{ fontStyle: 'italic' }} ><small>{text || 'Find great products!'}</small></p>
                        <a className="btn  btn-custom3" href="#shop" style={{ backgroundColor: '#efb31f', fontFamily: "Montserrat, sans-serif" }}>{callToAction || 'Know More'}</a>
                    </div>
                </div>

            </div>
        </div>
    )
}

Card.propTypes = {
    property: PropTypes.object.isRequired
}

export default Card;