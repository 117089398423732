import React, { Component } from 'react';
import { Redirect } from "react-router-dom"
import Gallery from './Gallery'
import Navbar from './Navbar'
import Landing from './Landing'
import Profile from './Profile'
import Cart from '../../../checkout/cartModal'
import Chat from '../../chats/Chat'
import Footer from "./Footer"
import Background from '../../theme-samples/sample-images/a2.jpg'
import pic1 from '../../theme-samples/sample-images/electric-1080584_576.jpg'
//import './App.css'; 


let FullCart = []

class Engineer extends Component {

  state = {
    nameShop: '',
    descriptionShop: '',
    tagline: '',
    shopBackgroundPicture: '',
    shopBackgroundPicture2: '',
    shopBackgroundPicture3: '',
    shopCartStatus: '',
    shopChat: '',
    shopCartBankAccount: '',
    shopSocials: {
      facebook: "",
      instagram: "",
      twitter: ""
    },
    postIDs: [],
    modalData: {
      price: 0,
      title: '',
      text: '',
      pictureURL: ''

    },
    logo: '',
    categories: '',
    productCategories: '',
    servicesOffered: "",
    services: '',
    hasloaded: false,
    redirect: false,
    shopNamefromURL: '',
    shopID: '',
    hasCustomDomain: false,
    url: '',
    theme: '',
    HomeData: '',
    FullCart: [],
    cartModalWidth: 0,
    showModalCart: false,
    clickedItems: '',
    selectedItems: '',
    cartShowbankDetails: '',
    cartTotal: '',
    shopInitials: '',
    shopHeader: '',
    shopHeader2: '',
    shopHeader3: '',
  }




  parseURLandReturnRelevantID = async () => {
    try {
      const fullURL = window.location.href.split('#')[0]
      const fullURLsubStrings = fullURL.split('/');
      console.log(fullURLsubStrings);
      fullURLsubStrings.forEach(itemURL => {
        if (fullURLsubStrings.indexOf(`${itemURL}`) === 3) {
          console.log('item url retreived in App.js is:', itemURL, ' and url is ', fullURLsubStrings[2])
          this.setState({ shopNamefromURL: itemURL, url: fullURLsubStrings[2] })
        }
      })
      return
    } catch (err) {
      console.log('error parsing url...')
    }

  }

  filterCategories = async () => {

    const keys = Object.keys(this.state.servicesOffered)
    const values = Object.values(this.state.servicesOffered)
    const newvalues = [].concat(...values)

    console.log(' the categories are: ', keys, ' the values are :', newvalues)
    newvalues.forEach(newvalue => {
      console.log('newvalue is: ', newvalue)
      //this.iconSelector(newvalue)

    })
    await this.setState({ productCategories: newvalues, services: keys });
    return (keys);

  }


  openAndCloseCartModal = () => {
    this.setState({
      cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
      showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
    })
  }

  updateModalCart = () => {
    this.setState({
      cartModalDetails: {
        FullCart: this.state.FullCart,
        shopNamefromURL: this.props.shopNamefromURL,
        hasCustomDomain: this.props.hasCustomDomain,
        nameShop: this.state.nameShop,
        shopCartBankAccount: this.state.shopCartBankAccount
      }
    })
  }




  getInitials = () => {
    let name = this.state.nameShop.trim('')
    let pieces = name.split(' ')
    let initials = ''

    console.log('your name pieces after split are: ', pieces)


    pieces.forEach(item => {
      initials += item[0]
    })
    console.log('your shop initials are: ', initials)
    this.setState({ shopInitials: initials })
  }






  componentDidMount = async () => {


    const categories = {
      Electronics: "../../images/categories/Electronics.png",
      Home: "../../images/categories/Home.png",
      Fashion: "../../images/categories/Fashion.png",
      Cars: "../../images/categories/Cars.png",
      Food: "../../images/categories/Food.png",
      Beauty: "../../images/categories/Beauty.png",
      Professionals: "../../images/categories/Professionals.png",
      Repair_Refill: "../../images/categories/Repair_Refill.png",
      Handyman: "../../images/categories/Handyman.png",
      QuickJobs: "../../images/categories/QuickJobs.png",
      Health: "../../images/categories/Health.png",
      Art: "../../images/categories/Art.png",
      Housing: "../../images/categories/Housing.png",
      Travel: "../../images/categories/Travel.png",
      Commercial: "../../images/categories/Commercial.png"
    }
    await this.setState({ categories });


    this.filterCategories()
    // console.log('Offered services ', servicesOffered)

    if (this.props.shopData) {
      console.log('Data received in APP JS is: ', this.props.shopData)
      document.title = this.props.shopData.profile.businessName
      let shopProfile = this.props.shopData.profile
      let shopProfileName = shopProfile.businessName
      let shopProfileDescription = shopProfile.description
      // let shopLogo = shopProfile.pictureURL
      let hasCustomDomain = this.props.hasCustomDomain
      //////////////////////////////////////////////////////////////////////////////

      let providerName = shopProfile.name;
      let providerAddress = shopProfile.locationWorkAddress
      let phoneNumber = shopProfile.phoneNumber
      let servicesOffered = shopProfile.servicesOffered
      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopPost = this.props.shopData.posts


      let shop = this.props.shopData.shop
      console.log('shops', shop)
      let shopLogo = shop.shopLogoPicture
      let tagline = shop.shopSlogan
      let shopID = shop.shopName
      let shopNamefromURL = shop.shopName
      let shopBackgroundPicture = shop.shopBackgroundPicture
      let shopBackgroundPicture2 = shop.shopBackgroundPicture2
      let shopBackgroundPicture3 = shop.shopBackgroundPicture3
      let theme = shop.shopType

      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopColorBackground = shop.shopColorBackground
      let shopColorButton = shop.shopColorButton
      let shopColorHeader = shop.shopColorHeader
      let shopColorTitle = shop.shopColorTitle
      let shopFrontPicture = shop.shopFrontPicture
      let shopHeader = shop.shopHeader
      let shopCartStatus = shop.shopCartStatus
      let shopChat = shop.shopChat
      let shopCartBankAccount = shop.shopCartBankAccount
      let shopSocials = shop.shopSocials
      ////////////////////////////////
      //ENGINEER THEME DETAILS ///////
      let shopSlogan2 = shop.shopSlogan2
      let shopSlogan3 = shop.shopSlogan3
      let shopHeader2 = shop.shopHeader2
      let shopHeader3 = shop.shopHeader3
      ///////////////////////////////////////////////////////////////////////////////////////////
      console.log(' shopNamefromURL from APP JS is: ', shopNamefromURL)

      console.log('shopName is shop ID which is: ', shopID)
      await this.setState({
        nameShop: shopProfileName,
        tagline,
        descriptionShop: shopProfileDescription,
        shopBackgroundPicture,
        shopBackgroundPicture2,
        shopBackgroundPicture3,
        shopColorHeader,
        shopColorBackground,
        shopColorButton,
        shopColorTitle,
        shopFrontPicture,
        shopCartStatus,
        shopChat,
        shopCartBankAccount,
        shopSocials,
        postIDs: shopPost,
        providerName,
        providerAddress,
        phoneNumber,
        servicesOffered,
        logo: shopLogo,
        hasloaded: true,
        url: this.props.url,
        shopNamefromURL,
        shopID,
        hasCustomDomain,
        theme,
        shopHeader,
        shopHeader2,
        shopHeader3,
        shopSlogan2,
        shopSlogan3
      })
      //  console.log('first postID is: ', this.state.postIDs[0].xID)
      console.log('shopSlogan2 is: ', this.state.shopSlogan2)
      console.log('hascustomDomain in state is: ', this.state.hasCustomDomain)

      this.getInitials()

    }

    const servicesOffered = await this.filterCategories()
    console.log('Offered services ', servicesOffered)


  }



  topFunction = () => {
    window.scrollTo(0, 0)
    return
  }


  render() {
    return (

      <div id="page-container" className="App container-fluid pr-0 pl-0 h-100"
        style={{ backgroundColor: '#F3F3F3', color: '#f1f1f1', overflowX: 'hidden', width: '100%', top: 0, left: 0, position: 'fixed', scrollBehavior: "smooth" }} >

        <Navbar
          // showCartModal={() => {
          //   this.openAndCloseCartModal()
          //   this.updateModalCart()
          // }}
          initials={this.state.shopInitials}
          showAbout={true}
          showContact={true}
          nameShop={this.state.nameShop}
          logo={this.state.logo}
          shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
          url={this.state.url}
          showSearch={false}
          shopColorBackground={this.state.shopColorBackground}
          shopColorHeader={this.state.shopColorHeader}
          //FullCart={this.state.FullCart}
          postIDs={this.state.postIDs}
          hasCustomDomain={this.props.hasCustomDomain}
          shopCartStatus={this.state.shopCartStatus}
          shopCartBankAccount={this.state.shopCartBankAccount}
        />




        <div className="row paral backImage img-fluid"
          style={{
            backgroundImage: `url(${this.state.shopBackgroundPicture || Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: "cover",
            backgroundPosition: "5% 5%",
            backgroundAttachment: 'fixed',
            overflow: 'hidden',
            height: "auto",
            maxWidth: '104%',
            height: 700, overflowX: 'hidden',
            marginTop: -100,
            overflow: 'hidden',
            position: 'relative',

          }}>
          <div
            style={{
              height: '100%', position: 'absolute', top: 0,
              backgroundImage: `linear-gradient(to right, rgba(155,155,155,0.6), rgba(255,255,255,0))`,
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100%",
              //backgroundBlendMode:'saturation',
              //mixBlendMode: "overlay",
            }}>

          </div>
          <div className="container" style={{ position: 'relative' }}>

            <div className="col-xl-6 col-lg-7 text-white mt-4 col-xs-12 col-sm-6 col-sm-offset-2 col-lg-6 col-lg-offset-2 text-sm-left text-center" style={{ position: 'absolute', bottom: 0, marginBottom: 120 }}>

              <h1 className="" style={{ lineHeight: 1.0, fontFamily: "Lato, sans-serif", fontWeight: 700, color: this.state.shopColorHeader || 'blue', marginBottom: 50, fontSize: 60, }}>{this.state.shopHeader || this.state.nameShop || 'Engineering Service'}</h1>
              <div style={{ display: 'flex', marginLeft: -5 }} className='col-md-6 col-sm-12 header-arrangement'>
                <p className=" text-left text-sm-nowrap mb-0 text-sm-left text-center "
                  style={{
                    fontFamily: 'Lato', color: this.state.shopColorHeader || 'blue', fontWeight: 'bold', lineHeight: 1.1,

                  }} >
                  <small style={{ fontWeight: 'bold', fontSize: 13, textAlign: 'left' }}>
                    {this.state.tagline || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}
                  </small>
                </p>
              </div>

              <a className=" btn btn-custom " href="#cart" style={{ backgroundColor: this.state.shopColorBackground || "blue", marginTop: 40 }}>Learn More</a>


            </div>

          </div>
        </div>
        {/*------------------------------ SERVICES ICONS -----------------------------------------*/}
        {this.state.services.length > 0 ? <div className='row container-fluid' style={{ display: 'flex', marginTop: 25, marginBottom: 10, justifyContent: 'center', alignItems: 'center', backgroundColor: '#F3F3F3', marginLeft: 10 }}>
          {this.state.services.map((category, id) => {
            return (
              <div className="mx-1 row" key={id} style={{ borderColor: 'white', }}>
                <div className="m-3 col" style={{ width: '7rem', justifyContent: 'center', alignItems: 'center' }}>
                  {/* {(this.props.servicesOffered[category].length > 0) && this.isActiveCategory(category)&& <img className="card-img-top img-fluid" src={require('../../images/categories/' + `${category}` + '.png')} />} */}
                  {<img className="card-img-top img-fluid" src={require('../../../images/categories/' + `${category}` + '.png' || '../../images/categories/Commercial.png')} />}
                  <p style={{ color: 'black', textAlign: 'center' }}>{category}</p>
                </div>

              </div>
            )
          })}
        </div> :
          <div style={{ marginTop: 0 }}>
          </div>}


        {/*------------------------------ ABOUT AND SERVICES -----------------------------------------*/}
        <div className='container-fluid'>
          <div className='row' style={{ overflow: 'hidden', height: 'auto', backgroundColor: '#F3F3F3' }}>
            <div className='col-md-6' style={{
              backgroundImage: `url('${this.state.shopBackgroundPicture2 || pic1}')`,
              height: 500,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              //backgroundColor: `red`, 
              backgroundAttachment: 'relative',

              //opacity: 1
            }}></div>
            <div className='col-md-6' id='services' style={{
              height: 500,
              background: this.state.shopColorBackground || `#0F00DC 0% 0% repeat`,
              opacity: 1,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <div style={{
                /* position: 'absolute',
                bottom: 0,
                marginBottom: 150, */
                overflow: 'hidden',
                paddingLeft: '12%',
                paddingRight: '10%',
                width: '78%'
              }}>
                <h2 style={{ fontWeight: 'bold', textAlign: 'left' }}>{this.state.shopHeader2 || 'Our Services'}</h2>
                <span style={{ color: "#F3F3F3", fontSize:13 }}>
                  {this.state.shopSlogan2 || <small style={{ textAlign: 'left', fontSize:13 }}>{"We deliver great servies in: "}
                    {this.state.productCategories && <div >
                      {this.state.productCategories.map((category, id) => {
                        return (
                          <small key={id} style={{ textAlign: 'left', fontSize:13 }}>{category + ' '}</small>
                        )
                      })}
                    </div>
                    }
                  </small>}
                </span>
              </div>

            </div>
          </div>
          <div className='row' style={{
            overflow: 'hidden', marginBottom: 10, height: 'auto',
            // border: '1px solid black', borderBottomWidth: 1, borderBottomColor: 'grey' 
          }}>

            <div className='col-md-6' id='about' style={{
              height: 500,
              background: `#F3F3F3 0% 0% repeat`,
              opacity: 1,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <div style={{
                /* position: 'absolute',
               bottom: 0,
               marginBottom: 150, */
                overflow: 'hidden',
                paddingLeft: '12%',
                paddingRight: '10%',
                width: '78%'
              }}>
                <h2 style={{ fontWeight: 'bold', textAlign: 'left', color: this.state.shopColorBackground || 'blue' }}>{this.state.shopHeader3 || 'About Us'}</h2>
                <span style={{ color: 'blue' }}>
                  <small style={{ textAlign: 'left', color: 'black', fontSize:13 }}>{this.state.shopSlogan3 || this.state.descriptionShop || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}</small>
                </span>
              </div>
            </div>
            <div className='col-md-6' style={{
              backgroundImage: `url('${this.state.shopBackgroundPicture3 || pic1}')`,
              height: 500,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              //backgroundColor: `red`, 
              backgroundAttachment: 'relative',

              //opacity: 1
            }}></div>
          </div>
        </div>
        {/*------------------------------ ABOUT AND SERVICES -----------------------------------------*/}

        {this.state.postIDs.length > 0 ? <Gallery postIDs={this.state.postIDs || [
          { pictureURL: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FElectrician.jpg?alt=media&token=f68488c6-10b9-462c-b320-6bb71ebda179" },
          { pictureURL: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FProfessional%2Ffacility-management.jpg?alt=media&token=052058ff-38a3-4db4-8fb9-dc345deddeff" },
          { pictureURL: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FElectrician.jpg?alt=media&token=f68488c6-10b9-462c-b320-6bb71ebda179" },
          { pictureURL: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FProfessional%2Ffacility-management.jpg?alt=media&token=052058ff-38a3-4db4-8fb9-dc345deddeff" },
          { pictureURL: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FProfessional%2Ffacility-management.jpg?alt=media&token=052058ff-38a3-4db4-8fb9-dc345deddeff" },
          { pictureURL: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FElectrician.jpg?alt=media&token=f68488c6-10b9-462c-b320-6bb71ebda179" }
        ]} color={this.state.shopColorBackground} />
          :
          <div style={{ height: 150 }}>
          </div>}

        <Profile
          nameShop={this.state.nameShop}
          initials={this.state.shopInitials}
          providerName={this.state.providerName}
          providerAddress={this.state.providerAddress}
          phoneNumber={this.state.phoneNumber}
          shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
          shopSocials={this.state.shopSocials}
          backgroundColor={this.state.shopColorBackground}
        />

        {(this.state.shopChat.status === "LIVE") && <Chat
          backgroundColor={this.state.shopColorBackground}
          nameShop={this.state.nameShop}
          shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}
        />}
        <div style={{ marginTop: -10 }}>
          <Footer
            backgroundColor={this.state.shopColorBackground}
            nameShop={this.state.nameShop}
          />
        </div>
      </div>

    );
  }

}

export default Engineer
