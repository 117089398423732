import React, { Component } from 'react'
import BankDetails from './BankDetails'

const prefix = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2F"


let chatID = ''
let message = []

class CartModal extends Component {
    state = {

        showBankDetails: false,
        itemQuantities: {},
        iconClass: 'fas fa-angle-down',
        collapsibleText: 'VIEW',
        cart: [
            {
                title: 'No Products in cart',
                text: '...',
                price: 0.0,
                pictureURL: prefix + 'General%2FUntitled-1Artboard%201.jpg?alt=media&token=bef82510-c9bc-42b0-aaba-ebea7bf982cc'

            }
        ],
        total: 0,
        promoCode: 'DISCOUNT',
        discount: -100,
        states: {
            '...': [
                ' ...',
            ],
            'Nigeria': [

                ' Abia',
                'Adamawa',
                'Akwa Ibom',
                'Anambra',
                'Bauchi',
                'Bayelsa',
                'Benue',
                'Borno',
                'Cross River',
                'Delta',
                'Ebonyi',
                'Enugu',
                'Edo',
                'Ekiti',
                'Gombe',
                'Imo',
                'Jigawa',
                'Kaduna',
                'Kano',
                'Katsina',
                'Kebbi',
                'Kogi',
                'Kwara',
                'Lagos',
                'Nasarawa',
                'Niger',
                'Ogun',
                'Ondo',
                'Osun',
                'Oyo',
                'Plateau',
                'Rivers',
                'Sokoto',
                'Taraba',
                'Yobe',
                'Zamfara'
            ],
            'Ghana': [
                'Northern',
                'Ashanti',
                'Western',
                'Volta',
                'Eastern',
                'Upper West',
                'Central',
                'Upper East',
                'Greater Accra',
                'Savannah',
                'North East',
                'Bono East',
                'Oti',
                'Ahafo',
                'Bono',
                'Western North'
            ]
        },
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        address2: '',
        chosenState: '',
        postalCode: '',
        billingInfo: {

            country: '...',
            state: '',

        },
        paymentType: 'Bank Transfer',
        shippingAddressIsBillingAddress: true,
        saveInfo: false,
        shopNamefromURL: '',
        hasCustomDomain: false,
        nameShop: '',
        shopCartBankAccount: '',
        chatID: '',
        redirect: false,
        processing: false,
        submitted: false,
        cartTotal: ''
    }



    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + " " + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + " " + pString;
            }
            else {
                priceString =
                    currency + " " + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = " ";
            }
            return priceString
        }
        //  return pString
    }

    topFunction = () => {
        window.scrollTo(0, 0)
        return
    }

    total = () => {
        let n = this.state.cart.length - 1
        let total = 0
        while (n >= 0) {
            total = total + (this.state.cart[n].price * (this.state.itemQuantities[this.state.cart[n].xID] || 1))
            n--
        }
        //total = total + this.state.discount
        this.setState({ total })
    }


    changeIcons = () => {
        if (this.state.iconClass === 'fas fa-angle-up') {
            this.setState({ iconClass: 'fas fa-angle-down', collapsibleText: 'VIEW' })
        }
        else {
            this.setState({ iconClass: 'fas fa-angle-up', collapsibleText: 'HIDE' })
        }

    }


    onSubmit = async (e) => {

        e.preventDefault()

        this.setState({ processing: true })

        console.log('...BillingInfo submitted...', '\n', '\n The total is: ', this.state.total)


        let data = [
            /* {
                author: 1,
                text: `Name: ${this.state.firstName || '-'} ${this.state.lastName || '-'}`
            },
            {
                author: 1,
                text: `PHONE NUMBER: ${this.state.phoneNumber || '-'}`
            }, */
            {
                author: 1,
                text: `EMAIL: ${this.state.email || '-'}`
            },
            {
                author: 1,
                text: `ADDRESS: ${this.state.address}, ${this.state.chosenState}` || '-'
            },
            {
                author: 1,
                text: `TOTAL AMOUNT: ${this.state.total}` || '-'
            }
        ]

        message = data

        // let text = 'YOU HAVE A NEW ORDER FROM YOUR WEBSITE'+'\n'
        let text = ''

        await message.forEach(item => {
            text += `${item.text}` + '\n'
        })

        text += '\n PRODUCTS ORDERED \n '

        if (this.state.cart) {
            this.state.cart.forEach(item => {

                text += `\n PRODUCT: ${item.title}, DESC: ${item.text}, PRICE: ${item.price} QTY: ${(this.state.itemQuantities[this.state.cart[this.state.cart.indexOf(item)].xID] || 1)}` + '\n'


            })
        }


        // text+='\n THE CUSTOMER NOW HAS TO PAY, PLEASE CHECK YOUR BANK ACCOUNT'
        this.sendOrderData(text)
    }


    // sendChatData = async (item) => {
    //     console.log('the message in sendData is...', item)
    //     console.log('chatID in state is...', this.state.chatID)
    //     try {
    //         /* if (!chatID) {
    //             this.setState({ processing: true })
    //         } */

    //         const r = await fetch("https://us-central1-af-shop.cloudfunctions.net/newChat", {
    //             method: "POST",
    //             //mode: "no-cors",
    //             headers: {
    //                 'Content-Type': 'application/json',

    //             },
    //             body: JSON.stringify({
    //                 shopName: `${this.state.shopNamefromURL}`,
    //                 message: item,
    //                 chatID: chatID ? chatID : null
    //             })
    //         });
    //         const r2 = await r.json();
    //         if (r2.msg === 'SUCCESS') {

    //             console.log('response from chat server is:', r2.data, 'total response is... ', r2)
    //             if (!chatID) {
    //                 chatID = r2.data
    //                 this.setState({ chatID: r2.data })
    //             }

    //         } else {
    //             console.log(r2.error)
    //             //this.setState({ processing: false })
    //         }

    //     } catch (err) {
    //         console.log("Error from firebase is: ", err);
    //         //this.setState({ processing: false })
    //     }

    // }








    sendOrderData = async (text) => {


        console.log('Username' + this.state.firstName + this.state.lastName)

        const newMessage = {
            shopName: this.state.shopNamefromURL,
            senderName: this.state.firstName + this.state.lastName,
            senderPhoneNumber: this.state.phoneNumber,
            message: text,
            type: 'Order'
        }
        console.log('new', newMessage)
        const url = "https://us-central1-afbase-7ee60.cloudfunctions.net/shopMessage"
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newMessage)
        }).then((res) => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {

                console.log('Success:', response)
                if (response.msg == 'SUCCESS') {
                    console.log('response from cart order submission is: ', response)
                    this.setState({ processing: false, submitted: true, showBankDetails: true })
                    this.props.clearLocalStorageCart()
                    //this.showBankDetails()
                } else {
                    this.setState({ error: true, processing: false, showBankDetails: true })
                }
            });


    }



    showBankDetails = async () => {
        await setTimeout(this.setState({ showBankDetails: true }), 7000)
        this.props.clearLocalStorageCart()
    }


    chooseBillingURL = () => {
        if (this.state.hasCustomDomain) {
            return '/bankdetails'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/' + this.state.shopNamefromURL + '/bankdetails'
        }
        else {
            return '/' + this.state.shopNamefromURL + '/bankdetails'
        }
    }

    componentWillUnmount() {
        this.props.storeCartDetails(this.state.showBankDetails, this.state.total)
    }

    componentWillMount = async () => {
        if (this.props.cartShowbankDetails) {
            this.setState({ showBankDetails: this.props.cartShowbankDetails })
        }
        if (this.props.cartTotal) {
            this.setState({ cartTotal: this.props.cartTotal })
        }
        console.log('props passed to cart modal are: ', this.props.CartDetails)
        const { FullCart, shopNamefromURL, hasCustomDomain, nameShop, shopCartBankAccount } = this.props.CartDetails

        let cart = Array.from(new Set(FullCart))

        await this.setState({ cart, shopNamefromURL, hasCustomDomain, nameShop, shopCartBankAccount })
        console.log('data received by Cart component is: ', this.state.cart)
        this.total()
        this.topFunction()
        // this.sendChatData('New ORDER')

        /* window.addEventListener('load', function () {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            var forms = document.getElementsByClassName('needs-validation');

            // Loop over them and prevent submission
            var validation = Array.prototype.filter.call(forms, function (form) {
                form.addEventListener('submit', function (event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    form.classList.add('was-validated');
                }, false);
            });
        }, false)*/


    }
    render() {

        return (
            <div className='container' style={{
                backgroundColor: '#F5F7F7',
                display: "flex",
                minHeight: "100vh",
                flexDirection: "column"
            }}> 
                <div className="container">
                    <div className="py-5 text-center" style={{ backgroundColor: 'white' }}>
                        <h2 style={{ color: 'black', fontWeight: 'bold', fontSize: 40 }}>Cart & Checkout</h2>
                        <p className="lead" style={{ color: 'grey' }}>Please confirm the products listed and the prices below.</p>
                    </div>




                    <div className="accordion" id="accordionCart">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button type="button" className="btn  btn-custom3" data-toggle="collapse" data-target="#collapseOne"
                                        style={{ padding: 10, marginTop: -10 }}
                                        onClick={this.changeIcons}
                                    ><i className={`${this.state.iconClass}`} aria-hidden="true"></i> {this.state.collapsibleText} Products</button>
                                </h2>
                            </div>
                            <div id="collapseOne" className="panel-collapse collapse in" aria-labelledby="headingOne" data-parent="#accordionCart">
                                <div className="card-body" style={{ backgroundColor: 'white', border: '2px solid black', alignItems: 'center' }}>
                                    {this.state.cart && this.state.cart.map((item, id) => {
                                        return (
                                            <div key={id} className='row'
                                                style={{
                                                    border: '1px solid rgba(232,232,232, 0.5)',
                                                    borderBottomColor: 'grey',
                                                    width: '100%',
                                                }}>
                                                <div className='row' style={{ width: '50%', marginLeft: 10, marginTop: 10, marginBottom: 10, alignItems: 'center' }}>
                                                    <div src={item.pictureURL}
                                                        style={{
                                                            height: 100,
                                                            width: 100,
                                                            marginRight: 5,
                                                            backgroundImage: `url(${item.pictureURL || prefix + "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FGeneral%2Fnot-available.jpg?alt=media&token=796daa91-5d15-4cc9-b0cc-ef396d779673"})`,
                                                            backgroundRepeat: 'no-repeat', backgroundSize: '100%', overflow: 'hidden',
                                                            backgroundPosition: 'center center',
                                                            borderRadius: 10,
                                                            backgroundColor: '#1ce0b8'
                                                        }}
                                                    ></div>
                                                    <div style={{ wordWrap: 'break-word', overflow: 'hidden', alignSelf: 'center', marginLeft: 10 }}>
                                                        <p style={{ color: 'black', fontSize: 13 }}>{item.title}</p>
                                                    </div>
                                                </div>


                                                <div className='row' style={{ justifyContent: 'space-between', marginLeft: 30, justifyContent: 'center', marginTop: 10 }}>

                                                    <div style={{ justifyContent: 'right', alignItems: 'right', position: 'aboslute', }}>
                                                        <p style={{ color: 'black', fontWeight: 'bold', paddingBottom: -15, paddingLeft: 15 }}>Qty.</p>
                                                        <input col={1} style={{ width: 50, borderRadius: 5, textAlign: 'center', marginTop: -10, height: 30 }}
                                                            placeholder='1' min='1' type='number'
                                                            onChange={async (text) => {
                                                                let qty = text.target.value
                                                                console.log('Quantity selected is: ', text.target.value)
                                                                await this.setState(prevState => {
                                                                    let itemQuantities = Object.assign({}, prevState.itemQuantities);
                                                                    itemQuantities[item.xID] = qty
                                                                    return { itemQuantities }
                                                                })
                                                                console.log(' itemQuantities are; ', this.state.itemQuantities)
                                                                this.total()
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ justifyContent: 'right', alignItems: 'right' }}>
                                                        <p style={{ color: 'black', fontWeight: 'bold', paddingLeft: 15, textAlign: 'center' }}>Price</p>
                                                        <p style={{ color: 'black', paddingLeft: 15, textAlign: 'center', fontSize: 14 }}>{item.price}</p>
                                                    </div>
                                                    <div style={{ justifyContent: 'right', alignItems: 'right' }}>
                                                        <p style={{ color: 'black', fontWeight: 'bold', paddingLeft: 15, textAlign: 'center' }}>Total</p>
                                                        <p style={{ color: 'black', paddingLeft: 15, textAlign: 'center', fontSize: 14 }}>{(this.state.itemQuantities[item.xID] || 1) * item.price}</p>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        let cartList = this.state.cart
                                                        cartList.splice(cartList.indexOf(item), 1);
                                                        this.setState({
                                                            cart: cartList
                                                        })
                                                        this.props.updateCart(cartList)
                                                    }}
                                                    style={{ position: 'absolute', right: 50 }}>
                                                    <button className='btn btn-custom3'
                                                        style={{ justifyContent: 'center', alignItems: 'center', height: 10, width: 10, padding: 10 }}>
                                                        <p style={{ textAlign: 'center', marginTop: -13, marginLeft: -4 }}><small>X</small></p></button>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="row" style={{ marginTop: 20 }}>

                        <div className="col-md-4 order-md-2 mb-4">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted" style={{ fontSize: 30, fontFamily: 'Arial, Helvetica, sans-serif' }}>Your cart</span>
                                <span className="badge badge-secondary badge-pill">{this.state.cart.length}</span>
                            </h4>
                            <ul className="list-group mb-3">
                                {this.state.cart && this.state.cart.map((product, id) => {
                                    return (
                                        <li key={id} className="list-group-item d-flex justify-content-between lh-condensed">
                                            <div className="px-2" style={{overflow:'hidden'}}>
                                                <h6 className="my-0">{this.state.itemQuantities[product.xID] > 1 ? (this.state.itemQuantities[product.xID] || 1) + 'x' : ''}{product.title}</h6>
                                                <small className="text-muted text-truncate">{product.text}</small>
                                            </div>
                                            <span className="text-muted">{product.price * (this.state.itemQuantities[product.xID] || 1)}</span>
                                        </li>)
                                })}
                                
                                {(this.state.cart[0] !== undefined) && <li className="list-group-item d-flex justify-content-between">
                                    {(this.state.cart || this.props.location.cartItems.FullCart[0]) && <span>Total ({this.state.cart[0].priceCurrency || ''})</span>}
                                    <strong>{this.state.total}</strong>
                                </li>}
                            </ul>

                           
                        </div>
                        <div className="col-md-8 order-md-1 needs-validation">
                       
                            {this.state.showBankDetails && <div style={{ border:'1px solid black', padding:20, borderRadius:20}}>
                                <div onClick={()=>{this.setState({showBankDetails:false})}} style={{ 
                            position:'absolute', right:20, top:10,borderRadius:'50%', border:'2px solid black', paddingLeft:2,paddingTop:16, width:30, height:30, display:'flex', justifyContent:'center', alignItems:'center'}}> <p style={{color:'black', fontFamily:'lato', fontWeight:'bold'}}>X </p></div>
                                <h4 className='text-success'>Congratulations Your Order has been submitted! Please see {this.props.nameShop}'s Bank details below and make your payment as soon as possible !</h4>
                                <h4 className='text-success'>Please make a payment of the sum of {this.state.cartTotal || this.state.total} to the following account details as soon as possible!</h4>
                                <main className='container' style={{ backgroundColor: '#ff4d4d', paddingBottom: 30, borderRadius: 30, border: '3px solid pink', margin: '0 auto', marginTop: 20, position:'relative' }}>
                                
                                    <h4 style={{ color: 'white', paddingTop: 50, textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Account Name:</span> {this.state.shopCartBankAccount.name || <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                                    <h4 style={{ color: 'white', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Account Number:</span> {this.state.shopCartBankAccount.number || <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                                    <h4 style={{ color: 'white', textAlign: 'center' }}><span style={{ fontWeight: 'bold' }}>Bank:</span> {this.state.shopCartBankAccount.bankName || <i className="fa fa-spinner fa-spin" style={{ lineHeight: 1.5 }}></i>}</h4>
                                </main>
                            </div>}
                            <h4 className="mb-3 text-muted" style={{ fontSize: 30, fontFamily: 'Arial, Helvetica, sans-serif' }}>Billing address</h4>



                            <form
                            //onSubmit={this.onSubmit} 
                            >
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        {/* <label for="firstName">First name</label> */}
                                        <input type="text" className="form-control" id="firstName" placeholder="First Name" style={{ borderBottomColor: 'grey' }}
                                            onChange={(e) => {
                                                console.log(e.target.value, ' is the firstname entered...')
                                                this.setState({
                                                    firstName: e.target.value
                                                })
                                            }}
                                            required />
                                        <div className="invalid-feedback">
                                            Valid first name is required.
                                     </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        {/* <label for="lastName" style={{}}>Last name</label> */}
                                        <input type="text" className="form-control" id="lastName" style={{ borderBottomColor: 'grey' }} placeholder="Last Name"
                                            onChange={(e) => {
                                                console.log(e.target.value, ' is the lastname entered...')
                                                this.setState({
                                                    lastName: e.target.value
                                                })
                                            }}
                                            required />
                                        <div className="invalid-feedback">
                                            Valid last name is required.
                                        </div>
                                    </div>
                                </div>



                                <div className="mb-3">
                                    {/* <label for="email">Email <span className="text-muted">(Optional)</span></label> */}
                                    <input type="email" className="form-control" id="email" required placeholder="you@example.com"
                                        onChange={(e) => {
                                            console.log(e.target.value, ' is the email entered...')
                                            this.setState({
                                                email: e.target.value
                                            })
                                        }}
                                        style={{ borderBottomColor: 'grey' }} />
                                    <div className="invalid-feedback">
                                        Please enter a valid email address for shipping updates.
                                    </div>
                                </div>

                                <div className="mb-3">
                                    {/* <label for="address">Address</label> */}
                                    <input type="text" className="form-control" id="address" placeholder="1234 Main St" style={{ borderBottomColor: 'grey' }}
                                        onChange={(e) => {
                                            console.log(e.target.value, ' is the address entered...')
                                            this.setState({
                                                address: e.target.value
                                            })
                                        }}
                                        required />
                                    <div className="invalid-feedback">
                                        Please enter your shipping address.
                                    </div>
                                </div>

                                <div className="mb-3">
                                    {/* <label for="phone">Phone Number <span className="text-muted">Phone Number</span></label> */}
                                    <input type="tel" className="form-control" id="phone" placeholder="Phone number" style={{ borderBottomColor: 'grey' }}
                                        onChange={(e) => {
                                            console.log(e.target.value, ' is the phone number entered...')
                                            this.setState({
                                                phoneNumber: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-md-5 mb-3">
                                        {/* <label for="country">Country</label> */}
                                        <select className="custom-select d-block w-100" id="country"
                                            value={this.state.billingInfo.country}
                                            onChange={(e) => {
                                                console.log(e.target.value, ' has been selected...')
                                                this.setState({
                                                    billingInfo: {
                                                        country: e.target.value,
                                                    }
                                                })
                                            }}
                                            required>
                                            <option value="...">Choose...</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Ghana">Ghana</option>
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select a valid country.
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        {/* <label for="state">State</label> */}
                                        <select className="custom-select d-block w-100" id="state"
                                            value={this.state.billingInfo.state}
                                            onChange={(e) => {
                                                console.log(e.target.value, ' has been selected...')
                                                this.setState({
                                                    chosenState: e.target.value
                                                })
                                            }}

                                            required>
                                            <option>... </option>
                                            {this.state.states[this.state.billingInfo.country].map((state) => {
                                                return (
                                                    <option>{state}</option>
                                                )
                                            })}
                                        </select>
                                        <div className="invalid-feedback">
                                            Please provide a valid state.
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        {/* <label for="Postal Code">Postal Code</label> */}
                                        <input type="number" className="form-control" id="zip" placeholder="100012" style={{ borderBottomColor: 'grey' }}
                                            onChange={(e) => {
                                                console.log(e.target.value, ' is the postalcode entered...')
                                                this.setState({
                                                    postalCode: e.target.value
                                                })
                                            }}
                                            required />
                                        <div className="invalid-feedback">
                                            Postal code is required.
                                        </div>
                                    </div>
                                </div>
                                <hr className="mb-4" />
                                

                                <div className="d-block my-3">
                                    <div className="custom-control custom-radio">
                                        <input id="credit" name="paymentMethod" type="radio" className="custom-control-input" style={{ borderBottomColor: 'grey' }} checked required />
                                        {/* <label className="custom-control-label" for="credit">Bank Transfer</label> */}
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input id="credit" name="paymentMethod" type="radio" className="custom-control-input" style={{ borderBottomColor: 'grey' }} required disabled />
                                        {/* <label className="custom-control-label" for="credit">Wakanda Pay</label> */}
                                    </div>
                                </div>

                                {!this.state.submitted && <button className="btn btn-primary btn-lg btn-block row"
                                    //type="submit"
                                    style={{ backgroundColor: '#d2154a', marginLeft:4 }}
                                    onClick={this.onSubmit}
                                    disabled={this.state.processing}
                                > {this.state.processing && <i className="fa fa-spinner fa-spin"
                                    style={{ lineHeight: 1.5, marginBottom: 3, marginLeft: 5 }}></i>}<span>Submit Order</span>
                                </button>}


                            </form>
                        </div>
                    </div>


                </div>
                <div className="mt-5" style={{ bottom: 0, width: '100%', position: 'relative' }}>
                    <footer className="text-muted text-center text-small" >
                        <span className="mb-1" style={{ color: 'white' }}>&copy;  {new Date().getFullYear()} {this.state.nameShop}. All rights reserved!</span>
                        {/* <ul className="list-inline">
                            <li className="list-inline-item"><a href="#">Privacy</a></li>
                            <li className="list-inline-item"><a href="#">Terms</a></li>
                            <li className="list-inline-item"><a href="#">Support</a></li>
                        </ul> */}
                    </footer>
                </div>

            </div>
        );
    }
}

export default CartModal;