import React, { Component } from 'react';
//import Image from './images/hero_1.jpg'
import Image1 from './images/shoe_2.jpg'
import Image2 from './images/shoe_1.png'



class Header extends Component {

    state = {
        products: '',
        titlePiece: '',
        categories: '',
        servicesOffered: '',
        tagline: ''
    }

    filterCategories = () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        /* const values1 = values.slice(0,values.length)
        const values2 = values1.join(' ') */
        // const newvalues = [].concat(...values)

        console.log(' the categories are: ', keys, ' and the services are: ', values)
        this.setState({ categories: keys, servicesOffered: values });

    }


    dotdotdot = (text, num) => {
        if (text.length >= num) {
            let i = 0
            let moretext = ''

            while (i < num - 3) {
                moretext += text[i]
                i++
            }
            return moretext + '...'
        } else return text
    }



    returnFirstService = (data) => {
        if (this.props.servicesOffered != 'undefined') {
            const keys = Object.keys(data)
            const values = Object.values(data)


            //const newvalues = [].concat(...values)

            console.log(' the categories in Header js are: ', keys, ' and the services are: ', values)
            // this.setState({ categories: keys, servicesOffered: newvalues });
            return values

        } else return 'Products'
    }


    componentDidMount = () => {
        if (this.props.products) {

            this.setState({ products: this.props.products });
            console.log('the products in shopper header are: ', this.props.products)
            if (this.props.servicesOffered) {
                this.filterCategories()
            }

        }
    }





    render() {
        return (
            <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} >
                {this.props.products && <img
                    src={this.props.shopFrontPicture || Image2}
                    className='img-fluid img-thumbnail'
                    style={{ width: 500, left: 150, overflow: 'hidden', borderRadius: 250, marginBottom: 50 }} />}


                <div style={{
                    // backgroundImage:`url(${Image})`,
                    top: -100,
                    backgroundColor: this.props.shopColorBackground || '#db2543',
                    //backgroundColor: '#A6F3ED',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                    width: '100%',
                    paddingBottom: 100
                }} id="about">

                    {/*   <div className="container" style={{ position: 'relative', width: '100%', alignItems: 'right', top: 50, overflow: 'hidden' }}>
                        <h1 className="mb-2 tracking-in-expand pt-4 mt-4" style={{ textAlign: 'center' }}> {'Find ' + `${this.dotdotdot(this.returnFirstService(this.props.servicesOffered), 100)}`}</h1>
                            <div className="intro-text text-md-right" style={{ textAlign: 'center' }}>
                            <p className="mb-4 " style={{ color: 'white', textAlign: 'center' }}>{this.props.tagline ? this.props.tagline : 'Choose from a wide selecion of attires and wears'}</p>
                            <p style={{ textAlign: 'center' }}>
                                <a href="#" className="btn btn-shopper btn-shopper-sm btn-shopper-primary" style={{ background: this.props.shopColorButton || '#7a73ea', border: 0, color: '#ffffff' }}>Shop Now</a>
                            </p>

                        </div>
                    </div> */}

                    {this.state.servicesOffered && <div className="container" style={{ position: 'relative', width: '100%', alignItems: 'right', top: 50, overflow: 'hidden' }}>

                        <h2 className="mb-2 tracking-in-expand pt-4 mt-4 "
                            style={{
                                textAlign: 'center',
                                //fontFamily: "Montserrat, sans-serif", 
                                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                                fontWeight: 'lighter',
                                fontSize: 40,
                                color: '#fff'
                            }}>
                            {/* Find {this.state.servicesOffered.map((service, id) => {
                            return (
                              <span key={id}>{service + ' '}</span> 
                            )
                        })} */}
                            {this.props.description}
                        </h2>
                        <p className="mb-4 " style={{ color: 'white', textAlign: 'center' }}>{this.props.tagline ? this.props.tagline : 'Choose from a wide selecion of attires and wears'}</p>
                        <p style={{ textAlign: 'center' }}>
                            <a href="#shop" className="btn btn-shopper btn-shopper-sm btn-shopper-primary" style={{ background: this.props.shopColorButton || '#7a73ea', border: 0, color: '#ffffff' }}>Shop Now</a>
                        </p>
                    </div>}
                </div>


            </div>

        );
    }
}

export default Header;