import React, { Component } from 'react'
import Card from "./slideCard"
import Styles from "./stylesheets/modaz.module.css"


class MainSlider extends Component {
    state = {
        properties: this.props.products,
        property: this.props.products[0]
    }

    nextProperty = () => {
        let newIndex = this.props.products.indexOf(this.state.property) + 1

        this.setState({
            property: this.props.products[newIndex]
        })
        /* if(newIndex !== this.props.products.length -1 ){
            this.setState({
                property: this.props.products[newIndex]
            })
        }else{
            newIndex = 0
            this.setState({
                property: this.props.products[newIndex]
            })
        } */

    }

    prevProperty = () => {
        const newIndex = this.props.products.indexOf(this.state.property) - 1
        this.setState({
            property: this.props.products[newIndex]
        })
    }

    componentWillMount = () => {

    }


    render() {
        const { properties, property } = this.state;
        if (this.props.products) {


            setTimeout(() => { this.nextProperty() }, 3000)
        }

        return (
            <div className={`${Styles['high-fashion-body']} ${Styles['high-fashion']}`} style={{ height: '100%', marginTop: 20, 
            position: 'relative', }}>
                
                <div className={`${Styles['cards-slider-wrapper']}`} style={{ width: '100%', "transform": `translateX(-${this.props.products.indexOf(property) * (properties.length * 25)}%)` }}>
                    {
                        properties.map(property => {
                            return (

                                <Card property={property} key={property._id} />
                            )
                        })
                    }
                </div>

            </div>

        );
    }
}

export default MainSlider;