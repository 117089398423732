import React, { Component } from 'react'
import Card from './Card'
import { Link } from 'react-router-dom'
import Productpage from '../../layout/ProductPage'

import Modal from '../../layout/Modal'

//importing utility function
import {getAllProductImages} from '../utilityfunctions'

let shop = 'shop'

let clickedItems = {}

let productImages = {};

export default class Products extends Component {


    state = {
        filteredProducts: [],
        property: '',
        productFilters: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: '',
            images:[]
        },
        clickedItems: {},
        selectedItems: [],
        descriptionShop: '',
        postIDs: [] 
    }

    nextProperty = () => {
        const newIndex = this.state.filteredProducts.indexOf(this.state.property) + 1
        this.setState({
            property: this.state.filteredProducts[newIndex]
        })
    }

    prevProperty = () => {
        const newIndex = this.state.filteredProducts.indexOf(this.state.property) - 1
        this.setState({
            property: this.state.filteredProducts[newIndex]
        })
    }


    selectItem = async (postID) => {
        //let selectedItems = this.state.selectedItems
        let selectedItems = this.props.FullCart || []
        
            let clickedItems = this.props.clickedItems;

            if (!clickedItems[postID.xID] || clickedItems[postID.xID] === 'unclicked') {
                clickedItems[postID.xID] = 'clicked'
                selectedItems.push(postID)
                this.setState({ selectedItems, clickedItems })
            } else {
                clickedItems[postID.xID] = 'unclicked'
                //selectedItems.pop(postID)
                var filtered = selectedItems.filter(function(value, index, arr){

                    return value !== postID;
                
                });
                selectedItems = filtered
                this.setState({ selectedItems: filtered, clickedItems })
            }
            //clickedItems = localStorage.getItem("clickedItems") 
            localStorage.setItem("clickedItems", JSON.stringify(clickedItems))
             
        
        console.log(' item clicked is; ', this.state.clickedItems, 'selected items are ', this.state.selectedItems)

        this.props.updateCart(selectedItems)
    }



    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + " " + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + " " + pString;
            }
            else {
                priceString =
                    currency + " " + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = "contact for details ";
            }

            return priceString
        }
        //  return pString
    }


  



    getCategories = async () => {
        let productFilters = [];

        this.props.products.forEach(product => {

            productFilters.push(product.category2)

        });

        let uniqueItems = Array.from(new Set(productFilters))

        await this.setState({ productFilters: uniqueItems })
        console.log(' the product filters are; ', this.state.productFilters)

    }


    componentWillUnmount() {
        this.props.saveProductData(this.state.clickedItems, this.state.selectedItems)
    }

    componentDidMount = async () => {
        if (this.props.products) {
            console.log('services offered in Electro product component are: ', this.state.filteredProducts)
            await this.setState({ filteredProducts: this.props.products, property: this.props.products[0], postIDs: this.props.products})
            
            await this.getCategories();
            
        }
    }

    componentWillMount = () => {
        clickedItems = JSON.parse(localStorage.getItem("clickedItems"))

        if (clickedItems) {
             
                
                let cart = this.props.FullCart || []
                let posts = this.state.postIDs
                let keys = Object.keys(clickedItems)
                keys.forEach(key => {
                    if (clickedItems[key] === 'clicked') {
                        posts.find(post => {
                            if (post.xID === key){
                                cart.push(post)
                            }
                        })
                    }
                })
                console.log('cart in Landing JS componentWillMount is: ', cart, 'clicked items stored are; ', clickedItems)
                this.setState({ selectedItems:cart, clickedItems}) 
        }
        if(this.props.selectedItems){
            this.setState({selectedItems:this.props.selectedItems, clickedItems: this.props.clickedItems})
        }
    }


    render() {
        const { filteredProducts, property, productFilters } = this.state;
        /*  if (this.props.products) {
             
 
             setTimeout(()=>{this.nextProperty()}, 3000) 
     }  */
        return (

            <div >

                <Modal
                    price={this.state.modalData.price}
                    pictureURL={this.state.modalData.pictureURL}
                    title={this.state.modalData.title}
                    text={this.state.modalData.text}
                    images={this.state.modalData.images}
                ></Modal>

                <div id='products' style={{ marginTop: 100, borderTopWidth: 2, borderTopColor: "#efb31f" }} className='container-fluid' >
                    <h2 className="text-center mb-4 font-weight-bold" style={{ color: '#ffffff' }}>All Products</h2>

                   {this.props.postIDs.length > 0 && <div className="row " style={{ justifyContent: 'center' }}>
                        {this.props.postIDs.map((postID, id) => {
                            
                            if(Object.keys(productImages).length !== this.props.postIDs.length){
                                let result ={}
                                result = getAllProductImages(postID)
                                productImages = { ...productImages, ...result}
                                console.log(' productimages are ...', productImages)
                            }
                           
                            return (
                                <div className="zoom-effect-container electro-card " key={id} >
                                    <div className="card image-card my-3 p-3 " style={{ backgroundColor: '#fff', borderRadius: 15, textAlign: "left" }}>
                                        <a data-toggle="modal" data-target="#myModal"
                                            onClick={() => {
                                                this.setState({
                                                    modalData: {
                                                        price: this.displayprice(postID.price, postID.priceType, postID.priceCurrency) || postID.price,
                                                        pictureURL: postID.pictureURL,
                                                        title: postID.title,
                                                        text: postID.text,
                                                        images:productImages[postID.xID]
                                                    }
                                                })
                                            }}>

                                          {/*   <img src={postID.pictureURL} className=" img-fluid" alt="product"
                                                style={{ backgroundSize: 'cover', height: 'auto', borderRadius: 10 }} /> */}
                                                <div style={{
                                                    height: 150,
                                                    width:'100%',
                                                    marginLeft:0,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center",
                                                    borderRadius: 10,
                                                    backgroundImage: `url(${postID.pictureURL})`
                                                }}></div>


                                        </a>


                                        <p className='text-truncate mt-3 mb-0' >

                                            <Link to={{
                                                pathname: `/${this.props.hasCustomDomain ? shop : this.props.shopNamefromURL}/${postID.xID}`,
                                                // pathname: '/productpage/',
                                                detailsProp: {
                                                    title: postID.title,
                                                    text: postID.text,
                                                    price: this.displayprice(postID.price, postID.priceType, postID.priceCurrency) || postID.price,
                                                    pictureURL: postID.pictureURL,
                                                    providerName: this.props.providerName,
                                                    providerAddress: this.props.providerAddress,
                                                    phoneNumber: this.props.phoneNumber,
                                                    shopNamefromURL: this.props.shopNamefromURL,
                                                    nameShop: this.props.nameShop,
                                                    url: this.props.url,
                                                    theme: this.props.theme,
                                                    shopColorTitle: this.props.shopColorTitle,
                                                    shopColorBackground: this.props.shopColorBackground,
                                                    shopLogo: this.props.shopLogo,
                                                    hasCustomDomain: this.props.hasCustomDomain,
                                                    shopSocials: this.props.shopSocials,
                                                    shopCartStatus: this.props.shopCartStatus,
                                                    shopChat: this.props.shopChat,
                                                    shopCartBankAccount: this.props.shopCartBankAccount,
                                                    FullCart: this.props.FullCart,
                                                    theme: 'Electro',
                                                    servicesOffered: this.props.servicesOffered,
                                                    xID:postID.xID,
                                                    productImages:productImages
                                                    //shopData:this.props.shopData
                                                }
                                            }}
                                            >
                                                <b style={{ color: '#000000' }}>{postID.title}</b>
                                            </Link>
                                        </p>
                                        <p className="card-text my-0 font-weight-bold" style={{ overflow: 'hidden', color: '#000000' }}>{this.displayprice(postID.price, postID.priceType, postID.priceCurrency) || postID.price}</p>

                                        <div className="row card-footer bg-transparent"
                                            style={{
                                                overflowX: 'hidden',
                                                borderTopWidth: 1, borderTopColor: "#000000",
                                                alignSelf: 'center'
                                            }}>

                                            <div className="text-muted mx-0">

                                                {(this.props.shopCartStatus === 'LIVE') && <button type="button" className="btn btn-sm btn-outline-secondary "
                                                    onClick={() => this.selectItem(postID)}
                                                    style={{
                                                        border: 'none',
                                                        // borderColor: this.props.shopColorTitle || '#000000', 
                                                        color: this.state.clickedItems[postID.xID] === 'clicked' ? '#fd4f53' : 'white',
                                                        backgroundColor: this.state.clickedItems[postID.xID] === 'clicked' ? 'white' : '#fd4f53'
                                                        //color: this.props.shopColorTitle || '#000000' 
                                                    }}

                                                > {this.state.clickedItems[postID.xID] === 'clicked' ? 'ADDED TO CART' : 'ADD TO CART'} </button>}
                                                {(this.props.shopCartStatus === 'LIVE') &&
                                                    <a className="d-none d-md-inline"
                                                    ><i className={this.state.clickedItems[postID.xID] === 'clicked' ? "fa fa-heart" : "far fa-heart "} style={{ marginLeft: 15, color: '#f6343f' }} ></i>
                                                    </a>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>}
                </div>
            </div >


        )

    }
}



