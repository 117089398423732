import React, { Component } from 'react'
import { Link } from "react-router-dom"
import Productpage from './ProductPage'
import About from './About'
//import shopimage from '../../images/images/shopimage.jpg'
//import cabinet from '../../images/images/cabinet.jpg'
import Modal from './Modal' 

const shop = 'shop'
let clickedItems = {}


class Landing extends Component {
    state = {
        nameShop: '',
        descriptionShop: '',
        postIDs: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: ''

        },
        shopNamefromURL: '',
        shopID: '',
        clickedItems: {},
        selectedItems: []
    }




    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + " " + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + " " + pString;
            }
            else {
                priceString =
                    currency + " " + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = " ";
            }
            return priceString
        }
        //  return pString
    }


    selectItem = async (postID) => {
        let selectedItems = this.state.selectedItems

        await this.setState(prevState => {
            let clickedItems = Object.assign({}, prevState.clickedItems);

            if (!clickedItems[postID.xID] || clickedItems[postID.xID] === 'unclicked') {
                clickedItems[postID.xID] = 'clicked'
                selectedItems.push(postID)
                this.setState({ selectedItems })
            } else {
                clickedItems[postID.xID] = 'unclicked'
                selectedItems.pop(postID)
                this.setState({ selectedItems })
            }
            //clickedItems = localStorage.getItem("clickedItems") 
            localStorage.setItem("clickedItems", JSON.stringify(clickedItems))
            return { clickedItems }
        })
        console.log(' item clicked is; ', this.state.clickedItems, 'selected items are ', this.state.selectedItems)

        this.props.updateCart(selectedItems)
    }



    componentWillUnmount() {
        this.props.saveProductData(this.state.clickedItems, this.state.selectedItems)
    }

    componentWillMount = () => {
        clickedItems = JSON.parse(localStorage.getItem("clickedItems"))

        if (clickedItems) {
            this.setState({ clickedItems })

            console.log('clicked items stored are; ', clickedItems)
        }
    }

    render() {
        //console.log('hascustomDomain in landing is: ', this.props.hasCustomDomain)
        return (
            <div >
                <div id="about">
                    <div className='container' style={{ width: '100%', marginTop: 50 }}>
                        {this.props.servicesOffered && <About
                            descriptionShop={this.props.descriptionShop}
                            //categories={this.props.categories}
                            servicesOffered={this.props.servicesOffered}
                            hasloaded={this.props.hasloaded}
                        />

                        }

                    </div>
                </div>


                <Modal
                    price={this.state.modalData.price}
                    pictureURL={this.state.modalData.pictureURL}
                    title={this.state.modalData.title}
                    text={this.state.modalData.text}
                ></Modal>

                <div id='cart' style={{ marginTop: 100, borderTopWidth: 2, borderTopColor: "#efb31f" }} className='container-fluid' >
                    <h2 className="text-center mb-4" style={{ color: '#efb31f' }}>All Products</h2>
                    <hr style={{ align: "center", width: 160, margin: "auto", color: '#efb31f' }} />

                    <div className="row " style={{ paddingTop: '40px', justifyContent: 'center' }}>
                        {this.props.postIDs.map((postID, id) => {
                            return (
                                <div className="zoom-effect-container test-card shadow-white " key={id}  >
                                    <div className="card image-card my-3 " style={{ backgroundColor: '#444' }}>
                                        <a data-toggle="modal" data-target="#myModal"
                                            onClick={() => {
                                                this.setState({
                                                    modalData: {
                                                        price: this.displayprice(postID.price, postID.priceType, postID.priceCurrency) || postID.price,
                                                        pictureURL: postID.pictureURL,
                                                        title: postID.title,
                                                        text: postID.text
                                                    }
                                                })
                                            }}>

                                            <img src={postID.pictureURL} className=" img-fluid test-card" alt="product"
                                                style={{ backgroundSize: 'cover', height: 'auto' }} />


                                        </a>


                                        <p className='card-title text-truncate' >
                                            {(this.props.shopCartStatus === 'LIVE') &&
                                                <a className="rave-likes"
                                                ><i className={this.state.clickedItems[postID.xID] === 'clicked' ? "fa fa-heart" : "far fa-heart "} style={{ marginRight: 15, marginTop: 8, color: '#f6343f' }} ></i>
                                                </a>}
                                            <Link to={{
                                                pathname: `/${this.props.hasCustomDomain ? shop : this.props.shopNamefromURL}/${postID.xID}`,
                                                // pathname: '/productpage/',
                                                detailsProp: {
                                                    title: postID.title,
                                                    text: postID.text,
                                                    price: this.displayprice(postID.price, postID.priceType, postID.priceCurrency) || postID.price,
                                                    pictureURL: postID.pictureURL,
                                                    providerName: this.props.providerName,
                                                    providerAddress: this.props.providerAddress,
                                                    phoneNumber: this.props.phoneNumber,
                                                    shopNamefromURL: this.props.shopNamefromURL,
                                                    nameShop: this.props.nameShop,
                                                    url: this.props.url,
                                                    theme: this.props.theme,
                                                    shopColorTitle: this.props.shopColorTitle,
                                                    shopColorBackground: this.props.shopColorBackground,
                                                    shopLogo: this.props.shopLogo,
                                                    hasCustomDomain: this.props.hasCustomDomain,
                                                    shopSocials: this.props.shopSocials,
                                                    shopCartStatus: this.props.shopCartStatus,
                                                    shopChat: this.props.shopChat,
                                                    shopCartBankAccount: this.props.shopCartBankAccount,
                                                    FullCart: this.props.FullCart
                                                    //shopData:this.props.shopData
                                                }
                                            }}
                                            >
                                                <b style={{ color: 'white' }}>{postID.title}</b>
                                            </Link>
                                        </p>

                                        <div className="row card-footer bg-transparent"
                                            style={{
                                                overflowX: 'hidden',
                                                borderTopWidth: 1, borderTopColor: "#efb31f",
                                                alignSelf: 'center'
                                            }}>
                                            <div className="text-muted ">

                                                {(this.props.shopCartStatus === 'LIVE') && <button type="button" className="btn btn-sm btn-outline-secondary"
                                                    onClick={() => this.selectItem(postID)}
                                                    style={{
                                                        borderColor: '#efb31f',
                                                        // borderColor: this.props.shopColorTitle || '#efb31f', 
                                                        color: this.state.clickedItems[postID.xID] === 'clicked' ? 'black' : '#efb31f',
                                                        backgroundColor: this.state.clickedItems[postID.xID] === 'clicked' ? '#efb31f' : ''
                                                        //color: this.props.shopColorTitle || '#efb31f' 
                                                    }}

                                                > {this.state.clickedItems[postID.xID] === 'clicked' ? 'ADDED TO CART' : 'ADD TO CART'} </button>}

                                                <p className="card-text text-center" style={{ overflow: 'hidden', color: '#d8d8d8', marginTop: 5 }}>{this.displayprice(postID.price, postID.priceType, postID.priceCurrency) || postID.price}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>

        )
    }
}



export default Landing


