import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Home from "./Home";
import Chat from "../src/components/chats/Chat";
import Cart from "./checkout/Cart";
import BankDetails from "./checkout/BankDetails";
import ProductPage from "./components/layout/ProductPage";
import NotFound from "./components/errors/notFound";
import Wakanda2 from './components/Home/wakandaHome2'
///------------ Blog Import --------------- ////
import SignIn from "./Blog/SigIn/SignIn";
import Admin from "./Blog/Admin/Admin";
import BlogPage from "./Blog/BlogPage/BlogPage";
import FullPost from "./Blog/Admin/FullPost/FullPost";
import NewPost from "./Blog/Admin/Newpost/NewPost";
import FullBlogPost from "./Blog/FullBlogPost/FullBlogPost";
///------------ Blog Import --------------- ////

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "firebase/firestore";

/* ------------------- Importing Samples -------------------------------- */

import EngineerSample from "./components/theme-samples/EngineerSample/EngineerSample";
import PlumberSample from "./components/theme-samples/PlumberSample/PlumberSample";
import MechanicSample from "./components/theme-samples/MechanicSample/MechanicSample";
import ConstructionSample from "./components/theme-samples/ConstructionSample/ConstructionSample";
import ShoppersSample from "./components/theme-samples/ShoppersSample/ShopperSample";
import StandardSample from "./components/theme-samples/SWSample/SWsample";
import CFactorySample from "./components/theme-samples/CFactorySample/CFactorySample";

/* ------------------- Importing Samples -------------------------------- */

const routing = (
  <Router>
    <Switch>
      <Route exact path="/not-found" component={NotFound} />
      <Route exact path="/" component={Home} />

      {/* ------------------- Blog -------------------------------- */}
      <Route exact path="/:id/admin" component={SignIn} />

      <Route
        exact
        path="/:id/blog"
        render={props => <BlogPage {...props} />}
      />
      <Route
        exact
        path="/:id/blog/:postid"
        render={props => <FullBlogPost {...props} />}
      />
      <Route
        exact
        path="/:id/admin/:postid/edit"
        render={props => <NewPost {...props} isAuthed={true} />}
      />
      <Route
        exact
        path="/:id/admin/:postid"
        render={props => <FullPost {...props} />}
      />
      {/* ------------------- Blog -------------------------------- */}
      {/* <Route exact path="/admin" component={SignIn} />

      <Route
        exact
        path="/blog"
        render={props => <BlogPage {...props} />}
      />
      <Route
        exact
        path="/blog/:postid"
        render={props => <FullBlogPost {...props} />}
      />
      <Route
        exact
        path="/admin/:postid/edit"
        render={props => <NewPost {...props} isAuthed={true} />}
      />
      <Route
        exact
        path="/admin/:postid"
        render={props => <FullPost {...props} />}
      /> */}


      {/* ------------------- Chat --------------------------------- */}
      <Route exact path="/chat" component={Chat} />

      {/* ------------------- Cart --------------------------------- */}
      <Route
        exact
        path="/cart"
        render={props => <Cart {...props} isAuthed={true} />}
      />
      {/* ------------------- Bank Details --------------------------------- */}
      <Route
        exact
        path="/bankdetails"
        render={props => <BankDetails {...props} isAuthed={true} />}
      />
      {/* ------------------- Samples --------------------------------- */}
      <Route exact path="/engineer-sample" component={EngineerSample} />
      <Route exact path="/plumber-sample" component={PlumberSample} />
      <Route exact path="/mechanic-sample" component={MechanicSample} />
      <Route exact path="/construction-sample" component={ConstructionSample} />
      <Route exact path="/shoppers-sample" component={ShoppersSample} />
      <Route exact path="/standard-white-sample" component={StandardSample} />
      <Route exact path="/cake-factory-sample" component={CFactorySample} />
      {/* ------------------- Home --------------------------------- */}
      <Route exact path="/new-home-page" component={Wakanda2} />
      <Route exact path="/:id" component={Home} />

      {/* ------------------- Blog --------------------------------- */}

      {/* ------------------- Chat ---------------------------------- */}
      <Route
        exact
        path="/:id/chat"
        render={props => <Chat {...props} isAuthed={true} />}
      />

      {/* ------------------- Cart ------------------------------------ */}
      <Route
        exact
        path="/:id/cart"
        render={props => <Cart {...props} isAuthed={true} />}
      />
      {/* ------------------- Bank Details ------------------------------------ */}
      <Route
        exact
        path="/:id/bankdetails"
        render={props => <BankDetails {...props} isAuthed={true} />}
      />

      {/* ------------------- Product Page -------------------------- */}
      <Route
        exact
        path="/:id/:xID"
        render={props => <ProductPage {...props} isAuthed={true} />}
      />

      <Route component={NotFound} />
    </Switch>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));