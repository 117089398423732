import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Profile from './Profile'
import ShoppersProductPage from '../themes/shoppers/ShoppersProductPage'
import ElectroProductPage from '../themes/electro-master/electroProductPage'
import RaveProductPage from '../themes/rave/RaveProductPage'
import ModazProductPage from '../themes/modaz/ModazProductPage'
import CFactoryProductPage from '../themes/CakeFactory/ProductPage'
import SWhiteProductPage from '../themes/StandardWhite/ProductPage'
import Cart from '../../checkout/cartModal'
import { Redirect } from 'react-router-dom'

import leftArrow from '../../assets/left-arrow.svg'
import shoppingCartIcon from "../../images/icons/add_shopping_cart-24px.svg"
import shippingIcon from "../../images/icons/truck.svg"
import { getAllProductImages } from '../themes/utilityfunctions'

let FullCart = []
//let cartStorage = window.localStorage;
let cartStorage = {}


let WM_DATA_Storage = {
    shopData: "",
    url: "",
    hasCustomDomain: "",
    theme: "",
    shopNamefromURL: "",
    nameShop: "",
    FullCart: [],
    clickedItems: {}
}
let clickedItems = {}
WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
clickedItems = JSON.parse(localStorage.getItem("clickedItems"))


class ProductPage extends Component {

    state = {
        product: {
            title: '',
            text: '',
            price: '',
            pictureURL: '',
            currency: '',
            xID: ''
        },
        description: '',
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        servicesOffered: '',
        postIDs: '',
        xID: '',
        shopNamefromURL: '',
        nameShop: '',
        shopLogo: '',
        url: '',
        shopID: '',
        redirect: false,
        productExists: '',
        loading: true,
        theme: '',
        shopColorTitle: '',
        shopColorBackground: '',
        shopColorButton: '',
        shopColorHeader: '',
        shopColorTitle: '',
        shopFrontPicture: '',
        HomePageData: '',
        hasCustomDomain: '',
        shopSocials: '',
        logo: '',
        shopLogo: '',
        servicesOffered: '',
        shopCartStatus: '',
        shopCartBankAccount: '',
        shopChat: '',
        shopNamefromURL: '',
        tagline: '',
        FullCart: '',
        cartModalDetails: {
            FullCart: '',
            shopNamefromURL: '',
            hasCustomDomain: '',
            nameShop: '',
            shopCartBankAccount: ''
        },
        cartModalWidth: 0,
        showModalCart: false,
        productImages: {},
        imageInView: ''
    }






    parseURLandReturnRelevantID = () => {
        const fullURL = window.location.href.split('#')[0]
        const fullURLsubStrings = fullURL.split('/');
        console.log(fullURLsubStrings);
        fullURLsubStrings.forEach(itemURL => {
            if (itemURL.length == 20 && fullURLsubStrings.indexOf(`${itemURL}`) == 4) {
                console.log('item url is:', itemURL)
                if (fullURLsubStrings[3] === 'shop') {
                    this.setState({ xID: itemURL, shopNamefromURL: '', url: fullURLsubStrings[2] })
                }
                else {
                    this.setState({ xID: itemURL, shopNamefromURL: fullURLsubStrings[3], url: fullURLsubStrings[2] })
                }


            }
        })
        return
    }

    chooseHomeURL = () => {
        if (this.state.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.state.shopNamefromURL
        }
    }

    fetchProduct = async () => {
        const xID = this.state.xID
        console.log('the id passsed to fetchproduct method is: ', this.state.xID, 'postIDs are: ', this.state.postIDs)
        let product = ''
        await this.state.postIDs.filter(postID => {
            if (postID.xID === xID) {

                product = postID

                this.setState({
                    product: {
                        title: postID.title,
                        text: postID.text,
                        price: this.displayprice(postID.price, postID.priceType, postID.priceCurrency),
                        pictureURL: postID.pictureURL,
                        imageInView: postID.pictureURL,
                        xID: postID.xID
                    },
                    productExists: true,
                    loading: false
                })
            }
        });
        if (this.state.productExists === '') {
            this.setState({ productExists: false })
        } else {
            let productImages = getAllProductImages(product)
            await this.setState({ productImages })
            console.log('product images are: ', this.state.productImages)
        }
        //console.log('the fetched product is: ', post)
    }


    fetchdataWithShopName = async () => {

        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    shopName: `${this.state.shopNamefromURL}`
                })
            });
            const r2 = await r.json();
            console.log("Received response using shopnamefromurl", this.state.shopNamefromURL, ' is: ', r2);
            if (r2.msg === 'SUCCESS') {

                let HomePageData = r2.data
                console.log("Profile ", r2.data.profile);
                let shopProfile = r2.data.profile
                let shopProfileName = shopProfile.businessName
                let description = shopProfile.description
                let servicesOffered = shopProfile.servicesOffered
                //////////////////////////////////////////////////////////////////////////////

                let providerName = shopProfile.name;
                let providerAddress = shopProfile.locationWorkAddress
                let phoneNumber = shopProfile.phoneNumber
                let theme = r2.data.shop.shopType
                //let shopLogo = shopProfile.pictureURL

                ////////////////////////////////////////////////////////////////////////////////////////////
                let shopPost = r2.data.posts

                //console.log('shopPost', shopPost)
                ////////////////////////////////////////////////////////////////////////////////////////////
                let shop = r2.data.shop
                let shopLogo = shop.shopLogoPicture
                let shopID = shop.shopName
                let shopColorBackground = shop.shopColorBackground
                let shopColorButton = shop.shopColorButton
                let shopColorHeader = shop.shopColorHeader
                let shopColorTitle = shop.shopColorTitle
                let shopFrontPicture = shop.shopFrontPicture
                let shopSocials = shop.shopSocials
                ///////////////////////////////////////////////////////////////////////////////////////////

                let tagline = shop.shopSlogan
                let shopNamefromURL = shop.shopName
                let shopCartStatus = shop.shopCartStatus
                let shopChat = shop.shopChat
                let shopCartBankAccount = shop.shopCartBankAccount

                //////////////////////////////////////////////////////////////////////////////////// 





                await this.setState({
                    nameShop: shopProfileName,
                    description, postIDs: shopPost, providerName,
                    providerAddress, phoneNumber, servicesOffered, theme,
                    shopColorBackground, shopColorButton,
                    shopColorHeader, shopColorTitle, shopFrontPicture,
                    shopLogo, HomePageData, shopID, shopSocials,
                    logo: shopLogo, servicesOffered, shopCartStatus,
                    shopCartBankAccount, shopChat, shopNamefromURL, tagline

                })
                document.title = this.state.nameShop || this.state.shopID

                let WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
                let cartStorage = JSON.parse(localStorage.getItem("cartStorage"))
                if (WM_DATA_Storage && (WM_DATA_Storage.shopNamefromURL === this.state.shopNamefromURL)) {
                    await this.setState({ FullCart: WM_DATA_Storage.FullCart })
                }
                //
                this.fetchProduct()

            } else {
                console.log('product data not loading...')
                //this.setState({ productExists: false })
            }


        } catch (err) {
            console.log("Error", err);
            //this.setState({ redirect: true })
        }

    }

    fetchdataWithURL = async () => {

        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    url: 'http://' + `${this.state.url}`
                })
            });
            const r2 = await r.json();
            console.log("Received response using url is. ", r2.msg);

            if (r2.msg === 'URL_DOES_NOT_EXIST') {
                return
            } else {

                let HomePageData = r2.data
                let hasCustomDomain = true
                console.log("Profile response using url is", r2.data.profile);
                let shopProfile = r2.data.profile
                let shopProfileName = shopProfile.businessName
                let description = shopProfile.description
                //////////////////////////////////////////////////////////////////////////////

                let providerName = shopProfile.name;
                let providerAddress = shopProfile.locationWorkAddress
                let phoneNumber = shopProfile.phoneNumber
                let theme = r2.data.shop.shopType
                ////////////////////////////////////////////////////////////////////////////////////////////

                let shopPost = r2.data.posts
                //console.log('shopPost', shopPost)
                let shop = r2.data.shop
                let shopLogo = shop.shopLogoPicture
                let shopID = shop.shopName
                let shopColorBackground = shop.shopColorBackground
                let shopColorButton = shop.shopColorButton
                let shopColorHeader = shop.shopColorHeader
                let shopColorTitle = shop.shopColorTitle
                let shopFrontPicture = shop.shopFrontPicture
                let shopSocials = shop.shopSocials


                /////////////////////////////////////////////////////////////////////////////////////
                let servicesOffered = shopProfile.servicesOffered
                let tagline = shop.shopSlogan
                let shopNamefromURL = shop.shopName
                let shopCartStatus = shop.shopCartStatus
                let shopChat = shop.shopChat
                let shopCartBankAccount = shop.shopCartBankAccount
                ////////////////////////////////////////////////////////////////////////////////////

                if (this.state.shopNamefromURL != shopID && this.state.shopNamefromURL != 'shop' && this.state.shopNamefromURL != '') {
                    this.setState({ redirect: true })
                    //this.setState({ productExists: false })
                }
                // console.log('shopName', shopProfileName)
                await this.setState({
                    nameShop: shopProfileName,
                    description, postIDs: shopPost,
                    providerName, providerAddress,
                    phoneNumber, shopID, shopColorBackground,
                    shopColorButton, shopColorHeader, shopColorTitle,
                    shopFrontPicture, theme,
                    shopLogo, HomePageData,
                    hasCustomDomain, shopSocials,
                    logo: shopLogo, servicesOffered, shopCartStatus,
                    shopCartBankAccount, shopChat, shopNamefromURL, tagline
                })
                // console.log('first postID is: ', this.state.postIDs[0].xID)

                /* let WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
                let cartStorage = JSON.parse(localStorage.getItem("cartStorage"))
                if (WM_DATA_Storage && (WM_DATA_Storage.shopNamefromURL === this.state.shopNamefromURL)) {
                    this.setState({ FullCart: WM_DATA_Storage.FullCart })
                } */
                WM_DATA_Storage = {
                    shopData: this.state.shopData,
                    url: this.state.initialURL,
                    hasCustomDomain: this.state.hasCustomDomain,
                    theme: this.state.theme,
                    shopNamefromURL: this.state.shopNamefromURL,
                    nameShop: this.state.nameShop,
                    FullCart: [],
                    clickedItems: {}
                }
                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                //
                this.fetchProduct()
            }


        } catch (err) {
            console.log("Error", err);
            //return
            // this.setState({redirect:true})
        }

    }





    openAndCloseCartModal = () => {
        this.setState({
            cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
            showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
        })
    }

    updateModalCart = () => {
        this.setState({
            cartModalDetails: {
                FullCart: this.state.FullCart,
                shopNamefromURL: this.state.shopNamefromURL,
                hasCustomDomain: this.state.hasCustomDomain,
                nameShop: this.state.nameShop,
                shopCartBankAccount: this.state.shopCartBankAccount
            }
        })
    }




    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + " " + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + " " + pString;
            }
            else {
                priceString =
                    currency + " " + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = " ";
            }
            return priceString
        }
        //  return pString
    }

    topFunction = () => {
        window.scrollTo(0, 0)
        return
    }

    goBack = () => {
        this.props.history.push(this.chooseHomeURL())
    }


    componentDidMount = async () => {


        if (this.props.location.detailsProp == null || undefined) {

            await this.parseURLandReturnRelevantID();

            if (WM_DATA_Storage && (WM_DATA_Storage.shopNamefromURL === this.state.shopNamefromURL)) {

                console.log('loading Data from Browser Local Storage: ', WM_DATA_Storage, ' FullCart items are ', WM_DATA_Storage.FullCart)
                const { shopData, url, hasCustomDomain, theme, shopNamefromURL, nameShop, FullCart } = WM_DATA_Storage



                if (this.state.url !== 'www.wakanda-market.com' && this.state.url !== 'wakanda-market.com' && this.state.url !== 'localhost:3000') {

                    this.fetchdataWithURL();
                    this.fetchdataWithShopName();
                    document.title = this.state.nameShop || this.state.shopID
                }
                else {
                    this.setState({ FullCart, shopData, url, hasCustomDomain, theme, shopNamefromURL, nameShop })
                    this.fetchdataWithShopName();

                }
            } else {
                await this.fetchdataWithShopName()
                WM_DATA_Storage = {
                    shopData: this.state.shopData,
                    url: this.state.url,
                    hasCustomDomain: this.state.hasCustomDomain,
                    theme: this.state.theme,
                    shopNamefromURL: this.state.shopNamefromURL,
                    nameShop: this.state.nameShop,
                    FullCart: [],
                    clickedItems: {}
                }
                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                localStorage.removeItem("cartStorage");
                localStorage.removeItem("clickedItems");
            }

        } else {


            const { hasCustomDomain, title, text, price, pictureURL, providerName, providerAddress,
                phoneNumber, shopNamefromURL, url, nameShop, servicesOffered, shopColorTitle, theme,
                shopColorBackground, shopColorButton, shopColorHeader, shopLogo, shopData, description, currency, shopSocials,
                shopCartStatus, shopChat, shopCartBankAccount, FullCart, productImages, xID } = this.props.location.detailsProp

            await this.setState({
                providerName, providerAddress, phoneNumber, shopNamefromURL,
                url, nameShop, description, theme,
                servicesOffered, shopColorTitle,
                shopColorBackground, shopColorButton, shopColorHeader, logo: shopLogo, product: {
                    title, text, price, pictureURL, currency, xID
                },
                productExists: true,
                HomePageData: shopData,
                hasCustomDomain,
                shopSocials, shopCartStatus, shopChat, shopCartBankAccount, FullCart, productImages,
            })
            this.topFunction()
        }

        console.log('data received on product page is: ', this.props.location.detailsProp)
    }


    render() {
        return (
            <div>
                {this.state.redirect && <Redirect to='/not-found' />}
                {!this.state.redirect && this.state.theme === 'Standard' && <div id="page-container"
                    className="App container-fluid pr-0 pl-0 h-100 d-flex flex-column"
                    style={{ backgroundColor: 'white' }}
                >
                    <Navbar
                        showCartModal={() => {
                            this.openAndCloseCartModal()
                            this.updateModalCart()
                        }}
                        showAbout={true}
                        showContact={true}
                        nameShop={this.state.nameShop}
                        logo={this.state.logo}
                        shopNamefromURL={(this.props.hasCustomDomain || this.state.hasCustomDomain) ? '' : this.state.shopNamefromURL}
                        url={this.state.url}
                        showSearch={false}
                        shopColorBackground={this.state.shopColorBackground}
                        FullCart={this.state.FullCart}
                        hasCustomDomain={this.props.hasCustomDomain}
                        shopCartStatus={this.state.shopCartStatus}
                        shopCartBankAccount={this.state.shopCartBankAccount}
                    />

                    <div className="container mb-4"> <img src={leftArrow} onClick={this.goBack} style={{ width: 40, height: 40, cursor: 'pointer' }} /></div>
                    <div className=" mx-auto container row">

                        {this.state.productExists === false && this.state.loading && <div style={{ height: '200px' }} >
                            <h2 >Oops! :'( Product Does Not Exist!</h2>
                            <p>Please contact the provider for a valid product link</p>
                        </div>
                        }
                        <div className="col-md-6">


                            {this.state.product.pictureURL &&
                                <div style={{ justifyContent: 'center' }} className="mb-4">
                                    <img src={this.state.imageInView || this.state.product.pictureURL} alt="picture"
                                        className="img-fluid img-thumbnail" />
                                </div>}



                            {this.state.productImages[this.state.product.xID] && <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, width: '100%' }}>
                                {this.state.productImages[this.state.product.xID].map((image, id) => {
                                    return (
                                        <div key={id} style={{
                                            backgroundImage: `url(${image})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: "cover",
                                            backgroundPosition: "center center",
                                            backgroundAttachment: 'relative',
                                            backgroundColor: 'rgba(255,255,255,0.3)',
                                            overflow: 'hidden',
                                            height: "auto",
                                            maxWidth: '104%',
                                            width: 100,
                                            height: 100,
                                            border: '1px solid rgba(204, 204, 204, 0.5)'
                                        }}
                                            onClick={() => this.setState({ imageInView: image })}
                                        ></div>
                                    )
                                })}
                            </div>}




                            {this.state.loading && this.state.productExists === '' &&

                                <p className="lead">Product loading...</p>
                            }


                        </div>

                        <div class=" mr-md-3  px-3 px-md-5 overflow-hidden col-md-5 m-0" >
                            <div class="my-3 py-3">
                                <h3 style={{ color: 'black' }} className="m-0 p-0" >{this.state.product.title}</h3>
                                <h5 className=" mt-0" style={{ color: "#888", marginBottom: 30 }} >{this.state.product.price}</h5>
                                <hr />
                                {this.state.shopCartStatus === 'LIVE' && <div>
                                    {/* <p className="text-black py-1 " style={{ fontSize: 14 }}><span className="mr-3">Quantity</span><span className="border py-3"><span><button style={{ backgroundColor: 'white', color: 'black', }} >-</button></span><span className="border-right border-left px-3">{this.state.quantity}</span><span><button style={{ backgroundColor: 'white', color: 'black' }}>+</button></span></span></p> */}
                                    {/* <hr /> */}
                                    <button style={{ backgroundColor: 'black', paddingLeft: 50, paddingRight: 50 }}>BUY NOW</button>
                                    <button style={{ backgroundColor: 'transparent', color: 'black', fontSize: 12 }}><img
                                        src={shoppingCartIcon}
                                        alt="shopping cart icon"
                                        width="30"
                                        height="30"
                                    />Add to Cart</button>
                                    <hr />
                                </div>}
                            </div>
                        </div>




                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div class="mb-4 box-shadow">
                                    <div class="card-body" style={{ backgroundColor: "#F3F3F3" }}>
                                        <h5 style={{ color: 'black' }}>DESCRIPTION</h5>
                                        <hr />
                                        <p class="card-text" style={{ color: 'black', fontSize: 12, lineHeight: 2 }}>{this.state.product.text}</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div class=" mb-4 box-shadow">
                                    <div class="card-body" style={{ backgroundColor: "#F3F3F3" }}>
                                        <h5 style={{ color: 'black' }}>SHIPPING & RETURNS</h5>
                                        <hr />
                                        <p class="card-text" style={{ color: 'black', fontSize: 12, lineHeight: 2 }}><img
                                            src={shippingIcon}
                                            alt="shopping cart icon"
                                            width="30"
                                            height="30"
                                        />&nbsp;Contact us for delivery details.</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div class=" mb-4 box-shadow">
                                    <div class="card-body" style={{ backgroundColor: "#F3F3F3" }}>
                                        <h5 style={{ color: 'black' }}>REVIEWS</h5>
                                        <hr />
                                        <p class="card-text" style={{ color: 'black', fontSize: 12, lineHeight: 2 }}> No reviews at this time.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {!this.state.showModalCart && <section style={{ backgroundColor: '#333', color: '#777', fontWeight: 'normal' }}>
                        <div style={{ borderTop: '1px solid #efb31f', marginTop: '-100px' }} className='container-fluid' id="contact">
                            <Profile
                                providerName={this.state.providerName}
                                providerAddress={this.state.providerAddress}
                                phoneNumber={this.state.phoneNumber}
                                shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                                shopSocials={this.state.shopSocials}
                            />
                        </div>
                    </section>}
                    {!this.state.showModalCart && <Footer shopSocials={this.state.shopSocials}
                        nameShop={this.state.nameShop ? this.state.nameShop : ''} />}
                    {/* -----------------------------  GENERAL CART -------------------------------- */}
                    <div id='cart-modal' style={{
                        width: this.state.cartModalWidth,
                        height: 'inherit',
                        position: 'absolute',
                        right: 0, top: 60,
                        backgroundColor: 'white',
                        WebkitTransition: 'width 0.5s',
                        transition: 'width 0.5s',
                        paddingTop: 50,
                        paddingBottom: 100
                    }}>

                        <div onClick={this.openAndCloseCartModal}>
                            <h1 style={{ color: 'black', paddingLeft: 50 }}>X</h1> </div>

                        {this.state.showModalCart && <div style={{ height: 'inherit', }}>
                            <Cart
                                CartDetails={this.state.cartModalDetails}
                                // cartShowbankDetails={this.state.cartShowbankDetails}
                                cartShowbankDetails={true}
                                cartTotal={this.state.cartTotal}
                                FullCart={this.state.FullCart}
                                updateCart={(FullCart) => {

                                    localStorage.setItem("cartStorage", JSON.stringify(FullCart));
                                    cartStorage = JSON.parse(localStorage.getItem("cartStorage"))
                                    WM_DATA_Storage.FullCart = cartStorage
                                    localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                    console.log('products added to cart are: ', this.state.FullCart, 'items stored in browser is therefore: ', WM_DATA_Storage.FullCart)
                                    this.setState({ FullCart })

                                }}

                                clearLocalStorageCart={() => {
                                    WM_DATA_Storage.FullCart = ''
                                    localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                    localStorage.removeItemItem("cartStorage");
                                }}

                                storeCartDetails={(a, b) => {
                                    this.setState({ cartShowbankDetails: a, cartTotal: b })
                                }}
                            />
                        </div>}

                    </div>
                </div>}
                {/* -----------------------------  SHOPPERS THEME PRODUCT PAGE -------------------------------- */}

                {this.props.location.detailsProp && <div>
                    {!this.state.redirect && this.props.location.detailsProp.theme === 'Shoppers' && <ShoppersProductPage
                        pictureURL={this.props.location.detailsProp.pictureURL || this.state.product.pictureURL}
                        title={this.props.location.detailsProp.title || this.state.product.title}
                        text={this.props.location.detailsProp.text || this.state.product.text}
                        price={this.props.location.detailsProp.price || this.state.product.price}
                        nameShop={this.props.location.detailsProp.nameShop || this.state.nameShop}
                        servicesOffered={this.props.location.detailsProp.servicesOffered || this.state.servicesOffered}
                        providerName={this.props.location.detailsProp.providerName || this.state.providerName}
                        providerAddress={this.props.location.detailsProp.providerAddress || this.state.providerAddress}
                        phoneNumber={this.props.location.detailsProp.phoneNumber || this.state.phoneNumber}
                        shopNamefromURL={this.props.location.detailsProp.shopNamefromURL || this.state.shopID}
                        description={this.props.location.detailsProp.description || this.state.description}
                        hasCustomDomain={this.state.hasCustomDomain}
                        shopSocials={this.props.location.detailsProp.shopSocials || this.state.shopSocials}
                        productExists={this.state.productExists}
                        loading={this.state.loading}
                        shopCartStatus={this.props.location.detailsProp.shopCartStatus || this.state.shopCartStatus}
                        shopChat={this.props.location.detailsProp.shopChat || this.state.shopChat}
                        shopCartBankAccount={this.props.location.detailsProp.shopCartBankAccount || this.state.shopCartBankAccount}
                        FullCart={this.props.location.detailsProp.FullCart || this.state.FullCart}
                        productImages={this.props.location.detailsProp.productImages || this.state.productImages}
                        xID={this.props.location.detailsProp.xID || this.state.product.xID}
                        shopLogo={this.props.location.detailsProp.shopLogo || this.state.shopLogo}
                    />}</div>}

                {!this.state.redirect && this.state.theme === 'Shoppers' && !this.props.location.detailsProp && <ShoppersProductPage
                    pictureURL={this.state.product.pictureURL}
                    title={this.state.product.title}
                    text={this.state.product.text}
                    price={this.state.product.price}
                    nameShop={this.state.nameShop}
                    servicesOffered={this.state.servicesOffered}
                    // pictureURL={this.state.pictureURL} 
                    url={this.state.url}
                    //shopNamefromURL={this.state.shopNamefromURL}
                    providerName={this.state.providerName}
                    providerAddress={this.state.providerAddress}
                    phoneNumber={this.state.phoneNumber}
                    shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                    description={this.state.shopProfileDescription}
                    hasCustomDomain={this.state.hasCustomDomain}
                    shopSocials={this.state.shopSocials}
                    productExists={this.state.productExists}
                    loading={this.state.loading}
                    shopCartStatus={this.state.shopCartStatus}
                    shopChat={this.state.shopChat}
                    shopCartBankAccount={this.state.shopCartBankAccount}
                    FullCart={this.state.FullCart}
                    productImages={this.state.productImages}
                    xID={this.state.product.xID}
                    shopLogo={this.state.shopLogo}
                />}
                {/* -----------------------------  SHOPPERS THEME PRODUCT PAGE -------------------------------- */}
                {/* -----------------------------  ELECTRO THEME PRODUCT PAGE -------------------------------- */}

                {this.props.location.detailsProp && <div>
                    {!this.state.redirect && this.props.location.detailsProp.theme === 'Electro' && <ElectroProductPage
                        pictureURL={this.props.location.detailsProp.pictureURL || this.state.product.pictureURL}
                        title={this.props.location.detailsProp.title || this.state.product.title}
                        text={this.props.location.detailsProp.text || this.state.product.text}
                        price={this.props.location.detailsProp.price || this.state.product.price}
                        currency={this.props.location.detailsProp.currency || this.state.product.currency}
                        nameShop={this.props.location.detailsProp.nameShop || this.state.nameShop}
                        servicesOffered={this.props.location.detailsProp.servicesOffered || this.state.servicesOffered}
                        providerName={this.props.location.detailsProp.providerName || this.state.providerName}
                        providerAddress={this.props.location.detailsProp.providerAddress || this.state.providerAddress}
                        phoneNumber={this.props.location.detailsProp.phoneNumber || this.state.phoneNumber}
                        shopNamefromURL={this.props.location.detailsProp.shopNamefromURL || this.state.shopID}
                        description={this.props.location.detailsProp.description || this.state.description}
                        hasCustomDomain={this.state.hasCustomDomain}
                        shopSocials={this.props.location.detailsProp.shopSocials || this.state.shopSocials}
                        productExists={this.state.productExists}
                        loading={this.state.loading}
                        shopCartStatus={this.props.location.detailsProp.shopCartStatus || this.state.shopCartStatus}
                        shopChat={this.props.location.detailsProp.shopChat || this.state.shopChat}
                        shopCartBankAccount={this.props.location.detailsProp.shopCartBankAccount || this.state.shopCartBankAccount}
                        FullCart={this.props.location.detailsProp.FullCart || this.state.FullCart}
                        productImages={this.props.location.detailsProp.productImages || this.state.productImages}
                        xID={this.props.location.detailsProp.xID || this.state.product.xID}
                        shopLogo={this.props.location.detailsProp.shopLogo || this.state.shopLogo}
                    />}</div>}

                {!this.state.redirect && this.state.theme === 'Electro' && !this.props.location.detailsProp && <ElectroProductPage
                    pictureURL={this.state.product.pictureURL}
                    title={this.state.product.title}
                    text={this.state.product.text}
                    price={this.state.product.price}
                    currency={this.state.product.currency}
                    nameShop={this.state.nameShop}
                    servicesOffered={this.state.servicesOffered}
                    url={this.state.url}
                    providerName={this.state.providerName}
                    providerAddress={this.state.providerAddress}
                    phoneNumber={this.state.phoneNumber}
                    shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                    description={this.state.shopProfileDescription}
                    hasCustomDomain={this.state.hasCustomDomain}
                    shopSocials={this.state.shopSocials}
                    productExists={this.state.productExists}
                    loading={this.state.loading}
                    shopCartStatus={this.state.shopCartStatus}
                    shopChat={this.state.shopChat}
                    shopCartBankAccount={this.state.shopCartBankAccount}
                    FullCart={this.state.FullCart}
                    productImages={this.state.productImages}
                    xID={this.state.product.xID}
                />}
                {/* -----------------------------  ELECTRO THEME PRODUCT PAGE -------------------------------- */}
                {/* -----------------------------  RAVE THEME PRODUCT PAGE -------------------------------- */}

                {this.props.location.detailsProp && <div>
                    {!this.state.redirect && this.props.location.detailsProp.theme === 'Rave' && <RaveProductPage
                        pictureURL={this.props.location.detailsProp.pictureURL || this.state.product.pictureURL}
                        title={this.props.location.detailsProp.title || this.state.product.title}
                        text={this.props.location.detailsProp.text || this.state.product.text}
                        price={this.props.location.detailsProp.price || this.state.product.price}
                        currency={this.props.location.detailsProp.currency || this.state.product.currency}
                        nameShop={this.props.location.detailsProp.nameShop || this.state.nameShop}
                        servicesOffered={this.props.location.detailsProp.servicesOffered || this.state.servicesOffered}
                        providerName={this.props.location.detailsProp.providerName || this.state.providerName}
                        providerAddress={this.props.location.detailsProp.providerAddress || this.state.providerAddress}
                        phoneNumber={this.props.location.detailsProp.phoneNumber || this.state.phoneNumber}
                        shopNamefromURL={this.props.location.detailsProp.shopNamefromURL || this.state.shopID}
                        description={this.props.location.detailsProp.description || this.state.description}
                        shopColorButton={this.props.location.detailsProp.shopColorButton || this.state.shopColorButton}
                        hasCustomDomain={this.state.hasCustomDomain}
                        shopSocials={this.props.location.detailsProp.shopSocials || this.state.shopSocials}
                        productExists={this.state.productExists}
                        loading={this.state.loading}
                        shopCartStatus={this.props.location.detailsProp.shopCartStatus || this.state.shopCartStatus}
                        shopChat={this.props.location.detailsProp.shopChat || this.state.shopChat}
                        shopCartBankAccount={this.props.location.detailsProp.shopCartBankAccount || this.state.shopCartBankAccount}
                        FullCart={this.props.location.detailsProp.FullCart || this.state.FullCart}
                        productImages={this.props.location.detailsProp.productImages || this.state.productImages}
                        xID={this.props.location.detailsProp.xID || this.state.product.xID}
                        shopLogo={this.props.location.detailsProp.shopLogo || this.state.shopLogo}
                    />}
                </div>}

                {!this.state.redirect && this.state.theme === 'Rave' && !this.props.location.detailsProp && <RaveProductPage
                    pictureURL={this.state.product.pictureURL}
                    title={this.state.product.title}
                    text={this.state.product.text}
                    price={this.state.product.price}
                    currency={this.state.product.currency}
                    nameShop={this.state.nameShop}
                    servicesOffered={this.state.servicesOffered}
                    url={this.state.url}
                    providerName={this.state.providerName}
                    providerAddress={this.state.providerAddress}
                    phoneNumber={this.state.phoneNumber}
                    shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                    description={this.state.shopProfileDescription}
                    shopColorButton={this.state.shopColorButton}
                    hasCustomDomain={this.state.hasCustomDomain}
                    shopSocials={this.state.shopSocials}
                    productExists={this.state.productExists}
                    loading={this.state.loading}
                    shopCartStatus={this.state.shopCartStatus}
                    shopChat={this.state.shopChat}
                    shopCartBankAccount={this.state.shopCartBankAccount}
                    FullCart={this.state.FullCart}
                    productImages={this.state.productImages}
                    xID={this.state.product.xID}
                    shopLogo={this.state.shopLogo}
                />}
                {/* -----------------------------  RAVE THEME PRODUCT PAGE -------------------------------- */}

                {/* -----------------------------  MODAZ THEME PRODUCT PAGE -------------------------------- */}

                {this.props.location.detailsProp && <div>
                    {!this.state.redirect && this.props.location.detailsProp.theme === 'Modaz' && <ModazProductPage
                        pictureURL={this.props.location.detailsProp.pictureURL || this.state.product.pictureURL}
                        title={this.props.location.detailsProp.title || this.state.product.title}
                        text={this.props.location.detailsProp.text || this.state.product.text}
                        price={this.props.location.detailsProp.price || this.state.product.price}
                        currency={this.props.location.detailsProp.currency || this.state.product.currency}
                        nameShop={this.props.location.detailsProp.nameShop || this.state.nameShop}
                        servicesOffered={this.props.location.detailsProp.servicesOffered || this.state.servicesOffered}
                        providerName={this.props.location.detailsProp.providerName || this.state.providerName}
                        providerAddress={this.props.location.detailsProp.providerAddress || this.state.providerAddress}
                        phoneNumber={this.props.location.detailsProp.phoneNumber || this.state.phoneNumber}
                        shopNamefromURL={this.props.location.detailsProp.shopNamefromURL || this.state.shopID}
                        description={this.props.location.detailsProp.description || this.state.description}
                        shopColorButton={this.props.location.detailsProp.shopColorButton || this.state.shopColorButton}
                        hasCustomDomain={this.state.hasCustomDomain}
                        shopSocials={this.props.location.detailsProp.shopSocials || this.state.shopSocials}
                        productExists={this.state.productExists}
                        loading={this.state.loading}
                        shopCartStatus={this.props.location.detailsProp.shopCartStatus || this.state.shopCartStatus}
                        shopChat={this.props.location.detailsProp.shopChat || this.state.shopChat}
                        shopCartBankAccount={this.props.location.detailsProp.shopCartBankAccount || this.state.shopCartBankAccount}
                        FullCart={this.props.location.detailsProp.FullCart || this.state.FullCart}
                        productImages={this.props.location.detailsProp.productImages || this.state.productImages}
                        xID={this.props.location.detailsProp.xID || this.state.product.xID}
                        shopLogo={this.props.location.detailsProp.shopLogo || this.state.shopLogo}
                    />}</div>}

                {!this.state.redirect && this.state.theme === 'Modaz' && !this.props.location.detailsProp && <ModazProductPage
                    pictureURL={this.state.product.pictureURL}
                    title={this.state.product.title}
                    text={this.state.product.text}
                    price={this.state.product.price}
                    currency={this.state.product.currency}
                    nameShop={this.state.nameShop}
                    servicesOffered={this.state.servicesOffered}
                    url={this.state.url}
                    providerName={this.state.providerName}
                    providerAddress={this.state.providerAddress}
                    phoneNumber={this.state.phoneNumber}
                    shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                    description={this.state.shopProfileDescription}
                    shopColorButton={this.state.shopColorButton}
                    hasCustomDomain={this.state.hasCustomDomain}
                    shopSocials={this.state.shopSocials}
                    productExists={this.state.productExists}
                    loading={this.state.loading}
                    shopCartStatus={this.state.shopCartStatus}
                    shopChat={this.state.shopChat}
                    shopCartBankAccount={this.state.shopCartBankAccount}
                    FullCart={this.state.FullCart}
                    productImages={this.state.productImages}
                    xID={this.state.product.xID}
                    shopLogo={this.state.shopLogo}
                />}
                {/* -----------------------------  MODAZ THEME PRODUCT PAGE -------------------------------- */}

                {/* -----------------------------  Cake Factory THEME PRODUCT PAGE -------------------------------- */}

                {this.props.location.detailsProp && <div>
                    {!this.state.redirect && this.props.location.detailsProp.theme === 'Cake Factory' && <CFactoryProductPage
                        pictureURL={this.props.location.detailsProp.pictureURL || this.state.product.pictureURL}
                        title={this.props.location.detailsProp.title || this.state.product.title}
                        text={this.props.location.detailsProp.text || this.state.product.text}
                        price={this.props.location.detailsProp.price || this.state.product.price}
                        currency={this.props.location.detailsProp.currency || this.state.product.currency}
                        nameShop={this.props.location.detailsProp.nameShop || this.state.nameShop}
                        servicesOffered={this.props.location.detailsProp.servicesOffered || this.state.servicesOffered}
                        providerName={this.props.location.detailsProp.providerName || this.state.providerName}
                        providerAddress={this.props.location.detailsProp.providerAddress || this.state.providerAddress}
                        phoneNumber={this.props.location.detailsProp.phoneNumber || this.state.phoneNumber}
                        shopNamefromURL={this.props.location.detailsProp.shopNamefromURL || this.state.shopID}
                        description={this.props.location.detailsProp.description || this.state.description}
                        shopColorButton={this.props.location.detailsProp.shopColorButton || this.state.shopColorButton}
                        shopColorHeader={this.props.location.detailsProp.shopColorHeader || this.state.shopColorHeader}
                        hasCustomDomain={this.state.hasCustomDomain}
                        shopSocials={this.props.location.detailsProp.shopSocials || this.state.shopSocials}
                        productExists={this.state.productExists}
                        loading={this.state.loading}
                        shopCartStatus={this.props.location.detailsProp.shopCartStatus || this.state.shopCartStatus}
                        shopChat={this.props.location.detailsProp.shopChat || this.state.shopChat}
                        shopCartBankAccount={this.props.location.detailsProp.shopCartBankAccount || this.state.shopCartBankAccount}
                        FullCart={this.props.location.detailsProp.FullCart || this.state.FullCart}
                        productImages={this.props.location.detailsProp.productImages || this.state.productImages}
                        xID={this.props.location.detailsProp.xID || this.state.product.xID}
                        shopLogo={this.props.location.detailsProp.shopLogo || this.state.shopLogo}
                    />}</div>}

                {!this.state.redirect && this.state.theme === 'Cake Factory' && !this.props.location.detailsProp && <CFactoryProductPage

                    pictureURL={this.state.product.pictureURL}
                    title={this.state.product.title}
                    text={this.state.product.text}
                    price={this.state.product.price}
                    currency={this.state.product.currency}
                    nameShop={this.state.nameShop}
                    servicesOffered={this.state.servicesOffered}
                    url={this.state.url}
                    providerName={this.state.providerName}
                    providerAddress={this.state.providerAddress}
                    phoneNumber={this.state.phoneNumber}
                    shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                    description={this.state.shopProfileDescription}
                    shopColorButton={this.state.shopColorButton}
                    shopColorHeader={this.state.shopColorHeader}
                    hasCustomDomain={this.state.hasCustomDomain}
                    shopSocials={this.state.shopSocials}
                    productExists={this.state.productExists}
                    loading={this.state.loading}
                    shopCartStatus={this.state.shopCartStatus}
                    shopChat={this.state.shopChat}
                    shopCartBankAccount={this.state.shopCartBankAccount}
                    FullCart={this.state.FullCart}
                    productImages={this.state.productImages}
                    xID={this.state.product.xID}
                    shopLogo={this.state.shopLogo}
                />}
                {/* -----------------------------  Cake Factory THEME PRODUCT PAGE -------------------------------- */}

                {/* -----------------------------  Standard White THEME PRODUCT PAGE -------------------------------- */}

                {this.props.location.detailsProp && <div>
                    {!this.state.redirect && this.props.location.detailsProp.theme === 'Standard White' && <SWhiteProductPage
                        pictureURL={this.props.location.detailsProp.pictureURL || this.state.product.pictureURL}
                        title={this.props.location.detailsProp.title || this.state.product.title}
                        text={this.props.location.detailsProp.text || this.state.product.text}
                        price={this.props.location.detailsProp.price || this.state.product.price}
                        currency={this.props.location.detailsProp.currency || this.state.product.currency}
                        nameShop={this.props.location.detailsProp.nameShop || this.state.nameShop}
                        servicesOffered={this.props.location.detailsProp.servicesOffered || this.state.servicesOffered}
                        providerName={this.props.location.detailsProp.providerName || this.state.providerName}
                        providerAddress={this.props.location.detailsProp.providerAddress || this.state.providerAddress}
                        phoneNumber={this.props.location.detailsProp.phoneNumber || this.state.phoneNumber}
                        shopNamefromURL={this.props.location.detailsProp.shopNamefromURL || this.state.shopID}
                        description={this.props.location.detailsProp.description || this.state.description}
                        shopColorButton={this.props.location.detailsProp.shopColorButton || this.state.shopColorButton}
                        hasCustomDomain={this.state.hasCustomDomain}
                        shopSocials={this.props.location.detailsProp.shopSocials || this.state.shopSocials}
                        productExists={this.state.productExists}
                        loading={this.state.loading}
                        shopColorBackground={this.state.shopColorBackground}
                        shopColorButton={this.state.shopColorButton}
                        hasCustomDomain={this.state.hasCustomDomain}
                        shopSocials={this.props.location.detailsProp.shopSocials || this.state.shopSocials}
                        productExists={this.state.productExists}
                        loading={this.state.loading}
                        shopCartStatus={this.props.location.detailsProp.shopCartStatus || this.state.shopCartStatus}
                        shopChat={this.props.location.detailsProp.shopChat || this.state.shopChat}
                        shopCartBankAccount={this.props.location.detailsProp.shopCartBankAccount || this.state.shopCartBankAccount}
                        FullCart={this.props.location.detailsProp.FullCart || this.state.FullCart}
                        productImages={this.props.location.detailsProp.productImages || this.state.productImages}
                        xID={this.props.location.detailsProp.xID || this.state.product.xID}
                        shopLogo={this.props.location.detailsProp.shopLogo || this.state.shopLogo}
                    />}</div>}

                {!this.state.redirect && this.state.theme === 'Standard White' && !this.props.location.detailsProp && <SWhiteProductPage

                    pictureURL={this.state.product.pictureURL}
                    title={this.state.product.title}
                    text={this.state.product.text}
                    price={this.state.product.price}
                    currency={this.state.product.currency}
                    nameShop={this.state.nameShop}
                    servicesOffered={this.state.servicesOffered}
                    url={this.state.url}
                    providerName={this.state.providerName}
                    providerAddress={this.state.providerAddress}
                    phoneNumber={this.state.phoneNumber}
                    shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                    description={this.state.shopProfileDescription}
                    shopColorButton={this.state.shopColorButton}
                    hasCustomDomain={this.state.hasCustomDomain}
                    shopSocials={this.state.shopSocials}
                    productExists={this.state.productExists}
                    loading={this.state.loading}
                    shopColorBackground={this.state.shopColorBackground}
                    logo={this.state.logo}
                    shopColorButton={this.state.shopColorButton}
                    hasCustomDomain={this.state.hasCustomDomain}
                    shopSocials={this.state.shopSocials}
                    productExists={this.state.productExists}
                    loading={this.state.loading}
                    shopCartStatus={this.state.shopCartStatus}
                    shopChat={this.state.shopChat}
                    shopCartBankAccount={this.state.shopCartBankAccount}
                    FullCart={this.state.FullCart}
                    productImages={this.state.productImages}
                    xID={this.state.product.xID}
                    shopLogo={ this.state.shopLogo}
                />}
                {/* -----------------------------  Standard White THEME PRODUCT PAGE -------------------------------- */}

            </div>
        );
    }
}

export default ProductPage;