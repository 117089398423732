import React from 'react'


function openAndCloseCartModal(width, showcart) {
  let cartModalWidth = width
  let showModalCart = showcart
  console.log('launching openAndCloseCartModal(), cartModalWidth and showModalCart are ', cartModalWidth, ' ', showModalCart)
  cartModalWidth = (cartModalWidth == 0) ? '100%' : (cartModalWidth == '100%' ? 0 : '100%')
  showModalCart = (showModalCart == true) ? false : (showModalCart == false ? true : false)
  return { cartModalWidth, showModalCart }
}


function updateModalCart(FullCart, shopNamefromURL, hasCustomDomain, nameShop, shopCartBankAccount) {
  console.log('launching updateModalCart... ')
  let cartModalDetails = {
    FullCart, shopNamefromURL, hasCustomDomain, nameShop, shopCartBankAccount
  }
  return cartModalDetails

}



function updateCart(FullCart){ 
  let cartStorage = {}
  let WM_DATA_Storage = {};
  localStorage.setItem("cartStorage", JSON.stringify(FullCart));
  WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
  cartStorage = JSON.parse(localStorage.getItem("cartStorage"))
  WM_DATA_Storage.FullCart = FullCart
  localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
  console.log('products added to cart are: ', FullCart, 'items stored in browser is therefore: ', WM_DATA_Storage.FullCart)
  return  FullCart 
}

 


function getAllProductImages(product) {

  let productImages = {};
  let keys = []

  console.log('getting all product images...')
  console.log(' the product is ', product.xID)
  productImages[product.xID] = []
  keys = Object.keys(product)
  keys.forEach(key => {
    if (key.indexOf('pictureURL') > -1) {
      productImages[product.xID].push(product[key])
    }
  })

  return productImages
}

export { getAllProductImages, openAndCloseCartModal, updateModalCart, updateCart }