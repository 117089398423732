import React, { Component } from "react";
import { Link } from "react-router-dom";

//import firebase from "../Firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FullBlogPost.css";
import config from '../../config/chatConfig'
import 'firebase/firestore';
const firebase = require('firebase') 



class FullBlogPost extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      user: "",
      blogPostID: "",
      author: "",
      title: " ",
      content: "",
      imageUrl: "",
      profileImage: ""
    };
  }

  componentDidMount = async () => {
    
    console.log("windows props", this.props.location.blogPostData);
    if (this.props.location.blogPostData == null || undefined) {
      await this.parseURLandReturnRelevantID();
      await this.getImageUrl();
      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Blogs")
        .where("author", "==", this.state.user)
        .onSnapshot(async collection => {
          if (collection.empty) {
            console.log("No matching documents");
            return;
          }
          const posts = [];
          collection.forEach(doc => {
            let items = doc.data();
            let fileID = doc.id;
            posts.push({
              author: items.author,
              title: items.title,
              content: items.content,
              imageUrl: items.imageUrl,
              fileId: fileID
            });
            // console.log("helllooooo...", posts);
          });
          this.setState({ posts });

          if (this.state.blogPostID) {
            let postData = this.state.posts.find(
              x => x.fileId === this.state.blogPostID
            );
            this.setState({
              author: postData.author,
              title: postData.title,
              content: postData.content,
              imageUrl: postData.imageUrl
            });
          }
        });
    } else {
      this.setState({
        author: this.props.location.blogPostData.author,
        title: this.props.location.blogPostData.title,
        content: this.props.location.blogPostData.content,
        imageUrl: this.props.location.blogPostData.imageUrl,
        profileImage: this.props.location.profileImage
      });
    }
  };

  getImageUrl = async () => {
    try {
      const r = await fetch(
        "https://us-central1-afbase-7ee60.cloudfunctions.net/shop",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user
          })
        }
      );
      const r2 = await r.json();
      console.log("imageUrl", r2);
      await this.setState({ profileImage: r2.data.profile.pictureURL });
    } catch (err) {
      console.log(err, "there seems to be an error");
    }
  };

  parseURLandReturnRelevantID = async () => {
    try {
      const fullURL = window.location.href.split("/");
      console.log("fullURL is: ", fullURL);
      await this.setState({
        blogPostID: fullURL[5],
        user: fullURL[3]
      });
      console.log("the shopnamefromURL in Home js is: ", this.state.blogPostID);
    } catch (err) {
      console.log("issues with parsing URL ", err);
    }
  };

  render() {
    let urls = window.location.href;
    console.log("url", urls);

    // console.log("this a full post prop", this.props.location.blogPostData);

    // console.log("this a full post prop id", this.props.location.id);

    // let post = <h1>Not Found</h1>;

    //   console.log(
    //     "this a full post  title prop",
    //     this.props.location.blogPostData.title
    //   );

    return (
      <div style={{ backgroundColor: "white", color: "black" }}>
        <div className="fullblogpost-container">
          <div className="meta">
            <div className="info">
              <h1 className="blog-title">{this.state.title}</h1>

              <div className="author">
                <div class="avatar">
                  <div className="avatar-img">
                    <img src={this.state.profileImage} alt="" />
                  </div>
                </div>
                <div className="authorInfo">
                  <div className="authorName">
                    <a href="/">{this.state.author}</a>
                  </div>
                  <div className="authorSub">
                    Nov 7 <span className="median-divider">·</span> 5 min read
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="image"
            style={{
              minHeight: "500px",
              width: "100%",
              backgroundImage: "url(" + `${this.state.imageUrl}` + ")",

              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          ></div>
          <main className="article">
            <p style={{ color: "black" }}>{this.state.content}</p>

            <p style={{ color: "black" }}>
              {" "}
              Happy readings,
              <br />
              <i>
                <a href="https://codepen.com/lucagez">Gez</a>
              </i>
            </p>
          </main>
        </div>
      </div>
    );
  }
}

export default FullBlogPost;
