import React, { Component } from "react";
import firebase from "../../Firebase";

import "./UpdatePost.css";

class UpdatePost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      user: "harmonyuniconcept",
      blogPostID: "",
      author: "",
      title: " ",
      content: "",
      imageUrl: "",
      EditData: {},
      selectedFile: null,
      downloadUrl: ""
    };
  }

  componentDidMount = async () => {
    firebase.auth().onIdTokenChanged(function(user) {
      if (user) {
        // User is signed in or token was refreshed.
        global.fetchIdToken();
      }
    });

    global.fetchIdToken = async () => {
      try {
        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true);
        if (idToken) {
          global.idToken = idToken;
        }
        console.log("Success fetching idToken", global.idToken);
      } catch (err) {
        console.log("ERROR fetching idToken", err);
      }
    };
    await this.parseURLandReturnRelevantID();

    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Blogs")
      .where("author", "==", this.state.user)
      .onSnapshot(async collection => {
        if (collection.empty) {
          console.log("No matching documents");
          return;
        }
        const posts = [];
        collection.forEach(doc => {
          let items = doc.data();
          let fileID = doc.id;
          posts.push({
            author: items.author,
            title: items.title,
            content: items.content,
            imageUrl: items.imageUrl,
            fileId: fileID
          });
          // console.log("helllooooo...", posts);
        });
        this.setState({ posts });

        if (this.state.blogPostID) {
          let postData = this.state.posts.find(
            x => x.fileId === this.state.blogPostID
          );
          this.setState({
            author: postData.author,
            title: postData.title,
            content: postData.content,
            imageUrl: postData.imageUrl
          });
        }
      });
  };

  parseURLandReturnRelevantID = async () => {
    try {
      const fullURL = window.location.href.split("/");
      console.log(fullURL);
      await this.setState({
        blogPostID: fullURL[4]
        // shopNamefromURL: fullURLsubStrings[3]
      });
      console.log("the shopnamefromURL in Home js is: ", this.state.blogPostID);
    } catch (err) {
      console.log("issues with parsing URL ", err);
    }
  };

  upDatePost = async () => {
    try {
      let file = this.state.selectedFile;
      if (file) {
        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg"
        };

        let storageRef = firebase.storage().ref(file.name);

        // Upload file and metadata of the storageRef
        let uploadTask = storageRef.put(file, metadata);

        const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
        this.setState({ downloadUrl });

        console.log("here is the downloadUrl", downloadUrl);
      }
      const r = await fetch(
        " https://us-central1-af-shop.cloudfunctions.net/updateBlogPost",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user,
            title: this.state.title,
            content: this.state.content,
            imageUrl: this.state.downloadUrl,
            idToken: global.idToken,
            blogID: this.state.blogPostID
          })
        }
      );

      const r2 = await r.json();
      console.log(r2);
      if (r2.msg === "SUCCESS") {
        console.log("message", r2.msg);
        this.props.history.push("/admin");
      }
    } catch {
      alert("An error occurred.");
      console.log("there seems to be an error");
    }
  };

  render() {
    console.log("User", this.state.user);
    console.log("title", this.state.title);
    console.log("content", this.state.content);
    console.log("url", this.state.downloadUrl);

    return (
      <div className="UpdatePost">
        <h1 className="UpdatePostHeader"> Add a Post</h1>
        <label>Title</label>
        <input type="file" />
        <input
          type="text"
          value={this.state.title}
          onChange={event => {
            this.setState({ title: event.target.value });
          }}
        />
        <label>Content</label>
        <textarea
          rows="32"
          value={this.state.content}
          onChange={event => {
            this.setState({ content: event.target.value });
          }}
        />
        <label>Author</label>

        <p>Sandra</p>

        <button onClick={this.upDatePost}>update Post</button>
      </div>
    );
  }
}

export default UpdatePost;
