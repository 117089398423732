import React, { Component } from 'react'

//importing stylesheets................
import "./fonts/icomoon/style.css"
import "./css/magnific-popup.css"
import "./css/style.css"
import "./css/aos.css"
import "./css/animation.css"
import "../animation.css"

//import "./css/jquery-ui.css"
//import "./css/owl.carousel.min.css"
//import "./css/owl.theme.default.min.css"

//importing components................
import NavSection from './NavSection'
import NavBar from './Navbar'
import Header from './Header'
import Categories from './Categories'
import FeaturedProducts from './FeaturedProducts';
import Chat from '../../../components/chats/Chat'
import Footer from './Footer'
import Cart from '../../../checkout/cartModal'
import { openAndCloseCartModal, updateModalCart, updateCart } from '../utilityfunctions'

import ReactPixel from 'react-facebook-pixel'
import Socials from '../../socials/socials'

const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('1719865501642535', options);
ReactPixel.pageView();


let FullCart = []
let cartStorage = {}
let WM_DATA_Storage = {};
console.log('cartStorage and WM_DATA_Storage initialized are:  ', 'cartStorage: ', JSON.stringify(cartStorage), 'WM_DATA_Storage: ', JSON.stringify(WM_DATA_Storage))

class Rave extends Component {
    state = {
        nameShop: '',
        description: '',
        tagline: '',
        shopBackgroundPicture: '',
        shopColorBackground: '',
        shopColorButton: '',
        shopColorHeader: '',
        shopColorGradient1: '',
        shopColorGradient2: '',
        shopColorTitle: '',
        shopFrontPicture: '',
        shopCartStatus: '',
        shopChat: '',
        postIDs: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: ''

        },
        logo: '',
        categories: '',
        services: '',
        servicesOffered: '',
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        hasloaded: false,
        redirect: false,
        shopNamefromURL: '',
        shopID: '',
        hasCustomDomain: false,
        url: '',
        theme: 'Rave',
        FullCart: [],
        shopCartBankAccount: '',
        shopSocials: {
            facebook: "",
            instagram: "",
            twitter: ""
        },
        cartModalWidth: 0,
        showModalCart: false,
        clickedItems: '',
        selectedItems: '',
        cartShowbankDetails: '',
        cartTotal: ''
    }




    componentWillMount() {
        console.log('firing component will mount in Rave.js')
        if (JSON.parse(localStorage.getItem("WM_DATA_Storage"))) {
            WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
            cartStorage = WM_DATA_Storage.FullCart
        } else {

            cartStorage = []

        }

        localStorage.setItem("cartStorage", JSON.stringify(cartStorage))
        this.setState({ FullCart: cartStorage })

    }


    componentDidMount = async () => {

        if (this.props.shopData) {
            document.title = this.props.shopData.profile.businessName
            console.log('Data received in Rave theme from Home JS is: ', this.props.shopData)
            let shopProfile = this.props.shopData.profile
            let shopProfileName = shopProfile.businessName
            let description = shopProfile.description
            let shopLogo = shopProfile.pictureURL
            let hasCustomDomain = this.props.hasCustomDomain
            //////////////////////////////////////////////////////////////////////////////

            let providerName = shopProfile.name;
            let providerAddress = shopProfile.locationWorkAddress
            let phoneNumber = shopProfile.phoneNumber
            let servicesOffered = shopProfile.servicesOffered
            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopPost = this.props.shopData.posts
            console.log('shopPost from APP JS is: ', shopPost)

            let shop = this.props.shopData.shop
            console.log('shops', shop)
            let tagline = shop.shopSlogan
            let shopID = shop.shopName
            let shopNamefromURL = shop.shopName
            let shopBackgroundPicture = shop.shopBackgroundPicture
            let theme = shop.shopType
            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopColorBackground = shop.shopColorBackground
            let shopColorButton = shop.shopColorButton
            let shopColorHeader = shop.shopColorHeader
            let shopColorTitle = shop.shopColorTitle
            let shopFrontPicture = shop.shopFrontPicture
            let shopColorGradient1 = shop.shopColorGradient1
            let shopColorGradient2 = shop.shopColorGradient2
            let shopHeader = shop.shopHeader
            let shopCartStatus = shop.shopCartStatus
            let shopChat = shop.shopChat
            let shopCartBankAccount = shop.shopCartBankAccount
            let shopSocials = shop.shopSocials
            ///////////////////////////////////////////////////////////////////////////////////////////

            console.log('shopName is shop ID which is: ', shopID)
            await this.setState({
                nameShop: shopProfileName,
                tagline,
                description,
                shopBackgroundPicture,
                shopColorBackground,
                shopColorButton,
                shopColorHeader,
                shopColorTitle,
                shopFrontPicture,
                shopColorGradient1,
                shopColorGradient2,
                shopCartStatus,
                shopChat,
                shopCartBankAccount,
                shopSocials,
                postIDs: shopPost,
                providerName,
                providerAddress,
                phoneNumber,
                servicesOffered,
                logo: shopLogo,
                hasloaded: true,
                url: this.props.url,
                shopNamefromURL,
                shopID,
                hasCustomDomain,
                theme,
                shopHeader,
                FullCart: this.props.FullCart,
                clickedItems: this.props.clickedItems

            })
            if (this.state.postIDs[0]) {
                console.log('first postID is: ', this.state.postIDs[0].xID)
            }

            this.topFunction()

            if (this.props.clickedItems) {

                let cart = []
                let posts = this.state.postIDs
                let keys = Object.keys(this.props.clickedItems)
                keys.forEach(key => {
                    if (this.props.clickedItems[key] === 'clicked') {
                        posts.find(post => {
                            if (post.xID === key) {
                                cart.push(post)
                            }
                        })
                    }
                })
                console.log('cart generated from clicked items is: ', cart)
                this.setState({ FullCart: cart })
            }


        }
    }


    topFunction = () => {
        window.scrollTo(0, 0)
        return
    }



    render() {
        return (
            <div className="App container-fluid pr-0 pl-0 h-100"
                style={{ backgroundColor: 'white', overflowX: 'hidden', width: '100%', top: 0, left: 0, position: 'fixed', scrollBehavior: "smooth" }}>
                <NavBar
                    showCartModal={async () => {
                        let result = openAndCloseCartModal(this.state.cartModalWidth, this.state.showModalCart)
                        this.setState({ cartModalWidth: result.cartModalWidth, showModalCart: result.showModalCart })
                        await this.setState({ cartModalDetails: updateModalCart(this.state.FullCart, this.state.shopNamefromURL, this.state.hasCustomDomain, this.state.nameShop, this.state.shopCartBankAccount) })
                        console.log('the this.state.cartModalDetails received is: ', this.state.cartModalDetails)
                    }}
                    nameShop={this.state.nameShop}
                    logo={this.state.logo}
                    shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
                    description={this.state.description}
                    url={this.state.url}
                    showSearch={false}
                    showAbout={true}
                    showContact={true}
                    showMenuItems={true}
                    shopCartStatus={this.state.shopCartStatus}
                    showCart={true}
                    FullCart={this.state.FullCart || []}
                />

                <section style={{
                    position: 'relative',
                    marginTop: '-125px',
                    height: 620,
                    width: '100%',
                    overflow: 'hidden'
                }}>

                    <div style={{ width: '100%', height: 620, overflow: 'hidden' }}>
                        <div id="rave-overlay"
                            style={{
                                height: '100%', position: 'absolute', top: 0,
                                backgroundColor: `linear-gradient(to left, ${this.props.shopData.shop.shopColorGradient1}, ${this.props.shopData.shop.shopColorGradient2})` || 'linear-gradient(to left,  rgba(12, 0, 255, 0.8), rgba(250, 16, 83, 0.8))',
                                //backgroundColor: `linear-gradient(to left,  rgba(12, 0, 255, 0.8), rgba(250, 16, 83, 0.8))`,
                                opacity: 0.5,
                                filter: 'alpha(opacity = 50)',
                            }}>
                        </div>
                        <div className="row paral backImage"
                            style={{
                                backgroundImage: `url(${this.state.shopBackgroundPicture || 'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2Fheader-image.jpg?alt=media&token=10956c6c-b578-40de-9a25-a71ddeb39b97'})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundAttachment: 'relative',
                                overflow: 'hidden',
                                height: 620
                            }}>
                            <div class="container"  >
                                <div>
                                    <div class="col-xl-6 col-lg-7 text-white mt-4 text-md-left ">
                                        <h1 className="font-weight-bold text-uppercase text-wrap rave-header-text flipInX" style={{ lineHeight: 1.2, fontFamily: "Helvetica, sans-serif" }}>{this.state.shopHeader || this.state.tagline || 'Find Great Products!'}</h1>
                                        {this.state.shopHeader && this.state.tagline && <p className="text-left mb-0" style={{ fontStyle: 'italic ' }} ><small>{this.state.tagline || 'Find great products!'}</small></p>}
                                        <a className="btn  btn-custom3 " href="#header-about" style={{ backgroundColor: this.state.shopColorButton || '#efb31f', fontFamily: "Montserrat, sans-serif" }}>Know More</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

                {this.props.shopData.posts && <Header
                    products={this.props.shopData.posts}
                    tagline={this.state.tagline}
                    servicesOffered={this.props.shopData.profile.servicesOffered}
                    shopColorBackground={this.state.shopColorBackground}
                    shopColorButton={this.state.shopColorButton}
                    shopColorHeader={this.state.shopColorHeader}
                    shopColorTitle={this.state.shopColorTitle}
                    shopFrontPicture={this.state.shopFrontPicture}
                    description={this.state.description}
                />}
                <div style={{
                    paddingLeft: 30,
                    marginLeft: -60,
                    marginBottom: 100,
                    marginTop: -200,
                    zIndex: 110,
                    height: 120,

                }} className="d-none d-sm-block">
                    <Socials shopSocials={this.state.shopSocials} /></div>

                {this.state.servicesOffered && !this.state.showModalCart && <div style={{ marginTop: -135, marginBottom: 50 }}>
                    <section id='collections'>
                        <Categories
                            servicesOffered={this.state.servicesOffered}
                            shopColorGradient1={this.props.shopData.shop.shopColorGradient1}
                            shopColorGradient2={this.props.shopData.shop.shopColorGradient2}
                        />
                    </section>
                </div>}

                {this.props.shopData.posts && !this.state.showModalCart &&
                    <div id="shop" style={{ marginTop: -150, overflow: 'hidden' }}>
                        <FeaturedProducts
                            updateCart={async (FullCart) => {
                                updateCart(FullCart)
                                await this.setState({ FullCart })
                                console.log('FullCart is...', this.state.FullCart)
                            }}
                            saveProductData={(clicked, selected) => {
                                this.setState({ clickedItems: clicked, selectedItems: selected })
                            }}

                            FullCart={this.state.FullCart}
                            selectedItems={this.state.selectedItems}
                            clickedItems={this.state.clickedItems || {}}
                            shopCartStatus={this.state.shopCartStatus}
                            shopCartBankAccount={this.state.shopCartBankAccount}
                            shopSocials={this.state.shopSocials}
                            shopChat={this.state.shopChat}
                            description={this.state.description}
                            products={this.props.shopData.posts}
                            nameShop={this.state.nameShop}
                            logo={this.state.logo}
                            shopNamefromURL={this.props.hasCustomDomain ? 'shop' : this.props.shopNamefromURL}
                            providerName={this.props.shopData.profile.name}
                            providerAddress={this.props.shopData.profile.locationWorkAddress}
                            phoneNumber={this.props.shopData.profile.phoneNumber}
                            url={this.state.url}
                            servicesOffered={this.props.shopData.profile.servicesOffered}
                            theme={'Rave'}
                            productTitleColor={this.state.shopColorTitle}
                            shopColorButton={this.state.shopColorButton}
                            shopColorBackground={this.props.shopData.shop.shopColorBackground}
                            hasCustomDomain={this.props.hasCustomDomain}
                            shopColorGradient1={this.props.shopData.shop.shopColorGradient1}
                            shopColorGradient2={this.props.shopData.shop.shopColorGradient2}
                        /></div>}

                {(this.state.shopChat.status === "LIVE") && <Chat
                    backgroundColor={this.state.shopColorButton}
                    nameShop={this.state.nameShop}
                    shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}
                />}

                {this.state.servicesOffered && !this.state.showModalCart &&

                    <Footer
                        servicesOffered={this.state.servicesOffered}
                        providerName={this.props.shopData.profile.name}
                        providerAddress={this.props.shopData.profile.locationWorkAddress}
                        phoneNumber={this.props.shopData.profile.phoneNumber}
                        textColor={this.state.shopColorTitle}
                        shopColorButton={this.state.shopColorButton}
                        shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                        nameShop={this.state.nameShop}
                    />


                }

                <div id='cart-modal' style={{
                    width: this.state.cartModalWidth,
                    height: 'inherit',
                    position: 'absolute',
                    right: 0, top: 60,
                    backgroundColor: 'white',
                    WebkitTransition: 'width 0.5s',
                    transition: 'width 0.5s',
                    paddingTop: 50,
                    paddingBottom: 100
                }}>

                    <div oonClick={() => {
                        let result = openAndCloseCartModal(this.state.cartModalWidth, this.state.showModalCart)
                        this.setState({ cartModalWidth: result.cartModalWidth, showModalCart: result.showModalCart })
                    }}>
                        <h1 style={{ color: 'black', paddingLeft: 50, paddingTop: 20 }}>X</h1> </div>

                    {this.state.showModalCart && <div style={{ height: 'inherit', }}>
                        <Cart
                            CartDetails={this.state.cartModalDetails}
                            // cartShowbankDetails={this.state.cartShowbankDetails}
                            cartShowbankDetails={this.state.cartShowbankDetails}
                            cartTotal={this.state.cartTotal}
                            nameShop={this.state.nameShop}
                            updateCart={async (FullCart) => {

                                await this.setState({ FullCart: updateCart(FullCart) })
                                console.log('FullCart is...', this.state.FullCart)
                            }}

                            clearLocalStorageCart={() => {
                                WM_DATA_Storage.FullCart = ''
                                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                localStorage.setItem("cartStorage", "");
                                this.setState({ FullCart: '' })
                            }}

                            storeCartDetails={(a, b) => {
                                this.setState({ cartShowbankDetails: a, cartTotal: b })
                            }}
                        />
                    </div>}

                </div>

            </div >
        );
    }
}

export default Rave;