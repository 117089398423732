import React, { Component } from 'react'




export default class Footer extends Component {
    state = {
        providerName: '',
        shopProfileName: '',
        descriptionShop: '',
        services: [],
        address: '',
        phoneNumber: '',
        senderName: '',
        shopName: '',
        msg: false,
        error: false,
        categories: '',
        services: '',
        loading: ''

    }


    filterCategories = () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        //  const values1 = values.slice(0, values.length)
        //   const values2 = values1.join(' ')
        //const values1 = values.slice(0, values.length)
        //const values2 = values1.join(' ')
        const newvalues = [].concat(...values)


        console.log(' the categories are: ', keys, ' and the services are: ', values)
        this.setState({ categories: keys, services: newvalues });

    }



    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })

        console.log('Username' + this.state.senderName)

        const newMessage = {
            shopName: this.props.shopNamefromURL,
            senderName: this.state.senderName,
            senderPhoneNumber: this.state.senderPhoneNumber,
            message: this.state.message
        }
        console.log('new', newMessage)
        const url = "https://us-central1-afbase-7ee60.cloudfunctions.net/shopMessage"
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newMessage)
        }).then((res) => res.json())
            .catch(error => { console.error('Error:', error); this.setState({ loading: false }) })
            .then(response => {


                console.log('Success:', response)
                if (response.msg == 'SUCCESS') {
                    this.setState({ msg: true, senderName: '', senderPhoneNumber: '', message: '', loading: false })
                } else {
                    this.setState({ error: true, loading: false })
                }
            });


    }
    componentDidMount = () => {
        if (this.props.servicesOffered) {
            console.log('services offered in shoppers footer component are: ', this.props.servicesOffered)
            this.filterCategories();
        }
    }


    render() {

        const { loading } = this.state

        return (
            <div id='footer'>
                <footer className=" border-top" style={{ backgroundColor: '#ffffff' }}>
                    <div className="row mx-auto">

                        <div className="footer-custom col-sm-12 col-md-4 col-lg-4 mx-auto " >
                            <div className="block-5">
                                <h6 className=" mb-4 mr-4 float-center font-weight-bold" style={{ color: this.props.textAndButtonColor || '#000000' }}>SEND US A MESSAGE</h6>
                                <form onSubmit={this.onSubmit}>
                                    <div className=" ">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Full Name"
                                            required
                                            name="senderName"
                                            value={this.state.senderName}
                                            onChange={this.onChange}
                                            style={{ border: 'solid 1px #fff', borderBottom: 'solid 1px #c4c2c2', borderRadius: 0 }}
                                        />
                                    </div>
                                    <div className="form-group my-1 ">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="Phone number"
                                            required
                                            name="senderPhoneNumber"
                                            value={this.state.senderPhoneNumber}
                                            onChange={this.onChange}
                                            style={{
                                                border: 'solid 1px #fff', borderBottom: 'solid 1px #c4c2c2', borderRadius: 0
                                            }}
                                        />
                                    </div>
                                    <div className="form-group border-dark" style={{}}>
                                        <textarea
                                            type="text"
                                            placeholder="Message"
                                            className="form-control"
                                            required
                                            name="message"
                                            style={{ height: '130px', borderBottom: 'solid 1px #c4c2c2', borderRadius: 0 }}
                                            value={this.state.message}
                                            onChange={this.onChange}>

                                        </textarea>

                                    </div>


                                    <div>  <button type="submit" class="" style={{ backgroundColor: this.props.textAndButtonColor || '#000000' }}>{loading && <i class="fa fa-spinner fa-spin"></i>} &nbsp;Submit</button></div>
                                    <div>
                                        <p className=" ">{this.state.msg && <div>
                                            <p className="text-success pt-2 text-center mr-4  "> Message sent, seller will contact you shortly</p></div>}
                                            {this.state.error && <div><p className="text-danger py-1">Oops!, Message not sent</p></div>}</p>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4 col-lg-4">
                            {this.props.servicesOffered && <div className="block-5 mb-5">
                                <h6 className="footer-custom my-4 ml-2 font-weight-bold " style={{ color: this.props.textAndButtonColor || '#000000' }}>CATEGORIES</h6>
                                <div style={{ height: '10px' }}></div>
                                {this.state.categories && <ul className="list-unstyled float-center mr-4  mt-2">
                                    {this.state.services.map((service, id) => {
                                        return (
                                            <li key={id} className=""><p className="text-center mt-1" style={{ color: "#555555" }} >{service}</p></li>
                                        )
                                    }
                                    )}
                                </ul>}
                            </div>}


                        </div>


                        <div class="col-sm-12 col-md-4 col-lg-4">
                            <h6 className="footer-custom mb-4 mt-4 font-weight-bold " style={{ color: this.props.textAndButtonColor || '#000000' }}>CONTACT INFO</h6>
                            <div style={{ height: '10px' }}></div>
                            <ul class="list-unstyled text-small">
                                <li><a class="text-muted" ><i className="fa fa-user"></i> &nbsp; {this.props.providerName || 'Not Available'}</a></li>
                                <li><a class="text-muted" ><i className="fa fa-map-marker" ></i> &nbsp; {this.props.providerAddress || 'Not Available'}</a></li>
                                <li><a class="text-muted" ><i className="fa fa-phone"></i> &nbsp; {this.props.phoneNumber || 'Not Available'}</a></li>

                            </ul>


                            <ul className="list-inline ">
                                {this.props.shopSocials.twitter &&
                                    <li className="list-inline-item m-2">
                                        <a href={this.props.shopSocials.facebook} target='_blank'>
                                            <i class="fab fa-facebook-f fa-lg"></i>
                                        </a>
                                    </li>}
                                {this.props.shopSocials.twitter && <li className="list-inline-item m-2">
                                    <a href={this.props.shopSocials.twitter} target='_blank'>
                                        <i class="fab fa-twitter fa-lg"></i>
                                    </a>
                                </li>}
                                {this.props.shopSocials.instagram && <li className="list-inline-item m-2">
                                    <a href={this.props.shopSocials.instagram} target='_blank'>
                                        <i class="fab fa-instagram fa-lg"></i>
                                    </a>
                                </li>}
                            </ul>
                        </div>


                        {/* <h6 className="footer-custom mb-4 mt-4">CONTACT INFO</h6>
                                <ul className="list-unstyled">

                                    <li><p className="text-left"> <i className="fa fa-user"></i> &nbsp; Name</p> </li>
                                    <li><p className="text-center">{this.props.providerName || ' Not Available'}</p></li>

                                    <li><p><i className="fa fa-map-marker"></i> &nbsp;  Address</p></li>
                                    <li><p className="text-center">{this.props.providerAddress || ' Not Available'}</p></li>
                                    <li><i className="fa fa-phone"></i> &nbsp; <p>{this.props.phoneNumber || 'Not Available'}</p></li>

                                </ul> */}


                        {/* <div className="block-7">
                                <form action="#" method="post">
                                    <label for="email_subscribe" className="footer-heading">Subscribe</label>
                                    <div className="form-group">
                                        <input type="text" className="form-control py-4" id="email_subscribe" placeholder="Email" />
                                        <input type="submit" className="btn btn-sm btn-primary" value="Send" />
                                    </div>
                                </form>
                            </div> */}


                    </div>


                </footer>

                <div>
                    {/* <footer className="footer small p-4 text-center" style={{ backgroundColor: '#ffffff', color: '#a19cf4' }}> */}
                    <footer className="footer small p-4 text-center" style={{ backgroundColor: '#ffffff', color: '#000000' }}>
                        Copyright &copy; {new Date().getFullYear()}  {this.props.nameShop ? this.props.nameShop : ''}. All rights reserved!
        </footer>
                </div>
            </div>
        )
    }
}
