import React, { Component } from 'react'
import AboutSection from './aboutSection'
import Typer from './typerText'
import astyles from "./src/app/css/app.module.css"
import bstyles from "./src/app/css/uikit-custom.module.css"
import icon from './src/img/x1.png'
import Footer from './footer'

import ReactPixel from 'react-facebook-pixel'
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('1719865501642535', options);
ReactPixel.pageView();


/* var divStyle = {
    WebkitTransition: 'all', // note the capital 'W' here
    msTransition: 'all', // 'ms' is the only lowercase vendor prefix
    background: `-webkit-linear-gradient(${this.state.shopColorGradient1 || '#0F219E'} , ${this.state.shopColorGradient2 || '#4AD6B7'})`,

    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
} */




class fredaEdu extends Component {
    state = {
        nameShop: '',
        description: '',
        tagline: '',
        shopBackgroundPicture: '',
        shopColorBackground: '',
        shopColorButton: '#0F79E5',
        shopColorHeader: '',
        shopColorTitle: '',
        shopFrontPicture: '',
        shopColorGradient1: '#0F219E',
        shopColorGradient2: '#02FF95',
        logo: '',
        categories: '',
        services: '',
        servicesOffered: '',
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        hasloaded: false,
        redirect: false,
        shopNamefromURL: '',
        shopID: '',
        hasCustomDomain: false,
        url: '',
        theme: 'Teacher',
        skills: {
            a: ['chemistry', 'christian religious studies'],
            b: ['mathematics', 'english']
        },
        categories: '',
        filteredSkills: ['mathematics', 'english', 'chemistry']
    }

    filterSkills = async () => {
        if (this.props.shopData.profile.skills) {
            const keys = Object.keys(this.props.shopData.profile.skills)
            const values = Object.values(this.props.shopData.profile.skills)
            /* const values1 = values.slice(0, values.length)
            const values2 = values1.join(' ') */
            const newvalues = [].concat(...values)


            console.log(' the categories are: ', keys, ' and skills are: ', newvalues)
            if (newvalues !== 'undefined') {
                await this.setState({ categories: keys, filteredSkills: newvalues });
            }
            return
        } else {
            return
        }

    }

    componentDidMount = async () => {
        if (this.props.shopData) {
            document.title = this.props.shopData.profile.businessName
            console.log('Data received in FredaEdu theme from Home JS is: ', this.props.shopData)
            let shopProfile = this.props.shopData.profile
            let shopProfileName = shopProfile.businessName
            let description = shopProfile.description
            let shopLogo = shopProfile.pictureURL
            let skills = this.props.shopData.profile.skills
            let hasCustomDomain = this.props.hasCustomDomain
            console.log('Skills are : ', skills)

            //////////////////////////////////////////////////////////////////////////////

            let providerName = shopProfile.name;
            let providerAddress = shopProfile.locationWorkAddress
            let phoneNumber = shopProfile.phoneNumber
            let servicesOffered = shopProfile.servicesOffered
            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopPost = this.props.shopData.posts
            console.log('shopPost from Home JS is: ', shopPost)

            let shop = this.props.shopData.shop
            console.log('shops', shop)
            let tagline = shop.shopSlogan
            let shopID = shop.shopName
            let shopNamefromURL = shop.shopName
            let shopBackgroundPicture = shop.shopBackgroundPicture
            let theme = shop.shopType
            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopColorBackground = shop.shopColorBackground
            let shopColorButton = shop.shopColorButton
            let shopColorHeader = shop.shopColorHeader
            let shopColorTitle = shop.shopColorTitle
            let shopFrontPicture = shop.shopFrontPicture
            let shopColorGradient1 = shop.shopColorGradient1
            let shopColorGradient2 = shop.shopColorGradient2
            let shopHeader = shop.shopHeader
            ///////////////////////////////////////////////////////////////////////////////////////////

            console.log('shopName is shop ID which is: ', shopID)
            await this.setState({
                nameShop: shopProfileName,
                tagline,
                description,
                shopBackgroundPicture,
                shopColorBackground,
                shopColorButton,
                shopColorHeader,
                shopColorTitle,
                shopFrontPicture,
                shopColorGradient1,
                shopColorGradient2,
                postIDs: shopPost,
                providerName,
                providerAddress,
                phoneNumber,
                servicesOffered,
                logo: shopLogo,
                hasloaded: true,
                url: this.props.url,
                shopNamefromURL,
                shopID,
                hasCustomDomain,
                theme,
                shopHeader,
                skills,
            })
            if (this.state.postIDs[0]) {
                console.log('first postID is: ', this.state.postIDs[0].xID)
            }
            if (this.props.shopData.profile.skills) {
                await this.filterSkills();
            }
            this.topFunction()
        }
    }


    topFunction = () => {
        window.scrollTo(0, 0)
        return
    }


    render() {

        return (
            <div>
                <header className={`${astyles['yb-main-header']}`} id='main-header' >

                    {/* -- home section --> */}
                    <div className={`${astyles['yb-full-height']}`} id="home">



                        <section className={`${astyles['yb-profile-desc']}`}>
                            <div className={`${astyles['yb-section-point-wrapper']}`} data-uk-parallax="opacity: 1, 0;">
                                <div className={`${bstyles['uk-container']}`}>
                                    <div className={`${astyles['yb-section-point']}`}>
                                        <sup>01</sup>
                                        <span>welcome</span>
                                    </div>
                                </div>
                            </div>
                            <div className={`${astyles['yb-graphics']}`}>
                                <div className={`${astyles['yb-graphic-1']}`} data-uk-parallax="blur: 0,30;" style={{ backgroundColor: this.state.shopColorGradient1 }}></div>
                                <div className={`${astyles['yb-graphic-2']}`} data-uk-parallax="blur: 0,30;" style={{ backgroundColor: this.state.shopColorGradient2 }}></div>
                            </div>
                            <div className={`${bstyles['uk-container']} ${bstyles['uk-text-center']} ${astyles['yb-profile-desc-wrap']} ${bstyles['uk-position-relative']}`}>
                                <img src={icon} alt="" style={{ width: 60 }} />
                                <h1 className={`${bstyles['uk-text-bold']} ${astyles['yb-gradient-text']}`}
                                    style={{
                                        WebkitTransition: 'all', // note the capital 'W' here
                                        msTransition: 'all', // 'ms' is the only lowercase vendor prefix
                                        //`-webkit-linear-gradient(${this.state.shopColorGradient1 || '#0F219E'} , ${this.state.shopColorGradient2 || '#4AD6B7'})`,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent'
                                    }}


                                ><span>{this.props.shopData.profile.name}</span></h1>
                                <div className={`${bstyles['uk-text-bold']} ${astyles['yb-myjob']}`}>
                                    I teach <span id="typed" className="typed">
                                        {this.state.filteredSkills && <Typer
                                            heading={'Things I want to type:'}
                                            dataText={this.state.filteredSkills || ['mathematics', 'english', 'chemistry']}
                                        />}
                                    </span>
                                </div>
                            </div>
                        </section>

                    </div>
                    {/* !-- end home section --> */}
                    {/* -- main menu --> */}

                    <nav className={`${astyles['yb-main-menu']}`} id="main-menu">
                        <div className={`${bstyles['uk-container']}`} data-uk-navbar>
                            <div className={`${bstyles['uk-navbar-right']} ${astyles['uk-navbar-right']} ${bstyles['uk-hidden\@m']}`}>
                                <a className={`${bstyles['uk-navbar-toggle']}`} href="#" id="btn-menu-toggle">
                                    <span data-uk-navbar-toggle-icon className={`${astyles['yb-icon-menu']}`}></span>
                                    <span data-uk-icon="close" className={`${astyles['yb-icon-close']}`}></span>
                                </a>
                            </div>
                            <div className={`${astyles['yb-menu-toggle-wrapper']}`} id="menucollapse">
                                <div className={`${bstyles['uk-navbar-center']} ${astyles['uk-navbar-center']}`}>
                                    <ul className={`${bstyles['uk-navbar-nav']}`} data-uk-scrollspy-nav="closest: li; scroll: false; offset: 79 ">
                                        <li>
                                            <a className={`${astyles['yb-inner-link']}`} href="#home"> Home </a>
                                        </li>
                                        <li>
                                            <a className={`${astyles['yb-inner-link']}`} href="#about"> About</a>
                                        </li>
                                        <li>
                                            <a className={`${astyles['yb-inner-link']}`} href="#blog"> Blog </a>
                                        </li>
                                        <li>
                                            <a className={`${astyles['yb-inner-link']}`} href="#contact"> Contact </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`${bstyles['uk-navbar-right']}`}>
                                    <ul className={`${bstyles['uk-navbar-nav']} ${astyles['uk-navbar-nav']} ${astyles['yb-social-icons']}`}>
                                        <li>
                                            <a href="#">
                                                <span className={`${bstyles['uk-icon']} ${astyles['uk-icon']}`} data-uk-icon="icon: facebook"></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span className={`${bstyles['uk-icon']} ${astyles['uk-icon']}`} data-uk-icon="icon: instagram"></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <span className={`${bstyles['uk-icon']} ${astyles['uk-icon']}`} data-uk-icon="icon: twitter"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    {/* !-- end main menu --> */}
                </header>
                {/* !-- end main header --> */}
                {this.props.shopData.profile.name && <AboutSection
                    shopBackgroundPicture={this.state.shopBackgroundPicture}
                    description={this.state.description}
                    shopHeader={this.state.shopHeader}
                    tagline={this.state.tagline}
                    providerName={this.props.shopData.profile.name}
                    providerAddress={this.props.shopData.profile.locationWorkAddress}
                    phoneNumber={this.props.shopData.profile.phoneNumber}
                    filteredSkills={this.state.filteredSkills}
                    logo={this.state.logo}
                    shopColorButton={this.state.shopColorButton}
                />}
                <Footer />

            </div>
        )
    }
}

export default fredaEdu
