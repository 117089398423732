import React, { Component } from 'react'
import styles from "./stylesheets/modaz.module.css"
import sliderImage from './images/slider/slider-bg-3.jpg'





const RightArrow = (props) => {
    return (
        <div onClick={props.goToNextSlide} className={styles.nextArrowRight}>

        </div>
    );
}

const LeftArrow = (props) => {
    return (
        <div onClick={props.goToPrevSlide} className={styles.backArrowLeft}>
            <i className="fas fa-arrow-left fa-2x" aria-hidden="true"></i>
        </div>
    );
}


const Slide = ({ image }) => {
    const stylez = {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 60%'
    }
    return <div className={styles.slide} style={stylez}>
        <img src={image} />
    </div>
}


export default class Slider extends Component {

    state = {
        images: ['https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image.jpg?alt=media&token=d394849b-c350-4b71-bd3e-9ff05c2b9228',
            'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image2.jpg?alt=media&token=4574e949-cd7d-42b9-bdc0-d0624ff0cd8c',
            'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image13.jpg?alt=media&token=1d390713-4676-43e8-ab09-0ec92c504752'],

        currentIndex: 0,
        translateX: 0
    }



    goToPrevSlide = () => {
        this.setState((prevState) => ({
            currentIndex: prevState.currentIndex - 1
        }))
    }

    goToNextSlide = () => {

        if (this.state.currentIndex === this.state.images.length - 1) {
            return this.setState({
                currentIndex: 0,
                translateValue: 0
            })
        }


        this.setState((prevState) => ({
            currentIndex: prevState.currentIndex + 1,
            translateValue: prevState.translateValue + -(this.slideWidth())
        }))
    }

    slideWidth = () => {
        return window.innerWidth
    }

    componentDidMount() {
    }


    render() {
        return (
            <div className={styles.slider}>


                <div className={styles.sliderWrapper}
                    style={{
                        transform: `translateX(${this.state.translateValue}px)`,
                        transition: 'transform ease-out 0.45s'
                    }}>


                    {
                        this.state.images.map((image, i) =>
                            <Slide key={i} image={image} />
                        )}
                </div>
                <LeftArrow goToPrevSlide={this.goToPrevSlide} />
                <RightArrow goToNextSlide={this.goToNextSlide} />

            </div>
        );
    }
}







































/* componentDidMount() {

}

render()
 {
    return (
        <div>
            <div className={`${styles.revSliderWrapper} ${styles.fullwidthbannerContainer}`}>
                <div id={styles.revSlider2} className={`${styles.revSlider} ${styles.fullwidthabanner}`}>
                    <ul>
                        <li data-transition="random">

                            <img src={sliderImage} alt="" data-bgposition="center center" data-no-retina />


                            <div>
                                In store & Online</div>

                            <div className={`${styles.tpCaption} ${styles.tpResizeme}${styles.text333} ${styles.fontWeight500} ${styles.letterSpacing3} display-4`}>
                                BLACK FRIDAY
                                </div>

                            <div className={`${styles.tpCaption} ${styles.tpResizeme}${styles.text6e6} ${styles.fontWeight300} `}>
                                30% SALE  OFF EVERYTHING
                   </div>

                            <div className={styles.tpCaption}>
                                <a href="#" class="themesflat-button has-padding-36 bg-accent has-shadow"><span>SHOP NOW</span></a>
                            </div>
                        </li>


                        <li data-transition="random">

                            <img src="images/slider/slider-bg-5.jpg" alt="" data-bgposition="center center" data-no-retina />


                            <div className={`${styles.tpCaption} ${styles.tpResizeme}${styles.text333} ${styles.fontWeight500} text-white`}>
                                NEW TREND 2018
                    </div>

                            <div className={`${styles.tpCaption} ${styles.tpResizeme} ${styles.fontWeight500} text-white`}>
                                New Collection
                    </div>

                            <div className={`${styles.tpCaption} ${styles.tpResizeme} ${styles.fontWeight400}`}>
                                BIG SALE OF THIS WEEK UP TO 30%
                    </div>

                            <div className={styles.tpCaption}>
                                <a href="#" class="themesflat-button has-padding-36 bg-accent has-shadow"><span>SHOP NOW</span></a>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

    )
}
} */

