import React, { Component } from "react";
//import '../styles/main.css';

export default class Profile extends Component {
  state = {
    providerName: "",
    shopProfileName: "",
    descriptionShop: "",
    services: [],
    address: "",
    phoneNumber: "",
    senderName: "",
    shopName: "",
    msg: false,
    error: false,
    ButtonStyles: {
      backgroundColor: "black",
      color: this.props.shopColorHeader,
      borderColor: this.props.shopColorHeader
    }
  };

  colorChange = () => {
    this.setState({
      ButtonStyles: {
        backgroundColor: this.props.shopColorHeader,
        color: "white",
        borderColor: this.props.shopColorHeader
      }
    });
  };
  colorRestored = () => {
    this.setState({
      ButtonStyles: {
        backgroundColor: "black",
        color: this.props.shopColorHeader,
        borderColor: this.props.shopColorHeader
      }
    });
  };

  componentDidMount() {
    if (this.props.shopColorHeader) {
      this.setState({
        ButtonStyles: {
          backgroundColor: "black",
          color: this.props.shopColorHeader,
          borderColor: this.props.shopColorHeader
        }
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    console.log("Username" + this.state.senderName);

    const newMessage = {
      shopName: this.props.shopNamefromURL,
      senderName: this.state.senderName,
      senderPhoneNumber: this.state.senderPhoneNumber,
      message: this.state.message
    };
    console.log("new", newMessage);
    const url =
      "https://us-central1-afbase-7ee60.cloudfunctions.net/shopMessage";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newMessage)
    })
      .then(res => res.json())
      .catch(error => console.error("Error:", error))
      .then(response => {
        console.log("Success:", response);
        if (response.msg == "SUCCESS") {
          this.setState({
            msg: true,
            senderName: "",
            senderPhoneNumber: "",
            message: ""
          });
        } else {
          this.setState({ error: true });
        }
      });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#000",
          paddingTop: "80px",
          paddingBottom: "40px"
        }}

       
      >
        <div className="container"  id='contact'>
          <div className="row">
            <div className="col-lg-4 col-md-5 mb-sm--30">
              <div className="heading mb--32">
                <h3 className="text-white font-weight-bold">Get In Touch</h3>
              </div> 
              <div className="contact-info mb-20 mt-4">
                <p style={{ color: "#ffffff" }}>
                  <i
                    className="fa fa-user"
                    style={{ color: this.props.shopColorHeader || "#f970b9" }}
                  ></i>{" "}
                  &nbsp;{this.props.providerName || " Business Name"}
                </p>
                <p style={{ color: "#ffffff" }}>
                  <i
                    className="fa fa-map-marker"
                    style={{ color: this.props.shopColorHeader || "#f970b9" }}
                  ></i>{" "}
                  &nbsp;
                  {this.props.providerAddress ||
                    "Shop, Olokuta street, Abeokuta, Nigeria"}
                </p>
                <p style={{ color: "#ffffff" }}>
                  <i
                    className="fa fa-phone"
                    style={{ color: this.props.shopColorHeader || "#f970b9" }}
                  ></i>
                  &nbsp; {this.props.phoneNumber || "+2348055578345"}
                </p>
                {/* <p style={{ color: '#ffffff' }}><i class="fas fa-clock" style={{ color: '#f970b9' }}></i> &nbsp;{this.props.phoneNumber || 'Mon - Fri : 9:00 -18:00'}</p> */}
              </div>

              <ul className="list-inline ">
                {this.props.shopSocials.facebook && (
                  <li className="list-inline-item m-2">
                    <a href={this.props.shopSocials.facebook} target="_blank">
                      <i class="fab fa-facebook-f fa-lg text-white"></i>
                    </a>
                  </li>
                )}
                {this.props.shopSocials.twitter && (
                  <li className="list-inline-item m-2">
                    <a href={this.props.shopSocials.twitter} target="_blank">
                      <i class="fab fa-twitter fa-lg text-white"></i>
                    </a>
                  </li>
                )}
                {this.props.shopSocials.instagram && (
                  <li className="list-inline-item m-2">
                    <a href={this.props.shopSocials.instagram} target="_blank">
                      <i class="fab fa-instagram fa-lg text-white"></i>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="col-md-7 offset-lg-1">
              <div className="heading mb--40">
                <h3 className="text-white font-weight-bold">Contact Us</h3>
              </div>
              <form className="mt-4" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label for="customerName"></label>
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Please type in your name"
                    required
                    style={{
                      backgroundColor: "#fff",
                      color: "black",
                      borderRadius: 0
                    }}
                    name="senderName"
                    value={this.state.senderName}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label for="phoneNumber"></label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Pls type in your phone number e.g +2348056666666"
                    required
                    style={{
                      backgroundColor: "#fff",
                      color: "black",
                      borderRadius: 0
                    }}
                    name="senderPhoneNumber"
                    value={this.state.senderPhoneNumber}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label for="request"></label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Write your message..."
                    style={{
                      height: "130px",
                      backgroundColor: "#fff",
                      color: "black",
                      borderRadius: 0
                    }}
                    required
                    name="message"
                    value={this.state.message}
                    onChange={this.onChange}
                  ></textarea>
                </div>
                <div className="row">
                  <button
                    type="submit"
                    className="btn btn-custom-cf"
                    style={this.state.ButtonStyles}
                    onMouseOver={() => {
                      this.colorChange();
                    }}
                    onMouseLeave={() => {
                      this.colorRestored();
                    }}
                  >
                    SEND REQUEST
                  </button>
                  <span className="mt-5 ml-5 text-right ">
                    {this.state.msg && (
                      <div>
                        <p className="text-success py-1 px-4 float-right ">
                          {" "}
                          Message sent, seller will contact you shortly
                        </p>
                      </div>
                    )}
                    {this.state.error && (
                      <div>
                        <p className="text-danger px-4 py-1 float-right">
                          Oops!, Message not sent
                        </p>
                      </div>
                    )}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <div className="google-map-area">
                            <div id="google-map"></div>
                        </div> */}
      </div>
    );
  }
}
