import React, { Component } from 'react'
import pic1 from '../../theme-samples/sample-images/cake-images/france-confectionery-raspberry-cake-fruit-69817.jpeg'
import pic2 from '../../theme-samples/sample-images/cake-images/pexels-photo-776945.jpeg'
import pic3 from '../../theme-samples/sample-images/cake-images/cake-3669245_960_720.jpg'

import './cFactory.css'



export class header extends Component {

    state = {
        nameShop: '',
        descriptionShop: '',
        tagline: '',
        shopBackgroundPicture: '',
        shopCartStatus: '',
        shopChat: '',
        shopCartBankAccount: '',
        shopSocials: {
            facebook: "",
            instagram: "",
            twitter: ""
        },
        postIDs: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: ''

        },
        logo: '',
        categories: '',
        servicesOffered: '',
        hasloaded: false,
        redirect: false,
        shopNamefromURL: '',
        shopID: '',
        hasCustomDomain: false,
        url: '',
        theme: '',
        HomeData: '',
        FullCart: [],
        cartModalWidth: 0,
        showModalCart: false,
        clickedItems: '',
        selectedItems: '',
        cartShowbankDetails: '',
        cartTotal: ''
    }


    render() {
        return (
            <div className="container">
                <div className='row' style={{ overflow: 'hidden', marginBottom: 60, height: 'auto' }}>

                    <div className='col-md-5 col-sm-12 header-text' style={{
                        background: `#FFF 0% 0% repeat`,
                        opacity: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div id='about' style={{
                            paddingRight: '25%',

                        }} >
                            <h2 style={{ fontWeight: 'bold', textAlign: 'left', color: '#000' }} className="header">{this.props.shopHeader || this.props.nameShop}</h2>
                            <span style={{ color: 'blue' }}>
                                <p style={{ textAlign: 'left', color: 'black', fontSize:10 }}>{this.props.shopSlogan}</p>
                            </span>
                            <p><a href='#shop'><button type="button" class="btn" style={{ backgroundColor: this.props.shopColorHeader == '#ffffff' ? '#f970b9': this.props.shopColorHeader || '#f970b9', color: '#ffffff',borderRadius: 0 , boxShadow: `0 8px 6px -6px ${this.props.shopColorHeader == '#ffffff' ? '#f970b9': this.props.shopColorHeader || '#f970b9'}`}}>Order Now</button></a></p>
                        </div>

                    </div>
                    <div className='col-md-7 col-sm-12 placeholder-img' style={{
                        backgroundImage: `url(${this.props.shopBackgroundPicture})`,
                        height: 600,
                        width: 872,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        //backgroundColor: `red`, 
                        backgroundAttachment: 'relative',

                        //opacity: 1
                    }}></div>
                </div>
                <div className='row' style={{ overflow: 'hidden' }}>

                    <div className='col-md-5 placeholder-img ' style={{
                        backgroundImage: `url(${this.props.shopBackgroundPicture2})`,
                        height: 400,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        //backgroundColor: `red`, 
                        backgroundAttachment: 'relative',

                        //opacity: 1
                    }}></div>
                    <div className='col-md-7' style={{
                        height: 400,
                        background: `#FFF 0% 0% repeat`,
                        opacity: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div id='about' style={{


                            paddingLeft: '15%',
                            paddingRight: '15%'

                        }}>
                            <h2 style={{ fontWeight: 'bold', textAlign: 'left', color: '#000' }} >{this.props.shopHeader2 || this.props.nameShop}</h2>
                            <span style={{ color: 'blue' }}>
                                <small style={{ textAlign: 'left', color: 'black' }}>{this.props.shopSlogan2}</small>
                            </span>
                            <p><a href="#shop"><button type="button" class="btn" style={{ backgroundColor: this.props.shopColorHeader || '#f970b9', color: '#ffffff', borderRadius: 0, boxShadow: `0 8px 6px -6px ${this.props.shopColorHeader||"#f970b9"}` }}>Order Now</button></a></p>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

export default header
