import React, { Component } from 'react'
import { Link } from "react-router-dom"
import wkLogo from "./images/logos/wk_edu_logo1.jpg"
//import Home from "../../Home"



class WakandaNav extends Component {
    state = {
        shopNamefromURL: '',
        homeURL: '/',

        //search: true
    }


    componentWillMount = () => {



        setTimeout(() => {

            this.setState({
                shopNamefromURL: this.props.shopNamefromURL,
                homeURL: '/' + this.props.shopNamefromURL,

            })

            console.log('the referrer from the Navbar is: ', document.referrer || window.location.href)
        }, 3000);


    }

    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-education.com' || window.location.href === 'https://wakanda-education.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }




    render() {
        //const shopLogo = this.props.logo
        console.log('checking', this.props.logo)

        return (
            <nav className="navbar navbar-expand-md navbar-dark mb-4 bg-custom sticky-top" style={{ backgroundColor: '#0128bc' }}>
                <div className="container">
                    <Link to={this.chooseHomeURL()} >
                        <div className="container-fluid text-md-center text-center"
                            style={{
                                width: '62px', height: '62px', borderRadius: '50%', backgroundColor: '#000',
                                justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${wkLogo})`,
                                backgroundRepeat: 'no-repeat', backgroundSize: '110%', borderStyle: 'solid',
                                borderColor: 'white',
                            }}>

                        </div>
                    </Link>
                    <span className='d-none' style={{ color: '#fff', fontSize: '30px', fontWeight: "lighter", marginBottom: '5px' }}> &nbsp;|&nbsp; </span>
                    <Link to={this.chooseHomeURL()} style={{ fontWeight: 'medium', fontFamily: "Muli, Helvetica, Arial, sans-serif", paddingLeft: 8, color: 'rgba(255,255,255,0.9)' }} className="navbar-brand">{this.props.nameShop || ' WAKANDA EDUCATION'}</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="mobile-nav">
                        {this.props.showSearch &&
                            <form className="form-inline flex-nowrap  mx-0 mx-lg-auto">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-search"></i></span>
                                    </div>
                                    <input className="form-control mr-sm-2 search-input " type="search" placeholder="Search" name="search" id="search" />
                                </div>

                            </form>}

                        <ul className="navbar-nav ml-auto">
                            {this.props.showAbout && <li className="nav-item">
                                <a href="#about" className="nav-link">About</a>
                            </li>}
                            {this.props.showContact && <li className="nav-item">
                                <a href="#contact" className="nav-link">Contact</a>
                            </li>}

                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default WakandaNav