import React, { Component } from 'react';
import { Redirect } from "react-router-dom"
import Gallery from './Gallery'
import Navbar from './Navbar'
import Landing from './Landing'
import Profile from './Profile'
import Cart from '../../../checkout/cartModal'
import Chat from '../../chats/Chat'
import Footer from "./Footer"
import Background from '../sample-images/a2.jpg'
import pic1 from '../sample-images/electric-1080584_576.jpg'
//import './App.css'; 


let FullCart = []

class EngineerSample extends Component {

  state = {
    nameShop: '',
    descriptionShop: '',
    tagline: '',
    shopBackgroundPicture: '',
    shopCartStatus: '',
    shopChat: '',
    shopCartBankAccount: '',
    shopSocials: {
      facebook: "",
      instagram: "",
      twitter: ""
    },
    postIDs: [],
    modalData: {
      price: 0,
      title: '',
      text: '',
      pictureURL: ''

    },
    logo: '',
    categories: '',
    servicesOffered: ["Fashion", "Food", "Professionals", "Handyman", "Health"],
    hasloaded: false,
    redirect: false,
    shopNamefromURL: '',
    shopID: '',
    hasCustomDomain: false,
    url: '',
    theme: '',
    HomeData: '',
    FullCart: [],
    cartModalWidth: 0,
    showModalCart: false,
    clickedItems: '',
    selectedItems: '',
    cartShowbankDetails: '',
    cartTotal: ''
  }




  parseURLandReturnRelevantID = async () => {
    try {
      const fullURL = window.location.href.split('#')[0]
      const fullURLsubStrings = fullURL.split('/');
      console.log(fullURLsubStrings);
      fullURLsubStrings.forEach(itemURL => {
        if (fullURLsubStrings.indexOf(`${itemURL}`) === 3) {
          console.log('item url retreived in App.js is:', itemURL, ' and url is ', fullURLsubStrings[2])
          this.setState({ shopNamefromURL: itemURL, url: fullURLsubStrings[2] })
        }
      })
      return
    } catch (err) {
      console.log('error parsing url...')
    }

  }

  filterCategories = () => {

    const keys = Object.keys(this.state.servicesOffered)
    const values = Object.values(this.state.servicesOffered)
    const newvalues = [].concat(...values)

    console.log(' the categories are: ', keys, ' the values are :', newvalues)
    newvalues.forEach(newvalue => {
      console.log('newvalue is: ', newvalue)
      //this.iconSelector(newvalue)

    })
    return (newvalues);

  }


  openAndCloseCartModal = () => {
    this.setState({
      cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
      showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
    })
  }

  updateModalCart = () => {
    this.setState({
      cartModalDetails: {
        FullCart: this.state.FullCart,
        shopNamefromURL: this.props.shopNamefromURL,
        hasCustomDomain: this.props.hasCustomDomain,
        nameShop: this.state.nameShop,
        shopCartBankAccount: this.state.shopCartBankAccount
      }
    })
  }


  componentDidMount = async () => {


    const categories = {
      Electronics: "../../images/categories/Electronics.png",
      Home: "../../images/categories/Home.png",
      Fashion: "../../images/categories/Fashion.png",
      Cars: "../../images/categories/Cars.png",
      Food: "../../images/categories/Food.png",
      Beauty: "../../images/categories/Beauty.png",
      Professionals: "../../images/categories/Professionals.png",
      Repair_Refill: "../../images/categories/Repair_Refill.png",
      Handyman: "../../images/categories/Handyman.png",
      QuickJobs: "../../images/categories/QuickJobs.png",
      Health: "../../images/categories/Health.png",
      Art: "../../images/categories/Art.png",
      Housing: "../../images/categories/Housing.png",
      Travel: "../../images/categories/Travel.png",
      Commercial: "../../images/categories/Commercial.png"
    }
    await this.setState({ categories });



    if (this.props.shopData) {
      console.log('Data received in APP JS is: ', this.props.shopData)
      document.title = "WM Engineer Website"
      let shopProfile = this.props.shopData.profile
      let shopProfileName = shopProfile.businessName
      let shopProfileDescription = shopProfile.description
      // let shopLogo = shopProfile.pictureURL
      let hasCustomDomain = this.props.hasCustomDomain
      //////////////////////////////////////////////////////////////////////////////

      let providerName = shopProfile.name;
      let providerAddress = shopProfile.locationWorkAddress
      let phoneNumber = shopProfile.phoneNumber
      let servicesOffered = shopProfile.servicesOffered
      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopPost = this.props.shopData.posts


      let shop = this.props.shopData.shop
      console.log('shops', shop)
      let shopLogo = shop.shopLogoPicture
      let tagline = shop.shopSlogan
      let shopID = shop.shopName
      let shopNamefromURL = shop.shopName
      let shopBackgroundPicture = shop.shopBackgroundPicture
      let theme = shop.shopType

      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopColorBackground = shop.shopColorBackground
      let shopColorButton = shop.shopColorButton
      let shopColorHeader = shop.shopColorHeader
      let shopColorTitle = shop.shopColorTitle
      let shopFrontPicture = shop.shopFrontPicture
      let shopHeader = shop.shopHeader
      let shopCartStatus = shop.shopCartStatus
      let shopChat = shop.shopChat
      let shopCartBankAccount = shop.shopCartBankAccount
      let shopSocials = shop.shopSocials
      ////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////
      console.log(' shopNamefromURL from APP JS is: ', shopNamefromURL)

      console.log('shopName is shop ID which is: ', shopID)
      await this.setState({
        nameShop: shopProfileName,
        tagline,
        descriptionShop: shopProfileDescription,
        shopBackgroundPicture,
        shopBackgroundPicture,
        shopColorBackground,
        shopColorButton,
        shopColorHeader,
        shopColorTitle,
        shopFrontPicture,
        shopCartStatus,
        shopChat,
        shopCartBankAccount,
        shopSocials,
        postIDs: shopPost,
        providerName,
        providerAddress,
        phoneNumber,
        servicesOffered,
        logo: shopLogo,
        hasloaded: true,
        url: this.props.url,
        shopNamefromURL,
        shopID,
        hasCustomDomain,
        theme,
        shopHeader
      })
      //  console.log('first postID is: ', this.state.postIDs[0].xID)
      console.log('hascustomDomain in state is: ', this.state.hasCustomDomain)

    }

    // const servicesOffered = this.filterCategories()
    //console.log('Offered services ', servicesOffered)


  }



  topFunction = () => {
    window.scrollTo(0, 0)
    return
  }


  render() {
    return (

      <div id="page-container" className="App container-fluid pr-0 pl-0 h-100"
        style={{ backgroundColor: '#F3F3F3', color: '#f1f1f1', overflowX: 'hidden', width: '100%', top: 0, left: 0, position: 'fixed', scrollBehavior: "smooth" }} >

        <Navbar
          showCartModal={() => {
            this.openAndCloseCartModal()
            this.updateModalCart()
          }}
          showAbout={true}
          showContact={true}
          nameShop={this.state.nameShop}
          logo={this.state.logo}
          shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
          url={this.state.url}
          showSearch={false}
          shopColorBackground={this.state.shopColorBackground}
          FullCart={this.state.FullCart}
          hasCustomDomain={this.props.hasCustomDomain}
          shopCartStatus={this.state.shopCartStatus}
          shopCartBankAccount={this.state.shopCartBankAccount}
        />




        <div className="row paral backImage img-fluid"
          style={{
            backgroundImage: `url(${this.state.shopBackgroundPicture || Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: "cover",
            backgroundPosition: "5% 5%",
            backgroundAttachment: 'relative',
            overflow: 'hidden',
            height: "auto",
            maxWidth: '104%',
            height: 700, overflowX: 'hidden',
            marginTop: -100,
            overflow: 'hidden',
            position: 'relative',

          }}>
          <div
            style={{
              height: '100%', position: 'absolute', top: 0,
              backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.6), rgba(255,255,255,0))`,
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100%",
              //backgroundBlendMode:'saturation',
               //mixBlendMode: "saturation",
            }}>

          </div>
          <div className="container" style={{ position: 'relative' }}>

            <div className="col-xl-6 col-lg-7 text-white mt-4 col-xs-12 col-sm-6 col-sm-offset-2 col-lg-6 col-lg-offset-2 text-sm-left text-center" style={{ position: 'absolute', bottom: 0, marginBottom: 120 }}>

              <h1 className="" style={{ lineHeight: 1.0, fontFamily: "Lato, sans-serif", fontWeight: 700, color: 'blue', marginBottom: 50, fontSize: 60, textShadow: "1px 1px 2px white",  }}>{this.state.shopHeader || this.state.nameShop || 'Engineering Service'}</h1>
              <div style={{display:'flex', justifyContent:'center', marginLeft:-5 }} className='col-md-6 col-sm-12'>
                <p className=" text-left text-sm-nowrap mb-0 text-sm-left text-center " 
                style={{  fontFamily: 'Lato', color: 'blue', fontWeight: 'bold', lineHeight: 1.1,  

                }} >
                  <small style={{ fontWeight: 'bold', textShadow: "1px 1px 1.5px white", fontSize:13 }}>
                    {this.state.tagline || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}
                  </small>
                </p>
              </div>

              <a className=" btn btn-custom " href="#cart" style={{ backgroundColor: this.state.shopColorButton || "blue", marginTop: 40 }}>Learn More</a>


            </div>

          </div>
        </div>
        {/*------------------------------ SERVICES ICONS -----------------------------------------*/}
        <div className='row container-fluid' style={{ display:'flex', marginTop: 35, marginBottom: 20, justifyContent: 'center', alignItems: 'center', backgroundColor:'#F3F3F3', marginLeft:10 }}>
          {this.state.servicesOffered.map((category, id) => {
            return (
              <div className="mx-1 row" key={id} style={{ borderColor: 'white', }}>
                <div className="m-3 col" style={{ width: '7rem', justifyContent: 'center', alignItems: 'center' }}>
                  {/* {(this.props.servicesOffered[category].length > 0) && this.isActiveCategory(category)&& <img className="card-img-top img-fluid" src={require('../../images/categories/' + `${category}` + '.png')} />} */}
                  {<img className="card-img-top img-fluid" src={require('../../../images/categories/' + `${category}` + '.png' || '../../images/categories/Commercial.png')} />}
                  <p style={{color:'black', textAlign:'center'}}>{category}</p>
                </div>

              </div>
            )
          })}
        </div>


        {/*------------------------------ ABOUT AND SERVICES -----------------------------------------*/}
        <div className='container-fluid'>
          <div className='row' style={{ overflow: 'hidden', height: 'auto', backgroundColor: '#F3F3F3' }}>
            <div className='col-md-6' style={{
              backgroundImage: `url('${pic1}')`,
              height: 400,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              //backgroundColor: `red`, 
              backgroundAttachment: 'relative',

              //opacity: 1
            }}></div>
            <div className='col-md-6' id='services' style={{
              height: 400,
              background: `#0F00DC 0% 0% repeat`,
              opacity: 1,
              position: 'relative'
            }}>
              <div style={{
                position: 'absolute',
                bottom: 0,
                marginBottom: 150,
                justifyContent: 'center',
                overflow: 'hidden',
                paddingLeft: '25%',
                width: '75%'
              }}>
                <h2 style={{ fontWeight: 'bold', textAlign: 'left' }}>{'Our Services'}</h2>
                <span style={{ color: "#F3F3F3", }}>
                  <small style={{ textAlign: 'left' }}>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}</small>
                </span>
              </div>

            </div>
          </div>
          <div className='row' style={{
            overflow: 'hidden', marginBottom: 10, height: 'auto',
            // border: '1px solid black', borderBottomWidth: 1, borderBottomColor: 'grey' 
          }}>

            <div className='col-md-6' id='about' style={{
              height: 400,
              background: `#F3F3F3 0% 0% repeat`,
              opacity: 1,
            }}>
              <div style={{
                position: 'absolute',
                bottom: 0,
                marginBottom: 150,
                justifyContent: 'center',
                overflow: 'hidden',
                paddingLeft: '25%',
                width: '75%'
              }}>
                <h2 style={{ fontWeight: 'bold', textAlign: 'left', color: 'blue' }}>{'About Us'}</h2>
                <span style={{ color: 'blue' }}>
                  <small style={{ textAlign: 'left', color: 'black' }}>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}</small>
                </span>
              </div>
            </div>
            <div className='col-md-6' style={{
              backgroundImage: `url('${pic1}')`,
              height: 400,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              //backgroundColor: `red`, 
              backgroundAttachment: 'relative',

              //opacity: 1
            }}></div>
          </div>
        </div>
        {/*------------------------------ ABOUT AND SERVICES -----------------------------------------*/}

        <Gallery images={this.state.postIDS || [
          { image: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FElectrician.jpg?alt=media&token=f68488c6-10b9-462c-b320-6bb71ebda179" },
          { image: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FProfessional%2Ffacility-management.jpg?alt=media&token=052058ff-38a3-4db4-8fb9-dc345deddeff" },
          { image: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FElectrician.jpg?alt=media&token=f68488c6-10b9-462c-b320-6bb71ebda179" },
          { image: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FProfessional%2Ffacility-management.jpg?alt=media&token=052058ff-38a3-4db4-8fb9-dc345deddeff" },
          { image: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FProfessional%2Ffacility-management.jpg?alt=media&token=052058ff-38a3-4db4-8fb9-dc345deddeff" },
          { image: "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHandyman%2FElectrician.jpg?alt=media&token=f68488c6-10b9-462c-b320-6bb71ebda179" }
        ]} />
        <Profile shopSocials={''} />

        {(this.state.shopChat.status === "LIVE") && <Chat
          backgroundColor={this.state.shopColorBackground}
          nameShop={this.state.nameShop}
          shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}
        />}
        <div style={{ marginTop: -22 }}>
          <Footer
            nameShop={this.state.nameShop}
          />
        </div>
      </div>

    );
  }

}

export default EngineerSample
