import React, { Component } from 'react'
import { Link } from "react-router-dom"
import Productpage from './ProductPage'
import About from './About'
//import shopimage from '../../images/images/shopimage.jpg'
//import cabinet from '../../images/images/cabinet.jpg'
import Modal from './Modal' 
import Portfolio from './Portfolio'
const shop = 'shop'
let clickedItems = {}


class Landing extends Component {
    state = {
        nameShop: '',
        descriptionShop: '',
        postIDs: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: ''

        },
        shopNamefromURL: '',
        shopID: '',
        clickedItems: {},
        selectedItems: []
    }




    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + " " + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + " " + pString;
            }
            else {
                priceString =
                    currency + " " + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = " ";
            }
            return priceString
        }
        //  return pString
    }


    selectItem = async (postID) => {
        let selectedItems = this.state.selectedItems

        await this.setState(prevState => {
            let clickedItems = Object.assign({}, prevState.clickedItems);

            if (!clickedItems[postID.xID] || clickedItems[postID.xID] === 'unclicked') {
                clickedItems[postID.xID] = 'clicked'
                selectedItems.push(postID)
                this.setState({ selectedItems })
            } else {
                clickedItems[postID.xID] = 'unclicked'
                selectedItems.pop(postID)
                this.setState({ selectedItems })
            }
            //clickedItems = localStorage.getItem("clickedItems") 
            localStorage.setItem("clickedItems", JSON.stringify(clickedItems))
            return { clickedItems }
        })
        console.log(' item clicked is; ', this.state.clickedItems, 'selected items are ', this.state.selectedItems)

        this.props.updateCart(selectedItems)
    }



    componentWillUnmount() {
        this.props.saveProductData(this.state.clickedItems, this.state.selectedItems)
    }

    componentWillMount = () => {
        clickedItems = JSON.parse(localStorage.getItem("clickedItems"))

        if (clickedItems) {
            this.setState({ clickedItems })

            console.log('clicked items stored are; ', clickedItems)
        }
    }

    render() {
        //console.log('hascustomDomain in landing is: ', this.props.hasCustomDomain)
        return (
            <div >
                <div id="about">
                    <div className='container' style={{ width: '100%', marginTop: 50 }}>
                         <About
                            descriptionShop={this.props.descriptionShop}
                            //categories={this.props.categories}
                            servicesOffered={this.props.servicesOffered}
                            hasloaded={this.props.hasloaded}
                        /> 

                    </div>
                </div>


                <Modal
                    price={this.state.modalData.price}
                    pictureURL={this.state.modalData.pictureURL}
                    title={this.state.modalData.title}
                    text={this.state.modalData.text}
                ></Modal>

                <div id='cart' style={{ marginTop: 100, borderTopWidth: 2, borderTopColor: "#efb31f" }} className='container-fluid' >
                    <h2 className="text-center mb-4" style={{ color: 'blue' }}>Portfolio</h2>
                    <hr style={{ align: "center", width: 160, margin: "auto", color: '#efb31f' }} />
 
                </div> 
            </div>

        )
    }
}



export default Landing


