import React, { Component } from 'react'
import data from '../imageData'
/* import Women from "./images/women.jpg";
import Fashion from "./images/children.jpg"
import Men from "./images/men.jpg"
 */
/* const shopStyle = {
    position: 'relative',
    overflow: 'hidden',
    margin: '15 0',
    backgroundColor: '#15161d',
    '&::before': {
        content: `""`,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '0px',
        width: '60%',
        background: 'red',
        zIndex: 99999,
        opacity: 0.85,
        WebkitTransform: [{skewX: '-45deg'}],
        msTransform: [{skewX: '-45deg'}],
        transform: [{skewX: '-45deg'}]
    },
    '&::after': {
        content: `""`,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '1px',
        width: '100%',
        background: 'orange',
        opacity: 0.85,
        WebkitTransform: [{skewX: '-45deg', translateX:'-100%'}] ,
        msTransform: [{skewX: '-45deg', translateX:'-100%'}] ,
        transform: [{skewX: '-45deg', translateX:'-100%'}]  
    }
} */



class Categories extends Component {
    state = {
        categories: '',
        services: '',
        images: data.images,
        url: ''
    }



    filterCategories = () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        /* const values1 = values.slice(0, values.length)
        const values2 = values1.join(' ') */
        const newvalues = [].concat(...values)


        console.log(' the categories are: ', keys, ' and the services are: ', values)
        this.setState({ categories: keys, services: newvalues });

    }




    componentDidMount = () => {
        if (this.props.servicesOffered) {
            console.log('services offered in shoppers category component are: ', this.props.servicesOffered)
            this.filterCategories();
        }
    }


    render() {
        return (
            <div className="section"
                style={{
                    width: this.props.width == '100%' ? 0 : (this.props.width == 0 ? '100%' : 0),
                    height: this.props.width == '100%' ? 0 : (this.props.width == 0 ? '100%' : 0),
                    WebkitTransition: 'width 0.5s',
                    transition: 'width 0.5s',

                }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7 site-section-heading-electro text-center pt-4" >
                            {this.props.width == 0 && <h2 style={{ color: "#1e1f28" }} className="h2-electro">We Deal In</h2>}
                        </div>
                    </div>
                    {this.state.categories && <div className="row" style={{ textAlign: 'center', justifyContent: 'center' }}>
                        {this.state.services.map((service, id) => {
                            return (

                                <div className="col-md-3 col-xs-6" key={id} >
                                    <a href={`#` + service}>
                                        <div className={`shop`} style={{border:'1px solid black'}}
                                        //style={{ height: '300px' }}
                                        >
                                            {/* <div className="shop-img" style={{}}>
                                                <img src={this.state.images[service]} alt="" className="img-fluid" />
                                            </div> */}
                                            <div style={{
                                                backgroundImage: `url(${this.state.images[service]})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: "cover",
                                                backgroundPosition: "center center",
                                                backgroundAttachment: 'relative',
                                                overflow: 'hidden',
                                                height: '300px',
                                                maxWidth: '104%'
                                            }}></div>
                                            <div className="shop-body" >
                                                <h3 className="h2-electro" style={{ fontSize: '20px', textAlign: 'left', color: '#000000', lineHeight: 1.3 }}>{service}</h3>
                                                <div style={{ marginTop: -10 }}>
                                                    <p style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'bold' }}><a href="#products" className="cta-btn" style={{ justifyContent: 'left', color: '#fd4f53' }}>Shop now <i className="fa fa-arrow-circle-right"></i></a></p>
                                                </div>

                                            </div>
                                        </div>
                                    </a>
                                </div>


                            )
                        })
                        }
                    </div>}
                </div>
            </div>
        )
    }
}
export default Categories;
