import React, { Component } from 'react'
import './css/new-age.css'
import './device-mockups/device-mockups.css'
import './vendor/simple-line-icons/css/simple-line-icons.css'
import iosDownload from "./img/app-store-badge.svg"
import googleDownload from "./img/google-play-badge.svg"


class Download extends Component {

    render() {
        return (
            <div>
                <section className="download bg-primary text-center" id="download">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h2 className="section-heading" style={{color:'black'}}>{this.props.msg1}</h2>
                                <p style={{color:'black'}}>{this.props.msg2}</p>
                                <div className="badge row ">

                                    <a className="badge-link" href="https://itunes.apple.com/ng/app/wakanda-market/id1451309384?mt=8" target="_blank"  ><img src={iosDownload} style={{ width: '200px' }} alt="" /></a>
                                    <a className="badge-link" href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" target="_blank" ><img src={googleDownload} style={{ width: '200px' }} alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>


        );
    }
}

export default Download;