import React, { Component } from 'react'
import SideDrawer from './sideDrawer';
import Backdrop from './backdrop';
import { Link } from "react-router-dom"

class NavSection extends Component {


    state = {
        sideDrawerOpen: false,
        showAbout: false,
        showNavigation: false
    }


    toggleDrawer = () => {

        this.setState((prevState) => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    }

    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false })
    }


    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }

    render() {
        let sideDrawer;
        let backdrop;

        if (this.state.sideDrawerOpen) {
            sideDrawer = <SideDrawer showNavigation={this.state.showNavigation} showAbout={this.state.showAbout} description={this.props.description} showMenuItems={this.props.showMenuItems} shopNamefromURL={this.props.shopNamefromURL}/>
            backdrop = <Backdrop click={this.backdropClickHandler} />
        }
        let sideDrawer2 = <SideDrawer />

        return (
            <header className="site-navbar" role="banner">
                <div className="site-navbar-top">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-6 col-md-4 order-2 order-md-1 site-search-icon text-left">
                               {/*  <form action="" className="site-block-top-search">
                                    <span className="icon icon-search2"></span>
                                    <input type="text" className="form-control border-0" placeholder="Search" />
                                </form> */}
                            </div>

                            <div className="col-12 mb-3 mb-md-0 col-md-4 order-1 order-md-2 text-center" >
                                <div className="site-logo">
                                    <a href={this.chooseHomeURL()} className="js-logo-clone">{this.props.nameShop || 'Shoppers'}</a>
                                </div>
                            </div>

                            <div className="col-6 col-md-4 order-3 order-md-3 text-right">
                                <div className="site-top-icons">
                                    <ul>
                                        <li className="d-inline-block d-md-none ml-md-0"><a href="#" onClick={() => { this.toggleDrawer(); this.setState({ showNavigation: true, showAbout: false }) }} className="site-menu-toggle js-menu-toggle"><span className="icon-menu"></span></a></li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <nav className="site-navigation text-center" role="navigation">
                    <div className="container">
                        <ul className="site-menu js-clone-nav d-none d-md-block">
                            <li><a href={this.chooseHomeURL()}>Home</a></li>
                           {this.props.showMenuItems && <li><a href="#about" onClick={() => { this.toggleDrawer(); this.setState({ showNavigation: false, showAbout: true }) }}>About</a></li>}
                           {this.props.showMenuItems &&<li><a href="#shop">Shop</a></li>}
                           {this.props.showMenuItems &&<li><a href="#collections">Collections</a></li>}
                             <li><a href="#footer">Contact</a></li>
                        </ul>
                    </div>
                </nav>
                {sideDrawer}
                {backdrop}
            </header>

        );
    }
}

export default NavSection;