import React, { Component } from 'react'
import './css/new-age.css'
import './device-mockups/device-mockups.css'
import './vendor/simple-line-icons/css/simple-line-icons.css'



class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <section className="features" id="features" style={{backgroundColor:'white', marginTop:-30}}>
                    <div className="container" style={{}}>
                        <div className="section-heading text-center">
                            {/* <h2 style={{color:'#029850'}}>All About Wakanda Market</h2> */}
                            <h1 className="text-black p-3 pb-0 font-weight-bold" style={{color:'#029850'}}>All About Wakanda Market</h1>
                            <p className="text-muted" style={{color:'black', marginTop:-20}}>Check out what you can do on our amazing platform!</p>
                            <hr style={{ width: "70px", height: 5, margin: "auto", borderColor: '#2A3A3A', backgroundColor: '#2A3A3A', marginTop:20 }} />

                            {/* <hr /> */}
                        </div>
                        <div className="row">

                            <div className="col-md-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i className="icon-camera text-primary " ></i>
                                                <h3 style={{color:"black"}}>Services</h3>
                                                <p className="text-muted"><small>Wakanda Market is a platform that connects buyers to providers of products and services. It also allows providers to offer and sell services like handyman services, photography, art etc</small></p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i className="icon-flag text-primary"></i>
                                                <h3 style={{color:"black"}}>No Commission</h3>
                                                <p className="text-muted"><small>Wakanda Market does not stock, does not do deliveries and does not take any money from sales on the platform., unlike platforms that stock products for sellers of products and in some cases do deliveries and take a monetary cut from each sale of product sold.</small></p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i className="icon-tag text-primary"></i>
                                                <h3 style={{color:"black"}}>Place Offers</h3>
                                                <p className="text-muted"><small>Wakanda Market features the latest offers from providers on the platform and an easy way to make special requests, an ability to chat with providers directly via the app or even call them if the need may arise.</small></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="cta">
                    <div className="cta-content">
                        <div className="container">
                            <h2 >Stop waiting.<br />Start Buying.</h2>
                            <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">Start Selling!</a>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </section>



                <section className="download bg-primary text-center" id="download" >
                    <div className="container" style={{marginTop:-100}}>
                        
                        <div className="col-md-8 mx-auto" >
                            {/* <h2 className="section-heading" style={{color:'black'}}>The Features That Matter</h2> */}
                            <h1 className="text-black p-3  pb-0 font-weight-bold">The Features That Matter</h1>
                            <p style={{color:'#2A3A3A', marginTop:-20, }}><b>Reasons why you will love it!</b></p>
                            <hr style={{ width: "70px", height: 5, margin: "auto", borderColor: '#2A3A3A', backgroundColor: '#2A3A3A' }} />

                        </div>

                        <div className="row" style={{marginTop:40}}>
                            <div className="col-md-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <h4 style={{ color:"#029850"}}>FASTER PURCHASES</h4>
                                                <p className="text"  style={{ color: '#444' }}><small>Going mobile means that your goods and services can be purchased at a faster rate than before. Through a simple mobile app like Wakanda Market, buyers can browse through your offers and purchase from you immediately once they are satisfied by your offer. They can even chat you up to negotiate prices and discuss pickup or delivery in a very easy manner. </small></p>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <h4 style={{color:"#029850"}}>LOCALISATION</h4>
                                                <p className="text"  style={{ color: '#444' }}><small>Doing business the mobile way ensures that you can reach customers within your immediate vicinity due to localization. This means you can streamline your offers to specific locations and target people of a particular demography. </small></p>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <h4 style={{color:"#029850"}}>REDUCED COST AND OVERHEADS</h4>
                                                <p className="text" style={{ color: '#444' }}><small>Selling through mobile channels reduces your overheads and costs to the barest minimum. It means your costs of marketing is greatly reduced and rather than go to a lot of trouble to keep finding customers, you can simply plug yourself into the existing global market using Wakanda Market and benefit immensely.</small></p>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <h4 style={{color:"#029850"}}>CONVENIENCE</h4>
                                                <p className="text"  style={{ color: '#444' }}><small>Doing business should not be the same as fighting a war. Serving your customers should be done in a way that doesn’t stress you or them. It should be convenient for you to discuss or chat with your customer and they should be able to reach you at any time they need your services. This convenience is one of the reasons that you should allow your business become mobile enabled.</small></p>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <h4 style={{color:"#029850"}}>CUSTOMER EXPERIENCE</h4>
                                                <p className="text"  style={{ color: '#444' }}><small>Having direct access to your customers is priceless. You can create more repeat sales and offers to benefit your existing clients and thereby do more business with them. </small></p>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <h4 style={{color:"#029850"}}>SAFETY</h4>
                                                <p className="text"  style={{ color: '#444' }}><small>Due to increasing stress on the economy and the eroding moral fibre of our society in the face of untold hardship, moving around with cash is no longer a viable option for both buyers and sellers of goods and services. Adopting cashless and easy payment options for goods and services should replace doing business with cash. Making your business mobile ensures that your transactions are done securely and efficiently.</small></p>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>



                {/* <section className="features" id="features" style={{backgroundColor:'white'}}>
                    <div className="container">
                        <div className="section-heading text-center">
                            <h2 style={{color:'#029850'}}>We make your life easier</h2>
                            <p className="text-muted">There's so much more on our amazing platform!</p>
                            <hr />
                        </div>
                        <div className="row">

                            <div className="col-md-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i className="icon-bag text-primary"></i>
                                                <h3 style={{color:'#029850'}}>The shop is easy to set up</h3>
                                                <p className="text-muted"><small>Wakanda Market helps you to setup an online shop in minutes and its opened for visitors 24/7 unlike a physical store. Wakanda Market removes this time barriers, customers can communicate, and order products from your shop at any time on Wakanda Market.</small></p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i className="icon-wallet text-primary"></i>
                                                <h3 style={{color:'#029850'}}>Easy Payment Options (E-WALLET)</h3>
                                                <p className="text-muted"><small>Wakanda market makes payment for good and services easy with the e-wallet, you can easily fund your accounts, make payments and also you can withdraw directly into personal bank account immediately without any delay once the sales or project is completed.</small></p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i className="icon-lock text-primary"></i>
                                                <h3 style={{color:'#029850'}}>Risk-Free Business (ESCROW PAYMENT)</h3>
                                                <p className="text-muted"><small>For the purpose of security, Wakanda market has an escrow payment available to ensure orders are confirmed completed before money is released to the sellers, this protects the seller to ensure that they are working with person and also protects the buyer that they would get value for their money.</small></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

            </div>

        );
    }
}

export default Features;