import React, { Component } from 'react'
import astyles from "./src/app/css/app.module.css"
import bstyles from "./src/app/css/uikit-custom.module.css"
import wave1 from "./src/img/bg/wave1.svg"
import wave2 from "./src/img/bg/wave2.svg"
import wave3 from "./src/img/bg/wave3.svg"

export class Footer extends Component {
    render() {
        return (
            <div>
                <div className={`${astyles['yb-main-footer-wrapper']}`}>
                    <div className={`${astyles['waveHorizontals']}`}>
                        <div id={`${astyles["waveHorizontal1"]}`} className={`${astyles['waveHorizontal']}`} style={{ backgroundImage: `url(${wave1})` }}></div>
                        <div id={`${astyles["waveHorizontal2"]}`} className={`${astyles['waveHorizontal']}`} style={{ backgroundImage: `url(${wave2})` }} ></div>
                        <div id={`${astyles["waveHorizontal3"]}`} className={`${astyles['waveHorizontal']}`} style={{ backgroundImage: `url(${wave3})` }} ></div>
                    </div>
                    <a href="#home" className={`${bstyles['uk-visible@s']} ${astyles['yb-totop']} ${astyles['yb-inner-link']}  ${bstyles['data-uk-totop']}`}><i class="fas fa-chevron-up fa-lg"></i></a>
                    <div className={`${astyles['yb-main-footer']}`}>
                        <div className={`${astyles['uk-container']}`}>
                            <div data-uk-grid className={`${bstyles['uk-child-width-1-2@s']} container clearfix`}>
                                <div className={`${bstyles['uk-margin-remove']} ${bstyles['uk-text-left@s']} float-left`}>
                                    &copy; 2019 Powered, by <a href="https://www.african-founders.com" target="_blank" className="text-white">African Founders</a>.</div>
                                <div class={`${bstyles['uk-margin-remove']} ${bstyles['uk-text-right@s']} float-right`}>
                                    <div class={`${astyles['yb-footer-social']}`}>
                                        <a href="#">Facebook</a>
                                        <a href="#">Instagram</a>
                                        <a href="#">Twitter</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Footer