import React, { useEffect, useState } from 'react'




const TopHeader = (props) => {



    const chooseCartURL = () => {
        if (props.hasCustomDomain) {
            return '/cart'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/' + props.shopNamefromURL + '/cart'
        }
        else {
            return '/' + props.shopNamefromURL + '/cart'
        }
    }

    return (
        <div id="top-header">
            <div class="container">
                <div className="row">
                    <ul class="header-links  mt-1">
                        <li><a href="#"><i class="fa fa-phone"></i>{props.phoneNumber}</a></li>
                        <li className=" d-none d-md-inline"><a href="#"><i class="fa fa-map-marker"></i> {props.address}</a></li>
                    </ul>
                    <ul class="header-links mt-1 d-md-block" style={{ position: 'absolute', right: 50 }}>
                        {props.currency && <li><a href="#" style={{ color: '#d10024', fontWeight: 'bold' }}> {props.currency.posts[0] ? (props.currency.posts[0].priceCurrency || 'NGN'):''}</a></li>}
                        {props.shopCartStatus === "LIVE" && (
                            <li className="nav-item mt-2 ml-1 ">
                                <a onClick={() => { props.showCartModal() }}>
                                    <i className="fas fa-shopping-cart"  ></i>
                                    {props.FullCart.length > 0 && (
                                        <small
                                            style={{
                                                backgroundColor: "red",
                                                borderRadius: "50%",
                                                paddingRight: 5,
                                                paddingLeft: 5,
                                                color: "white",
                                                paddingTop: 2,
                                                paddingBottom: 2
                                            }}
                                        >
                                            {props.FullCart.length}
                                        </small>
                                    )}
                                </a>

                            </li>

                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TopHeader
