import React from "react";
// import { withRouter } from "react-router-dom";

import "./Post.css";

const post = props => (
  <article className="item-1" onClick={props.clicked}>
    <div className="card-blog">
      <div
        className="thumb-blog"
        style={{
          backgroundImage: "url(" + `${props.imageUrl}` + ")"
        }}
      ></div>
      <article>
        <h1>{props.title}</h1>
        <span>{props.author}</span>
      </article>
    </div>
  </article>
);

export default post;
