import React, { Component } from 'react';
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'


import '../../App.css';

class notFound extends Component {

    render() {
        return (
            <div style={{ width: '100%' }}>
                <Navbar
                    url={'wakanda-market.com'}
                />
                <div className="container">
                    <h2 className="display-3 " style={{ lineHeight: 5, zIndex: 99 }}>
                        Page Not Found!
                 </h2>
                </div>

                <div style={{ bottom: 0, position: 'absolute', width: '100%' }}>
                    <Footer />
                </div>

            </div>

        );
    }
}

export default notFound;