import React, { Component } from 'react'
import styles from "./stylesheets/modaz.module.css"
import image from "./images/logo.png"
import { Link } from 'react-router-dom'
import Cart from "../../../checkout/Cart"

import './main.css'

export class Navigation extends Component {




  chooseHomeURL = () => {
    if (this.props.hasCustomDomain) {
      return '/'
    } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
      return '/'
    }
    else {
      return '/' + this.props.shopNamefromURL
    }
  }


  chooseCartURL = () => {
    if (this.props.hasCustomDomain) {
      return '/cart'
    } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
      return '/' + this.props.shopNamefromURL + '/cart'
    }
    else {
      return '/' + this.props.shopNamefromURL + '/cart'
    }
  }



  render() {
    return (
      <nav className="navbar navbar-expand-lg fixed-top bg-white  navbar-dark " style={{ width: "100vw" }}>
        <div
          className={`${styles.clearfix} container `}
          id={styles.siteHeaderInner}
        >
          <div
            id={styles.logo}
            className={`${styles.logo}`}
            style={{ textAlign: "center" }}
          >
            <Link to={this.chooseHomeURL()} title="logo">
              <h1
                className="text-wrap"
                style={{
                  fontFamily: "Helvetica Neue, cursive",
                  fontWeight: "bold",
                  fontSize: 25,
                  color: "#F6343F"
                }}
              >
                {this.props.nameShop}{" "}
              </h1>
            </Link>
          </div>

          <div className="navmenu">
            <label for="toggle">&#9776;</label>
            <input type="checkbox" id="toggle" />
            <div className="nav menu">
              <div><a href="#">Home</a></div>
              <div> <a href="#shop">Shop</a></div>
              <div><a href="#contact">Contact</a></div>
              {/*  <Link to={this.chooseBlogURL()}>Blog</Link> */}
              {/* {this.props.shopCartStatus === "LIVE" && (
                <Link
                  to={{
                    pathname: `${this.chooseCartURL()}`,
                    cartItems: {
                      FullCart: this.props.FullCart || [],
                      shopNamefromURL: this.props.shopNamefromURL,
                      hasCustomDomain: this.props.hasCustomDomain
                    }
                  }}
                >
                  <i className="fas fa-shopping-cart"></i>
                  {this.props.FullCart.length > 0 && (
                    <small
                      style={{
                        backgroundColor: "red",
                        borderRadius: "50%",
                        paddingRight: 5,
                        paddingLeft: 5,
                        color: "white",
                        paddingTop: 2,
                        paddingBottom: 2
                      }}
                    >
                      {this.props.FullCart.length}
                    </small>
                  )}
                </Link>
              )} */}
              {this.props.shopCartStatus === "LIVE" && (
                <div className="nav-item mt-0 ml-1 ">
                  <a onClick={() => { this.props.showCartModal() }}>
                    <i className="fas fa-shopping-cart text-dark"  ></i>
                    {this.props.FullCart.length > 0 && (
                      <small
                        style={{
                          backgroundColor: "red",
                          paddingRight: 3,
                          paddingLeft: 3,
                          color: "white",
                          paddingTop: 1,
                          paddingBottom: 1,
                          position: 'relative',
                          bottom: 8,
                          right: 3
                        }}
                      >
                        {this.props.FullCart.length}
                      </small>
                    )}
                  </a>

                </div>

              )}
            </div>
          </div>
        </div >
      </nav >
    );
  }
}


export default Navigation
