import React, { Component } from 'react'

export default class SideDrawer extends Component {
    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }

    render() {
        return (
            <div >
                {this.props.showNavigation && <div className="container side-drawer align-content-center" >
                    <ul className="site-menu js-clone-nav d-md-block list-unstyled">
                        <li><a href={this.chooseHomeURL()}>HOME</a></li>
                        {this.props.showMenuItems && <li><a href="#about">ABOUT</a></li>}
                        {this.props.showMenuItems &&<li><a href="#shop">SHOP</a></li>}
                        {this.props.showMenuItems && <li><a href="#collections">COLLECTIONS</a></li>}
                        <li><a href="#footer">CONTACT</a></li>
                    </ul>
                </div>}

                {this.props.showAbout && <div className="container side-drawer align-content-center">
                    <p>{this.props.description || 'Provider description...'}</p>
                </div>}
            </div>
        )
    }
}
