import React, { Component } from 'react'
import Navbar from './Navbar'
import Profile from './Profile'
import Footer from "./Footer"
import leftArrow from '../../../assets/left-arrow.svg'


class CFactoryProductPage extends Component {
    state = {
        product: {
            title: '',
            text: '',
            price: '',
            pictureURL: '',
            currency: ''
        },
        description: '',
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        servicesOffered: '',
        postIDs: '',
        xID: '',
        shopNamefromURL: '',
        nameShop: '',
        shopLogo: '',
        url: '',
        shopID: '',
        redirect: false,
        productExists: '',
        loading: true,
        theme: '',
        shopColorTitle: '',
        shopColorBackground: '',
        shopColorButton: '',
        shopColorHeader: '',
        shopColorTitle: '',
        shopFrontPicture: '',
        HomePageData: '',
        hasCustomDomain: '',
        shopSocials: '',
        logo: '',
        shopLogo: '',
        servicesOffered: '',
        shopCartStatus: '',
        shopCartBankAccount: '',
        shopChat: '',
        shopNamefromURL: '',
        tagline: '',
        FullCart: '',
        cartModalDetails: {
            FullCart: '',
            shopNamefromURL: '',
            hasCustomDomain: '',
            nameShop: '',
            shopCartBankAccount: ''
        },
        cartModalWidth: 0,
        showModalCart: false,
        theme: 'Standard White'
    }


    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }

    openAndCloseCartModal = () => {
        this.setState({
            cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
            showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
        })
    }

    updateModalCart = () => {
        this.setState({
            cartModalDetails: {
                FullCart: this.state.FullCart,
                shopNamefromURL: this.state.shopNamefromURL,
                hasCustomDomain: this.state.hasCustomDomain,
                nameShop: this.state.nameShop,
                shopCartBankAccount: this.state.shopCartBankAccount
            }
        })
    }







    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + " " + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + " " + pString;
            }
            else {
                priceString =
                    currency + " " + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = " ";
            }
            return priceString
        }
        //  return pString
    }

    topFunction = () => {
        window.scrollTo(0, 0)
        return
    }




    goBack = () => {
        this.props.history.push(this.chooseHomeURL())
    }


    render() {
        return (
            <div style={{ backgroundColor: 'white' }}>
                {this.props.nameShop && <Navbar
                    showCartModal={() => {
                        this.openAndCloseCartModal()
                        this.updateModalCart()
                    }}
                    showAbout={true}
                    showContact={true}
                    nameShop={this.props.nameShop}
                    logo={this.props.logo}
                    shopNamefromURL={(this.props.hasCustomDomain || this.state.hasCustomDomain) ? '' : this.props.shopNamefromURL}
                    url={this.props.url}
                    showSearch={false}
                    shopColorBackground={this.props.shopColorBackground}
                    FullCart={this.props.FullCart}
                    hasCustomDomain={this.props.hasCustomDomain}
                    shopCartStatus={this.props.shopCartStatus}
                    shopCartBankAccount={this.props.shopCartBankAccount}
                />}

                <div className="container mb-4">
                    {(this.props.shopNamefromURL || this.props.nameShop) && <img src={leftArrow} onClick={this.chooseHomeURL()} style={{ width: 40, height: 40, cursor: 'pointer' }} />}</div>
                <div className=" mx-auto container row mb-4">

                    {this.props.productExists === false && this.props.loading && <div style={{ height: '200px' }} >
                        <h2 >Oops! :'( Product Does Not Exist!</h2>
                        <p>Please contact the provider for a valid product link</p>
                    </div>
                    }
                    <div className="col-md-6">



                        {/* this.props.productExists && <div style={{ justifyContent: 'center' }} className="mb-4">
                            <img src={this.props.pictureURL} alt="picture"
                                className="img-fluid img-thumbnail" />
                        </div> */}



                        {this.props.productExists && <div style={{
                            backgroundImage: `url(${this.state.imageInView || this.props.pictureURL})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            backgroundColor: 'rgba(255,255,255,0.3)',
                            overflow: 'hidden',
                            height: "auto",
                            maxWidth: '104%',
                            width: '100%',
                            height: 400,
                            border: '1px solid rgba(204, 204, 204, 0.5)',
                            marginBottom: 20
                        }}></div>}

                        {this.props.productImages[this.props.xID] && this.props.productExists && (this.props.productImages[this.props.xID].length > 1) && <div style={{ display: 'flex', justifyContent: 'left', marginTop: 10, marginBottom: 10, width: '100%', height: 'auto' }}>
                            {this.props.productImages[this.props.xID].map((image, id) => {
                                return (
                                    <div key={id} style={{
                                        backgroundImage: `url(${image})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center",
                                        backgroundAttachment: 'relative',
                                        backgroundColor: 'rgba(255,255,255,0.3)',
                                        overflow: 'hidden',
                                        height: "auto",
                                        maxWidth: '104%',
                                        width: 100,
                                        height: 100,
                                        border: '1px solid rgba(204, 204, 204, 0.5)',
                                        marginRight: 10
                                    }}
                                        onClick={() => this.setState({ imageInView: image })}
                                    ></div>
                                )
                            })}
                        </div>}





                        {!this.props.pictureURL &&

                            <p className="lead">Product loading...</p>
                        }


                    </div>

                    <div class=" mr-md-3 pt-3 px-3 pt-md-5 px-md-5 overflow-hidden col-md-5" >
                        <div class="my-3 py-3">

                            <h3 style={{ color: 'black' }} ><b>{this.state.product.title}</b></h3>
                            <h6 style={{ color: '#888', fontSize: 18 }}  >{this.props.text}</h6>
                            <hr />
                            <h5 style={{ color: 'grey', marginTop: 10 }}>{this.props.price}</h5>

                        </div>
                    </div>
                </div>


                {!this.state.showModalCart &&
                    <div id="contact">
                        <Profile
                            providerName={this.props.providerName}
                            providerAddress={this.props.providerAddress}
                            phoneNumber={this.props.phoneNumber}
                            shopNamefromURL={this.props.shopNamefromURL || this.props.shopID}
                            shopSocials={this.props.shopSocials}
                            shopColorButton={this.props.shopColorButton}
                        />
                    </div>}
                <Footer />
            </div>

        );
    }
}

export default CFactoryProductPage;
