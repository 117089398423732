import React, { Component } from 'react'
import './css/new-age.css'
import './device-mockups/device-mockups.css'
import demoScreen1 from "./img/demo-screen-1a.png"
//import './device-mockups/device-mockups2.css'


class header extends Component {

    render() {
        return (
            <div style={this.props.styles}>

                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-lg-7 my-auto">
                                <div className="header-content mx-auto">
                                    <h1 className="mb-5">{this.props.headerText}</h1>
                                    <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">Start Now for Free!</a>
                                </div>
                            </div>
                            <div className="col-lg-5 my-auto">
                                <div className="device-container" style={{marginTop:'60px'}}>
                                    <div className="device-mockup iphone6_plus portrait white">
                                        <div className="device">
                                            <div className="screen">

                                                <img src={demoScreen1} className="img-fluid" alt="" />
                                            </div>
                                            <div className="button">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

export default header;