import React, { Component } from 'react'
import astyles from "./src/app/css/app.module.css"
import bstyles from "./src/app/css/uikit-custom.module.css"
import empty from './src/img/empty.png'
import profile2 from './src/img/profile2.jpg'

class Contact extends Component {

    state = {
        providerName: '',
        shopProfileName: '',
        address: '',
        phoneNumber: '',
        senderName: '',
        shopName: '',
        msg: false,
        error: false,
        loading: ''
    }



    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        console.log('Username' + this.state.senderName)

        const newMessage = {
            shopName: this.props.shopNamefromURL,
            senderName: this.state.senderName,
            senderPhoneNumber: this.state.senderPhoneNumber,
            message: this.state.message
        }
        console.log('new', newMessage)
        const url = "https://us-central1-afbase-7ee60.cloudfunctions.net/shopMessage"
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newMessage)
        }).then((res) => res.json())

            .catch(error => { console.error('Error:', error); this.setState({ loading: false }) })
            .then(response => {


                console.log('Success:', response)
                if (response.msg == 'SUCCESS') {
                    this.setState({ msg: true, senderName: '', senderPhoneNumber: '', message: '', loading: false })
                } else {
                    this.setState({ error: true, loading: false })
                }
            });


    }



    render() {
        const { loading } = this.state
        return (
            <div>
                {/* -- contact section --> */}
                <div className={`${astyles['yb-section']} ${astyles['yb-border-top']}`} id="contact">
                    <div className={`${astyles['yb-section-point-wrapper']}`}>
                        <div className={`${bstyles['uk-container']}`}>
                            <div className={`${astyles['yb-section-point']}`}>
                                <sup>03</sup>
                                <span>Contact</span>
                            </div>
                        </div>
                    </div>
                    <div className={`${bstyles['uk-container']}`}>

                        <div data-uk-grid className={`${bstyles['uk-grid-divider']} row`}>
                            <div className={`${astyles['yb-section-padding']} ${astyles['yb-contact-left']} ${bstyles['uk-width-3-4@m']}  col-md-8`}>
                                <div>
                                    <h2 className={`${astyles['yb-section-title']} ${bstyles['uk-heading-line']}`}>
                                        <span style={{ color: 'black' }}>Get in touch</span>
                                    </h2>
                                    <p className={`${bstyles['uk-text-bold']}`} style={{ color: 'grey' }}>Feel free to contact me</p>
                                    <div id="contact-form-alert"></div>
                                    <form id="contact-form" className={`${bstyles['uk-form-stacked']}`} onSubmit={this.onSubmit}>
                                        <div className={`${bstyles['uk-grid']} ${bstyles['uk-grid-small']} ${bstyles['uk-child-width-1-2@s']}`}>
                                            <div>
                                                <div className={`${bstyles['uk-margin']}`}>
                                                    <label for="f_email" className={`${bstyles['uk-form-label']}`}>Name*</label>
                                                    <div className={`${bstyles['uk-form-controls']} ${bstyles['uk-inline']} ${bstyles['uk-width-1-1']}`}>
                                                        <input className={`${astyles['yb-input']} ${bstyles['uk-input']}  ${bstyles['uk-border-rounded']}`} type="text" name="senderName" id="f_name" value={this.state.senderName}
                                                            onChange={this.onChange} required placeholder=" Your name here . . ." />
                                                    </div>
                                                    <small className={`${bstyles['uk-text-danger']} err-msg`} id="f_email_error"></small>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={`${bstyles['uk-margin']}`}>
                                                    <label for="f_phone" className={`${bstyles['uk-form-label']}`}>Phone*</label>
                                                    <div className={`${bstyles['uk-form-controls']} ${bstyles['uk-inline']} ${bstyles['uk-width-1-1']} form-group`}>
                                                        <input className={`${astyles['yb-input']} ${bstyles['uk-input']}  ${bstyles['uk-border-rounded']}`} type="tel" name="senderPhoneNumber" id="f_phone" value={this.state.senderPhoneNumber}
                                                            onChange={this.onChange} required placeholder=" Your phone here . . ." />

                                                    </div>
                                                    <small className={`${bstyles['uk-text-danger']} err-msg`} id="f_phone_error"></small>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  <div className={`${bstyles['uk-margin']}`}>
                                            <label className={`${bstyles['uk-form-label']}`}>Subject*</label>
                                            <div className={`${bstyles['uk-form-controls']} ${bstyles['uk-grid-small']} ${bstyles['uk-child-width-auto']} ${bstyles['uk-grid']}`}>
                                                <label><input className={`${bstyles['uk-radio']}`} type="radio" name="f_subject" value="General" checked /> General</label>
                                                <label><input className={`${bstyles['uk-radio']}`} type="radio" name="f_subject" value="Project Preview" /> Project Review</label>
                                                <label><input className={`${bstyles['uk-radio']}`} type="radio" name="f_subject" value="Hire Me" /> Hire Me</label>
                                            </div>
                                        </div> */}
                                        <div className={`${bstyles['uk-margin']}`} >
                                            <label for="f_message" className={`${bstyles['uk-form-label']}`}>Message*</label>
                                            <div className={`${bstyles['uk-form-controls']} form-group`}>
                                                <textarea className={`${astyles['yb-input']}  ${bstyles['uk-textarea']} ${bstyles['uk-border-rounded']}`} rows="7" name="message" id="exampleFormControlTextarea1" value={this.state.message}
                                                    onChange={this.onChange} required placeholder="Your message here . . ."></textarea>
                                            </div>
                                            <small className={`${bstyles['uk-text-danger']} err-msg`} id="f_message_error"></small>
                                        </div>
                                        <div className={`${bstyles['uk-margin-top']}`}>
                                            <button className={`${bstyles['uk-button']} ${bstyles['uk-button-primary']} ${bstyles['uk-button-large']}  ${astyles['yb-btn']}`} type="submit" id="btn-contact-form" data-textreset="Send Now">
                                                {loading && <i class="fa fa-spinner fa-spin"></i>} &nbsp;
                                                <span className={`${bstyles['uk-margin-small-right']}  btn-text-wrap]}`}>Send Now</span>
                                                <span data-uk-icon="arrow-right" className={`${bstyles['uk-icon']}`}> <i class="fas fa-arrow-right"></i> </span>
                                            </button>
                                        </div>

                                        <p className="mt-1 ">{this.state.msg && <div>
                                            <p className="text-success py-1 px-4  "><small>Message sent, seller will contact you shortly</small> </p></div>}
                                            {this.state.error && <div><p className="text-danger px-4 py-1">  <small className={`${bstyles['uk-text-danger']} err-msg`} id="f_message_error">Oops!, Message not sent</small></p></div>}</p>
                                    </form>
                                </div>
                            </div>

                            <div className={`${bstyles['uk-width-1-4@m']} ${astyles['yb-section-padding']} ${astyles['yb-contact-right']} col-md-4 text-center`}>
                                <p className={`${bstyles['uk-margin-medium-bottom']} ${bstyles['uk-visible@m']} `}>
                                    <img src={this.props.logo || profile2} data-src="src/img/profile2.jpg" data-uk-img alt="" className={`${bstyles['uk-border-circle']}`} style={{ width: 250, height: 250 }} />
                                </p>
                                <ul className={`${bstyles['uk-list']} ${bstyles['uk-list-divider']} ${bstyles['uk-text-small']} ${astyles['yb-contact-mini']}`}>
                                    <li className={`${bstyles['uk-flex']} ${bstyles['uk-flex-middle']}`}>
                                        <span data-uk-icon="mail" className={`${bstyles['uk-flex-none']} ${bstyles['uk-text-primary']} mx-4`}><i class="far fa-envelope"></i></span>
                                        <span>my.email@gmail.com</span>
                                    </li>
                                    <li className={`${bstyles['uk-flex']} ${bstyles['uk-flex-middle']}`}>
                                        <span data-uk-icon="phone" className={`${bstyles['uk-flex-none']} ${bstyles['uk-text-primary']} mx-4`}><i class="fas fa-mobile-alt"></i></span>
                                        <span>{this.props.providerNumber || 'NOT AVAILABLE'}</span>
                                    </li>
                                    <li className={`${bstyles['uk-flex']} ${bstyles['uk-flex-middle']}`}>
                                        <span data-uk-icon="location" className={`${bstyles['uk-flex-none']} ${bstyles['uk-text-primary']} mx-4`}><i class="fas fa-map-marker-alt"></i></span>
                                        <span>{this.props.providerAddress || 'NOT AVAILABLE'}</span>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
                {/* !-- end contact section --> */}
            </div>
        )
    }
}

export default Contact
