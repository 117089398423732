import React, { Component } from 'react'

//importing stylesheets................
import "./fonts/icomoon/style.css"
import "./css/magnific-popup.css"
import "./css/style.css"
import "./css/aos.css"
import "./css/animation.css"
//import "./css/jquery-ui.css"
//import "./css/owl.carousel.min.css"
//import "./css/owl.theme.default.min.css"

//importing components................
import NavSection from './NavSection'
import Header from './Header'
import Categories from './Categories'
import FeaturedProducts from './FeaturedProducts';
import Chat from '../../../components/chats/Chat'
import Footer from './Footer'
import Cart from '../../../checkout/cartModal'


import ReactPixel from 'react-facebook-pixel'
import Socials from '../../socials/socials'

const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('1719865501642535', options);
ReactPixel.pageView();


let FullCart = []
let cartStorage = {}
let WM_DATA_Storage = {}


class shoppers extends Component {
    state = {
        nameShop: '',
        description: '',
        tagline: '',
        shopBackgroundPicture: '',
        shopColorBackground: '',
        shopColorButton: '',
        shopColorHeader: '',
        shopColorTitle: '',
        shopFrontPicture: '',
        shopCartStatus: '',
        shopChat: '',
        shopCartBankAccount: '',
        shopSocials: {
            facebook: "",
            instagram: "",
            twitter: ""
        },
        postIDs: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: ''

        },
        logo: '',
        categories: '',
        services: '',
        servicesOffered: '',
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        hasloaded: false,
        redirect: false,
        shopNamefromURL: '',
        shopID: '',
        hasCustomDomain: false,
        url: '',
        theme: 'shoppers',
        FullCart: [],
        cartModalWidth: 0,
        showModalCart: false,
        clickedItems: '',
        selectedItems: '',
        cartShowbankDetails: '',
        cartTotal: ''
    }





    openAndCloseCartModal = () => {
        this.setState({
            cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
            showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
        })
    }

    updateModalCart = () => {
        this.setState({
            cartModalDetails: {
                FullCart: this.state.FullCart,
                shopNamefromURL: this.props.shopNamefromURL,
                hasCustomDomain: this.props.hasCustomDomain,
                nameShop: this.state.nameShop,
                shopCartBankAccount: this.state.shopCartBankAccount
            }
        })
    }






    componentDidMount = async () => {

        if (JSON.parse(localStorage.getItem("WM_DATA_Storage"))) {
            WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
            //cartStorage = WM_DATA_Storage.FullCart
        }

        if (JSON.parse(localStorage.getItem("cartStorage") || localStorage.getItem("cartStorage")) && (JSON.parse(localStorage.getItem("cartStorage")).length > 0)) {
            FullCart = JSON.parse(localStorage.getItem("cartStorage"))
            this.setState({ FullCart })
        }


        if (this.props.shopData) {
            document.title = this.props.shopData.profile.businessName
            console.log('Data received in Shoppers theme from Home JS is: ', this.props.shopData)
            let shopProfile = this.props.shopData.profile
            let shopProfileName = shopProfile.businessName
            let description = shopProfile.description
            let shopLogo = shopProfile.pictureURL
            let hasCustomDomain = this.props.hasCustomDomain
            //////////////////////////////////////////////////////////////////////////////

            let providerName = shopProfile.name;
            let providerAddress = shopProfile.locationWorkAddress
            let phoneNumber = shopProfile.phoneNumber
            let servicesOffered = shopProfile.servicesOffered
            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopPost = this.props.shopData.posts
            console.log('shopPost from APP JS is: ', shopPost)

            let shop = this.props.shopData.shop
            console.log('shops', shop)
            let tagline = shop.shopSlogan
            let shopID = shop.shopName
            let shopNamefromURL = shop.shopName
            let shopBackgroundPicture = shop.shopBackgroundPicture
            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopColorBackground = shop.shopColorBackground
            let shopColorButton = shop.shopColorButton
            let shopColorHeader = shop.shopColorHeader
            let shopColorTitle = shop.shopColorTitle
            let shopFrontPicture = shop.shopFrontPicture
            let shopCartStatus = shop.shopCartStatus
            let shopChat = shop.shopChat
            let shopCartBankAccount = shop.shopCartBankAccount
            let shopSocials = shop.shopSocials
            ///////////////////////////////////////////////////////////////////////////////////////////

            console.log('shopName is shop ID which is: ', shopID)
            await this.setState({
                nameShop: shopProfileName,
                tagline,
                description,
                shopBackgroundPicture,
                shopColorBackground,
                shopColorButton,
                shopColorHeader,
                shopColorTitle,
                shopFrontPicture,
                shopCartStatus,
                shopSocials,
                shopChat,
                postIDs: shopPost,
                providerName,
                providerAddress,
                phoneNumber,
                servicesOffered,
                logo: shopLogo,
                hasloaded: true,
                url: this.props.url,
                shopNamefromURL,
                shopID,
                shopCartBankAccount,
                hasCustomDomain
            })
            if (this.state.postIDs[0]) {
                console.log('first postID is: ', this.state.postIDs[0].xID)
            }

            this.topFunction()
        }
    }


    topFunction = () => {
        window.scrollTo(0, 0)
        return
    }



    render() {
        return (
            <div className="App container-fluid pr-0 pl-0 h-100" style={{ backgroundColor: 'white', overflowX: 'hidden', width: '100%', top: 0, left: 0, position: 'fixed', scrollBehavior: "smooth" }}>

                <NavSection
                    showCartModal={() => {
                        this.openAndCloseCartModal()
                        this.updateModalCart()
                    }}
                    nameShop={this.state.nameShop}
                    logo={this.state.logo}
                    shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
                    description={this.state.description}
                    url={this.state.url}
                    showSearch={false}
                    showMenuItems={true}
                    FullCart={this.state.FullCart}
                    shopCartStatus={this.state.shopCartStatus}
                    shopCartBankAccount={this.state.shopCartBankAccount}
                    hasCustomDomain={this.state.hasCustomDomain}
                />
                {/*   <div
                    style={{
                        position: 'static',
                        paddingLeft: 30,
                        marginTop: -300,
                        marginBottom: 100,
                        height: 120
                    }}
                >
                    <Socials shopSocials={this.state.shopSocials} /></div> */}

                {this.props.shopData.posts && <Header
                    products={this.props.shopData.posts}
                    tagline={this.state.tagline}
                    description={this.state.description}
                    servicesOffered={this.props.shopData.profile.servicesOffered}
                    shopColorBackground={this.state.shopColorBackground}
                    shopColorButton={this.state.shopColorButton}
                    shopColorHeader={this.state.shopColorHeader}
                    shopColorTitle={this.state.shopColorTitle}
                    shopFrontPicture={this.state.shopFrontPicture}

                />}



                {this.state.servicesOffered && !this.state.showModalCart && <div style={{ marginTop: -10 }}>
                    <div id='collections'>
                        <Categories
                            servicesOffered={this.state.servicesOffered}
                        /* updateCategories={(services, categories) => {
                            this.setState({ categories, services })
                        }} */
                        />
                    </div>
                </div>}

                {this.props.shopData.posts && !this.state.showModalCart &&
                    <div id="shop" style={{ backgroundColor: '#f3f3f3', paddingTop: 10, paddingBottom: 50, paddingLeft: 30, paddingRight: 30 }}>
                        <FeaturedProducts

                            addtoCart={(product) => {

                                FullCart.push(product)
                                let cart = Array.from(new Set(FullCart))
                                this.setState({ FullCart: cart })
                                console.log('products added to cart are: ', this.state.FullCart)

                            }}

                            updateCart={(FullCart) => {

                                localStorage.setItem("cartStorage", JSON.stringify(FullCart));
                                cartStorage = JSON.parse(localStorage.getItem("cartStorage"))
                                WM_DATA_Storage.FullCart = cartStorage
                                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                console.log('products added to cart are: ', this.state.FullCart, 'items stored in browser is therefore: ', WM_DATA_Storage.FullCart)
                                this.setState({ FullCart })
                            }}

                            saveProductData={(clicked, selected) => {
                                this.setState({ clickedItems: clicked, selectedItems: selected })
                            }}
                            FullCart={this.state.FullCart}
                            selectedItems={this.state.selectedItems}
                            clickedItems={this.state.clickedItems}
                            shopCartStatus={this.state.shopCartStatus}
                            shopCartBankAccount={this.state.shopCartBankAccount}
                            shopData={this.props.shopData}
                            shopNamefromURL={this.state.shopNamefromURL}
                            url={this.props.url}
                            nameShop={this.state.nameShop}
                            descriptionShop={this.state.descriptionShop}
                            postIDs={this.state.postIDs}
                            providerName={this.state.providerName}
                            providerAddress={this.state.providerAddress}
                            phoneNumber={this.state.phoneNumber}
                            shopID={this.state.shopID}
                            //shopLogo={this.state.logo}
                            shopLogo={this.state.logo}
                            servicesOffered={this.state.servicesOffered}
                            hasloaded={this.state.hasloaded}
                            hasCustomDomain={this.props.hasCustomDomain}
                            theme={this.state.theme}
                            shopColorTitle={this.state.shopColorTitle}
                            shopColorBackground={this.state.shopColorBackground}
                            shopSocials={this.state.shopSocials}
                            shopCartStatus={this.state.shopCartStatus}
                            shopChat={this.state.shopChat}
                            shopCartBankAccount={this.state.shopCartBankAccount}
                            shopSocials={this.state.shopSocials}
                        /></div>}

                <div className="d-none d-md-block">
                    {(this.state.shopChat.status === "LIVE") && <Chat
                        textColor={'white'}
                        backgroundColor={this.state.shopColorButton}
                        nameShop={this.state.nameShop}
                        shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}
                    />}
                </div>

                {/* <Socials shopSocials={this.state.shopSocials} /> */}

                {this.state.servicesOffered && !this.state.showModalCart &&
                    <Footer
                        servicesOffered={this.state.servicesOffered}
                        providerName={this.props.shopData.profile.name}
                        providerAddress={this.props.shopData.profile.locationWorkAddress}
                        phoneNumber={this.props.shopData.profile.phoneNumber}
                        textColor={this.state.shopColorTitle}
                        buttonColor={this.state.shopColorButton}
                        shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                        shopSocials={this.state.shopSocials}
                        nameShop={this.state.nameShop ? this.state.nameShop : 'wakanda'}
                    />}


                <div id='cart-modal' style={{
                    width: this.state.cartModalWidth,
                    height: 'inherit',
                    position: 'absolute',
                    right: 0, top: 60,
                    backgroundColor: 'white',
                    WebkitTransition: 'width 0.5s',
                    transition: 'width 0.5s',
                    paddingTop: 50,
                    paddingBottom: 100
                }}>

                    <div onClick={this.openAndCloseCartModal}>
                        <h1 style={{ color: 'black', paddingLeft: 50 }}>X</h1> </div>

                    {this.state.showModalCart && <div style={{ height: 'inherit', }}>
                        <Cart
                            CartDetails={this.state.cartModalDetails}
                            // cartShowbankDetails={this.state.cartShowbankDetails}
                            cartShowbankDetails={this.state.cartShowbankDetails}
                            cartTotal={this.state.cartTotal}
                            nameShop={this.state.nameShop}
                            updateCart={(FullCart) => {

                                localStorage.setItem("cartStorage", JSON.stringify(FullCart));
                                cartStorage = JSON.parse(localStorage.getItem("cartStorage"))
                                WM_DATA_Storage.FullCart = cartStorage
                                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                console.log('products added to cart are: ', this.state.FullCart, 'items stored in browser is therefore: ', WM_DATA_Storage.FullCart)
                                this.setState({ FullCart })

                            }}

                            clearLocalStorageCart={() => {
                                WM_DATA_Storage.FullCart = ''
                                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                localStorage.setItem("cartStorage", "");
                                this.setState({ FullCart: '' })
                            }}

                            storeCartDetails={(a, b) => {
                                this.setState({ cartShowbankDetails: a, cartTotal: b })
                            }}
                        />
                    </div>}

                </div>
            </div>
        );
    }
}

export default shoppers;