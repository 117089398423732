import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import style from './stylesheets/modaz.module.css'
const prefix = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2F"

const Card = (props) => {
    //const { images } = props;
    return (
        <div className={`${style.zoom} card bg-dark m-2 `} style={{ border: 'none' }}>
            {/* <img className="card-img" src={props.images} style={{ width: '300px', height: '300px' }} alt="Card image" /> */}
            <a className="block-2-item"><div
                style={{
                    width: '100%',
                    height: 300,
                    backgroundColor: '#000',
                    backgroundImage: `url(${props.images || prefix + "Professional%2Fcomputer-IT.jpg?alt=media&token=3c0fe653-70c3-46e2-94e8-958802a77c3a"})`,
                    backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow: 'hidden',

                }}>
                <div style={{ width: '100%', background: 'rgba(0,0,0,0.5)' }}></div>
            </div>
                <div class="card-img-overlay" style={{

                }}>
                    <p class="card-title text-center text-black bg-white " style={{
                        position: "absolute",
                        top: '45%',
                        left: 0,
                        width: "100%"
                    }}>{props.categories}</p>
                </div>
            </a>
        </div>



    )
}

Card.propTypes = {
    property: PropTypes.object.isRequired
}

export default Card;