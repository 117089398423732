import React, { Component } from 'react'
import { Link } from "react-router-dom"

export default class SideDrawer extends Component {
    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }

    chooseCartURL = () => {
        if (this.props.hasCustomDomain) {
            return "/cart";
        } else if (
            window.location.href === "http://localhost:3000/" ||
            window.location.href === "https://localhost:3000/" ||
            window.location.href === "http://wakanda-market.com" ||
            window.location.href === "https://wakanda-market.com"
        ) {
            return "/" + this.props.shopNamefromURL + "/cart";
        } else {
            return "/" + this.props.shopNamefromURL + "/cart";
        }
    };

    render() {
        return (
            <div >
                {this.props.showNavigation && <div className="container side-drawer" >
                    <ul className="site-menu js-clone-nav d-md-block list-unstyled" style={{ transition: "300ms ease all" }}>
                        <li><a href={this.chooseHomeURL()}>HOME</a></li>
                        {this.props.showMenuItems && <li><a href="#about">ABOUT</a></li>}
                        {this.props.showMenuItems && <li><a href="#shop">SHOP</a></li>}
                        {this.props.showMenuItems && <li><a href="#collections">COLLECTIONS</a></li>}
                        <li><a href="#footer">CONTACT</a></li>
                        {this.props.shopCartStatus === "LIVE" && (
                                <li>
                                    <a onClick={() => { this.props.showCartModal() }}>
                                        <i className="fas fa-shopping-cart"></i>
                                        {this.props.FullCart.length > 0 && (
                                            <small
                                                style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    color: "white",
                                                    paddingTop: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                {this.props.FullCart.length}
                                            </small>
                                        )} </a>
                                </li>
                            )}
                    </ul>
                </div>}


            </div>
        )
    }
}
