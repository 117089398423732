import React from 'react';

const Banner = (props) => {
    return (
        <div class=" img-fluid"
            style={{
                backgroundImage: `url(${props.shopPromoPicture || "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2Fbanner.jpg?alt=media&token=93361d2e-0d8f-4143-b3cc-686ae33d8e6b"})`,
                backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', paddingTop: '8%', paddingBottom: '8%', paddingLeft: 25, paddingRight: 25
            }}>
            <div class="container" style={{ justifyContent: 'center', alignItems: 'center' }}>
                <h1 className="text-center tracking-in-expand text-uppercase" style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>{props.shopPromoTitle || 'Hot deals available!'}</h1>
                <p className="text-center text-uppercase" style={{ color: 'white', fontWeight: 'bold', fontSize: 20 }}> {props.shopPromoText || 'NEW COLLECTION UP TO 50% OFF'}</p>
                <div className="form-group text-center" style={{ alignSelf: 'center' }}>
                    <button className="newsletter-btn2 py-2" style={{ color: '#FFC914' }}><a href="#products"><i className="fas fa-shopping-cart" style={{ color: '#FFC914' }}></i><span style={{ color: '#FFC914' }}>&nbsp;Shop now</span></a></button>
                </div>
            </div>
        </div>
    );
};

export default Banner;