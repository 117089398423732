import React, { Component } from 'react';
//import '../styles/main.css';



export default class Profile extends Component {
    state = {
        providerName: '',
        shopProfileName: '',
        descriptionShop: '',
        services: [],
        address: '',
        phoneNumber: '',
        senderName: '',
        shopName: '',
        msg: false,
        error: false

    }



    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = (e) => {
        e.preventDefault()

        console.log('Username' + this.state.senderName)

        const newMessage = {
            shopName: this.props.shopNamefromURL,
            senderName: this.state.senderName,
            senderPhoneNumber: this.state.senderPhoneNumber,
            message: this.state.message,
        }
        console.log('new', newMessage)
        const url = "https://us-central1-afbase-7ee60.cloudfunctions.net/shopMessage"
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newMessage)
        }).then((res) => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {


                console.log('Success:', response)
                if (response.msg == 'SUCCESS') {
                    this.setState({ msg: true, senderName: '', senderPhoneNumber: '', message: '' })
                } else {
                    this.setState({ error: true })
                }
            });


    } 
    render() {
        return (
            <div style={{ marginTop: '40px', marginBottom: '10px', paddingTop: '40px', paddingBottom: '40px', backgroundColor:'#0F00DC', display:'flex', justifyContent:'center', alignItems:'center' }}>
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-4 col-md-3 col-sm-12 mb-sm--30"  >
                            <div className="row heading mb--32" style={{alignItems:'center'}}>
                              <span style={{ borderRadius:'50%', backgroundColor:'white', padding:5, color:'blue', marginRight:10, marginBottom:10}}><b style={{fontWeight:'bold'}}>{"HS"}</b></span>
                              <span>  <h5 className="text-white"><b style={{fontWeight:'bold'}}>{this.props.providerName ||"Heritage Store"}</b></h5> </span>
                            </div>
                            <div className="contact-info mb-20 mt-2"> 
                                <p><small><b>Shop: </b>{this.props.providerAddress || 'Not Available'}</small> </p>
                                <p> <small><b>Tel: </b>{this.props.phoneNumber || 'Not Available'}</small></p>
                            </div>

                            <ul className="list-inline ">
                                {this.props.shopSocials.facebook && <li className="list-inline-item m-2">
                                    <a href={this.props.shopSocials.facebook} target='_blank'>
                                        <i class="fab fa-facebook-f fa-lg"></i>
                                    </a>
                                </li>}
                                {this.props.shopSocials.twitter && <li className="list-inline-item m-2">
                                    <a href={this.props.shopSocials.twitter} target='_blank'>
                                        <i class="fab fa-twitter fa-lg"></i>
                                    </a>
                                </li>}
                                {this.props.shopSocials.instagram && <li className="list-inline-item m-2">
                                    <a href={this.props.shopSocials.instagram} target='_blank'>
                                        <i class="fab fa-instagram fa-lg"></i>
                                    </a>
                                </li>}
                            </ul>

                        </div>
                        <div className="col-lg-4 col-md-3 mb-sm--30">
                            <div className="heading mb--32">
                                <h5 className="text-white"><b style={{fontWeight:'bold'}}>Links</b></h5> 
                            </div>
                            <div className="contact-info mb-20 mt-4">
                                <p><small> {this.props.providerName || 'What We Do'}</small> </p>
                                <p> <small>{this.props.providerAddress || 'About Us'}</small></p>
                                <p> <small> {this.props.phoneNumber || 'Contact Us'}</small></p>
                               {/*  <p> <small>{this.props.phoneNumber || 'Terms & Conditions'}</small></p> */} 
                            </div>

                            <ul className="list-inline ">
                                {this.props.shopSocials.facebook && <li className="list-inline-item m-2">
                                    <a href={this.props.shopSocials.facebook} target='_blank'>
                                        <i class="fab fa-facebook-f fa-lg"></i>
                                    </a>
                                </li>}
                                {this.props.shopSocials.twitter && <li className="list-inline-item m-2">
                                    <a href={this.props.shopSocials.twitter} target='_blank'>
                                        <i class="fab fa-twitter fa-lg"></i>
                                    </a>
                                </li>}
                                {this.props.shopSocials.instagram && <li className="list-inline-item m-2">
                                    <a href={this.props.shopSocials.instagram} target='_blank'>
                                        <i class="fab fa-instagram fa-lg"></i>
                                    </a>
                                </li>}
                            </ul>

                        </div>
                        <div className="col-md-4">
                            <div className="heading mb--40">
                                <h5 className="text-white"><b style={{fontWeight:'bold'}}>Send Us A Message</b></h5> 
                            </div>
                            <form className="mt-4" onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label for="customerName"></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        aria-describedby="emailHelp"
                                        placeholder="Please type in your name"
                                        required
                                        style={{  backgroundColor: 'white', color: 'black',}}
                                        name="senderName"
                                        value={this.state.senderName}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="phoneNumber"></label>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        placeholder="Pls type in your phone number e.g +2348056666666"
                                        required
                                        style={{  backgroundColor: 'white', color: 'black', }}
                                        name="senderPhoneNumber"
                                        value={this.state.senderPhoneNumber}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="request"></label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Write your message..."
                                        style={{ height: '130px', backgroundColor: 'white', color: 'black', border: '1px solid white' }}
                                        required
                                        name="message"
                                        value={this.state.message}
                                        onChange={this.onChange}>

                                    </textarea>
                                </div>
                                <div className="row">
                                    <button type="submit" className="btn" style={{ backgroundColor:'white', marginLeft:15}} >SEND REQUEST</button>
                                    <span className="mt-5 ml-5 text-right ">{this.state.msg && <div>
                                        <p className="text-success py-1 px-4 float-right "> Message sent, seller will contact you shortly</p></div>}
                                        {this.state.error && <div><p className="text-danger px-4 py-1 float-right">Oops!, Message not sent</p></div>}</span></div>

                            </form>
                        </div>
                    </div>
                </div>


                {/* <div className="google-map-area">
                            <div id="google-map"></div>
                        </div> */}


            </div>

        )
    }
}
