import React, { Component } from 'react'; 
import { Redirect } from "react-router-dom"

import Navbar from './Navbar' 
import Landing from './Landing'
import Profile from './Profile'
import Cart from '../../../checkout/cartModal'
import Chat from '../../chats/Chat'
import Footer from "./Footer"

//import './App.css'; 


let FullCart = [] 

class PlumberSample extends Component {

  state = {
    nameShop: '',
    descriptionShop: '',
    tagline: '',
    shopBackgroundPicture: '',
    shopCartStatus: '',
    shopChat: '',
    shopCartBankAccount: '',
    shopSocials: {
      facebook: "",
      instagram: "",
      twitter: ""
    },
    postIDs: [],
    modalData: {
      price: 0,
      title: '',
      text: '',
      pictureURL: ''

    },
    logo: '',
    categories: '',
    servicesOffered: '',
    hasloaded: false,
    redirect: false,
    shopNamefromURL: '',
    shopID: '',
    hasCustomDomain: false,
    url: '',
    theme: '',
    HomeData: '',
    FullCart: [],
    cartModalWidth: 0,
    showModalCart: false,
    clickedItems: '',
    selectedItems: '',
    cartShowbankDetails: '',
    cartTotal: ''
  }




  parseURLandReturnRelevantID = async () => {
    try {
      const fullURL = window.location.href.split('#')[0]
      const fullURLsubStrings = fullURL.split('/');
      console.log(fullURLsubStrings);
      fullURLsubStrings.forEach(itemURL => {
        if (fullURLsubStrings.indexOf(`${itemURL}`) === 3) {
          console.log('item url retreived in App.js is:', itemURL, ' and url is ', fullURLsubStrings[2])
          this.setState({ shopNamefromURL: itemURL, url: fullURLsubStrings[2] })
        }
      })
      return
    } catch (err) {
      console.log('error parsing url...')
    }

  }

  filterCategories = () => {

    const keys = Object.keys(this.state.servicesOffered)
    const values = Object.values(this.state.servicesOffered)
    const newvalues = [].concat(...values)

    console.log(' the categories are: ', keys, ' the values are :', newvalues)
    newvalues.forEach(newvalue => {
      console.log('newvalue is: ', newvalue)
      //this.iconSelector(newvalue)

    })
    return (newvalues);

  }


  openAndCloseCartModal = () => {
    this.setState({
      cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
      showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
    })
  }

  updateModalCart = () => {
    this.setState({
      cartModalDetails: {
        FullCart: this.state.FullCart,
        shopNamefromURL: this.props.shopNamefromURL,
        hasCustomDomain: this.props.hasCustomDomain,
        nameShop: this.state.nameShop,
        shopCartBankAccount: this.state.shopCartBankAccount
      }
    })
  }
 

  componentDidMount = async () => {
    

    const categories = {
      Electronics: "../../images/categories/Electronics.png",
      Home: "../../images/categories/Home.png",
      Fashion: "../../images/categories/Fashion.png",
      Cars: "../../images/categories/Cars.png",
      Food: "../../images/categories/Food.png",
      Beauty: "../../images/categories/Beauty.png",
      Professionals: "../../images/categories/Professionals.png",
      Repair_Refill: "../../images/categories/Repair_Refill.png",
      Handyman: "../../images/categories/Handyman.png",
      QuickJobs: "../../images/categories/QuickJobs.png",
      Health: "../../images/categories/Health.png",
      Art: "../../images/categories/Art.png",
      Housing: "../../images/categories/Housing.png",
      Travel: "../../images/categories/Travel.png",
      Commercial: "../../images/categories/Commercial.png"
    }
    await this.setState({ categories });
    


    if (this.props.shopData) {
      console.log('Data received in APP JS is: ', this.props.shopData)
      document.title = this.props.shopData.profile.businessName
      let shopProfile = this.props.shopData.profile
      let shopProfileName = shopProfile.businessName
      let shopProfileDescription = shopProfile.description
      // let shopLogo = shopProfile.pictureURL
      let hasCustomDomain = this.props.hasCustomDomain
      //////////////////////////////////////////////////////////////////////////////

      let providerName = shopProfile.name;
      let providerAddress = shopProfile.locationWorkAddress
      let phoneNumber = shopProfile.phoneNumber
      let servicesOffered = shopProfile.servicesOffered
      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopPost = this.props.shopData.posts


      let shop = this.props.shopData.shop
      console.log('shops', shop)
      let shopLogo = shop.shopLogoPicture
      let tagline = shop.shopSlogan
      let shopID = shop.shopName
      let shopNamefromURL = shop.shopName
      let shopBackgroundPicture = shop.shopBackgroundPicture
      let theme = shop.shopType

      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopColorBackground = shop.shopColorBackground
      let shopColorButton = shop.shopColorButton
      let shopColorHeader = shop.shopColorHeader
      let shopColorTitle = shop.shopColorTitle
      let shopFrontPicture = shop.shopFrontPicture
      let shopHeader = shop.shopHeader
      let shopCartStatus = shop.shopCartStatus
      let shopChat = shop.shopChat
      let shopCartBankAccount = shop.shopCartBankAccount
      let shopSocials = shop.shopSocials
      ////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////
      console.log(' shopNamefromURL from APP JS is: ', shopNamefromURL)

      console.log('shopName is shop ID which is: ', shopID)
      await this.setState({
        nameShop: shopProfileName,
        tagline,
        descriptionShop: shopProfileDescription,
        shopBackgroundPicture,
        shopBackgroundPicture,
        shopColorBackground,
        shopColorButton,
        shopColorHeader,
        shopColorTitle,
        shopFrontPicture,
        shopCartStatus,
        shopChat,
        shopCartBankAccount,
        shopSocials,
        postIDs: shopPost,
        providerName,
        providerAddress,
        phoneNumber,
        servicesOffered,
        logo: shopLogo,
        hasloaded: true,
        url: this.props.url,
        shopNamefromURL,
        shopID,
        hasCustomDomain,
        theme,
        shopHeader
      })
      //  console.log('first postID is: ', this.state.postIDs[0].xID)
      console.log('hascustomDomain in state is: ', this.state.hasCustomDomain)
      this.topFunction()
    }
    /* else {
      
      this.parseURLandReturnRelevantID();

      //this.fetchdataWithURL()

      if (this.state.url === 'wakanda-market.com' || 'www.wakanda-market.com'){
        console.log('the URL seen is: ',this.state.url)
        this.fetchdataWithShopName();
      }else {
        console.log('the URL should go to ',this.state.url) 
        return(<Redirect to='/'/>)
      
      } 
      

    }*/

    //await this.parseURLandReturnRelevantID();


    const servicesOffered = this.filterCategories()
    console.log('Offered services ', servicesOffered)


    //window.addEventListener("scroll", this.handleScroll());

  }



  topFunction = () => {
    window.scrollTo(0, 0)
    return
  }


  render() {
    return (

      <div id="page-container" className="App container-fluid pr-0 pl-0 h-100"
        style={{ backgroundColor: '#222', color: '#f1f1f1', overflowX: 'hidden', width: '100%', top: 0, left: 0, position: 'fixed', scrollBehavior: "smooth" }} >

        <Navbar 
          showCartModal={() => {
            this.openAndCloseCartModal()
            this.updateModalCart()
          }}
          showAbout={true}
          showContact={true}
          nameShop={this.state.nameShop}
          logo={this.state.logo}
          shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
          url={this.state.url}
          showSearch={false}
          shopColorBackground={this.state.shopColorBackground}
          FullCart={this.state.FullCart}
          hasCustomDomain={this.props.hasCustomDomain}
          shopCartStatus={this.state.shopCartStatus}
          shopCartBankAccount={this.state.shopCartBankAccount}
        />


        <section
          style={{
            position: 'relative',
            marginTop: '-125px',
            width: '100%',
            width: '100%',
            overflow: 'hidden'
          }}

          className="headerHeight">
          <div style={{ width: '100%', height: 620, overflowX: 'hidden' }}>
            <div id="overlay"
              style={{
                height: '100%', position: 'absolute', top: 0,
              }}>

            </div>
            <div className="row paral backImage img-fluid"
              style={{
                backgroundImage: `url(${this.state.shopBackgroundPicture})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundAttachment: 'relative',
                overflow: 'hidden',
                height: "auto",
                maxWidth: '104%'
              }}>
              <div class="container">

                <div class="col-xl-6 col-lg-7 text-white mt-4 col-xs-12 col-sm-6 col-sm-offset-2 col-lg-6 col-lg-offset-2 text-sm-left text-center" >

                  <h1 className=" display-4  " style={{ lineHeight: 1.2, fontFamily: "Lato, sans-serif", fontWeight: 700 }}>{this.state.shopHeader || this.state.nameShop}</h1>
                  <p className=" text-left text-sm-nowrap mb-0 text-sm-left text-center" style={{ fontSize: 25, fontFamily: 'Lato', fontWeight: 100 }} ><small>{this.state.tagline || 'Find great products!'}</small></p>
                  <a className=" btn btn-custom2 " href="#cart" style={{ backgroundColor: this.state.shopColorButton }}>Shop Now</a>
                  <a className=" btn btn-custom2 btn-customb2" href="#about" style={{}}>Learn More</a>

                </div>
                {/*  <div class="offer-card text-white">
                <span>from</span>
                <h2>$29</h2>
                <p>SHOP NOW</p>
              </div> */}
              </div>
            </div>
          </div>

          {/* <div class="container">
            <div class="slide-num-holder" id="snh-1"></div>
          </div> */}
        </section>



        {!this.state.showModalCart && <Landing
           
          FullCart={this.state.FullCart}
          selectedItems={this.state.selectedItems}
          clickedItems={this.state.clickedItems}
          shopCartStatus={this.state.shopCartStatus}
          shopCartBankAccount={this.state.shopCartBankAccount}
          shopData={this.props.shopData}
          shopNamefromURL={this.state.shopNamefromURL}
          url={this.props.url}
          nameShop={this.state.nameShop}
          descriptionShop={this.state.descriptionShop}
          postIDs={this.state.postIDs}
          providerName={this.state.providerName}
          providerAddress={this.state.providerAddress}
          phoneNumber={this.state.phoneNumber}
          shopID={this.state.shopID}
          //shopLogo={this.state.logo}
          shopLogo={this.state.logo}
          servicesOffered={this.state.servicesOffered}
          hasloaded={this.state.hasloaded}
          hasCustomDomain={this.props.hasCustomDomain}
          theme={this.state.theme}
          shopColorTitle={this.state.shopColorTitle}
          shopColorBackground={this.state.shopColorBackground}
          shopSocials={this.state.shopSocials}
          shopCartStatus={this.state.shopCartStatus}
          shopChat={this.state.shopChat}
          shopCartBankAccount={this.state.shopCartBankAccount}
          shopSocials={this.state.shopSocials}
        ></Landing>}


        {!this.state.showModalCart && <div style={{ borderTop: '1px solid #efb31f', backgroundColor: '#333', marginTop: '70px', color: '#777', overflowX: 'hidden' }}
          className='container-fluid' id="contact">
          <Profile
            providerName={this.state.providerName}
            providerAddress={this.state.providerAddress}
            phoneNumber={this.state.phoneNumber}
            shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
            shopSocials={this.state.shopSocials}

          ></Profile>
          <div className="text-center my-4">
            <button onClick={() => { this.topFunction(); }} title="Go to top"
              className="p-3 mt-4 mb-4 btn btn-outline-secondary"><i className="fas fa-arrow-up"></i></button>
          </div>
        </div>}

        {(this.state.shopChat.status === "LIVE") && <Chat
          backgroundColor={this.state.shopColorBackground}
          nameShop={this.state.nameShop}
          shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}
        />}
        <div style={{ marginTop: -22 }}>
          <Footer
            nameShop={this.state.nameShop}
          />
        </div>

        <div id='cart-modal' style={{
          width: this.state.cartModalWidth,
          height: 'inherit',
          position: 'absolute',
          right: 0, top: 60,
          backgroundColor: 'white',
          WebkitTransition: 'width 0.5s',
          transition: 'width 0.5s',
          paddingTop: 50,
          paddingBottom: 100
        }}>

          <div onClick={this.openAndCloseCartModal}>
            <h1 style={{ color: 'black', paddingLeft: 50 }}>X</h1> </div>

          {this.state.showModalCart && <div style={{ height: 'inherit', }}>
            <Cart
              CartDetails={this.state.cartModalDetails}
              // cartShowbankDetails={this.state.cartShowbankDetails}
              cartShowbankDetails={true}
              cartTotal={this.state.cartTotal}
              nameShop={this.state.nameShop}
               
            />
          </div>}

        </div>
      </div >

    );
  }

}

export default PlumberSample
