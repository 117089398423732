import React, { Component } from 'react';
import { Redirect } from "react-router-dom"

import Navbar from './Navbar'
import Header from './header'
import Landing from './Landing'
import Profile from './Profile'
import Cart from '../../../checkout/cartModal'
import Chat from '../../chats/Chat'
import About from "./About"
import Footer from "./Footer"
import { openAndCloseCartModal, updateModalCart, updateCart } from '../utilityfunctions'
//import productImage from 'https://images.pexels.com/photos/709841/pexels-photo-709841.jpeg'

//import './App.css'; 



let FullCart = []
let cartStorage = {}
let WM_DATA_Storage = {};
console.log('cartStorage and WM_DATA_Storage initialized are:  ', 'cartStorage: ', JSON.stringify(cartStorage), 'WM_DATA_Storage: ', JSON.stringify(WM_DATA_Storage))

class CFactory extends Component {

  state = {
    nameShop: '',
    descriptionShop: '',
    shopSlogan: '',
    shopSlogan2: '',
    shopBackgroundPicture: '',
    shopBackgroundPicture2: '',
    shopColorHeader: '',
    shopCartStatus: '',
    shopChat: '',
    shopCartBankAccount: '',
    shopSocials: {
      facebook: "",
      instagram: "",
      twitter: ""
    },
    postIDs: [],
    modalData: {
      price: 0,
      title: '',
      text: '',
      pictureURL: ''

    },
    logo: '',
    categories: '',
    servicesOffered: '',
    hasloaded: false,
    redirect: false,
    shopNamefromURL: '',
    shopID: '',
    hasCustomDomain: false,
    url: '',
    theme: '',
    HomeData: '',
    FullCart: [],
    cartModalWidth: 0,
    showModalCart: false,
    clickedItems: '',
    selectedItems: '',
    cartShowbankDetails: '',
    cartTotal: '',
    shopInitials: '',
    color: ''
  }





  filterCategories = () => {

    const keys = Object.keys(this.state.servicesOffered)
    const values = Object.values(this.state.servicesOffered)
    const newvalues = [].concat(...values)

    console.log(' the categories are: ', keys, ' the values are :', newvalues)
    newvalues.forEach(newvalue => {
      console.log('newvalue is: ', newvalue)
      //this.iconSelector(newvalue)

    })
    return (newvalues);

  }




  componentWillMount() {
    console.log('firing component will mount in Cake Factory.js')
    if (JSON.parse(localStorage.getItem("WM_DATA_Storage"))) {
      WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
      cartStorage = WM_DATA_Storage.FullCart
    } else {

      cartStorage = []

    }

    localStorage.setItem("cartStorage", JSON.stringify(cartStorage))
    this.setState({ FullCart: cartStorage })


  }




  componentDidMount = async () => {


    const categories = {
      Electronics: "../../images/categories/Electronics.png",
      Home: "../../images/categories/Home.png",
      Fashion: "../../images/categories/Fashion.png",
      Cars: "../../images/categories/Cars.png",
      Food: "../../images/categories/Food.png",
      Beauty: "../../images/categories/Beauty.png",
      Professionals: "../../images/categories/Professionals.png",
      Repair_Refill: "../../images/categories/Repair_Refill.png",
      Handyman: "../../images/categories/Handyman.png",
      QuickJobs: "../../images/categories/QuickJobs.png",
      Health: "../../images/categories/Health.png",
      Art: "../../images/categories/Art.png",
      Housing: "../../images/categories/Housing.png",
      Travel: "../../images/categories/Travel.png",
      Commercial: "../../images/categories/Commercial.png"
    }
    await this.setState({ categories });



    if (this.props.shopData) {
      console.log('Data received in Cake Factory JS is: ', this.props.shopData)
      document.title = this.props.shopData.profile.businessName
      let shopProfile = this.props.shopData.profile
      let shopProfileName = shopProfile.businessName
      let shopProfileDescription = shopProfile.description
      // let shopLogo = shopProfile.pictureURL
      let hasCustomDomain = this.props.hasCustomDomain
      //////////////////////////////////////////////////////////////////////////////

      let providerName = shopProfile.name;
      let providerAddress = shopProfile.locationWorkAddress
      let phoneNumber = shopProfile.phoneNumber
      let servicesOffered = shopProfile.servicesOffered
      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopPost = this.props.shopData.posts


      let shop = this.props.shopData.shop
      console.log('shops', shop)
      let shopLogo = shop.shopLogoPicture
      let shopSlogan = shop.shopSlogan
      let shopSlogan2 = shop.shopSlogan2
      let shopID = shop.shopName
      let shopNamefromURL = shop.shopName
      let shopBackgroundPicture = shop.shopBackgroundPicture
      let shopBackgroundPicture2 = shop.shopBackgroundPicture2
      let theme = shop.shopType

      ////////////////////////////////////////////////////////////////////////////////////////////
      let shopColorBackground = shop.shopColorBackground
      let shopColorButton = shop.shopColorButton
      let shopColorHeader = shop.shopColorHeader
      let shopColorTitle = shop.shopColorTitle
      let shopFrontPicture = shop.shopFrontPicture
      let shopHeader = shop.shopHeader
      let shopHeader2 = shop.shopHeader2
      let shopCartStatus = shop.shopCartStatus
      let shopChat = shop.shopChat
      let shopCartBankAccount = shop.shopCartBankAccount
      let shopSocials = shop.shopSocials
      ////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////
      console.log(' shopNamefromURL from Cake Factory JS is: ', shopNamefromURL)

      console.log('shopName is shop ID which is: ', shopID)
      await this.setState({
        nameShop: shopProfileName,
        shopSlogan,
        shopSlogan2,
        descriptionShop: shopProfileDescription,
        shopBackgroundPicture,
        shopBackgroundPicture2,
        shopColorHeader,
        shopColorBackground,
        shopColorButton,
        shopColorHeader,
        shopColorTitle,
        shopFrontPicture,
        shopCartStatus,
        shopChat,
        shopCartBankAccount,
        shopSocials,
        postIDs: shopPost,
        providerName,
        providerAddress,
        phoneNumber,
        servicesOffered,
        logo: shopLogo,
        hasloaded: true,
        url: this.props.url,
        shopNamefromURL,
        shopID,
        hasCustomDomain,
        theme,
        shopHeader,
        shopHeader2,
        FullCart: this.props.FullCart,
        clickedItems: this.props.clickedItems
      })
      //  console.log('first postID is: ', this.state.postIDs[0].xID)
      console.log('hascustomDomain in state is: ', this.state.hasCustomDomain)
      this.topFunction()

      if (this.props.clickedItems) {

        let cart = []
        let posts = this.state.postIDs
        let keys = Object.keys(this.props.clickedItems)
        keys.forEach(key => {
          if (this.props.clickedItems[key] === 'clicked') {
            posts.find(post => {
              if (post.xID === key) {
                cart.push(post)
              }
            })
          }
        })
        console.log('cart generated from clicked items is: ', cart)
        this.setState({ FullCart: cart })
      }

    }



    const servicesOffered = this.filterCategories()
    console.log('Offered services ', servicesOffered)


    //window.addEventListener("scroll", this.handleScroll());

  }



  topFunction = () => {
    window.scrollTo(0, 0)
    return
  }


  render() {
    return (

      <div id="page-container" className="App container-fluid pr-0 pl-0 w-100 h-100"
        style={{ backgroundColor: '#fff', overflowX: 'hidden', width: '100%', top: 0, left: 0, position: 'fixed', scrollBehavior: "smooth" }} >

        {this.state.nameShop && <Navbar
          showCartModal={async () => {
            let result = openAndCloseCartModal(this.state.cartModalWidth, this.state.showModalCart)
            this.setState({ cartModalWidth: result.cartModalWidth, showModalCart: result.showModalCart })
            await this.setState({ cartModalDetails: updateModalCart(this.state.FullCart, this.state.shopNamefromURL, this.state.hasCustomDomain, this.state.nameShop, this.state.shopCartBankAccount) })
            console.log('the this.state.cartModalDetails received is: ', this.state.cartModalDetails)
          }}
          showAbout={true}
          showContact={true}
          nameShop={this.state.nameShop}
          logo={this.state.logo}
          shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
          url={this.state.url}
          showSearch={false}
          shopColorBackground={this.state.shopColorBackground}
          FullCart={this.state.FullCart || []}
          hasCustomDomain={this.props.hasCustomDomain}
          shopCartStatus={this.state.shopCartStatus}
          shopCartBankAccount={this.state.shopCartBankAccount}
          shopColorHeader={this.state.shopColorHeader}
        />}

     


        {!this.state.showModalCart && <Header
          nameShop={this.state.nameShop}
          shopHeader={this.state.shopHeader}
          shopHeader2={this.state.shopHeader2}
          shopSlogan={this.state.shopSlogan}
          shopSlogan2={this.state.shopSlogan2}
          shopBackgroundPicture={this.state.shopBackgroundPicture}
          shopBackgroundPicture2={this.state.shopBackgroundPicture2}
          shopColorHeader={this.state.shopColorHeader}
        />}



        {!this.state.showModalCart && <Landing

          updateCart={async (FullCart) => {
            updateCart(FullCart)
            await this.setState({ FullCart })
            console.log('FullCart is...', this.state.FullCart)
          }}
          saveProductData={(clicked, selected) => {
            this.setState({ clickedItems: clicked, selectedItems: selected })
          }}

          FullCart={this.state.FullCart}
          selectedItems={this.state.selectedItems}
          clickedItems={this.state.clickedItems || {}}
          shopCartStatus={this.state.shopCartStatus}
          shopCartBankAccount={this.state.shopCartBankAccount}
          shopData={this.props.shopData}
          shopNamefromURL={this.state.shopNamefromURL}
          url={this.props.url}
          nameShop={this.state.nameShop}
          descriptionShop={this.state.descriptionShop}
          postIDs={this.state.postIDs}
          //postIDs={offers}
          providerName={this.state.providerName}
          providerAddress={this.state.providerAddress}
          phoneNumber={this.state.phoneNumber}
          shopID={this.state.shopID}
          //shopLogo={this.state.logo}
          shopLogo={this.state.logo}
          servicesOffered={this.state.servicesOffered}
          hasloaded={this.state.hasloaded}
          hasCustomDomain={this.props.hasCustomDomain}
          theme={this.state.theme}
          shopColorTitle={this.state.shopColorTitle}
          shopColorBackground={this.state.shopColorBackground}
          shopSocials={this.state.shopSocials}
          shopCartStatus={this.state.shopCartStatus}
          shopChat={this.state.shopChat}
          shopCartBankAccount={this.state.shopCartBankAccount}
          shopSocials={this.state.shopSocials}

          offers={this.offers}

        ></Landing>}

        {!this.state.showModalCart && <div id="about">
          <div style={{ width: '100%', marginTop: 50 }}>
            <About
              descriptionShop={this.state.descriptionShop}
              //categories={this.props.categories}
              servicesOffered={this.props.servicesOffered}
              hasloaded={this.props.hasloaded}
              shopBackgroundPicture={this.state.shopBackgroundPicture}
              shopColorHeader={this.state.shopColorHeader}
            />
          </div>
        </div>}


        {!this.state.showModalCart && this.state.shopColorHeader &&
          <Profile
            providerName={this.state.providerName}
            providerAddress={this.state.providerAddress}
            phoneNumber={this.state.phoneNumber}
            shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
            shopSocials={this.state.shopSocials}
            shopColorHeader={this.state.shopColorHeader}
          ></Profile>

        }


        {(this.state.shopChat.status === "LIVE") && <Chat
          backgroundColor={this.state.shopColorHeader|| '#f970b9'}
          nameShop={this.state.nameShop}
          shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}

        />}

        {!this.state.showModalCart && <div >
          <Footer
            nameShop={this.state.nameShop}
          />
        </div>}

        <div id='cart-modal' style={{
          width: this.state.cartModalWidth,
          height: 'inherit',
          position: 'absolute',
          right: 0, top: 60,
          backgroundColor: 'white',
          WebkitTransition: 'width 0.5s',
          transition: 'width 0.5s',
          paddingTop: 50,
          paddingBottom: 100
        }}>

          <div onClick={() => {
            let result = openAndCloseCartModal(this.state.cartModalWidth, this.state.showModalCart)
            this.setState({ cartModalWidth: result.cartModalWidth, showModalCart: result.showModalCart })
          }}>
            <h1 style={{ color: 'black', paddingLeft: 50 }}>X</h1> </div>

          {this.state.showModalCart && <div style={{ height: 'inherit', }}>
            <Cart
              CartDetails={this.state.cartModalDetails}
              // cartShowbankDetails={this.state.cartShowbankDetails}
              cartShowbankDetails={this.state.cartShowbankDetails}
              cartTotal={this.state.cartTotal}
              nameShop={this.state.nameShop}
              updateCart={async (FullCart) => {

                await this.setState({ FullCart: updateCart(FullCart) })
                console.log('FullCart is...', this.state.FullCart)
              }}


              clearLocalStorageCart={() => {
                WM_DATA_Storage.FullCart = ''
                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                localStorage.setItem("cartStorage", "");
                this.setState({ FullCart: '' })
              }}

              storeCartDetails={(a, b) => {
                this.setState({ cartShowbankDetails: a, cartTotal: b })
              }}
            />
          </div>}

        </div>
      </div >

    );
  }

}

export default CFactory
