import React, { Component } from 'react'

//importing category image icons
/* import Electronics from "../../images/categories/Electronics.png"
import Home from "../../images/categories/Home.png"
import Fashion from "../../images/categories/Fashion.png"
import Cars from "../../images/categories/Cars.png"
import Food from "../../images/categories/Food.png"
import Beauty from "../../images/categories/Beauty.png"
import Professionals from "../../images/categories/Professionals.png"
import Repair_Refill from "../../images/categories/Repair_Refill.png"
import Handyman from "../../images/categories/Handyman.png"
import QuickJobs from "../../images/categories/QuickJobs.png"
import Health from "../../images/categories/Health.png"
import Art from "../../images/categories/Art.png"
import Housing from "../../images/categories/Housing.png"
import Travel from "../../images/categories/Travel.png"
import Commercial from "../../images/categories/Commercial.png" */

export default class About extends Component {
    state = {
        services: '',
        categories: '',
        image1: '',
        image2: '',
        URLs: [],
        activeCategories: []
    }






    filterCategories = async () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        // const values1 = values.slice(0,values.length)
        // const values2 = values1.join(' ')
        const newvalues = [].concat(...values)

        console.log(' the categories are: ', keys, ' and the services are: ', values)
        await this.setState({ categories: keys, services: newvalues });
        return
    }

    activeCategories = (services) => {
        const validCategories = []
        this.state.categories.forEach((category) => {
            if (services[category].length > 0) {
                validCategories.push(category)
            }
        })
        console.log('All categories are: ', services)
        console.log('the valid categories are: ', validCategories)
        this.setState({ activeCategories: validCategories })
    }


    isActiveCategory = (category) => {
        let itemValue = false
        this.state.activeCategories.forEach(item => {
            if (category === item) {
                itemValue = true
            }
        })
        return itemValue
    }


    componentWillMount = async () => {

        if (this.props.servicesOffered) {

            await this.filterCategories();
            this.activeCategories(this.props.servicesOffered)

        }

    }



    render() {

        return (

            <div className='container' style={{ width: '100%' }}>
                <div className="mx-auto text-center white  "  >
                    <h2 className="text-center mb-4" style={{ color: 'blue', }}>What We Do</h2>
                    <hr style={{ align: "center", width: 160, margin: "auto", color: 'grey' }} />
                    <p style={{ color: 'black', marginTop: 50 }}>{this.props.descriptionShop || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "}.</p>
                </div> 
                <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                    <h2 className="text-center" style={{ color: '#bbb', fontSize: 20 }}>We Deliver Great Services in</h2>
                    {this.state.categories && <div className="row " style={{ alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                        {this.state.activeCategories.map((category, id) => {
                            return (
                                <div className="mx-1 row" key={id} style={{ backgroundColor: '#222', borderColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="m-3 col" style={{ width: '5rem', justifyContent: 'center', alignItems: 'center' }}>
                                        {/* {(this.props.servicesOffered[category].length > 0) && this.isActiveCategory(category)&& <img className="card-img-top img-fluid" src={require('../../images/categories/' + `${category}` + '.png')} />} */}
                                        {<img className="card-img-top img-fluid" src={require('../../../images/categories/' + `${category}` + '.png' || '../../images/categories/Commercial.png')} />}

                                    </div>
                                    <div className='col m-3' style={{ width: '18rem', backgroundColor: '#222', borderColor: 'white', alignItems: 'center' }}>
                                        {this.props.servicesOffered[category].map((service, id) => {
                                            return (
                                                <p style={{ lineHeight: 1, fontSize:15 }} key={id}>{service + '\n'} </p>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    }

                </div>






            </div>
        )
    }
}