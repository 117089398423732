import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Profile from './Profile' 
import Cart from '../../../checkout/cartModal'
import { Redirect } from 'react-router-dom'

import leftArrow from '../../../assets/left-arrow.svg'
 
class ProductPage extends Component {

    state = {
        product: {
            title: '',
            text: '',
            price: '',
            pictureURL: '',
            currency: ''
        },
        description: '',
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        servicesOffered: '',
        postIDs: '',
        xID: '',
        shopNamefromURL: '',
        nameShop: '',
        shopLogo: '',
        url: '',
        shopID: '',
        redirect: false,
        productExists: '',
        loading: true,
        theme: '',
        shopColorTitle: '',
        shopColorBackground: '',
        shopColorButton: '',
        shopColorHeader: '',
        shopColorTitle: '',
        shopFrontPicture: '',
        HomePageData: '',
        hasCustomDomain: '',
        shopSocials: '',
        logo: '',
        shopLogo:'',
        servicesOffered: '',
        shopCartStatus: '',
        shopCartBankAccount: '',
        shopChat: '',
        shopNamefromURL: '',
        tagline: '',
        FullCart: '',
        cartModalDetails: {
            FullCart: '',
            shopNamefromURL: '',
            hasCustomDomain: '',
            nameShop: '',
            shopCartBankAccount: ''
        },
        cartModalWidth: 0,
        showModalCart: false
    }






    parseURLandReturnRelevantID = () => {
        const fullURL = window.location.href.split('#')[0]
        const fullURLsubStrings = fullURL.split('/');
        console.log(fullURLsubStrings);
        fullURLsubStrings.forEach(itemURL => {
            if (itemURL.length == 20 && fullURLsubStrings.indexOf(`${itemURL}`) == 4) {
                console.log('item url is:', itemURL)
                if (fullURLsubStrings[3] === 'shop') {
                    this.setState({ xID: itemURL, shopNamefromURL: '', url: fullURLsubStrings[2] })
                }
                else {
                    this.setState({ xID: itemURL, shopNamefromURL: fullURLsubStrings[3], url: fullURLsubStrings[2] })
                }


            }
        })
        return
    }

    chooseHomeURL = () => {
        if (this.state.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.state.shopNamefromURL
        }
    }

    fetchProduct = async () => {
        const xID = this.state.xID
        console.log('the id passsed to fetchproduct method is: ', this.state.xID, 'postIDs are: ', this.state.postIDs)
        //const post =
        await this.state.postIDs.filter(postID => {
            if (postID.xID === xID) {
                this.setState({
                    product: {
                        title: postID.title,
                        text: postID.text,
                        price: this.displayprice(postID.price, postID.priceType, postID.priceCurrency),
                        pictureURL: postID.pictureURL,
                    },
                    productExists: true,
                    loading: false
                })
            }
        });
        if (this.state.productExists === '') {
            this.setState({ productExists: false })
        }
        //console.log('the fetched product is: ', post)
    }


    fetchdataWithShopName = async () => {

        try {
            const r = await fetch("https://us-central1-afbase-7ee60.cloudfunctions.net/shop", {
                method: "POST",
                //mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify({
                    shopName: `${this.state.shopNamefromURL}`
                })
            });
            const r2 = await r.json();
            console.log("Received response using shopnamefromurl", this.state.shopNamefromURL, ' is: ', r2);
            if (r2.msg === 'SUCCESS') {

                let HomePageData = r2.data
                console.log("Profile ", r2.data.profile);
                let shopProfile = r2.data.profile
                let shopProfileName = shopProfile.businessName
                let description = shopProfile.description
                let servicesOffered = shopProfile.servicesOffered
                //////////////////////////////////////////////////////////////////////////////

                let providerName = shopProfile.name;
                let providerAddress = shopProfile.locationWorkAddress
                let phoneNumber = shopProfile.phoneNumber
                let theme = r2.data.shop.shopType
                //let shopLogo = shopProfile.pictureURL

                ////////////////////////////////////////////////////////////////////////////////////////////
                let shopPost = r2.data.posts

                //console.log('shopPost', shopPost)
                ////////////////////////////////////////////////////////////////////////////////////////////
                let shop = r2.data.shop
                let shopLogo = shop.shopLogoPicture
                let shopID = shop.shopName
                let shopColorBackground = shop.shopColorBackground
                let shopColorButton = shop.shopColorButton
                let shopColorHeader = shop.shopColorHeader
                let shopColorTitle = shop.shopColorTitle
                let shopFrontPicture = shop.shopFrontPicture
                let shopSocials = shop.shopSocials
                ///////////////////////////////////////////////////////////////////////////////////////////

                let tagline = shop.shopSlogan
                let shopNamefromURL = shop.shopName
                let shopCartStatus = shop.shopCartStatus
                let shopChat = shop.shopChat
                let shopCartBankAccount = shop.shopCartBankAccount

                //////////////////////////////////////////////////////////////////////////////////// 





                await this.setState({
                    nameShop: shopProfileName,
                    description, postIDs: shopPost, providerName,
                    providerAddress, phoneNumber, servicesOffered,
                    theme, shopColorBackground, shopColorButton,
                    shopColorHeader, shopColorTitle, shopFrontPicture,
                    shopLogo, HomePageData, shopID, shopSocials,
                    logo: shopLogo, servicesOffered, shopCartStatus,
                    shopCartBankAccount, shopChat, shopNamefromURL, tagline

                })
                // console.log('first postID is: ', this.state.postIDs[0].xID)
                document.title = this.state.nameShop || this.state.shopID
                this.fetchProduct()

            } else {
                console.log('product data not loading...')
                //this.setState({ productExists: false })
            }


        } catch (err) {
            console.log("Error", err);
            //this.setState({ redirect: true })
        }

    }
 





    openAndCloseCartModal = () => {
        this.setState({
            cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
            showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
        })
    }

    updateModalCart = () => {
        this.setState({
            cartModalDetails: {
                FullCart: this.state.FullCart,
                shopNamefromURL: this.state.shopNamefromURL,
                hasCustomDomain: this.state.hasCustomDomain,
                nameShop: this.state.nameShop,
                shopCartBankAccount: this.state.shopCartBankAccount
            }
        })
    }







    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + " " + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + " " + pString;
            }
            else {
                priceString =
                    currency + " " + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = " ";
            }
            return priceString
        }
        //  return pString
    }

    topFunction = () => {
        window.scrollTo(0, 0)
        return
    }

    goBack = () => {
        this.props.history.push(this.chooseHomeURL())
    }


    componentDidMount = async () => {
        

        if (this.props.location.detailsProp == null || undefined) {

            await this.parseURLandReturnRelevantID();

            if (this.state.url !== 'www.wakanda-market.com' && this.state.url !== 'wakanda-market.com' && this.state.url !== 'localhost:3000') {
                this.fetchdataWithURL();
                this.fetchdataWithShopName();
                document.title = this.state.nameShop || this.state.shopID
            }
            else {
                this.fetchdataWithShopName();

            }


        } else {


            const { hasCustomDomain, title, text, price, pictureURL, providerName, providerAddress, 
                phoneNumber, shopNamefromURL, url, nameShop, servicesOffered, theme, shopColorTitle, 
                shopColorBackground, shopColorButton, shopLogo, shopData, description, currency, shopSocials, 
                shopCartStatus, shopChat, shopCartBankAccount, FullCart } = this.props.location.detailsProp

            await this.setState({
                providerName, providerAddress, phoneNumber, shopNamefromURL, 
                url, nameShop, description, theme, servicesOffered, shopColorTitle, 
                shopColorBackground, shopColorButton, logo:shopLogo, product: {
                    title, text, price, pictureURL, currency
                },
                productExists: true,
                HomePageData: shopData,
                hasCustomDomain,
                shopSocials, shopCartStatus, shopChat, shopCartBankAccount, FullCart
            })
            this.topFunction()
        }

        console.log('data received on product page is: ', this.props.location.detailsProp)
    }


    render() {
        return (
            <div>
                {this.state.redirect && <Redirect to='/not-found' />}
                {!this.state.redirect && this.state.theme === 'Standard' && <div id="page-container"
                    className="App container-fluid pr-0 pl-0 h-100 d-flex flex-column"
                    style={{ backgroundColor: 'white' }}
                >
                    <Navbar
                        showCartModal={() => {
                            this.openAndCloseCartModal()
                            this.updateModalCart()
                        }}
                        showAbout={true}
                        showContact={true}
                        nameShop={this.state.nameShop}
                        logo={this.state.logo}
                        shopNamefromURL={(this.props.hasCustomDomain || this.state.hasCustomDomain) ? '' : this.state.shopNamefromURL}
                        url={this.state.url}
                        showSearch={false}
                        shopColorBackground={this.state.shopColorBackground}
                        FullCart={this.state.FullCart}
                        hasCustomDomain={this.props.hasCustomDomain}
                        shopCartStatus={this.state.shopCartStatus}
                        shopCartBankAccount={this.state.shopCartBankAccount}
                    />

                    <div className="container mb-4"> <img src={leftArrow} onClick={this.goBack} style={{ width: 40, height: 40, cursor: 'pointer' }} /></div>
                    <div className=" mx-auto container row">

                        {this.state.productExists === false && this.state.loading && <div style={{ height: '200px' }} >
                            <h2 >Oops! :'( Product Does Not Exist!</h2>
                            <p>Please contact the provider for a valid product link</p>
                        </div>
                        }
                        <div className="col-md-6">


                            {this.state.product.pictureURL &&
                                <div style={{ justifyContent: 'center' }} className="mb-4">
                                    <img src={this.state.product.pictureURL} alt="picture"
                                        className="img-fluid img-thumbnail" />
                                </div>}
                            {this.state.loading && this.state.productExists === '' &&

                                <p className="lead">Product loading...</p>
                            }


                        </div>

                        <div class=" mr-md-3 pt-3 px-3 pt-md-5 px-md-5 overflow-hidden col-md-5" >
                            <div class="my-3 py-3">

                                <h3 style={{ color: 'black' }} ><b>{this.state.product.title}</b></h3>
                                <h6 style={{ color: '#888', fontSize: 18 }}  >{this.state.product.text}</h6>
                                <hr />
                                <h5 style={{ color: 'grey', marginTop: 10 }}>{this.state.product.price}</h5>

                            </div>
                        </div>
                    </div>


                    {!this.state.showModalCart && <section style={{ backgroundColor: '#333', color: '#777', fontWeight: 'normal' }}>
                        <div style={{ borderTop: '1px solid #efb31f', marginTop: '-100px' }} className='container-fluid' id="contact">
                            <Profile
                                providerName={this.state.providerName}
                                providerAddress={this.state.providerAddress}
                                phoneNumber={this.state.phoneNumber}
                                shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                                shopSocials={this.state.shopSocials}
                            />
                        </div>
                    </section>}
                    {!this.state.showModalCart && <Footer shopSocials={this.state.shopSocials}
                        nameShop={this.state.nameShop ? this.state.nameShop : 'wakanda'} /> }
                {/* -----------------------------  GENERAL CART -------------------------------- */}
                <div id='cart-modal' style={{
                    width: this.state.cartModalWidth,
                    height: 'inherit',
                    position: 'absolute',
                    right: 0, top: 60,
                    backgroundColor: 'white',
                    WebkitTransition: 'width 0.5s',
                    transition: 'width 0.5s',
                    paddingTop: 50,
                    paddingBottom: 100
                }}>

                    <div onClick={this.openAndCloseCartModal}>
                        <h1 style={{ color: 'black', paddingLeft: 50 }}>X</h1> </div>

                    {this.state.showModalCart && <div style={{ height: 'inherit', }}>
                        <Cart
                            CartDetails={this.state.cartModalDetails}
                            // cartShowbankDetails={this.state.cartShowbankDetails}
                            cartShowbankDetails={true}
                            cartTotal={this.state.cartTotal}
                            FullCart={this.state.FullCart}
                             
                        />
                    </div>}

                </div>
                </div>}
               
            </div>
        );
    }
}

export default ProductPage;