import React, { Component } from "react";
import { Link } from "react-router-dom";
import Productpage from "./ProductPage";
import About from "./About";
//import shopimage from '../../images/images/shopimage.jpg'
//import cabinet from '../../images/images/cabinet.jpg'
import Modal from "./Modal";
import {getAllProductImages} from '../utilityfunctions'

let shop = 'shop'

let clickedItems = {}

let productImages = {};

class Landing extends Component {
  state = {
    nameShop: "",
    descriptionShop: "",
    postIDs: [],
    modalData: {
      price: 0,
      title: "",
      text: "",
      pictureURL: ""
    },
    shopNamefromURL: "",
    shopID: "",
    clickedItems: {},
    selectedItems: []
  };

  displayprice = (price, type, currency) => {
    if (currency == "NGN") {
      currency = "N";
    }
    if (currency == "GHS") {
      currency = "C";
    }

    let pString = Math.round(price).toString();
    if (pString.length > 8) {
      pString = pString.slice(0, pString.length - 6) + "M";
    } else if (pString.length > 6) {
      pString = Number(pString.slice(0, pString.length - 5)) / 10;
      pString = pString.toString() + "M";
    } else if (pString.length > 4) {
      pString = pString.slice(0, pString.length - 3) + "K";
    }

    let priceString = pString;
    if (type) {
      if (type == "Starting From") {
        priceString = "Starting From " + currency + " " + pString;
      } else if (type == "Fixed Price") {
        priceString = currency + " " + pString;
      } else {
        priceString = currency + " " + pString + " (" + type + ")";
      }
      if (type == "Free") {
        priceString = "FREE";
      }
      if (type == "NONE") {
        priceString = " ";
      }
      return priceString;
    }
    //  return pString
  };

  selectItem = async (postID) => {
    //let selectedItems = this.state.selectedItems
    let selectedItems = this.props.FullCart || []
    
        let clickedItems = this.props.clickedItems;

        if (!clickedItems[postID.xID] || clickedItems[postID.xID] === 'unclicked') {
            clickedItems[postID.xID] = 'clicked'
            selectedItems.push(postID)
            this.setState({ selectedItems, clickedItems })
        } else {
            clickedItems[postID.xID] = 'unclicked'
            //selectedItems.pop(postID)
            var filtered = selectedItems.filter(function(value, index, arr){

                return value !== postID;
            
            });
            selectedItems = filtered
            this.setState({ selectedItems: filtered, clickedItems })
        }
        //clickedItems = localStorage.getItem("clickedItems") 
        localStorage.setItem("clickedItems", JSON.stringify(clickedItems))
         
    
    console.log(' item clicked is; ', this.state.clickedItems, 'selected items are ', this.state.selectedItems)

    this.props.updateCart(selectedItems)
}


  componentWillUnmount() {
    this.props.saveProductData(
      this.state.clickedItems,
      this.state.selectedItems
    );
  }

  componentWillMount = () => {
    clickedItems = JSON.parse(localStorage.getItem("clickedItems"))

    if (clickedItems) {
         
            
            let cart = this.props.FullCart || []
            let posts = this.state.postIDs
            let keys = Object.keys(clickedItems)
            keys.forEach(key => {
                if (clickedItems[key] === 'clicked') {
                    posts.find(post => {
                        if (post.xID === key){
                            cart.push(post)
                        }
                    })
                }
            })
            console.log('cart in Landing JS componentWillMount is: ', cart, 'clicked items stored are; ', clickedItems)
            this.setState({ selectedItems:cart, clickedItems}) 
    }
    if(this.props.selectedItems){
        this.setState({selectedItems:this.props.selectedItems, clickedItems: this.props.clickedItems})
    }
}

  render() {
    //console.log('hascustomDomain in landing is: ', this.props.hasCustomDomain)
    return (
      <div>
        <div id="about">
          <div className="container" style={{ width: "100%", marginTop: 50 }}>
            {this.props.servicesOffered && (
              <About
                descriptionShop={this.props.descriptionShop}
                //categories={this.props.categories}
                servicesOffered={this.props.servicesOffered}
                hasloaded={this.props.hasloaded}
              />
            )}
          </div>
        </div>

        <Modal
          price={this.state.modalData.price}
          pictureURL={this.state.modalData.pictureURL}
          title={this.state.modalData.title}
          text={this.state.modalData.text}
          images={this.state.modalData.images}
        ></Modal>

        <div id="cart" style={{}} className="container-fluid">
          <h2
            className="text-center mb-4 font-weight-bold"
            style={{ color: "#000000", marginTop: 100 }}
          >
            All Offers
          </h2>
          {/* <hr style={{ align: "center", width: 160, margin: "auto", color: '#efb31f' }} /> */}

          <div
            className="row "
            style={{
              paddingTop: "40px",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {this.props.postIDs.map((postID, id) => {
              if(Object.keys(productImages).length !== this.props.postIDs.length){
                let result ={}
                result = getAllProductImages(postID)
                productImages = { ...productImages, ...result}
                console.log(' productimages are ...', productImages)
            }
              return (
                <div
                  className="zoom-effect-container cf-card shadow-white"
                  key={id}
                >
                  <div
                    className="card image-card my-3 "
                    style={{
                      backgroundColor: "#fff",
                      border: "",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <a
                      data-toggle="modal"
                      data-target="#myModal"
                      style={{ marginLeft: -11 }}
                      onClick={() => {
                        this.setState({
                          modalData: {
                            price:
                              this.displayprice(
                                postID.price,
                                postID.priceType,
                                postID.priceCurrency
                              ) || postID.price,
                            pictureURL: postID.pictureURL,
                            title: postID.title,
                            text: postID.text,
                            images:productImages[postID.xID]
                          }
                        });
                      }}
                    >
                      <div
                        style={{
                          height: 150,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          backgroundImage: `url(${postID.pictureURL})`
                        }}
                      ></div>
                    </a>

                    <p className="card-title text-truncate mb-0 pb-0 mt-0 pb-1 mx-2">
                      <Link
                        to={{
                          pathname: `/${
                            this.props.hasCustomDomain
                              ? shop
                              : this.props.shopNamefromURL
                          }/${postID.xID}`,
                          // pathname: '/productpage/',
                          detailsProp: {
                            title: postID.title,
                            text: postID.text,
                            price:
                              this.displayprice(
                                postID.price,
                                postID.priceType,
                                postID.priceCurrency
                              ) || postID.price,
                            pictureURL: postID.pictureURL,
                            providerName: this.props.providerName,
                            providerAddress: this.props.providerAddress,
                            phoneNumber: this.props.phoneNumber,
                            shopNamefromURL: this.props.shopNamefromURL,
                            nameShop: this.props.nameShop,
                            url: this.props.url,
                            theme: this.props.theme,
                            shopColorTitle: this.props.shopColorTitle,
                            shopColorBackground: this.props.shopColorBackground,
                            shopLogo: this.props.shopLogo,
                            hasCustomDomain: this.props.hasCustomDomain,
                            shopSocials: this.props.shopSocials,
                            shopCartStatus: this.props.shopCartStatus,
                            shopChat: this.props.shopChat,
                            shopCartBankAccount: this.props.shopCartBankAccount,
                            FullCart: this.props.FullCart,
                            xID:postID.xID,
                            productImages:productImages
                            
                            //shopData:this.props.shopData
                          }
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: 0,
                            paddingBottom: 0,
                            marginTop: 2
                          }}
                        >
                          {" "}
                          <b style={{ color: "black", textAlign: "center" }}>
                            {postID.title}
                          </b>
                        </p>
                      </Link>
                    </p>

                    <p
                      className="card-text text-center font-weight-bold pt-0 mt-0 "
                      style={{
                        overflow: "hidden",
                        color: this.props.shopColorBackground || "#CE0930"
                      }}
                    >
                      {this.displayprice(
                        postID.price,
                        postID.priceType,
                        postID.priceCurrency
                      ) || postID.price}
                    </p>

                    {
                      <button
                        onClick={() => this.selectItem(postID)}
                        style={{
                          borderColor: "#efb31f",
                          // borderColor: this.props.shopColorTitle || '#efb31f',
                          color:
                            this.state.clickedItems[postID.xID] === "clicked"
                              ? "black"
                              : "#fff",
                          backgroundColor:
                            this.state.clickedItems[postID.xID] === "clicked"
                              ? this.props.shopColorBackground
                              : "#000"
                          //color: this.props.shopColorTitle || '#efb31f'
                        }}
                      >
                        {" "}
                        {this.props.shopCartStatus === "LIVE" && (
                          <i
                            className={
                              this.state.clickedItems[postID.xID] === "clicked"
                                ? "fa fa-heart"
                                : "far fa-heart "
                            }
                            style={{ color: "#fff" }}
                          ></i>
                        )}
                        {this.state.clickedItems[postID.xID] === "clicked"
                          ? "ADDED TO CART"
                          : "ADD TO CART"}
                      </button>
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
