import React, { Component } from 'react'
import stylez from './stylesheets/modaz.module.css'

export class Contact extends Component {

    state = {
        phoneNumber: '',
        senderName: '',
        providerName: '',
        shopProfileName: '',
        descriptionShop: '',
        services: [],
        address: '',
        phoneNumber: '',
        senderName: '',
        shopName: '',
        msg: false,
        error: false,
        categories: '',
        services: '',
        loading: ''
    }


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })

        console.log('Username' + this.state.senderName)

        const newMessage = {
            shopName: this.props.shopNamefromURL,
            senderName: this.state.senderName,
            senderPhoneNumber: this.state.senderPhoneNumber,
            message: this.state.message
        }
        console.log('new', newMessage)
        const url = "https://us-central1-afbase-7ee60.cloudfunctions.net/shopMessage"
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newMessage)
        }).then((res) => res.json())
            .catch(error => { console.error('Error:', error); this.setState({ loading: false }) })
            .then(response => {


                console.log('Success:', response)
                if (response.msg == 'SUCCESS') {
                    this.setState({ msg: true, senderName: '', senderPhoneNumber: '', message: '', loading: false })
                } else {
                    this.setState({ error: true, loading: false })
                }
            });


    }




    render() {
        const { loading } = this.state
        return (
            <div id="contact" style={{height:'100%'}}>
                <section
                    className={`${stylez["flat-row"]} ${stylez["mail-chimp"]}`}
                >
                    <div class="container"  >
                        <div class="row">
                            <div
                                class="col-md-3 align-self-center text-center"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <h3
                                    style={{
                                        color: "#464646",
                                        fontSize: "24px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Message Us
                    </h3>
                            </div>
                            <div class="col-md-6 ">
                                <form onSubmit={this.onSubmit}>
                                    <div className="container-fluid">
                                        <div
                                            className="row justify-content-sm-center justify-content-center"
                                            style={{ width: "100%", marginLeft: 0.5 }}
                                        >
                                            <div class="form-group" style={{ width: "50%" }}>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="fname"
                                                    placeholder="Enter fullname"
                                                    name="senderName"
                                                    value={this.state.senderName}
                                                    onChange={this.onChange}
                                                    required
                                                />
                                            </div>
                                            <div
                                                class="form-group justify-content-end"
                                                style={{ width: "50%" }}
                                            >
                                                <input
                                                    type="tel"
                                                    class="form-control"
                                                    id="phone"
                                                    placeholder="Enter phone number"
                                                    name="senderPhoneNumber"
                                                    value={this.state.senderPhoneNumber}
                                                    onChange={this.onChange}
                                                    required

                                                />
                                            </div>
                                        </div>
                                        <div class="form-group text-center">
                                            <textarea
                                                class="form-control m-auto"
                                                id="exampleFormControlTextarea1"
                                                rows={3}
                                                name="message"
                                                value={this.state.message}
                                                onChange={this.onChange}
                                            ></textarea>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <button
                                                type="submit"
                                                class="btn text-white"
                                                style={{ backgroundColor: "#f63440" }}>
                                                {loading && <i class="fa fa-spinner fa-spin"></i>} &nbsp;<i class="fa fa-envelope"></i> Submit
                                            </button>

                                        </div>
                                        <div className="row justify-content-sm-center justify-content-center"
                                            style={{ width: "100%", marginLeft: 0.5 }}>
                                            <p className="mt-1 ">{this.state.msg && <div>
                                                <p className="text-success py-1 px-4  "> Message sent, seller will contact you shortly</p></div>}
                                                {this.state.error && <div><p className="text-danger px-4 py-1">Oops!, Message not sent</p></div>}</p>
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div
                                className="col-md-3 justify-content-sm-center align-self-center overflow-hidden"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <ul className="list-inline ">
                                    {this.props.shopSocials.facebook && <li className="list-inline-item m-2">
                                        <a href={this.props.shopSocials.facebook} target='_blank'>
                                            <i class="fab fa-facebook-f fa-lg"></i>
                                        </a>
                                    </li>}
                                    {this.props.shopSocials.twitter && <li className="list-inline-item m-2">
                                        <a href={this.props.shopSocials.twitter} target='_blank'>
                                            <i class="fab fa-twitter fa-lg"></i>
                                        </a>
                                    </li>}
                                    {this.props.shopSocials.instagram &&<li className="list-inline-item m-2">
                                        <a href={this.props.shopSocials.instagram} target='_blank'>
                                            <i class="fab fa-instagram fa-lg"></i>
                                        </a>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Contact
