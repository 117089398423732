import React, { Component } from 'react';
import './socials.css'

class Socials extends Component {
    render() {
        return (
            <div className="social">
                <ul className="list-group">

                    {this.props.shopSocials.twitter && <li className="list-group-item ">
                        <a href={this.props.shopSocials.twitter} target='_blank'>
                            <i class="fab fa-twitter fa-lg"></i>
                        </a>
                    </li>}
                    {this.props.shopSocials.facebook && <li className="list-group-item ">
                        <a href={this.props.shopSocials.facebook} target='_blank'>
                            <i class="fab fa-facebook-f fa-lg"></i>
                        </a>
                    </li>}
                    {this.props.shopSocials.instagram && <li className="list-group-item">
                        <a href={this.props.shopSocials.instagram} target='_blank'>
                            <i class="fab fa-instagram fa-lg"></i>
                        </a>
                    </li>}
                </ul>

            </div>
        );
    }
}

export default Socials;
