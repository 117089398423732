import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Productpage from '../../layout/ProductPage'
import Modal from '../../layout/Modal'
//import Productpage from './ShoppersProductPage'

import stylez from "./stylesheets/modaz.module.css"
import { getAllProductImages } from '../utilityfunctions'

let shop = 'shop'

let clickedItems = {}

let productImages = {};



class FeaturedProducts extends Component {



    state = {
        nameShop: '',
        descriptionShop: '',
        products: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: ''

        },
        shopNamefromURL: '',
        shopID: '',
        productFilters: [],
        filteredProducts: '',
        filter: '',
        animation: 'product-entry',
        animation1: 'product-exit',
        cart: [],
        clickedItems: {},
        selectedItems: []
    }




    dotdotdot = (text, num) => {
        if (text.length >= num) {
            let i = 0
            let moretext = ''

            while (i < num - 3) {
                moretext += text[i]
                i++
            }
            return moretext + '...'
        } else return text
    }

    selectItem = async (postID) => {
        //let selectedItems = this.state.selectedItems
        let selectedItems = this.props.FullCart || []

        let clickedItems = this.props.clickedItems;

        if (!clickedItems[postID.xID] || clickedItems[postID.xID] === 'unclicked') {
            clickedItems[postID.xID] = 'clicked'
            selectedItems.push(postID)
            this.setState({ selectedItems, clickedItems })
        } else {
            clickedItems[postID.xID] = 'unclicked'
            //selectedItems.pop(postID)
            var filtered = selectedItems.filter(function (value, index, arr) {

                return value !== postID;

            });
            selectedItems = filtered
            this.setState({ selectedItems: filtered, clickedItems })
        }
        //clickedItems = localStorage.getItem("clickedItems") 
        localStorage.setItem("clickedItems", JSON.stringify(clickedItems))


        console.log(' item clicked is; ', this.state.clickedItems, 'selected items are ', this.state.selectedItems)

        this.props.updateCart(selectedItems)
    }




    displayprice = (price, type, currency) => {
        if (currency == 'NGN') {
            currency = 'N'
        }
        if (currency == 'GHS') {
            currency = 'C'
        }



        let pString = Math.round(price).toString();
        if (pString.length > 8) {
            pString = pString.slice(0, pString.length - 6) + "M";
        } else if (pString.length > 6) {
            pString = Number(pString.slice(0, pString.length - 5)) / 10;
            pString = pString.toString() + "M";
        } else if (pString.length > 4) {
            pString = pString.slice(0, pString.length - 3) + "K";
        }


        let priceString = pString;
        if (type) {
            if (type == "Starting From") {
                priceString = "Starting From " + currency + pString;
            }
            else if (type == "Fixed Price") {
                priceString = currency + pString;
            }
            else {
                priceString =
                    currency + pString + " (" + type + ")";
            }
            if (type == "Free") {
                priceString = "FREE";
            }
            if (type == "NONE") {
                priceString = "contact for details ";
            }

            return priceString
        }
        //  return pString
    }


    getCategories = async () => {
        let productFilters = ['all'];

        this.props.products.forEach(product => {

            productFilters.push(product.category2)

        });

        let uniqueItems = Array.from(new Set(productFilters))

        await this.setState({ productFilters: uniqueItems })
        console.log(' the product filters are; ', this.state.productFilters)
    }


    componentDidMount = async () => {
        if (this.props.products) {
            console.log('services offered in Rave featured product component are: ', this.props.products)
            await this.setState({ filteredProducts: this.props.products })
            await this.getCategories();
            // this.getCategoryURLs();
        }
    }
    componentWillUnmount() {
        this.props.saveProductData(
            this.state.clickedItems,
            this.state.selectedItems
        );
    }

    componentWillMount = () => {
        clickedItems = JSON.parse(localStorage.getItem("clickedItems"))

        if (clickedItems) {


            let cart = this.props.FullCart || []
            let posts = this.state.products
            let keys = Object.keys(clickedItems)
            keys.forEach(key => {
                if (clickedItems[key] === 'clicked') {
                    posts.find(post => {
                        if (post.xID === key) {
                            cart.push(post)
                        }
                    })
                }
            })
            console.log('cart in Landing JS componentWillMount is: ', cart, 'clicked items stored are; ', clickedItems)
            this.setState({ selectedItems: cart, clickedItems })
        }
        if (this.props.selectedItems) {
            this.setState({ selectedItems: this.props.selectedItems, clickedItems: this.props.clickedItems })
        }
    }

    render() {

        return (
            <div style={{ backgroundColor: 'white' }}>
                <div className="site-section block-3 site-blocks-2 " >
                    <div className="container">
                        <div className="row justify-content-center" style={{ marginBottom: 0 }}>
                            <div className="text-center pt-4 mt-4">
                                <h2 className="text-uppercase rave-title" style={{ color: 'black', fontFamily: "Montserrat, sans-serif", fontWeight: 'lighter', fontSize: 35 }}>Products</h2>
                            </div>
                        </div>

                        <Modal
                            price={this.state.modalData.price}
                            pictureURL={this.state.modalData.pictureURL}
                            title={this.state.modalData.title}
                            text={this.state.modalData.text}
                            images={this.state.modalData.images}
                        ></Modal>

                        {this.state.productFilters && <div className='text-center' style={{ marginTop: 10 }}>
                            {this.state.productFilters.map((pFilter, id) => {
                                return (
                                    <button key={id} className={`btn mx-1 my-1 ${stylez['btn-modaz']}`}
                                        style={{ border: "1px solid rgba(0,0,0,0.1)", backgroundImage: `linear-gradient(to top, rgba(211,211,211,0.4), rgba(255,255,255,0))`}}
                                        onClick={() => {
                                            let filteredProducts = []
                                            this.setState({ filter: pFilter })
                                            this.props.products.filter(product => {
                                                if (product.category2 === pFilter) {
                                                    filteredProducts.push(product);
                                                } else if (pFilter === 'all') {
                                                    filteredProducts.push(product);
                                                }
                                            })

                                            this.setState({ filteredProducts });

                                        }}
                                    >{pFilter}</button>
                                )
                            })}
                        </div>}
                        {this.props.products && this.state.filteredProducts && <div className='row text-center'>
                            {this.state.filteredProducts.map((product, id) => {
                                if (Object.keys(productImages).length !== this.props.products.length) {
                                    let result = {}
                                    result = getAllProductImages(product)
                                    productImages = { ...productImages, ...result }
                                    console.log(' productimages are ...', productImages)
                                }
                                return (
                                    <div className={`${stylez['zoom-effect-container']} col-md-2  ${this.state.animation}`} key={id} >
                                        <div className={`${stylez["image-card"]} card  my-3 w-100 border-0`} >
                                            <a data-toggle="modal" data-target="#myModal"
                                                onClick={() => {
                                                    this.setState({
                                                        modalData: {
                                                            price: this.displayprice(product.price, product.priceType, product.priceCurrency) || product.price,
                                                            pictureURL: product.pictureURL,
                                                            title: product.title,
                                                            text: product.text,
                                                            images: productImages[product.xID]
                                                        }
                                                    })
                                                }}>

                                                <div style={{
                                                    height: 150,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center",
                                                    backgroundImage: `url(${product.pictureURL})`
                                                }}></div>


                                            </a>
                                            <div className="card-body image-body rave-card-body" style={{ background: '#ffffff' }}>
                                                <Link to={{
                                                    pathname: `/${this.props.hasCustomDomain ? shop : this.props.shopNamefromURL}/${product.xID}`,
                                                    // pathname: '/productpage/',
                                                    detailsProp: {
                                                        title: product.title,
                                                        text: product.text,
                                                        price: this.displayprice(product.price, product.priceType, product.priceCurrency) || product.price,
                                                        pictureURL: product.pictureURL,
                                                        providerName: this.props.providerName,
                                                        providerAddress: this.props.providerAddress,
                                                        phoneNumber: this.props.phoneNumber,
                                                        shopNamefromURL: this.props.shopNamefromURL,
                                                        nameShop: this.props.nameShop,
                                                        url: this.props.url,
                                                        theme: 'Modaz',
                                                        shopColorTitle: this.props.shopColorTitle,
                                                        shopColorBackground: this.props.shopColorBackground,
                                                        shopColorButton: this.props.shopColorButton,
                                                        shopLogo: this.props.shopLogo,
                                                        hasCustomDomain: this.props.hasCustomDomain,
                                                        shopSocials:this.props.shopSocials,
                                                        shopData:this.props.shopData,
                                                        shopCartStatus: this.props.shopCartStatus,
                                                        shopChat: this.props.shopChat,
                                                        shopCartBankAccount: this.props.shopCartBankAccount,
                                                        FullCart: this.props.FullCart,
                                                        xID:product.xID,
                                                        productImages:productImages
                                                    }
                                                }}
                                                >
                                                    <p className='card-title text-truncate text-uppercase rave-product-title ' style={{ color: '#313030' }} ><b>{product.title}</b></p>
                                                </Link>


                                                <div className="" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                    <div style={{ height: 30 }}>
                                                        <p className="card-text text-center" style={{ overflow: 'hidden', color: '#f6343f', fontSize: 10, fontWeight: 'bold' }}>{this.displayprice(product.price, product.priceType, product.priceCurrency) || product.price}</p>

                                                    </div>


                                                </div>
                                            </div>
                                            <div className="text-muted" style={{}}>
                                                <a>
                                                    <div className={`${stylez['custome-btn']} btn`} style={{ color: '#ffffff', width: '100%', borderRadius: 0, fontSize: 10, display: 'flex', justifyContent: 'center' }}
                                                        onClick={() => this.selectItem(product)}
                                                    >{(this.props.shopCartStatus === 'LIVE') && <a className="rave-likes" ><i className={this.state.clickedItems[product.xID] === 'clicked' ? "fa fa-heart" : "far fa-heart "} style={{ marginRight: 10, color: '#f6343f' }} ></i>
                                                    </a>}{(this.props.shopCartStatus === 'LIVE') && ((this.state.clickedItems[product.xID] === 'clicked') ? 'ADDED TO CART' : 'ADD TO CART')}</div>
                                                </a>

                                            </div>
                                        </div>

                                    </div>
                                )
                            })}

                        </div>}

                    </div>
                </div>
            </div>
        );
    }
}

export default FeaturedProducts;