import React, { Component } from 'react'
import Navbar from './WakandaNav'

class WakandaEducation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div style={{ backgroundColor: 'white' }}>
                <Navbar link={'/'} showSearch={false} nameShop={' Wakanda Education'}
                    showAbout={false} showContact={false} shopNamefromURL={''} />
                {/*  <section style={{
                    position: 'relative',
                    marginTop: '-125px',
                    height: 620,
                    width: '100%',
                    overflow: 'hidden'
                }}>

                    <div style={{ width: '100%', height: 620, overflow: 'hidden' }}>
                        <div id="rave-overlay"
                            style={{
                                height: '100%', position: 'absolute', top: 0,
                                background: 'rgba(0,0,0,0.8)'
                            }}>
                        </div>
                        <div className="row paral backImage"
                            style={{
                                backgroundImage: `url(${'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image15.jpg?alt=media&token=1de7201a-7aa0-4d6d-8b1c-4ec2115921f4'})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundAttachment: 'relative',
                                overflow: 'hidden',
                                height: 620
                            }}>
                            <div class="container"  >
                                <div>
                                    <div class="col-xl-6 col-lg-7 text-white mt-4 text-md-left ">

                                        <h1 className="font-weight-bold  flipInX" style={{ color: '#0128bc', lineHeight: 1.0, fontSize: '5vw' }}>Here we can help you  <span style={{ color: 'white', fontWeight: 'bold', fontSize: '6vw' }}><br />Find Great Teachers!</span></h1>

                                       
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </section> */}
                <section>

                    <header className=""
                        style={{
                            marginTop: -150,
                            backgroundImage: `url(${'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image15.jpg?alt=media&token=1de7201a-7aa0-4d6d-8b1c-4ec2115921f4'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundAttachment: 'relative',
                            overflow: 'hidden',
                            height: 620
                        }}>
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-lg-7 my-auto">
                                    <div className="header-content mx-auto">
                                        <h1 className="mb-5">Wakanda Education is improving the educational system by connecting children to professional teachers, instructors and mentors. </h1>
                                        <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">Get Started!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div style={{
                        backgroundColor: 'rgba(20,20,20,0.8)',
                        height: 620,
                        marginTop: -620
                    }}>

                    </div>
                </section>

                <section className="features" id="features" style={{ backgroundColor: 'white' }}>
                    <div className="container">
                        <div className="text-center">
                            <h2 style={{ color: '#0129bc' }}>WAKANDA EDUCATION</h2>
                            <p className="text-muted" style={{ color: 'black' }}> helps you to:</p>
                            <hr />
                        </div>
                        <div className="row">

                            <div className="col-md-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i class="fas fa-chalkboard-teacher "></i>
                                                <h3 style={{ color: "#0129BC" }}>FIND TEACHERS FASTER</h3>
                                                <p className="text-muted"><small> Through Wakanda Education App you can find teachers at a faster rate than before. Parents can browse through different profile of teachers, chat and make offers and connect immediately they are satisfied with your offer. </small></p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i class="fas fa-user-tie"></i>
                                                <h3 style={{ color: "#0129BC" }}> FIND NICHE SPECIFIC TEACHERS </h3>
                                                <p className="text-muted"><small>With the large number of registered professionals; you are not limited to the teacher’s gender, field or the subject matter. You can select a teacher by area of expertise or specify your requirements and suitable teachers will contact you. Online chat, call and interview session via the mobile app to ascertain that the teacher will be the right fit for your child. </small></p>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="feature-item">
                                                <i class="far fa-check-square"></i>
                                                <h3 style={{ color: "#0129BC" }}> GET TRUSTED PROFESSIONALS</h3>
                                                <p className="text-muted"><small>Wakanda Education App connects you with experienced, friendly, and vetted professionals for highest quality. It also allows you to have background knowledge of the teacher before employing them.</small></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="" style={{
                    backgroundImage: `url(${'https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2FHeaderImages%2FGeneral%20Images%2Fheader-image5.jpg?alt=media&token=7d28c73b-b194-46d6-ae54-9ffc58576ce4'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat', height: 540,
                    overflow: 'hidden', marginTop: -150,
                }}>
                    <div className="" >
                        <div className="container"
                            style={{
                                backgroundColor: 'rgba(20,20,20,0.8)',
                                height: '100%',
                                padding: 30

                            }} >
                            <h2 >HIRE TUTORS YOU CAN TRUST</h2>
                            <p className='col-md-5' style={{ color: 'grey' }}><small>
                                Get fully vetted home tutors who're specifically experienced in teaching, mentoring and inspiring kids like yours to excel in their academics!
                                </small></p>
                            <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">Get started!</a>
                        </div>
                    </div>
                    <div style={{
                        backgroundColor: 'rgba(20,20,20,0.8)',
                        marginTop: -350,

                    }}>

                    </div>
                </section>



                <section className=" download text-center" id="download" style={{ backgroundColor: "#2444b7" }}>
                    <div className="container">

                        <div className="col-md-8 mx-auto">
                            <h2 className="section-heading" style={{ color: '#FDCC52' }}>The Features That Matter</h2>
                            <p style={{ color: '#ffffff' }}><i><u>Reasons why you will love it!</u></i></p>

                        </div>



                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="feature-item mx-2">
                                        <h4 style={{ color: 'white' }}>LOCALISATION</h4>
                                        <p className="text" style={{ color: '#BBE8EA' }}><small>Finding a qualified teacher around your location can be very tasking; asking from
one neighbor or friend to another. With Wakanda Education App you don’t have to worry. You
can reach teachers within your immediate vicinity due to localization. This means you can
streamline your search to specific locations and find teachers of a particular demography. </small></p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="feature-item">
                                        <h4 style={{ color: 'white' }}>CONVENIENCE</h4>
                                        <p className="text" style={{ color: '#BBE8EA' }}><small>Having direct access to a wide variety of teachers is priceless. Finding a Teacher
should not be the same as fighting a war. With Wakanda Education it can be done in a way that
doesn’t stress you or them. This convenience is one of the reasons you should use Wakanda
Education to find a professional teacher for your child.</small></p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="feature-item">
                                    <h4 style={{ color: 'white' }}>BETTER PERFORMANCE</h4>
                                    <p className="text" style={{ color: '#BBE8EA' }}><small>With our high standard vetting of professionals, we can guarantee that
your child&#39;s performance will be increased and their performance in class will be enhanced. They
will also get help with homework when they need it, build confidence in weak areas, and overall
improved academic results.</small></p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="feature-item">
                                    <h4 style={{ color: 'white' }}>SAFE AND SECURE</h4>
                                    <p className="text" style={{ color: '#BBE8EA' }}><small>Due to increasing stress on the economy and the eroding moral fibre of our
society in the face of untold hardship, For the purpose of security,<strong>Wakanda Education
verifies that teachers are vetted with government established institutions.</strong> </small></p>
                                </div>
                            </div>


                        </div>

                    </div>



                </section>



                <section className="features" id="features" style={{ backgroundColor: 'white' }}>

                    <div className="text-center">
                        <h2 style={{ color: '#0129bc' }}>ARE YOU A TEACHER?</h2>
                        <hr />
                    </div>
                    <div className="row">

                        <div className="col-md-12 my-auto">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="feature-item">
                                            <i class="fas fa-chalkboard-teacher "></i>
                                            <p className="text-muted"><small>Here is a method that is helping professionals just like you earn extra income by offering home tutorials
to children.</small></p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="feature-item">
                                            <i class="fas fa-network-wired "></i>

                                            <p className="text-muted"><small>Wakanda education helps you to connect with many parents who are looking to hire a professional
teacher for their children.</small></p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="feature-item">
                                            <i class="fas fa-seedling"></i>

                                            <p className="text-muted"><small>This is an avenue for experienced teachers like you to manage and grow their home tutoring business or
add an extra source of income.</small></p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </section>

                <div className="footer text-white p-4 text-center" style={{ backgroundColor: '#0128bc', bottom: 0 }}>
                    Copyright &copy; {new Date().getFullYear()} Powered by African Founders. All rights reserved!
                 </div>
            </div>
        );
    }
}

export default WakandaEducation;