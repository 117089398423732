import React, { Component } from 'react'
import Card from './Card'
import { Link } from 'react-router-dom'
import Productpage from '../../layout/ProductPage'
import data from "../imageData"


export default class ModazCategories extends Component {


    state = {
        images: data.images,
        services: '',
        categories: '',
        properties: this.props.servicesOffered,
        property: this.props.servicesOffered[1]
    }

    nextProperty = () => {
        const newIndex = this.state.services.indexOf(this.state.property) + 1
        this.setState({
            property: this.state.services[newIndex]
        })
    }

    prevProperty = () => {
        const newIndex = this.state.services.indexOf(this.state.property) - 1
        this.setState({
            property: this.state.services[newIndex]
        })
    }




    filterCategories = async () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        const newvalues = [].concat(...values)


        console.log(' the categories are: ', keys, ' and the services are: ', values)
        await this.setState({ categories: keys, services: newvalues });


    }


    componentDidMount = async () => {
        if (this.props.servicesOffered) {
            console.log('services offered in Rave category component are: ', this.props.servicesOffered)
            await this.filterCategories();
            // this.getCategoryURLs();
        }
    }



    render() {
        const { properties, property, images } = this.state;
        return (

            <div className="container" id="products" >


                {/* <div style={{ marginBottom: '20px', textAlign: 'right' }}>
                    <button
                        style={{ float: 'left' }}
                        className="px-2 ml-3 mt-3"
                        onClick={() => this.prevProperty()}
                        disabled={this.props.products.indexOf(property) === 0}
                    > <i class="fas fa-angle-left"></i></button>
                    <button
                        style={{}}
                        className="px-2 ml-3 mt-3"
                        onClick={() => this.nextProperty()}
                        disabled={this.props.products.indexOf(property) === (this.state.services.length - 1)}
                    ><i class="fas fa-angle-right"></i></button>
                </div> */}
                <div style={{ marginTop: '50px', marginBottom: 50, }}>
                    {this.state.categories && <div className=" " style={{ "transform": `translateX(-${this.state.services.indexOf(property) * (100 / properties.length)}%)` }}>

                        {this.state.categories && <div className="row" >
                            {this.state.services.map((service, id) => {
                                return (
                                    <div className="col-sm-12 col-md-4 my-2"><Card categories={service} property={property} images={images[service]} key={id} /></div>
                                )
                            })
                            }
                        </div>}
                    </div>}
                </div>

            </div>


        )

    }
}



