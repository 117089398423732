import React, { Component } from 'react'
import { Link } from "react-router-dom"

import wkLogo from "../../../images/logos/home_logo1.png"
//import Home from "../../Home"



class Navbar extends Component {
    state = {
        shopNamefromURL: '',
        homeURL: '/',
        headerTextWidth: (window.innerWidth <= 375) ? 200 : 500

        //search: true
    }


    componentWillMount = () => {



        setTimeout(() => {

            this.setState({
                shopNamefromURL: this.props.shopNamefromURL,
                homeURL: '/' + this.props.shopNamefromURL,

            })

            console.log('the referrer from the Navbar is: ', document.referrer || window.location.href)
        }, 3000);


    }

    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }



    chooseCartURL = () => {
        if (this.props.hasCustomDomain) {
            return '/cart'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
        else {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
    }



    render() {
        const shopLogo = this.props.logo
        console.log('checking', this.props.logo)

        return (
            <nav className="navbar navbar-expand-md navbar-dark mb-4 bg-custom sticky-top" style={{ backgroundColor: this.props.shopColorBackground || 'rgba(0, 168, 89, 0.9)' }}>
                <div className="container">
                    <Link to={this.chooseHomeURL()} >
                        <div className="container-fluid text-md-center text-center d-none d-md-block"
                            style={{
                                width: '62px', height: '62px', borderRadius: '50%', backgroundColor: '#000',
                                justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${shopLogo || wkLogo})`,
                                backgroundRepeat: 'no-repeat', backgroundSize: '65px 65px', borderStyle: 'solid',
                                borderColor: 'white', borderWidth: '3px'
                            }}>

                        </div>
                    </Link>
                    <span className='d-none' style={{ color: '#fff', fontSize: '30px', fontWeight: "lighter", marginBottom: '5px' }}> &nbsp;|&nbsp; </span>
                    <Link to={this.chooseHomeURL()} style={{
                        fontWeight: 'bold', fontFamily: "Lato, sans-serif", paddingLeft: 5,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }} className="navbar-brand">{this.props.nameShop || 'WAKANDA - MARKET'}</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse mt-2" id="mobile-nav">
                        {this.props.showSearch &&
                            <form className="form-inline flex-nowrap  mx-0 mx-lg-auto">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-search"></i></span>
                                    </div>
                                    <input className="form-control mr-sm-2 search-input " type="search" placeholder="Search" name="search" id="search" />
                                </div>

                            </form>}

                        <ul className="navbar-nav ml-auto">
                            {this.props.showAbout && <li className="nav-item">
                                <a href="#about" className="nav-link">About</a>
                            </li>}
                            {this.props.showContact && <li className="nav-item">
                                <a href="#contact" className="nav-link">Contact</a>
                            </li>}
                            {this.props.shopCartStatus === "LIVE" && (
                                <li className="nav-item mt-2 ml-1 ">
                                    <a onClick={() => { this.props.showCartModal() }}>
                                        <i className="fas fa-shopping-cart text-white"  ></i>
                                        {this.props.FullCart.length > 0 && (
                                            <small
                                                style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    color: "white",
                                                    paddingTop: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                {this.props.FullCart.length}
                                            </small>
                                        )}
                                    </a>

                                </li> 

                            )}

                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
