import React, { Component } from 'react'
import Header from './header'
import Nav from '../layout/Navbar'
import Download from './download'
import Footer from '../layout/Footer'
import Features from './features'
import SocialConnect from './socialConnect'
import ReactPixel from 'react-facebook-pixel'

const options = {
	autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('1719865501642535', options);
ReactPixel.pageView();

class wakandaHome extends Component {
    
    render() {
        return (
            <div>
                <Nav link={'/'} showSearch={false} nameShop={'WELCOME TO WAKANDA MARKET!'}
                    showAbout={true} showContact={true} shopNamefromURL={''} />
                <Header
                    styles={{ marginTop: '-110px' }}
                    headerText={
                        "Wakanda Market is changing the way we do business online. " +
                        "Download Now to Find out how"
                    } />
                <Download
                    msg1={"Discover what all the buzz is about!"}
                    msg2={"Our app is available on any mobile device! Download now to get started!"} />
                <Features />
                <SocialConnect />
                <Footer />
            </div>

        );
    }
}

export default wakandaHome;