/* import React, { Component } from 'react';

class Modal extends Component {

 state = {
   imageInView: ''
 }

 componentDidMount() {
   if (this.props.pictureURL) {
     this.setState({ imageInView:this.props.images[0] })
   }

 }

 componentWillUnmount(){
   this.setState({ imageInView: this.props.pictureURL })
 }

 render() {
   return (
     <div id="myModal" className="modal fade" role="dialog" >
       <div className="modal-dialog" style={{ width:'110%'}}>

         <div className="modal-content" >
           <div className="modal-header">
             <h4 className="modal-title text-dark font-weight-bold" style={{ color: 'black' }}>{this.props.title}</h4>
             <button type="button" className="btn text-dark close" data-dismiss="modal" onClick={()=>{this.setState({imageInView:''})}}><p style={{ color: "black" }}>X</p></button>
           </div>
           <div className="modal-body" >
             <div style={{ display: 'flex', justifyContent:'space-between'}}>
             {this.props.pictureURL && <img src={this.state.imageInView || this.props.pictureURL} className="img-fluid" alt="Responsive image" 
             style={{width:'74%', border:'1px solid grey'}}/>}
             {this.props.images && <div style={{ justifyContent:'center', marginLeft:10, width:'20%' }}>
               {this.props.images.map((image, id) => {
                 return (
                   <div key={id} style={{
                     backgroundImage: `url(${image})`,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: "cover",
                     backgroundPosition: "center center",
                     backgroundAttachment: 'relative',
                     backgroundColor:'rgba(255,255,255,0.3)',
                     overflow: 'hidden',
                     height: "auto",
                     maxWidth: '104%',
                     width:100,
                     height:100,
                     border:'1px solid grey'
                   }} 
                   onClick={()=> this.setState({imageInView:image})}
                   ></div>
                 )
               })}
             </div>}
             </div>
             
             <p style={{ color: 'black' }}>{this.props.text}</p>
             <p style={{ color: 'black' }}>{this.props.price}</p>
           </div>
           <div class="modal-footer">
             <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>{this.setState({imageInView:''})}}>Close</button>
           </div>
         </div>

       </div>
       <div class="modal-back"></div>
     </div>

   );
 }
}

export default Modal;  */

import React, { Component } from 'react';

class Modal extends Component {

  state = {
    imageInView: ''
  }

  componentDidMount() {
    if (this.props.pictureURL) {
      this.setState({ imageInView: this.props.images[0] })
    }

  }

  componentWillUnmount() {
    this.setState({ imageInView: this.props.pictureURL })
  }

  render() {
    return (
      <div id="myModal" className="container modal fade" role="dialog">
        <div class="modal-dialog">

          <div class="conatiner modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-dark font-weight-bold" style={{ color: 'black' }}>{this.props.title}</h4>
              <button type="button" className="btn text-dark close" data-dismiss="modal" onClick={() => { this.setState({ imageInView: '' }) }}><p style={{ color: "black" }}>X</p></button>
            </div>
            <div className="modal-body" >
              <div style={{ display: 'flex'}}>
              {/* {this.props.pictureURL && <img src={this.state.imageInView || this.props.pictureURL} className="img-fluid" alt="Responsive image" 
              style={{width:'74%', border:'1px solid grey'}}/>} */}
              {this.props.pictureURL && <div style={{
                      backgroundImage: `url(${this.state.imageInView || this.props.pictureURL})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundAttachment: 'relative',
                      backgroundColor:'rgba(255,255,255,0.3)',
                      overflow: 'hidden', 
                      maxWidth: '104%',
                      width:'100%',
                      height:300,
                      border:'1px solid grey'
                    }} 
                    className="img-fluid" 
                    ></div>}
             
              </div>
              {this.props.images && <div style={{ display:'flex',justifyContent:'space-between', marginTop:10, width:'100%' }}>
                {this.props.images.map((image, id) => {
                  return (
                    <div key={id} style={{
                      backgroundImage: `url(${image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundAttachment: 'relative',
                      backgroundColor:'rgba(255,255,255,0.3)',
                      overflow: 'hidden',
                      height: "auto",
                      maxWidth: '104%',
                      width:100,
                      height:100,
                      border:'1px solid grey'
                    }} 
                    onClick={()=> this.setState({imageInView:image})}
                    ></div>
                  )
                })}
              </div>}
              <p style={{ color: 'black' }}>{this.props.text}</p>
              <p style={{ color: 'black' }}>{this.props.price}</p>
            </div>
            <div class="modal-footer">
             <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>{this.setState({imageInView:''})}}>Close</button>
           </div>
          </div>

        </div>
      </div>

    );
  }
}

export default Modal; 