import React, { Component } from 'react'
//importing styles
import "./css/style-electro.css"
import "./css/slick.css"
import "./css/slick-theme.css"
import "./css/nouislider.min.css"

//importing components
import TopHeader from './topHeader';
import MainHeader from './mainHeader';
import Categories from './categories';
import Products from './products';
import Banner from './banner'
import Chat from '../../../components/chats/Chat'
import Footer from './footer';
import Cart from '../../../checkout/cartModal'
import Socials from '../../socials/socials'

//importing utility function
import { openAndCloseCartModal, updateModalCart, updateCart } from '../utilityfunctions'
//facebook tracking pixels
import ReactPixel from 'react-facebook-pixel'

const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('1719865501642535', options);
ReactPixel.pageView();


//initializing variables
let FullCart = []
let cartStorage = {}
let WM_DATA_Storage = {};
console.log('cartStorage and WM_DATA_Storage initialized are:  ', 'cartStorage: ', JSON.stringify(cartStorage), 'WM_DATA_Storage: ', JSON.stringify(WM_DATA_Storage))

export default class Electro extends Component {

    state = {

        shopPromoIsActive: 'false',
        nameShop: '',
        description: '',
        tagline: '',
        shopBackgroundPicture: '',
        shopColorBackground: '',
        shopColorButton: '',
        shopColorHeader: '',
        shopColorTitle: '',
        shopFrontPicture: '',
        shopCartStatus: '',
        shopChat: '',
        shopCartBankAccount: '',
        shopSocials: {
            facebook: "",
            instagram: "",
            twitter: ""
        },
        postIDs: [],
        modalData: {
            price: 0,
            title: '',
            text: '',
            pictureURL: ''

        },
        logo: '',
        categories: '',
        services: '',
        servicesOffered: '',
        providerName: '',
        providerAddress: '',
        phoneNumber: '',
        hasloaded: false,
        redirect: false,
        shopNamefromURL: '',
        shopID: '',
        hasCustomDomain: false,
        url: '',
        theme: 'Electro',
        FullCart: [],
        cartModalWidth: 0,
        showModalCart: false,
        clickedItems: '',
        selectedItems: '',
        cartShowbankDetails: '',
        cartTotal: '',
    }




/* 
    openAndCloseCartModal = () => {

        this.setState({
            cartModalWidth: (this.state.cartModalWidth == 0) ? '100%' : (this.state.cartModalWidth == '100%' ? 0 : '100%'),
            showModalCart: (this.state.showModalCart == true) ? false : (this.state.showModalCart == false ? true : false)
        })
    }

    updateModalCart = () => {
        this.setState({
            cartModalDetails: {
                FullCart: this.state.FullCart,
                shopNamefromURL: this.props.shopNamefromURL,
                hasCustomDomain: this.props.hasCustomDomain,
                nameShop: this.state.nameShop,
                shopCartBankAccount: this.state.shopCartBankAccount
            }
        })
    }
 */

    componentWillMount() {
        console.log('firing componet will mount in Electro.js')
        if (JSON.parse(localStorage.getItem("WM_DATA_Storage"))) {
            WM_DATA_Storage = JSON.parse(localStorage.getItem("WM_DATA_Storage"))
            cartStorage = WM_DATA_Storage.FullCart
        } else {

            cartStorage = []

        }

        localStorage.setItem("cartStorage", JSON.stringify(cartStorage))
        this.setState({ FullCart: cartStorage })


    }

    componentDidMount = async () => {
        if (this.props.shopData) {
            document.title = this.props.shopData.profile.businessName
            console.log('Data received in Electro theme from Home JS is: ', this.props.shopData)
            let shopProfile = this.props.shopData.profile
            let shopProfileName = shopProfile.businessName
            let shopProfileDescription = shopProfile.description
            // let shopLogo = shopProfile.pictureURL
            let hasCustomDomain = this.props.hasCustomDomain
            //////////////////////////////////////////////////////////////////////////////

            let providerName = shopProfile.name;
            let providerAddress = shopProfile.locationWorkAddress
            let phoneNumber = shopProfile.phoneNumber
            let servicesOffered = shopProfile.servicesOffered
            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopPost = this.props.shopData.posts


            let shop = this.props.shopData.shop
            console.log('shops', shop)
            let shopLogo = shop.shopLogoPicture
            let tagline = shop.shopSlogan
            let shopID = shop.shopName
            let shopNamefromURL = shop.shopName
            let shopBackgroundPicture = shop.shopBackgroundPicture
            let theme = shop.shopType

            ////////////////////////////////////////////////////////////////////////////////////////////
            let shopColorBackground = shop.shopColorBackground
            let shopColorButton = shop.shopColorButton
            let shopColorHeader = shop.shopColorHeader
            let shopColorTitle = shop.shopColorTitle
            let shopFrontPicture = shop.shopFrontPicture
            let shopHeader = shop.shopHeader
            let shopCartStatus = shop.shopCartStatus
            let shopChat = shop.shopChat
            let shopCartBankAccount = shop.shopCartBankAccount
            let shopSocials = shop.shopSocials
            let shopPromoIsActive = shop.shopPromoIsActive
            ////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////
            console.log(' shopNamefromURL from APP JS is: ', shopNamefromURL)

            console.log('shopName is shop ID which is: ', shopID)
            await this.setState({
                nameShop: shopProfileName,
                tagline,
                descriptionShop: shopProfileDescription,
                shopBackgroundPicture,
                shopBackgroundPicture,
                shopColorBackground,
                shopColorButton,
                shopColorHeader,
                shopColorTitle,
                shopFrontPicture,
                shopCartStatus,
                shopChat,
                shopCartBankAccount,
                shopSocials,
                postIDs: shopPost,
                providerName,
                providerAddress,
                phoneNumber,
                servicesOffered,
                logo: shopLogo,
                hasloaded: true,
                url: this.props.url,
                shopNamefromURL,
                shopID,
                hasCustomDomain,
                theme,
                shopHeader,
                shopPromoIsActive,
                FullCart: this.props.FullCart,
                clickedItems: this.props.clickedItems
            })


            if (this.props.clickedItems) {

                let cart = []
                let posts = this.state.postIDs
                let keys = Object.keys(this.props.clickedItems)
                keys.forEach(key => {
                    if (this.props.clickedItems[key] === 'clicked') {
                        posts.find(post => {
                            if (post.xID === key) {
                                cart.push(post)
                            }
                        })
                    }
                })
                console.log('cart generated from clicked items is: ', cart)
                this.setState({ FullCart: cart })
            }
        }


    }

    render() {
        return (
            <div className="electro-body App container-fluid pr-0 pl-0 h-100" style={{ overflowX: 'hidden', width: '100%', top: 0, left: 0, position: 'fixed', scrollBehavior: "smooth" }}>
                {this.props.shopData && <TopHeader
                    showCartModal={async () => {
                        let result = openAndCloseCartModal(this.state.cartModalWidth, this.state.showModalCart)
                        this.setState({ cartModalWidth: result.cartModalWidth, showModalCart: result.showModalCart })
                        await this.setState({ cartModalDetails: updateModalCart(this.state.FullCart, this.state.shopNamefromURL, this.state.hasCustomDomain, this.state.nameShop, this.state.shopCartBankAccount) })
                        console.log('the this.state.cartModalDetails received is: ', this.state.cartModalDetails)
                    }}
                    address={this.state.providerAddress}
                    phoneNumber={this.state.phoneNumber}
                    shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
                    FullCart={this.state.FullCart || []}
                    shopCartStatus={this.state.shopCartStatus}
                    shopCartBankAccount={this.state.shopCartBankAccount}
                    hasCustomDomain={this.state.hasCustomDomain}
                    currency={this.props.shopData || []}
                />}
                <MainHeader
                    shopName={this.state.providerName}
                    shopNamefromURL={this.props.hasCustomDomain ? '' : this.state.shopNamefromURL}
                    url={this.state.url}
                    nameShop={this.state.nameShop}
                />

                {!this.state.showModalCart && this.state.servicesOffered &&
                    <div style={{
                        marginTop: -10,
                        width: this.state.cartModalWidth == '100%' ? 0 : (this.state.cartModalWidth == 0 ? '100%' : 0),
                        WebkitTransition: 'width 0.5s',
                        transition: 'width 0.5s',
                    }}
                        id='collections'>
                        <Categories
                            shopColorBackground={this.props.shopData.shop.shopColorBackground}
                            shopColorHeader={this.props.shopData.shop.shopColorHeader}
                            servicesOffered={this.state.servicesOffered}
                            width={this.state.cartModalWidth}
                        />
                    </div>}

                {!this.state.showModalCart && <div style={{ width: '100%', overflow: 'hidden', margin: '0 auto', backgroundColor: '#EFB31F', paddingBottom: 100, }} id='products'>
                    <Products
                        updateCart={async (FullCart) => {
                            updateCart(FullCart)
                            await this.setState({ FullCart })
                            console.log('FullCart is...', this.state.FullCart)
                        }}


                        saveProductData={(clicked, selected) => {
                            this.setState({ clickedItems: clicked, selectedItems: selected })
                        }}
                        /* postIDs={this.state.postIDs}
                        shopCartStatus={this.state.shopCartStatus}
                        shopSocials={this.state.shopSocials}
                        products={this.props.shopData.posts}
                        nameShop={this.state.nameShop}
                        logo={this.state.logo}
                        shopNamefromURL={this.props.hasCustomDomain ? 'shop' : this.props.shopNamefromURL}
                        providerName={this.props.shopData.profile.name || ""}
                        providerAddress={this.props.shopData.profile.locationWorkAddress}
                        phoneNumber={this.props.shopData.profile.phoneNumber}
                        url={this.state.url}
                        servicesOffered={this.state.servicesOffered}
                        theme={'Electro'}
                        description={this.props.shopData.profile.description}
                        productTitleColor={this.state.shopColorTitle}
                        buttonColor={this.state.shopColorButton}
                        hasCustomDomain={this.props.hasCustomDomain} */
                        FullCart={this.state.FullCart}
                        selectedItems={this.state.selectedItems}
                        clickedItems={this.state.clickedItems}
                        shopCartStatus={this.state.shopCartStatus}
                        shopCartBankAccount={this.state.shopCartBankAccount}
                        shopData={this.props.shopData}
                        shopNamefromURL={this.state.shopNamefromURL}
                        url={this.props.url}
                        nameShop={this.state.nameShop}
                        descriptionShop={this.state.descriptionShop}
                        postIDs={this.state.postIDs}
                        providerName={this.state.providerName}
                        providerAddress={this.state.providerAddress}
                        phoneNumber={this.state.phoneNumber}
                        shopID={this.state.shopID}
                        //shopLogo={this.state.logo}
                        shopLogo={this.state.logo}
                        servicesOffered={this.state.servicesOffered}
                        hasloaded={this.state.hasloaded}
                        hasCustomDomain={this.props.hasCustomDomain}
                        theme={this.state.theme}
                        shopColorTitle={this.state.shopColorTitle}
                        shopColorBackground={this.state.shopColorBackground}
                        shopSocials={this.state.shopSocials}
                        shopCartStatus={this.state.shopCartStatus}
                        shopChat={this.state.shopChat}
                        shopCartBankAccount={this.state.shopCartBankAccount}
                        shopSocials={this.state.shopSocials}
                    />
                </div>}


                {this.state.shopPromoIsActive && !this.state.showModalCart && <Banner
                    shopPromoPicture={this.props.shopData.shop.shopPromoPicture}
                    shopPromoTitle={this.props.shopData.shop.shopPromoTitle}
                    shopPromoText={this.props.shopData.shop.shopPromoText}
                />}


                {(this.state.shopChat.status === "LIVE") && <Chat
                    textColor={'white'}
                    backgroundColor={this.state.shopColorButton}
                    nameShop={this.state.nameShop}
                    shopNamefromURL={this.props.hasCustomDomain ? this.state.shopID : this.props.shopNamefromURL}
                />}




                {this.state.servicesOffered && !this.state.showModalCart &&
                    <Footer
                        providerName={this.state.providerName}
                        address={this.state.providerAddress}
                        phoneNumber={this.state.phoneNumber}
                        description={this.state.descriptionShop}
                        servicesOffered={this.state.servicesOffered}
                        shopNamefromURL={this.state.shopNamefromURL || this.state.shopID}
                        shopSocials={this.state.shopSocials}
                        nameShop={this.state.nameShop ? this.state.nameShop : 'wakanda'}
                    />}

                <div id='cart-modal' style={{
                    width: this.state.cartModalWidth,
                    height: 'inherit',
                    position: 'absolute',
                    right: 0, top: 60,
                    backgroundColor: 'white',
                    WebkitTransition: 'width 0.5s',
                    transition: 'width 0.5s',
                    paddingTop: 50,
                    paddingBottom: 100
                }}>

                    <div onClick={() => {
                        let result = openAndCloseCartModal(this.state.cartModalWidth, this.state.showModalCart)
                        this.setState({ cartModalWidth: result.cartModalWidth, showModalCart: result.showModalCart })
                    }}>
                        <h1 style={{ color: 'black', paddingLeft: 50 }}>X</h1> </div>

                    {this.state.showModalCart && <div style={{ height: 'inherit'}}>
                        <Cart
                            CartDetails={this.state.cartModalDetails}
                            // cartShowbankDetails={this.state.cartShowbankDetails}
                            cartShowbankDetails={this.state.cartShowbankDetails}
                            cartTotal={this.state.cartTotal}
                            nameShop={this.state.nameShop}
                            updateCart={async (FullCart) => {

                                await this.setState({ FullCart: updateCart(FullCart) })
                                console.log('FullCart is...', this.state.FullCart)
                              }}
                

                            clearLocalStorageCart={() => {
                                WM_DATA_Storage.FullCart = ''
                                localStorage.setItem("WM_DATA_Storage", JSON.stringify(WM_DATA_Storage));
                                localStorage.setItem("cartStorage", "");
                                this.setState({ FullCart: '' })
                            }}

                            storeCartDetails={(a, b) => {
                                this.setState({ cartShowbankDetails: a, cartTotal: b })
                            }}
                        />
                    </div>}

                </div>
            </div>
        )
    }
}
