import React, { Component } from 'react'
import { Link } from "react-router-dom"
import styles from "./stylesheets/modaz.module.css"
//import wkLogo from "../../images/logos/home_logo1.png"
//import Home from "../../Home"



class Navigation2 extends Component {
    state = {
        shopNamefromURL: '',
        homeURL: '/',
        headerTextWidth: (window.innerWidth <= 375) ? 200 : 500

        //search: true
    }


    componentWillMount = () => {



        setTimeout(() => {

            this.setState({
                shopNamefromURL: this.props.shopNamefromURL,
                homeURL: '/' + this.props.shopNamefromURL,

            })

            console.log('the referrer from the Navbar is: ', document.referrer || window.location.href)
        }, 3000);


    }

    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }



    chooseCartURL = () => {
        if (this.props.hasCustomDomain) {
            return '/cart'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
        else {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
    }



    render() {
        const shopLogo = this.props.logo
        console.log('checking', this.props.logo)

        return (
            <nav className="navbar navbar-expand-md bg-white navbar-light " style={{width: "100vw", paddingBottom: 0 }}>
                <div className="container" style={{
                    position: "relative",
                    height: "100%",
                    paddingTop: 20
                }}>

                    <Link to={this.chooseHomeURL()} style={{
                    }} className="navbar-brand">
                        <h1 className="text-wrap" style={{
                            fontFamily: "Helvetica Neue, cursive",
                            fontWeight: "bold",
                            fontSize: 20,
                            color: "#F6343F"
                        }}>{this.props.nameShop || 'WAKANDA - MARKET'}</h1>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav" style={{ backgroundColor:'#F6343F'}}>
                        <span className="navbar-toggler-icon navbar-dark" style={{color: 'white'}}></span>
                    </button>

                    <div className="collapse navbar-collapse" id="mobile-nav">
                        <div className="navbar-nav ml-auto nav ">
                            <div className="nav-item">
                                <a href="#about" className="nav-link" style={{color: 'grey'}}>Home</a>
                            </div>
                            <div className="nav-item">
                                <a href="#contact" className="nav-link" style={{color: 'grey'}}>Shop</a>
                            </div>
                            <div className="nav-item">
                                <a href="#contact" className="nav-link" style={{color: 'grey'}}>Contact</a>
                            </div>
                            {this.props.shopCartStatus === "LIVE" && (
                                <li className="nav-item mt-2 ml-1 ">
                                    <a onClick={() => { this.props.showCartModal() }}>
                                        <i className="fas fa-shopping-cart "  ></i>
                                        {this.props.FullCart.length > 0 && (
                                            <small
                                                style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    color: "white",
                                                    paddingTop: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                {this.props.FullCart.length}
                                            </small>
                                        )}
                                    </a>

                                </li>

                            )}

                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navigation2
