import React, { Component } from 'react'
import { Link } from "react-router-dom"

import wkLogo from "../../../images/logos/home_logo1.png"
//import Home from "../../Home"



class Navbar extends Component {
    state = {
        shopNamefromURL: '',
        homeURL: '/',
        headerTextWidth: (window.innerWidth <= 375) ? 200 : 500

        //search: true
    }


    componentWillMount = () => {



        setTimeout(() => {

            this.setState({
                shopNamefromURL: this.props.shopNamefromURL,
                homeURL: '/' + this.props.shopNamefromURL,

            })

            console.log('the referrer from the Navbar is: ', document.referrer || window.location.href)
        }, 3000);


    }

    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }



    chooseCartURL = () => {
        if (this.props.hasCustomDomain) {
            return '/cart'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
        else {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
    }



    render() {
        const shopLogo = this.props.logo
        console.log('checking', this.props.logo)

        return (
            <nav className="navbar navbar-expand-md navbar-dark mb-4 sticky-top"
                style={{ position: 'relative' }}
            //style={{backgroundImage: `linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))`}}
            >
                <div className="container">
                    <span className='d-none' style={{ color: '#fff', fontSize: '30px', fontWeight: "lighter", marginBottom: '5px' }}> &nbsp;|&nbsp; </span>
                    <Link to='#' style={{
                        fontWeight: 'bold', fontFamily: "Lato, sans-serif", paddingLeft: 5,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: 'blue',
                        textOverflow: "ellipsis"
                    }} className="navbar-brand">
                        <span style={{
                            //borderRadius:'50%', 
                            backgroundColor: this.props.shopColorBackground || 'blue', padding: 8, paddingBottom: 5, paddingTop: 5, color: 'white', marginRight: 5, textTransform: 'uppercase'
                        }}>{this.props.initials || "CL"}</span> <b style={{ color: this.props.shopColorHeader || 'blue', textTransform: 'capitalize' }}>{this.props.nameShop || "Logo"}</b></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse  mt-2 " id="mobile-nav">


                        <ul className="navbar-nav  ml-auto" style={{ justifyContent: 'space-between' }}>
                        <li className="nav-item">
                                <a href="#about" className="nav-link" style={{ color: this.props.shopColorHeader || 'blue', fontWeight: 'bold' }}>About Us</a>
                            </li>
                            <li className="nav-item">
                                <a href="#services" className="nav-link" style={{ color: this.props.shopColorHeader || 'blue', fontWeight: 'bold' }}>Our Services</a>
                            </li>
                            <li className="nav-item">
                                <a href="#contact" className="nav-link" style={{ color: this.props.shopColorHeader || 'blue', fontWeight: 'bold' }}>Contact</a>
                            </li>

                             
                            {this.props.postIDs.length > 0 && <li className="nav-item">
                                <a href="#gallery" className="nav-link" style={{ color: this.props.shopColorHeader || 'blue', fontWeight: 'bold' }}>Gallery</a>
                            </li>}
                           
                            
                           
                            {/* {this.props.shopCartStatus === "LIVE" && (
                                <li className="nav-item mt-2 ml-1 ">
                                    <a onClick={() => { this.props.showCartModal() }}>
                                        <i className="fas fa-shopping-cart text-white"  ></i>
                                        {this.props.FullCart.length > 0 && (
                                            <small
                                                style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    color: "white",
                                                    paddingTop: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                {this.props.FullCart.length}
                                            </small>
                                        )}
                                    </a>

                                </li>

                            )} */}

                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
