import firebase from "firebase";
import "firebase/firestore";

// const config = {
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };
const blogconfig = {
  apiKey: "AIzaSyCeFZYtvim7iu_ME6r5h796-GNlYxzOya0",
  authDomain: "af-shop.firebaseapp.com",
  databaseURL: "https://af-shop.firebaseio.com",
  projectId: "af-shop",
  storageBucket: "af-shop.appspot.com",
  messagingSenderId: "504098500727",
  appId: "1:504098500727:web:559710f55d728bfc8b9672"
};

//export default firebase.initializeApp(config);
//firebase.initializeApp(blogconfig);
export default blogconfig