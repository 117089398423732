import React, { Component } from "react";

import { Switch, Route, NavLink, Redirect, Link } from "react-router-dom";

//import firebase from "../../Blog/Firebase";

import { createBrowserHistory } from "history";

import NewPost from "./Newpost/NewPost";
import Post from "../../Blog/Post/Post";
import FullPost from "./FullPost/FullPost";
import "./Admin.css";
import BlogPage from "../../Blog/BlogPage/BlogPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import initiateGlobal from "../utilities/initiateGlobals";

import config from '../../config/chatConfig'
import 'firebase/firestore';
const firebase = require('firebase') 

const admin = "admin";

let idTokenPresent = false


export default class AdminPage extends Component {
  /* constructor(props) {
    super(props);

    this.unsubscribeFirestore = () => {};
    this._isMounted = false;
    this.state = {
      posts: [],
      pathName: "new-post",

      selectedPostId: null,
      error: false,
      profileImage:
        "https://i2.cdn.turner.com/cnnnext/dam/assets/140926165711-john-sutter-profile-image-large-169.jpg",
      selectedFile: null,
      title: "",
      content: "",
      imageURL: "",
      fileId: "",
      id: "",
      user: this.props.user,
      error: false,
      submitted: false,
      renderEditBlog: false,
      menu_class: "",
      idTokenPresent:false
    };
  } */
  state = {
    posts: [],
    pathName: "new-post",

    selectedPostId: null,
    error: false,
    profileImage:
      "https://i2.cdn.turner.com/cnnnext/dam/assets/140926165711-john-sutter-profile-image-large-169.jpg",
    selectedFile: null,
    title: "",
    content: "",
    imageURL: "",
    fileId: "",
    id: "",
    user: this.props.user,
    error: false,
    submitted: false,
    renderEditBlog: false,
    menu_class: "",
  };



    unsubscribeFirestore = () => {};
    _isMounted = false;
     

  componentDidMount = async => {
    
    if(this.props){
      this.setState({user:this.props.user, hasCustomDomain:this.props.hasCustomDomain})
    }
    
    console.log(
      "user has domain",
      this.props.hasCustomDomain,
      " the user is: ",
      this.state.user
    );

    this._isMounted = true;

    
    firebase.auth().onIdTokenChanged(function(user) {
      if (user && !idTokenPresent) {
        // User is signed in or token was refreshed.
        global.fetchIdToken();
      }
    });

    global.fetchIdToken = async () => {
      try {
        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true);
        if (idToken) {
          global.idToken = idToken;
          idTokenPresent = true
        }
        console.log("Success fetching idToken", global.idToken);
        
      } catch (err) {
        console.log("ERROR fetching idToken", err);
      }
    };

    this.unsubscribeFirestore = firebase
      .firestore()
      .collection("Blogs")
      .where("author", "==", this.state.user)
      .onSnapshot(async collection => {
        if (collection.empty) {
          console.log("No matching documents");
          return;
        }
        const posts = [];
        collection.forEach(doc => {
          let items = doc.data();
          let fileID = doc.id;
          posts.push({
            author: items.author,
            title: items.title,
            content: items.content,
            imageUrl: items.imageUrl,
            fileId: fileID
          });
        });
        this.setState({ posts });
      });
  };

  postSelectedHandler = id => {
    console.log("this is a  clicked item", id);
    this.setState({ selectedPostId: id });
  };
  deletePostHandler = async () => {
    try {
      const r = await fetch(
        "https://us-central1-af-shop.cloudfunctions.net/deleteBlog",
        {
          method: "POST",
          // mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user,
            idToken: global.idToken,
            blogID: this.state.selectedPostId
          })
        }
      );
      const r4 = await r.json();
      console.log("message from delete endpoint", r4);
      if (r4.msg === "BLOG DELETED") {
        this.props.history.push("/admin");
      }
    } catch (err) {
      console.log(err, "there seems to be an error");
    }
  };
  renderEdit = () => {
    this.setState({ renderEditBlog: true });
  };
  Changetitle = event => {
    this.setState({ title: event.target.value });
  };
  changeContent = event => {
    this.setState({ content: event.target.value });
  };

  componentWillUnmount() {
    this.unsubscribeFirestore();
    this._isMounted = false;
  }
  setToggleTopMenuClass = () => {
    if (this.state.menu_class === "") {
      this.setState({
        menu_class: "toggled"
      });
    } else {
      this.setState({
        menu_class: ""
      });
    }
  };

  render() {
    let idparams;
    let data;
    if (this.state.selectedPostId) {
      data = this.state.posts.find(x => x.fileId === this.state.selectedPostId);
      console.log("data to be handle ", data);
      idparams = data.fileId;
    }
    console.log("this is user state", this.state.user);/* 
    console.log("this is history props", this.props.history);
    console.log("this is location props", this.props.location);
    console.log("this is match props", this.props.match); */
    // console.log("here is the downloadurl in render", this.state.downloadUrl);
    // console.log(this.state.posts);
    console.log(this.state.posts[this.state.selectedPostId]);
    const post = this.state.posts;
    const newPosts = post.slice(0, 8);
    console.log("See me here......", newPosts);
    // console.log("this is the selected index", this.state.selectedPostId);
    // let newId = this.state.posts[this.state.selectedPostId];
    // console.log(newId, "the new ID");
    let top_menu_class = `top-menu ${this.state.menu_class}`;
    return (
      <div className="Admincontainer">
        <div className={top_menu_class}>
          <FontAwesomeIcon
            style={{}}
            icon="bars"
            className="top-menu-icon"
            onClick={this.setToggleTopMenuClass}
          />
          <ul className="sidebarMenuInner">
            <li>
              {this.state.user && this.state.hasCustomDomain && <NavLink
                className="links"
                to={{
                  pathname: `/${this.props.user}/blog`,
                  blogData: this.state.posts,
                  user: this.props.user
                }}
              >
                BlogPage
              </NavLink>}
            </li>
            <li>
              {this.state.user && this.state.hasCustomDomain && <NavLink
                className="links"
                to={{
                  pathname: `/${this.state.user}/admin/${this.state.pathName}/edit`,

                  user: this.props.user,
                  hasCustomDomain: this.state.hasCustomDomain
                }}
              >
                new Post
              </NavLink>}
            </li>
          </ul>
        </div>
        <section>
          <div className="adminSection">
            {this.state.user && this.state.hasCustomDomain &&<div className="Posts">
              {post.map(item => {
                return (
                  <Link
                    key={item.fileId}
                    to={{
                      //pathname: this.props.hasCustomDomain?`/admin/${item.fileId}`:`${this.props.user}/admin/${item.fileId}`,
                      pathname: this.state.hasCustomDomain == true ? `/${admin}/${item.fileId}`  : `/${this.props.user}/admin/${item.fileId}`, 
                      data: item,
                      renderEditBlog: this.state.renderEditBlog,
                      onClick1: this.deletePostHandler,
                      onClick2: this.renderEdit,
                      onChange1: this.Changetitle,
                      onChange2: this.changeContent,
                      user: this.props.user,
                      hasCustomDomain: this.state.hasCustomDomain
                    }}
                   /*  to={{
                      //pathname: this.props.hasCustomDomain?`/admin/${item.fileId}`:`${this.props.user}/admin/${item.fileId}`,
                      pathname: `/${ this.props.hasCustomDomain  ? admin  : this.state.user + "/" + admin  }/${item.fileId}`
                    }} */
                    className="links"
                  >
                    {this.state.user && <Post
                      title={item.title}
                      author={item.author || this.state.user}
                      imageUrl={item.imageUrl}
                      fileId={item.fileId}
                      clicked={() => this.postSelectedHandler(item.fileId)}
                    />}
                  </Link>
                );
              })}
            </div>}
          </div>
        </section>

        {this.state.selectedPostId && (
          <Redirect to={`${this.props.match.url}/:${idparams}`} />
        )}
      </div>
    );
  }
}
