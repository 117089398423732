import React from 'react'



const MainHeader = (props) => {

    const chooseHomeURL = () => {
        if (props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + props.shopNamefromURL
        }
    }

    return (
        <div>
            <div id="header">
                <div class="container">
                    <div class="row justify-content-center">
                        <div className="text-center">
                            <h2 className="h2-electro text-white">{props.nameShop}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <nav id="navigation" className="navbar navbar-expand-sm">
                <div className="container">
                    <div className="responsive-nav">
                        <ul className="main-nav nav">
                            <li className="active nav-item "><a href={chooseHomeURL()} className="a-electro text-black">Home</a></li>
                            <li className="text-black"><a href="#products" className="a-electro text-black">Products</a></li>
                            {/*    <li className="nav-item"><a href="#about" className="a-electro">About</a></li> */}
                            <li className="nav-item"><a href="#contact" className="a-electro">Contact</a></li>
                        </ul>
                    </div>
                </div>

            </nav>
        </div>
    )
}

export default MainHeader
