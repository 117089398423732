import React, { Component } from 'react'
import { Link } from "react-router-dom"
import './style.css'

import wkLogo from "../../../images/logos/home_logo1.png"
import { relative } from 'path'
//import Home from "../../Home"



class Navbar extends Component {
    state = {
        shopNamefromURL: '',
        homeURL: '/',
        headerTextWidth: (window.innerWidth <= 375) ? 200 : 500

        //search: true
    }


    componentWillMount = () => {



        setTimeout(() => {

            this.setState({
                shopNamefromURL: this.props.shopNamefromURL,
                homeURL: '/' + this.props.shopNamefromURL,

            })

            console.log('the referrer from the Navbar is: ', document.referrer || window.location.href)
        }, 3000);


    }

    chooseHomeURL = () => {
        if (this.props.hasCustomDomain) {
            return '/'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/'
        }
        else {
            return '/' + this.props.shopNamefromURL
        }
    }



    chooseCartURL = () => {
        if (this.props.hasCustomDomain) {
            return '/cart'
        } else if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://localhost:3000/' || window.location.href === 'http://wakanda-market.com' || window.location.href === 'https://wakanda-market.com') {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
        else {
            return '/' + this.props.shopNamefromURL + '/cart'
        }
    }



    render() {
        const shopLogo = this.props.logo
        console.log('checking', this.props.logo)

        return (
            <nav
                className="navbar navbar-expand-md navbar-dark sticky-top"
                style={{ position: 'relative', marginBottom: 100, width: '100%', }}
            //style={{backgroundImage: `linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))`}}
            >
                <div className="container" style={{ paddingRight: 0, paddingLeft: 0, }}>
                    {/* <Link to= '#' >
                        <div className="container-fluid text-md-center text-center d-none d-md-block"
                            style={{
                                width: '62px', height: '62px', borderRadius: '50%', backgroundColor: '#000',
                                justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${shopLogo || wkLogo})`,
                                backgroundRepeat: 'no-repeat', backgroundSize: '65px 65px', borderStyle: 'solid',
                                borderColor: 'white', borderWidth: '3px'
                            }}>

                        </div>
                    </Link> */}
                    <span className='d-none' style={{ fontSize: '30px', fontWeight: "lighter", marginBottom: '5px' }}> &nbsp;|&nbsp; </span>
                    <Link to='#' style={{
                        fontWeight: 'bold',
                        fontFamily: "Lato, sans-serif",
                        paddingLeft: 5,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",

                    }}
                        className="navbar-brand">
                        <div style={{
                            display: 'inline-Block',
                            width: 53,
                            height: 53,
                            backgroundColor: this.props.shopColorBackground || '#fff',
                            color: '#000',
                            borderRadius: '50%',
                            paddingTop: 15,
                            marginRight: 5,
                            textTransform: 'uppercase'
                        }}>
                            <p style={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                            }}>{this.props.initials || "CL"}</p>
                        </div>
                        <b
                            style={{
                                color: this.props.shopColorHeader || '#000',
                                fontFamily: 'aria', fontSize: 23

                            }}>
                            {this.props.nameShop || "Logo"}
                        </b>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="mobile-nav">
                        {this.props.showSearch &&
                            <form className="form-inline flex-nowrap  mx-0 mx-lg-auto">
                                <div class="input-group">
                                    <div class="input-group-prepend" >
                                        <span class="input-group-text" style={{ backgroundColor: '#a6a6a6', color: "#000" }}><i class="fa fa-search fa-lg" ></i></span>
                                    </div>
                                    <input className="form-control mr-sm-2 search-input " type="search" placeholder="Search for  product brand and categories" name="search" id="search" style={{ width: 350, height: 38, backgroundColor: '#f3f3f3' }} />
                                </div>

                            </form>}

                        <ul className="navbar-nav" style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: 0 }}>
                            {this.props.showAbout &&
                                <li className="nav-item">
                                    <a href="#about" className="nav-link" style={{ color: this.props.shopColorHeader || '#000', fontWeight: 500 }}>
                                        About
                                    </a>
                                </li>}
                            {/* {this.props.postIDs.length > 0 && <li className="nav-item">
                                <a href="#gallery" className="nav-link" style={{ color: this.props.shopColorHeader || '#000', fontWeight: 'bold' }}>Gallery</a>
                            </li>} */}
                            {this.props.showContact &&
                                <li className="nav-item">
                                    <a href="#contact" className="nav-link" style={{ color: this.props.shopColorHeader || '#000', fontWeight: 500 }}>
                                        Contact
                                </a>
                                </li>}
                            {this.props.postIDs.length > 0 &&
                                <li className="nav-item">
                                    <a href="#gallery" className="nav-link" style={{ color: this.props.shopColorHeader || '#000', fontWeight: 500 }}>
                                        Blog
                                </a>
                                </li>}
                            {this.props.shopCartStatus === "LIVE" && (
                                <li className="nav-item mt-2 ml-1 ">
                                    <button onClick={() => { this.props.showCartModal() }} style={{ backgroundColor: this.props.shopColorBackground || '#000', paddingTop: 0, paddingBottom: 0, paddingLeft: 13, paddingRight: 13, }}>
                                        <i className="fas fa-shopping-cart text-white "  ></i>
                                        {this.props.FullCart.length > 0 && (
                                            <small
                                                style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                    paddingRight: 5,
                                                    paddingLeft: 5,
                                                    color: "white",
                                                    paddingTop: 2,
                                                    paddingBottom: 2
                                                }}
                                            >
                                                {this.props.FullCart.length}
                                            </small>
                                        )}
                                    </button>

                                </li>

                            )}

                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
