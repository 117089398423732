import React, { Component } from "react";
import { Link } from "react-router-dom";
import UpdatePost from "../UpdatePost/UpdatePost";
import firebase from "../../Firebase";
import "./FullPost.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { thisExpression } from "@babel/types";

let idToken_ = ''

class FullPost extends Component {
  constructor(props) {
    super(props);
    this.unsubscribeFirestore = () => {};
    this._isMounted = false;
    this.state = {
      posts: [],
      user: "",
      blogPostID: "",
      author: "",
      title: " ",
      content: "",
      imageUrl: "",
      editPost: null,
      activating: false,
      updateActivityIndicator: false,
      DeleteActivityIndicator: false,
      visibility: false,
      profileImage: "",
      isModalOpen: false,
      hasCustomDomain:false
    };
  }




  componentDidMount = async () => {
    console.log("Full Post js mounted...");
    await this.getImageUrl();
    this._isMounted = true;

    firebase.auth().onIdTokenChanged(function(user) {
      if (user) {
        // User is signed in or token was refreshed.
        global.fetchIdToken();
      }
    });

    global.fetchIdToken = async () => {
      try {
        const idToken = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true);
        if (idToken) {
          global.idToken = idToken;
          idToken_ = idToken
        }
        console.log("Success fetching idToken", global.idToken);
      } catch (err) {
        console.log("ERROR fetching idToken", err);
      }
    };
    console.log("windows props", this.props.location.data);
   
    if (this.props.location.data == null || undefined) {
      await this.parseURLandReturnRelevantID();

      this.unsubscribeFirestore = firebase
        .firestore()
        .collection("Blogs")
        .where("author", "==", this.state.user)
        .onSnapshot(async collection => {
          if (collection.empty) {
            console.log("No matching documents");
            console.log("the user is...",this.state.user );
            return;
          }
          const posts = [];
          collection.forEach(doc => {
            let items = doc.data();
            let fileID = doc.id;
            posts.push({
              author: items.author,
              title: items.title,
              content: items.content,
              imageUrl: items.imageUrl,
              fileId: fileID
            });
             console.log("the user is...",this.state.user," posts are: ", posts);
          });
          this.setState({ posts });

          if (this.state.blogPostID) {
            let postData = this.state.posts.find(
              x => x.fileId === this.state.blogPostID
            );
            this.setState({
              editPost: postData,
              author: postData.author,
              title: postData.title,
              content: postData.content,
              imageUrl: postData.imageUrl
            });
          }
        });
    } else {
      this.setState({
        author: this.props.location.data.author,
        title: this.props.location.data.title,
        content: this.props.location.data.content,
        imageUrl: this.props.location.data.imageUrl,
        blogPostID: this.props.location.data.fileId,
        user: this.props.location.user,
        hasCustomDomain: this.props.location.hasCustomDomain
      });
    }
  };

  
  parseURLandReturnRelevantID = async () => {
    try {
      const fullURL = window.location.href.split("/");
      console.log(fullURL);
      await this.setState({
        blogPostID: fullURL[5],
        user: fullURL[3],
        hasCustomDomain: fullURL[3] == 'admin'?true:false
      });
      console.log("the blogpostID in FullPost js is: ", this.state.blogPostID);
    } catch (err) {
      console.log("issues with parsing URL ", err);
    }
  };
  
  
  
  
  
  
  getImageUrl = async () => {
    try {
      const r = await fetch(
        "https://us-central1-afbase-7ee60.cloudfunctions.net/shop",
        {
          method: "POST",
          //mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user
          })
        }
      );
      const r2 = await r.json();
      console.log("imageUrl", r2);
      await this.setState({ profileImage: r2.data.profile.pictureURL });
    } catch (err) {
      console.log(err, "there seems to be an error");
    }
  };







  deletePostHandler = async () => {
    this.setState({ isModalOpen: false, DeleteActivityIndicator: true });
    try {
      const r = await fetch(
        "https://us-central1-af-shop.cloudfunctions.net/deleteBlog",
        {
          method: "POST",
          // mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            shopName: this.state.user,
            idToken: global.idToken,
            blogID: this.state.blogPostID
          })
        }
      );
      const r4 = await r.json();
      console.log("message from delete endpoint", r4);
      if (r4.msg === "BLOG DELETED") {
        this.props.history.push(this.state.hasCustomDomain == true ? `/admin`  : `/${this.state.user}/admin`)
      }
    } catch (err) {
      console.log(err, "there seems to be an error");
    }
  };
  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  // toggleVisibility = () => {
  //   if (this.state.visibility) {
  //     this.setState({
  //       visibility: false
  //     });
  //   } else {
  //     this.setState({
  //       visbility: true
  //     });
  //   }
  // };

  render() {
    console.log("blogId", this.state.blogPostID);
    console.log("blog posts are:", this.state.posts);
    console.log("EditPost", this.state.editPost);
    console.log("activityIndicator", this.state.DeleteActivityIndicator);

    return (
      <div style={{ backgroundColor: "white" }}>
        <div
          className={
            this.state.isModalOpen
              ? " fullpost-container container--is-open"
              : "fullpost-container"
          }
        >
          <div className="box bg-1">
            <div className="wrap">
              {this.state.updateActivityIndicator ? (
                <div className="authenticate">
                  <img
                    src="https://www.dropbox.com/s/qfu4871umzhlcfo/check_arrow_2.svg?dl=1"
                    alt=""
                  />
                  <svg width="66px" height="66px">
                    <circle
                      className="circle_2"
                      stroke-position="outside"
                      stroke-width="2"
                      fill="none"
                      cx="34"
                      cy="33"
                      r="29"
                      stroke="#1ECD97"
                    ></circle>
                  </svg>
                </div>
              ) : (
                <button className="fullpost-btn second update">
                 {this.state.user && <Link
                    className="fullpost-link"
                    to={{
                      pathname:this.state.hasCustomDomain == true ? `/admin/${this.state.blogPostID}/edit`  : `/${this.state.user}/admin/${this.state.blogPostID}/edit`,
                      //pathname: `/${this.state.user}/admin/${this.state.blogPostID}/edit`,
                      user:this.state.user,
                      hasCustomDomain: this.state.hasCustomDomain
                    }}
                  >
                    Update
                  </Link>}
                </button>
              )}
            </div>

            <div className="wrap">
              {this.state.DeleteActivityIndicator ? (
                <div className="authenticate">
                  <FontAwesomeIcon icon="spinner" className="loadingIcon " />
                </div>
              ) : (
                <div>
                   <button
                    className="fullpost-btn second delete"
                    onClick={this.openModal}
                  >
                    {this.state.user && "Delete"}
                  </button>
                  <div
                    className={
                      this.state.isModalOpen ? "fp-modal modal--is-open" : "fp-modal"
                    }
                  >
                    <button
                      className="Modal-button-close"
                      onClick={this.closeModal}
                    >
                      X
                    </button>

                    <p>Are you sure you want do delete</p>
                    <p>click Okay if yes</p>
                    <button
                      className="button-ok"
                      onClick={this.deletePostHandler}
                    >
                      ok
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="wrap">
              {this.state.DeleteActivityIndicator ? (
                <div className="authenticate">
                  <FontAwesomeIcon icon="spinner" className="loadingIcon " />
                </div>
              ) : (
                <button
                  className="fullpost-btn second delete"
                  onClick={this.deletePostHandler}
                >
                  Delete
                </button>
              )}
            </div> */}
          </div>
          <div className="meta">
            <div className="info">
              <h1 className="blogTitle">{this.state.title}</h1>

              <div className="author">
                <div class="avatar">
                  <div className="avatar-img">
                    <img src={this.state.profileImage} alt="" />
                  </div>
                </div>

                <div className="authorInfo">
                  <div className="authorName">
                    <a href="/">{this.state.author}</a>
                  </div>
                  <div className="authorSub">
                    Nov 7 <span className="median-divider">·</span> 5 min read
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="image"
            style={{
              height: "500px",
              width: "100%",
              backgroundImage: "url(" + `${this.state.imageUrl}` + ")",

              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          ></div>
          <main className="article">
            <p
              className="main-paragraph"
              style={{
                color: "#282828",
                marginTop: "21px",
                fontFamily: "Lora",
                fontSize: "21px",
                letterSpacing: " -0.03px",
                lineHeight: " 1.58"
              }}
            >
              {this.state.content}
            </p>

            <p>
              {" "}
              Happy readings,
              <br />
              <i>
                <a href="https://codepen.com/lucagez">Gez</a>
              </i>
            </p>
          </main>

          {/* <div className="userDeletepromt">
            <h1 className="button-cancel">X</h1>
            <div>
              Are you sure you want to delete this image? This process cannot be
              undone.
            </div>
            <button className="button-ok">ok</button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default FullPost;
