import React, { Component } from 'react'
import data from '../imageData'
import Women from "./images/women.jpg";
import Fashion from "./images/children.jpg"
import Men from "./images/men.jpg"


const prefix = "https://firebasestorage.googleapis.com/v0/b/afbase-7ee60.appspot.com/o/WebsiteCategoryPictures%2F"

class Categories extends Component {


    state = {
        categories: '',
        services: '',
        images: data.images,
        url: ''
    }

    filterCategories = async () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        /* const values1 = values.slice(0, values.length)
        const values2 = values1.join(' ') */
        const newvalues = [].concat(...values)


        console.log(' the categories are: ', keys, ' and the services are: ', values)
        await this.setState({ categories: keys, services: newvalues });
        return

    }




    componentDidMount = async () => {
        if (this.props.servicesOffered) {
            console.log('services offered in shoppers category component are: ', this.props.servicesOffered)
            await this.filterCategories();
            // this.getCategoryURLs();
        }
    }


    render() {
        return (
            <div className="site-section site-blocks-2">

                <div className="container">
                    {this.state.services &&
                        <div className="row justify-content-center" style={{ marginBottom: 50 }}>
                            <div className="col-md-7 site-section-heading text-center pt-4">
                                <h2 style={{ color: 'black' }}>Collections</h2>
                            </div>
                        </div>}

                    {this.state.categories && <div className="row" style={{ textAlign: 'center', justifyContent: 'center', marginTop: -30 }}>
                        {this.state.services.map((service, id) => {
                            //console.log(' the url for the service', service, 'gotten from state is: ', this.state.images[service])
                            return (
                                <div className="col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0 "  >
                                    <a className="block-2-item" href="#shop">
                                        <div key={id}
                                            style={{
                                                width: '100%', height: '350px', backgroundColor: '#000', justifyContent: 'center', alignItems: 'center',
                                                backgroundImage: `url(${this.state.images[service] || prefix + "Beauty%2FAccessories%2Faccessories-accessory-hat-1460838.jpg?alt=media&token=df7bff06-400d-47e6-9d01-dff638da1d38"})`,
                                                backgroundRepeat: 'no-repeat', backgroundSize: '100%',  backgroundPosition: 'center center',marginTop: 30, marginRight: 30, overflow: 'hidden', justifyContent: 'center'
                                            }}>

                                            <div className="text" style={{
                                                position: 'absolute', top: 160, height: 51, textAlign: 'center'
                                            }}>
                                                <h4 className="mx-auto" style={{ fontFamily: "Segoe-UI,Roboto,Helvetica-Neue,Arial,sans-serif", fontWeight: 'normal', textAlign: 'center ' }}>{service}</h4>
                                            </div>

                                        </div>
                                    </a>
                                </div>
                            )
                        })


                        }
                    </div>}
                </div>
            </div>

        );
    }
}

export default Categories;





