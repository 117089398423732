import React, { Component } from 'react'
import pic3 from '../../theme-samples/sample-images/cake-images/cake-3669245_960_720.jpg'
import { relative } from 'path'


//importing category image icons
/* import Electronics from "../../images/categories/Electronics.png"
import Home from "../../images/categories/Home.png"
import Fashion from "../../images/categories/Fashion.png"
import Cars from "../../images/categories/Cars.png"
import Food from "../../images/categories/Food.png"
import Beauty from "../../images/categories/Beauty.png"
import Professionals from "../../images/categories/Professionals.png"
import Repair_Refill from "../../images/categories/Repair_Refill.png"
import Handyman from "../../images/categories/Handyman.png"
import QuickJobs from "../../images/categories/QuickJobs.png"
import Health from "../../images/categories/Health.png"
import Art from "../../images/categories/Art.png"
import Housing from "../../images/categories/Housing.png"
import Travel from "../../images/categories/Travel.png"
import Commercial from "../../images/categories/Commercial.png" */

export default class About extends Component {
    state = {
        services: '',
        categories: '',
        image1: '',
        image2: '',
        URLs: [],
        activeCategories: []
    }






    filterCategories = async () => {

        const keys = Object.keys(this.props.servicesOffered)
        const values = Object.values(this.props.servicesOffered)
        // const values1 = values.slice(0,values.length)
        // const values2 = values1.join(' ')
        const newvalues = [].concat(...values)

        console.log(' the categories are: ', keys, ' and the services are: ', values)
        await this.setState({ categories: keys, services: newvalues });
        return
    }

    activeCategories = (services) => {
        const validCategories = []
        this.state.categories.forEach((category) => {
            if (services[category].length > 0) {
                validCategories.push(category)
            }
        })
        console.log('All categories are: ', services)
        console.log('the valid categories are: ', validCategories)
        this.setState({ activeCategories: validCategories })
    }


    isActiveCategory = (category) => {
        let itemValue = false
        this.state.activeCategories.forEach(item => {
            if (category === item) {
                itemValue = true
            }
        })
        return itemValue
    }


    componentWillMount = async () => {

        if (this.props.servicesOffered) {

            await this.filterCategories();
            this.activeCategories(this.props.servicesOffered)

        }

    }



    render() {

        return (

            <div style={{ position: "relative" }}>
                {/*  <div className="mx-auto text-center white  "  >
                    <h2 className="text-center mb-4" style={{ color: '#efb31f' }}>What We Do</h2>
                    <hr style={{ align: "center", width: 160, margin: "auto", color: '#efb31f' }} />
                    <p style={{ color: 'white', marginTop: 50 }}>{this.props.descriptionShop || 'Business description'}.</p>
                </div> */}



                <div className="text-center" style={{
                    marginTop: 50,
                    backgroundImage: `url('${pic3}')`,
                    height: 600,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    padding: 100,
                    backgroundAttachment: 'fixed'


                    //backgroundColor: `red`, 


                }}>
                    <div style={{ backgroundColor: '#f970b9', height: '100%', position: "absolute", top: 0, left: 0, width: '100%', opacity: 0.5 }}>

                    </div>
                    <div className="container" style={{ zIndex: 99, position: 'relative', height: '100%', width: '100%', overflow: 'scroll' }}>

                        <h2 className="text-center p-4" style={{ color: '#fff', fontSize: 25, fontWeight: 'bold' }}>About Us</h2>
                        <hr className="" style={{
                            textAlign: 'center', width: '10%', maxWidth: '100%',
                            margin: '0 auto'
                        }} />
                        <p className="text-white">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fuga eaque rem aspernatur ipsam eaque rem aspernatur ipsam. Fuga eaque rem aspernatur ipsam. Fuga eaque rem aspernatur ipsam. Omnis laboriosam ipsam architecto harum maiores voluptas minus distinctio,Fuga eaque rem aspernatur ipsam.
                    ipsum dolor sit amet consectetur adipisicing elit. Fuga eaque rem aspernatur ipsam eaque rem aspernatur ipsam. Fuga eaque rem aspernatur ipsam. Fuga eaque rem aspernatur ipsam. Omnis laboriosam ipsam architecto harum maiores voluptas minus distinctio,Fuga eaque rem aspernatur ipsam. Fuga eaque rem aspernatur ipsam.</p>
                        {this.state.categories && <div className="row " style={{ alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                            {this.state.activeCategories.map((category, id) => {
                                return (
                                    <div className="mx-1 row" key={id} style={{ backgroundColor: '#222', borderColor: 'white', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className="m-3 col" style={{ width: '5rem', justifyContent: 'center', alignItems: 'center' }}>
                                            {/* {(this.props.servicesOffered[category].length > 0) && this.isActiveCategory(category)&& <img className="card-img-top img-fluid" src={require('../../images/categories/' + `${category}` + '.png')} />} */}
                                            {<img className="card-img-top img-fluid" src={require('../../../images/categories/' + `${category}` + '.png' || '../../images/categories/Commercial.png')} />}

                                        </div>
                                        <div className='col m-3' style={{ width: '18rem', backgroundColor: '#222', borderColor: 'white', alignItems: 'center' }}>
                                            {this.props.servicesOffered[category].map((service, id) => {
                                                return (
                                                    <p style={{ lineHeight: 1, fontSize: 15 }} key={id}>{service + '\n'} </p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        }
                    </div>

                </div>







            </div>
        )
    }
}