import React, { Component } from 'react'
import wkLogo from "../../images/logos/home_logo.png"


class Loader extends Component {
     
    render() {
        return (
            <div className="container" style={{ alignItems:'center', textAlign:'center', marginTop:'200px', height:'100%', width:'100%'}}>
                <img src={wkLogo} style={{ width:'300px' }} alt="logo" />
                <p style={{textAlign:'center'}}>Loading...</p>
            </div>
        );
    }
}

export default Loader;